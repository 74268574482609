import React from 'react'
import { FormControl } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import 'ace-builds'
import 'ace-builds/webpack-resolver'
import FormActions from 'forms/FormActions'
import FormContainer from 'components/FormContainer'
import CodePreview from 'components/CodePreview/CodePreview'
import { BlockHtmlEntity } from '@jackfruit/common'

interface Props {
  entity: BlockHtmlEntity
  isLoading: boolean
  onSubmit: (data: BlockHtmlEntity) => void
}

const BlockHtmlForm: React.FC<Props> = ({ entity, onSubmit, isLoading }) => {
  const defaultValues = {
    ...entity,
  }

  const { handleSubmit, control } = useForm<any>({
    defaultValues,
  })

  return (
    <>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Controller as={CodePreview as any} name="html" control={control} />
        </FormControl>

        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </>
  )
}

export default BlockHtmlForm
