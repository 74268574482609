import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Select,
} from "@chakra-ui/react"
import { PageSeoEntity, SettingsEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import "components/ReactDatePickerCustom.css"
import FormActions from "forms/FormActions"
import { useEntityList } from "hooks/useEntityList"
import { RouteParams } from "navigation/RouteParams"
import React, { useCallback, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useParams } from "react-router"
import slugify from "slugify"
import AssetInput from "forms/inputs/AssetInput"
import { formatDateForApi } from "utils/helpers"
import { useCreateEntity } from "hooks/useCreateEntity"
import { usePatchEntity } from "hooks/usePatchEntity"

interface Props {
  entity?: any
  pageSeoEntity?: PageSeoEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppPageForm: React.FC<Props> = ({
  entity,
  pageSeoEntity,
  onSubmit,
  isLoading,
}) => {
  const { pageId, appId } = useParams<RouteParams>()

  const { mutate: createPageSeo, status: createStatus } =
    useCreateEntity<PageSeoEntity>("page-seo", {
      showNotification: true,
      onSuccess: () => {
        //do nothing
      },
    })
  const { mutate: patchPageSeo, status: patchStatus } =
    usePatchEntity<PageSeoEntity>("page-seo")

  const isCreating = createStatus === "loading"
  const isPatching = patchStatus === "loading"

  let defaultValues = { ...entity, ...pageSeoEntity }

  if (!pageId) {
    // Default values on creation
    defaultValues = {
      ...defaultValues,
      status: "draft",
      publishedAt: formatDateForApi(new Date()),
    }
  }

  const methods = useForm({ defaultValues })
  const { handleSubmit, register, errors, setValue, getValues, control } =
    methods

  const { data: settings, isLoading: isLoadingSettings } =
    useEntityList<SettingsEntity>("settings", {
      query: {
        appId,
      },
    })

  const [defaultCountryCode, setDefaultCountryCode] = useState<string>()

  useEffect(() => {
    if (!isLoadingSettings) {
      setDefaultCountryCode(settings?.data?.[0]?.defaultLocale ?? "US")
    }
  }, [isLoadingSettings, defaultCountryCode, settings])

  const getSlug = (value: string) =>
    slugify(value, {
      lower: true,
      strict: true,
    })

  const onGenerateSlug = useCallback(() => {
    setValue("slug", getSlug(getValues("name")))
  }, [getValues, setValue])

  const enhancedSubmit = (data: any) => {
    const { name, status, slug, publishedAt, ...rest } = data

    const pageGeneralData = {
      name,
      status,
      slug,
      publishedAt,
    }

    const pageFulfillmentData: PageSeoEntity = {
      pageId,
      ...rest,
    }

    if (!pageSeoEntity) {
      createPageSeo(pageFulfillmentData)
    } else {
      patchPageSeo({ ...pageFulfillmentData, id: pageSeoEntity.id })
    }

    onSubmit(pageGeneralData)
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
        <FormSectionHeader>General</FormSectionHeader>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <InputGroup>
            <Input ref={register} name="name" type="text" />
          </InputGroup>
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Slug</FormLabel>
          <InputGroup>
            <InputLeftAddon children="/" />
            <Input ref={register} name="slug" type="text" />
            <InputRightElement width="6rem">
              <Button
                h="1.75rem"
                size="sm"
                colorScheme="primary"
                onClick={onGenerateSlug}
              >
                Generate
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Status</FormLabel>
          <FormHelperText pb={4}>
            Draft pages will be purposefully omitted from Navigation list
            generation.
          </FormHelperText>
          <Select ref={register} name="status" defaultValue="draft">
            <option value="draft">Draft</option>
            <option value="active">Active</option>
          </Select>
        </FormControl>

        <FormControl isInvalid={!!errors.openGraphTitle}>
          <FormLabel>SEO Title</FormLabel>
          <InputGroup>
            <Input ref={register()} name="openGraphTitle" type="text" />
          </InputGroup>
          <FormErrorMessage>
            {errors.openGraphTitle && errors.openGraphTitle.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.openGraphDescription}>
          <FormLabel>SEO description</FormLabel>
          <InputGroup>
            <Input ref={register()} name="openGraphDescription" type="text" />
          </InputGroup>
          <FormErrorMessage>
            {errors.openGraphDescription && errors.openGraphDescription.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>SEO Feature Image</FormLabel>
          <FormHelperText pb={4}>
            <Box>
              Please ensure image is minimum 400x400 pixels. Ideal resolution is
              16:9 or
            </Box>
            <Box>
              1200x675 pixels for maximum social media and SEO interoperability.
            </Box>
          </FormHelperText>
          <Controller
            as={AssetInput as any}
            name="openGraphFeatureImageId"
            control={control}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Published At Date</FormLabel>
          <FormHelperText pb={4}>
            Defaults to the created at date
          </FormHelperText>
          <Controller
            name="publishedAt"
            control={control}
            render={({ onChange, value }) => {
              return (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={new Date(value)}
                  onChange={newDate => {
                    onChange(formatDateForApi(newDate as Date))
                  }}
                  isClearable={true}
                  placeholderText="Select a date"
                />
              )
            }}
          />
        </FormControl>

        <FormActions
          isLoading={isLoading || isCreating || isPatching}
          entity={entity}
        />
      </FormContainer>
    </FormProvider>
  )
}

export default AppPageForm
