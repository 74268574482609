import { Box, BoxProps, Flex, useMediaQuery } from "@chakra-ui/react"
import { nanoid } from "@reduxjs/toolkit"
import React, { useMemo } from "react"
import Indicator from "./Indicator"

interface indicatorStyleProps extends BoxProps {
  borderFocusColor: string
}

interface WidthProps {
  md?: string | undefined
  lg?: string | undefined
  xl?: string | undefined
}

interface Props {
  images: {
    src: string
    alt?: string
    showthumbnails: "0" | "1"
    srcthumbnail: string
  }[]
  width: number
  height: number
  delay?: number
  carouselStyle?: BoxProps
  circleStyle?: indicatorStyleProps
  thumbnailStyle?: indicatorStyleProps
  thumbnailIndicatorSize?: WidthProps
  mobileThumbnailType: string
}

const Carousel: React.FC<Props> = props => {
  const {
    images,
    width,
    height,
    carouselStyle = {},
    circleStyle = { borderColor: "white", borderFocusColor: "#2AAAE1" },
    thumbnailStyle = {
      borderColor: "#2AAAE1",
      borderFocusColor: "#1C95C9",
    },
    mobileThumbnailType,
  } = props

  const [isMobile] = useMediaQuery("(max-width: 768px)")

  const displayableImages = images.filter(
    item => Number(item.showthumbnails) === 1
  )

  const componentUUID = useMemo(nanoid, [])

  return (
    <Flex direction="column" p={4}>
      <Box
        aria-label="carousel-container"
        zIndex={10}
        maxWidth={width}
        width={width}
        height={height}
        backgroundColor="#fff"
        maxHeight={height}
        display="flex"
        align="center"
        overflow="hidden"
        alignItems={"center"}
        justifyContent={"center"}
        {...carouselStyle}
        position="relative"
      >
        <Box
          id={`carousel-image-${componentUUID}`}
          key={`carousel-image-${componentUUID}`}
          as="img"
          src={displayableImages[0].src}
          alt={"alt"}
          w="100%"
          h="100%"
          objectFit="cover"
          objectPosition="center"
        />
      </Box>

      <Flex
        mt={4}
        overflowX="scroll"
        aria-label="indicator-wrapper"
        p={0}
        justifyContent={isMobile ? "center" : "flex-start"}
      >
        {displayableImages.map((image, index) => (
          <Indicator
            isMobile={isMobile}
            image={image}
            currentIndex={0}
            index={index}
            thumbnailStyle={thumbnailStyle}
            circleStyle={circleStyle}
            mobileThumbnailType={mobileThumbnailType}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default Carousel
