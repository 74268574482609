import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import { PageEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"

interface Props {
  entity: PageEntity
  isLoading: boolean
  onSubmit: (data: any) => void
}

const AppPageExperimentForm: React.FC<Props> = ({
  entity,
  isLoading,
  onSubmit,
}) => {
  const methods = useForm({ defaultValues: { ...entity } })
  const { handleSubmit, register, errors } = methods

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Experiments</FormSectionHeader>
      <FormControl>
        <FormLabel>GrowthBook Experiment ID</FormLabel>
        <InputGroup>
          <Input ref={register} name="lambdaAbExperimentId" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.lambdaAbExperimentId && errors.lambdaAbExperimentId.message}
        </FormErrorMessage>
      </FormControl>
      <FormActions isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppPageExperimentForm
