import React, { useCallback, useContext } from "react"
import {
  Badge,
  Box,
  Button,
  Flex,
  Text,
  Image,
  Heading,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react"
import { BiCustomize } from "react-icons/bi"
import { CpContext, CpContextInterface, snippetsWidth } from "./CpContext"
import { useEntityList } from "hooks/useEntityList"
import { SnippetEntity } from "screens/snippets/all/SnippetsListScreen"
import { SnippetCategoryEntity } from "screens/snippets/categories/SnippetCategoriesListScreen"

interface Props {
  onChange: (value: string, type: string) => void
}

const CpWidgets: React.FC<Props> = ({ onChange }) => {
  const { htmlValue } = useContext<CpContextInterface>(CpContext)

  const { data: categories } = useEntityList<SnippetCategoryEntity>(
    "snippet-categories",
    {
      query: {
        $limit: 1000,
        $sort: {
          id: -1,
        },
        $eager: "[snippets.[asset]]",
      },
    }
  )

  const amendContent = useCallback(
    (content: string, type: string) => {
      const baseContent = htmlValue
      const editedContent = baseContent ? baseContent + "\n" + content : content
      onChange(editedContent, type)
    },
    [htmlValue, onChange]
  )

  const getContent = useCallback(
    (snippet: SnippetEntity) => {
      amendContent(snippet.html, "html")
      amendContent(snippet.css, "css")
    },
    [amendContent]
  )

  return (
    <Box
      position="absolute"
      w={`${snippetsWidth}px`}
      h="100%"
      zIndex={1}
      bg="#fbfbfb"
      top={0}
      boxShadow="base"
      overflow="auto"
      left={0}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        color="primary.500"
        my={5}
      >
        <BiCustomize size={18} />
        <Heading as="h4" size="md" textTransform="uppercase" marginLeft={2}>
          Snippets
        </Heading>
      </Flex>

      <Accordion allowMultiple>
        {categories?.data.map(category => (
          <AccordionItem key={category.id}>
            <AccordionButton h="50px" fontWeight="bold">
              <Flex flex="1" alignItems="center">
                <Badge>{Object.keys(category.snippets!).length}</Badge>
                <Text marginLeft={3} color="primary.500">
                  {category.name}
                </Text>
              </Flex>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel paddingTop={2} paddingBottom={4}>
              {category.snippets!.map(snippet => (
                <Box w="100%" px={0} key={snippet.id}>
                  <Button
                    variant="ghost"
                    w="100%"
                    h="100%"
                    py={3}
                    flexDirection="column"
                    alignItems="flex-start"
                    onClick={() => getContent(snippet)}
                  >
                    <Heading
                      as="h4"
                      size="xs"
                      textTransform="uppercase"
                      mb={3}
                      color="gray.500"
                    >
                      {snippet.name}
                    </Heading>
                    <Image
                      maxW="100%"
                      src={snippet.asset!.path}
                      boxShadow="lg"
                      rounded="md"
                    />
                  </Button>
                </Box>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default React.memo(CpWidgets)
