import { Badge, Button, Icon } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { FiPlusCircle } from "react-icons/fi"
import Body from "components/Body"
import Table, { ColumnDefinition } from "components/Table"
import TableRowActions from "components/TableRowActions"
import TableRowId from "components/TableRowId"
import { useRouteMatch, Link } from "react-router-dom"
import Page from "components/Page"
import { AssetEntity, Entity } from "@jackfruit/common"
import { ObjectValue } from "components/CodePreview/CodePreview"

export interface SnippetEntity extends Entity {
  snippetCategoryId: number
  name: string
  html: string
  css: string
  assetId: number
  contentArea?: ObjectValue
  asset?: AssetEntity
  isDefault: boolean
}

const query = {
  $limit: 1000,
  $eager: "[snippetCategory]",
}

const SnippetsListScreen: React.FC = () => {
  return (
    <Page title="All Snippets">
      <Body title="All Snippets" headerActions={<Actions />} padding={0}>
        <Table resource="snippets" columns={columns} query={query} />
      </Body>
    </Page>
  )
}

export default SnippetsListScreen

const columns: ColumnDefinition[] = [
  {
    header: "Id",
    accessor: "id",
    render: snippet => <TableRowId entity={snippet} />,
  },
  { header: "Name", accessor: "name" },
  {
    header: "Category",
    render: snippet => (
      <Badge colorScheme="gray">{snippet.snippetCategory.name}</Badge>
    ),
  },
  {
    header: "Is Default",
    accessor: "isDefault",
  },
  {
    header: "Updated at",
    render: snippet => format(new Date(snippet.updatedAt), "yyyy/MM/dd"),
  },
  {
    header: "Created at",
    render: snippet => format(new Date(snippet.createdAt), "yyyy/MM/dd"),
  },
  {
    header: "",
    accessor: "actions",
    render: snippet => (
      <TableRowActions
        resource="snippets"
        entity={snippet}
        canView={false}
        canEdit={true}
        canDelete={true}
      />
    ),
  },
]

const Actions = () => {
  const match = useRouteMatch()
  return (
    <Link to={`${match.url}/new`}>
      <Button colorScheme="primary" leftIcon={<Icon as={FiPlusCircle} />}>
        New Snippet
      </Button>
    </Link>
  )
}
