import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "services/api"
import { AsyncState } from "./interfaces"

export interface ConfigurationState extends AsyncState {
  configuration: {
    deployment: {
      gitReference: string
      liveDeploymentTestTags: string[]
      testTags: string
    }
  }
}

const loadConfiguration = createAsyncThunk("configuration/load", async () => {
  return await api.find("configuration")
})

const initialState: ConfigurationState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  configuration: {
    deployment: {
      gitReference: "",
      liveDeploymentTestTags: [],
      testTags: "",
    },
  },
}

const configuration = createSlice({
  name: "configuration",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadConfiguration.pending, state => {
      state.isLoading = true
      state.isError = false
      state.isSuccess = false
    })
    builder.addCase(loadConfiguration.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = true
      state.configuration = action.payload
    })
    builder.addCase(loadConfiguration.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.isSuccess = false
      state.errorMessage = action.error.message
    })
  },
})

export { configuration, loadConfiguration }
