import { Stack, VStack } from "@chakra-ui/react"
import React from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import AppBrandingCRUDScreen from "../AppBrandingCRUDScreen"
import AppBrandingDistributionAppOrderSummaryForm from "../forms/AppBrandingDistributionAppOrderSummaryForm"
import AppBrandingDistributionAppStoreURLsForm from "../forms/AppBrandingDistributionAppStoreURLsForm"
import AppBrandingDistributionAppVisibilityForm from "../forms/AppBrandingDistributionAppVisibilityForm"
import AppBrandingGeneralForm from "../forms/AppBrandingGeneralForm"
import AppBrandingGeneralImageryForm from "../forms/AppBrandingGeneralImageryForm"
import AppBrandingGeneralLegalsForm from "../forms/AppBrandingGeneralLegalsForm"
import AppBrandingLocalizationAdditionalLocaleUrlsForm from "../forms/AppBrandingLocalizationAdditionalLocaleUrlsForm"
import AppBrandingLocalizationForm from "../forms/AppBrandingLocalizationForm"
import AppBrandingSeoAccountForm from "../forms/AppBrandingSeoAccountForm"
import AppBrandingSeoForm from "../forms/AppBrandingSeoForm"
import AppBrandingSeoStoresForm from "../forms/AppBrandingSeoStoresForm"
import AppBrandingSocialMediaForm from "../forms/AppBrandingSocialMediaForm"
import AppBrandingThemeForm from "../forms/AppBrandingThemeForm"

interface Props {}

const AppBrandingNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <Stack direction="row" alignItems="start" spacing={6}>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/general`} />
        <Route path={`${match.path}/general`}>
          <VStack>
            <AppBrandingCRUDScreen form={AppBrandingGeneralForm} />
            <AppBrandingCRUDScreen form={AppBrandingGeneralImageryForm} />
            <AppBrandingCRUDScreen form={AppBrandingGeneralLegalsForm} />
          </VStack>
        </Route>
        <Route path={`${match.path}/theme`}>
          <AppBrandingCRUDScreen form={AppBrandingThemeForm} />
        </Route>
        <Route path={`${match.path}/distribution`}>
          <VStack>
            <AppBrandingCRUDScreen
              form={AppBrandingDistributionAppVisibilityForm}
            />
            <AppBrandingCRUDScreen
              form={AppBrandingDistributionAppOrderSummaryForm}
            />
            <AppBrandingCRUDScreen
              form={AppBrandingDistributionAppStoreURLsForm}
            />
          </VStack>
        </Route>
        <Route path={`${match.path}/seo`}>
          <VStack>
            <AppBrandingCRUDScreen form={AppBrandingSeoForm} />
            <AppBrandingCRUDScreen form={AppBrandingSeoAccountForm} />
            <AppBrandingCRUDScreen form={AppBrandingSeoStoresForm} />
          </VStack>
        </Route>
        <Route path={`${match.path}/social-medias`}>
          <AppBrandingCRUDScreen form={AppBrandingSocialMediaForm} />
        </Route>
        <Route path={`${match.path}/localization`}>
          <VStack>
            <AppBrandingCRUDScreen form={AppBrandingLocalizationForm} />
            <AppBrandingCRUDScreen
              form={AppBrandingLocalizationAdditionalLocaleUrlsForm}
            />
          </VStack>
        </Route>
      </Switch>
    </Stack>
  )
}

export default AppBrandingNavigation
