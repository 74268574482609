import { Button, Flex } from "@chakra-ui/react"
import { EntityId, NavigationMegaMenuCellEntity } from "@jackfruit/common"
import { useCreateEntity } from "hooks/useCreateEntity"
import React from "react"
import { FiPlus } from "react-icons/fi"
import { useQueryClient } from "react-query"

interface Props {
  megaMenuColumnId: EntityId
}

const MegaMenuAddCellButton: React.FC<Props> = ({ megaMenuColumnId }) => {
  const queryClient = useQueryClient()
  const { mutateAsync: createMegaMenuCell } = useCreateEntity<
    Partial<NavigationMegaMenuCellEntity>
  >("navigation-megamenu-cells")

  const handleCreateMegaMenuCell = async () => {
    await createMegaMenuCell({
      navigationMegamenuColumnId: megaMenuColumnId,
      title: "new cell",
      type: "navigation",
    })

    queryClient.invalidateQueries(["navigation-megamenus"])
  }

  return (
    <Flex flex={1}>
      <Button
        w="full"
        colorScheme="gray"
        justifyContent="space-between"
        rightIcon={<FiPlus />}
        onClick={handleCreateMegaMenuCell}
      >
        Add Cell
      </Button>
    </Flex>
  )
}

export default MegaMenuAddCellButton
