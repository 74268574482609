import React, { useContext } from "react"
import { BiCustomize, BiFullscreen } from "react-icons/bi"
import { BsMoon } from "react-icons/bs"
import { IoMdClose } from "react-icons/io"
import { HStack, Tooltip, Button, IconButton } from "@chakra-ui/react"
import { CpContext, CpContextInterface } from "./CpContext"

interface Props {}

const CpButtons: React.FC<Props> = () => {
  const {
    theme,
    modalState,
    onToggleMode,
    darkMode,
    snippetsMode,
    enableSnippets,
  } = useContext<CpContextInterface>(CpContext)
  const { color } = theme

  return (
    <HStack spacing={1}>
      {modalState && (
        <>
          {enableSnippets && (
            <Tooltip label="Toggle snippets area">
              <Button
                aria-label="snippets-area"
                leftIcon={<BiCustomize />}
                size="sm"
                rounded="full"
                variant="ghost"
                colorScheme="primary"
                color={!modalState ? color : "#fff"}
                onClick={() => onToggleMode("snippets")}
                bg={snippetsMode ? "secondary.300" : "inherit"}
              >
                Snippets
              </Button>
            </Tooltip>
          )}

          <Tooltip label="Toggle dark mode">
            <IconButton
              aria-label="dark-mode"
              icon={<BsMoon size={22} />}
              size="sm"
              rounded="full"
              variant="ghost"
              colorScheme="primary"
              color={!modalState ? color : "#fff"}
              onClick={() => onToggleMode("dark")}
              bg={darkMode ? "secondary.300" : "inherit"}
            />
          </Tooltip>

          <Tooltip label="Close focus mode">
            <IconButton
              aria-label="close-focus-mode"
              icon={<IoMdClose size={28} />}
              size="sm"
              rounded="full"
              variant="ghost"
              colorScheme="primary"
              color="#fff"
              onClick={() => onToggleMode("fullscreen")}
            ></IconButton>
          </Tooltip>
        </>
      )}

      {!modalState && (
        <Tooltip label="Open focus mode">
          <IconButton
            aria-label="open-focus-mode"
            icon={<BiFullscreen />}
            size={"md"}
            rounded="full"
            variant="ghost"
            colorScheme="primary"
            color="#fff"
            my={1}
            onClick={() => onToggleMode("fullscreen")}
          />
        </Tooltip>
      )}
    </HStack>
  )
}

export default React.memo(CpButtons)
