import React from 'react'
import Page from 'components/Page'
import Body from 'components/Body'
import { format } from 'date-fns'
import Table, { ColumnDefinition } from 'components/Table'
import TableRowId from 'components/TableRowId'
import TableRowActions from 'components/TableRowActions'
import AssetUploader from 'components/AssetUploader'
import { Box, Link, Text } from '@chakra-ui/react'
import FilePreview from 'components/FilePreview'
import { AssetEntity } from '@jackfruit/common'

interface Props {}

const columns: ColumnDefinition[] = [
  {
    header: 'Id',
    render: (asset: any) => <TableRowId entity={asset} />,
  },
  {
    header: 'Path',
    render: (asset: AssetEntity) => (
      <Link href={asset.path} isExternal>
        <FilePreview size={70} path={asset.path} mimeType={asset.mimeType} />
      </Link>
    ),
  },
  { header: 'File Name', accessor: 'filename' },

  { header: 'Mime Type', accessor: 'mimeType' },
  {
    header: 'Size',
    render: (asset: AssetEntity) => (
      <Text>{(asset.size / 1000).toFixed(2)} Kb</Text>
    ),
  },
  {
    header: 'Updated',
    render: (asset: any) => format(new Date(asset.createdAt), 'yyyy/MM/dd'),
  },
  {
    header: 'Created',
    render: (asset: any) => format(new Date(asset.updatedAt), 'yyyy/MM/dd'),
  },
  {
    header: '',
    accessor: 'actions',
    render: (asset) => (
      <TableRowActions
        resource="assets"
        entity={asset}
        canView={false}
        canEdit={true}
        canDelete={true}
      />
    ),
  },
]

const AssetListScreen: React.FC<Props> = () => {
  const onStart = () => {}

  const onComplete = (data: any) => {}

  return (
    <Page title="Files">
      <Body fullWidth={true} padding={0} title="All Assets">
        <Box mb={3} p={4}>
          <AssetUploader onStart={onStart} onComplete={onComplete} />
        </Box>
        <Table
          resource="assets"
          columns={columns}
          sortByCol="id"
          orderBy="asc"
        />
      </Body>
    </Page>
  )
}

export default AssetListScreen
