import {
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react"
import { EntityId, LambdaAbVariantEntity } from "@jackfruit/common"
import DataTable, { ColumnDefinition } from "components/Table"
import TableEditableField from "components/TableEditableField"
import TableRowActions from "components/TableRowActions"
import AutoSaveInputWrapper from "forms/inputs/AutoSaveInputWrapper"
import React from "react"

const IntValue = ({ value }: { value?: number }) => {
  return <Text fontSize="xs">{Number(value).toFixed(0)}</Text>
}

const columns: ColumnDefinition[] = [
  {
    header: "Name",
    render: (entity: LambdaAbVariantEntity) => (
      <TableEditableField
        resource="lambda-ab-variants"
        entity={entity}
        field="name"
      />
    ),
  },
  {
    header: "Bucket Name",
    render: (entity: LambdaAbVariantEntity) => (
      <TableEditableField
        resource="lambda-ab-variants"
        entity={entity}
        field="bucket"
      />
    ),
  },
  {
    header: "Percent",
    render: (entity: LambdaAbVariantEntity) => (
      <Flex width="150px">
        <AutoSaveInputWrapper
          entityType="lambda-ab-variants"
          entity={entity}
          fieldName="percent"
        >
          <IntValue />
          <Flex w={3} />
          <Slider aria-label="percentage-slider">
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </AutoSaveInputWrapper>
      </Flex>
    ),
  },
  {
    header: "",
    accessor: "actions",
    render: (entity: LambdaAbVariantEntity) => (
      <TableRowActions
        resource="lambda-ab-variants"
        entity={entity}
        canView={false}
        canEdit={false}
        canDelete={true}
      />
    ),
  },
]

interface Props {
  settingId: EntityId
}

const LambdaABTestConfigurationTable: React.FC<Props> = ({ settingId }) => {
  const query = {
    settingId,
  }

  return (
    <DataTable
      resource="lambda-ab-variants"
      columns={columns}
      query={query}
      showPagination={false}
    />
  )
}

export default LambdaABTestConfigurationTable
