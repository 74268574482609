import React from "react"
import { Badge } from "@chakra-ui/react"
import { DeploymentStatus } from "@jackfruit/common"

interface Props {
  status: DeploymentStatus
}

const DeploymentStatusBadge: React.FC<Props> = ({ status }) => {
  let colorScheme = "gray"
  switch (status) {
    case "pending":
      colorScheme = "gray"
      break
    case "deploying":
      colorScheme = "blue"
      break
    case "complete":
      colorScheme = "green"
      break
    case "failed":
      colorScheme = "red"
      break
    case "canceled":
      colorScheme = "orange"
      break
  }
  return <Badge colorScheme={colorScheme}>{status}</Badge>
}

export default DeploymentStatusBadge
