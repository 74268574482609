import { Box, Stack } from "@chakra-ui/react"
import React from "react"
import PanelHeader from "./PanelHeader"

const defaultPadding = 4

interface Props {
  canGoBack?: boolean
  goBackPath?: string
  variant?: string
  isLoading?: boolean
  padding?: number
  sidebar?: React.ReactNode
  headerActions?: React.ReactNode
  title?: string
  fullWidth?: boolean
}

const Body: React.FC<Props> = ({
  canGoBack = false,
  goBackPath,
  variant,
  children,
  isLoading,
  padding = defaultPadding,
  sidebar,
  title = "",
  headerActions,
  fullWidth = false,
}) => {
  if (isLoading) {
    return (
      <Box
        minW="2xl"
        display="inline-block"
        p={padding}
        borderRadius="md"
        borderWidth={1}
        bg={variant === "transparent" ? "transparent" : "white"}
      >
        Loading...
      </Box>
    )
  }
  return (
    <Stack
      direction={fullWidth ? "column" : "row"}
      spacing={6}
      width={fullWidth ? "full" : "auto"}
    >
      <Box
        minW="2xl"
        display="inline-block"
        p={variant === "transparent" ? 0 : padding}
        bg={variant === "transparent" ? "transparent" : "white"}
        borderRadius="md"
        borderWidth={variant === "transparent" ? 0 : 1}
      >
        {title && (
          <Box padding={padding === 0 ? defaultPadding : 0} pb={0}>
            <PanelHeader
              canGoBack={canGoBack}
              goBackPath={goBackPath}
              title={title}
              actions={headerActions}
            />
          </Box>
        )}
        {children}
      </Box>
      {sidebar}
    </Stack>
  )
}

export default Body
