import LayoutPage from "layouts/LayoutPage"
import React from "react"
import { Route, useRouteMatch, Switch } from "react-router"
import DeploymentListScreen from "./DeploymentListScreen"

interface Props {}

const DeploymentNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <LayoutPage>
      <Switch>
        <Route path={`${match.path}/`}>
          <DeploymentListScreen />
        </Route>
      </Switch>
    </LayoutPage>
  )
}

export default DeploymentNavigation
