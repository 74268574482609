import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import UserCreateScreen from "screens/users/UserCreateScreen"
import UserEditScreen from "screens/users/UserEditScreen"
import UserDetailsScreen from "screens/users/UserDetailsScreen"
import UserListScreen from "screens/users/UserListScreen"
import LayoutPage from "layouts/LayoutPage"

interface Props {}

const UserNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <LayoutPage>
      <Switch>
        <Route path={`${match.path}/new`}>
          <UserCreateScreen />
        </Route>
        <Route path={`${match.path}/:userId/edit`}>
          <UserEditScreen />
        </Route>
        <Route path={`${match.path}/:userId`}>
          <UserDetailsScreen />
        </Route>
        <Route path={`${match.path}/`}>
          <UserListScreen />
        </Route>
      </Switch>
    </LayoutPage>
  )
}

export default UserNavigation
