import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react"
import { PageEntity } from "@jackfruit/common"
import React, { useRef } from "react"
import { FiChevronDown } from "react-icons/fi"
import { generatePathForPage } from "utils/helpers"
import AutoCompleteInput, {
  AutoCompleteRenderInput,
  AutoCompleteRenderItem,
} from "./AutoCompleteInput"

const PageName: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box maxW="500px" borderWidth={0} textAlign="left">
      <Text
        color="gray.500"
        fontWeight="semibold"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        borderWidth={0}
      >
        {children}
      </Text>
    </Box>
  )
}

const PagePath: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box maxW="500px" borderWidth={0} textAlign="left">
      <Text
        color="gray.400"
        fontWeight="normal"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        borderWidth={0}
      >
        {children}
      </Text>
    </Box>
  )
}

interface PageInputProps {
  search: string
  setSearchValue: (value: string) => void
  isDirty: boolean
  isInvalid: boolean
  selected?: PageEntity
  toggle: () => void
}

const PageInput: React.FC<PageInputProps> = ({
  isDirty,
  search,
  setSearchValue,
  selected,
  toggle,
  isInvalid = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const shouldShowSelected = !isDirty && selected
  const value = shouldShowSelected ? selected.name : search
  const note = shouldShowSelected
    ? `${selected.slug}`
    : "Enter a name for a page..."

  return (
    <Flex
      borderWidth={1}
      borderColor={isInvalid ? "red.500" : "gray.200"}
      direction="row"
      borderRadius="md"
      alignItems="center"
      py={2}
      pl={4}
      cursor="pointer"
      onClick={() => {
        inputRef.current?.select()
      }}
    >
      <VStack flex={1} alignItems="flex-start" spacing={0}>
        <Input
          ref={inputRef}
          variant="unstyled"
          placeholder="Search"
          borderWidth={0}
          fontWeight="semibold"
          value={value}
          onChange={e => setSearchValue(e.target.value)}
        />
        <PagePath>{note}</PagePath>
      </VStack>
      <IconButton
        icon={<Icon as={FiChevronDown} />}
        aria-label="open"
        variant="unstyled"
        _focus={{ shadow: "none" }}
        onClick={toggle}
      />
    </Flex>
  )
}

const renderEmpty = () => {
  return (
    <Flex justifyContent="center" alignItems="center" w="full" py={4}>
      <Text color="gray.400">Page not found</Text>
    </Flex>
  )
}

const renderInput: AutoCompleteRenderInput<PageEntity> = ({
  isDirty,
  isInvalid = false,
  search,
  setSearchValue,
  selected,
  toggle,
}) => {
  return (
    <PageInput
      isInvalid={isInvalid}
      isDirty={isDirty}
      search={search}
      setSearchValue={setSearchValue}
      toggle={toggle}
      selected={selected}
    />
  )
}

const renderItem: AutoCompleteRenderItem<PageEntity> = (page, isSelected) => {
  return (
    <VStack
      as={Button}
      alignItems="flex-start"
      variant="ghost"
      colorScheme="primary"
      w="full"
      height={16}
      p={2}
      spacing={0}
      borderRadius="md"
      bg={isSelected ? "primary.50" : "transparent"}
    >
      <PageName>{page.name}</PageName>
      <PagePath>{page.slug}</PagePath>
    </VStack>
  )
}

interface Props {
  name: string
  pages: PageEntity[]
  isInvalid?: boolean
}

const PageSelectInput: React.FC<Props> = props => {
  const { pages, name, isInvalid } = props

  const pagesWithPath = pages.map(page => {
    return {
      ...page,
      slug: generatePathForPage(page, pages),
    }
  })

  return (
    <AutoCompleteInput<PageEntity>
      name={name}
      data={pagesWithPath}
      isInvalid={isInvalid}
      filterFunction={(page, search) =>
        page.name.toLowerCase().includes(search.toLowerCase())
      }
      keyExtractor={page => page.id}
      valueExtractor={page => page.id}
      renderInput={renderInput}
      renderItem={renderItem}
      renderEmpty={renderEmpty}
    />
  )
}

export default PageSelectInput
