import { Text } from "@chakra-ui/react"
import { AppEntity, DeploymentEntity } from "@jackfruit/common"
import { formatDistance } from "date-fns"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import React from "react"

interface Props {
  app: AppEntity
}

const AppCardDeployedVersion: React.FC<Props> = ({ app }) => {
  const { lastDeployedAt } = app
  const { data: deployments } = useFindOneEntity<DeploymentEntity>(
    "deployments",
    {
      query: {
        appId: app.id,
        type: "live",
        status: "complete",
        $limit: 1,
        $sort: { finishedAt: "desc" },
      },
    }
  )

  const hasBeenDeployed = Boolean(lastDeployedAt)

  return (
    <>
      <Text fontSize="sm" color="gray.500">
        {deployments?.deploymentVersion ?? "Unspecified"}
      </Text>
      <Text fontSize="sm" color="gray.500">
        {hasBeenDeployed
          ? `Last deployed ${formatDistance(
              new Date(lastDeployedAt),
              new Date(),
              {
                includeSeconds: true,
              }
            )} ago`
          : "Never Deployed"}
      </Text>
    </>
  )
}

export default AppCardDeployedVersion
