import { CircularProgress } from "@chakra-ui/progress"
import { Flex, Icon, Text } from "@chakra-ui/react"
import React from "react"
import { FiCheck, FiX } from "react-icons/fi"
import { StepStatus } from "./DeploymentStep"

interface Props {
  status: StepStatus
  index: number
}

const boxSize = "30px"

const DeploymentStepIndicator: React.FC<Props> = ({ status, index }) => {
  let indicator = (
    <Flex
      w={boxSize}
      h={boxSize}
      justifyContent="center"
      alignItems="center"
      backgroundColor="blackAlpha.100"
      borderRadius={100}
      boxShadow="md"
    >
      <Text>{index}</Text>
    </Flex>
  )

  if (status === "processing") {
    indicator = (
      <Flex
        w={boxSize}
        h={boxSize}
        justifyContent="center"
        alignItems="center"
        backgroundColor="orange.100"
        borderRadius={100}
        color="white"
        boxShadow="md"
      >
        <CircularProgress isIndeterminate size={4} color="orange.600" />
      </Flex>
    )
  } else if (status === "failed") {
    indicator = (
      <Flex
        w={boxSize}
        h={boxSize}
        justifyContent="center"
        alignItems="center"
        backgroundColor="red.300"
        borderRadius={100}
        color="white"
        boxShadow="md"
      >
        <Icon as={FiX} />
      </Flex>
    )
  } else if (status === "completed") {
    indicator = (
      <Flex
        w={boxSize}
        h={boxSize}
        justifyContent="center"
        alignItems="center"
        backgroundColor="green.300"
        borderRadius={100}
        color="white"
        boxShadow="md"
      >
        <Icon as={FiCheck} />
      </Flex>
    )
  }

  return indicator
}

export default DeploymentStepIndicator
