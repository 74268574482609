import { Entity } from "@jackfruit/common"
import { usePatchEntity } from "hooks/usePatchEntity"
import { useUpdateDebounce } from "hooks/useUpdateDebounce"
import { isNumber, isString } from "lodash"
import React, { ChangeEvent, useCallback, useState } from "react"
import { Resources } from "services/api"

interface Props {
  entityType: Resources
  entity: Entity
  fieldName: string
}

const AutoSaveInputWrapper: React.FC<Props> = ({
  entityType,
  entity,
  fieldName,
  children,
}) => {
  const field = fieldName as keyof typeof entity
  const [value, setValue] = useState(entity[field])
  const { mutate: patchEntity } = usePatchEntity(entityType, {
    showNotification: false,
    onSuccess: () => {},
  })

  const handleOnChange = useCallback(
    (payload: ChangeEvent<HTMLInputElement> | number | string) => {
      let newValue = null
      if (isNumber(payload) || isString(payload)) {
        newValue = payload
      } else {
        newValue = payload.target.value
      }
      setValue(newValue)
    },
    []
  )

  useUpdateDebounce(
    () => {
      patchEntity({
        id: entity.id,
        [fieldName]: value,
      })
    },
    500,
    [value]
  )

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        // @ts-ignore
        onChange: handleOnChange,
        value: value ?? "",
      })
    }
    return child
  })

  return <>{childrenWithProps}</>
}

export default AutoSaveInputWrapper
