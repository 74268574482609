import React, { useMemo } from "react"
import { Box } from "@chakra-ui/react"
import { replace } from "@wordpress/shortcode"
import ReactDOM from "react-dom"
import { renderToString } from "react-dom/server"
import { ShortCodeParser } from "../replaceShortCodes"
import Accordion from "../Accordion"
import { nanoid } from "@reduxjs/toolkit"

// Usage:
// [accordion width="100%" allowMultiple=1 allowToggle=1 expandedStyle='{"bg": "tomato", "color": "white"}']
//   [item title="This is a title 🚀"]<div>Some HTML goes here.</div>[/item]
//   [item title="This title is expanded 1 🎉" expanded=1]<div>Some other HTML goes here.</div>[/item]
//   [item title="This title is expanded 2 🎉" expanded=1]<div>Some other HTML goes here.</div>[/item]
// [/accordion]
//
// Styles:
// #banner .s__accordion-button {
//   padding: 16px;
// }

// #banner .s__accordion-title {
//   text-align: left;
//   font-size: 24px;
// }

export const parser: ShortCodeParser = (html: string = "") => {
  return replace("accordion", html, args => {
    const {
      width = 300,
      allowmultiple = 1,
      allowtoggle = 1,
      expandedstyle = "{}",
    } = args.attrs.named
    const componentUUID = useMemo(nanoid, [])
    const elementId = `accordion-${componentUUID}`

    const allowMultiple = Number(allowmultiple) === 1
    const allowToggle = Number(allowtoggle) === 1
    const expandedStyle = JSON.parse(expandedstyle)

    // @ts-ignore
    const content = args.content ?? ""

    const accodionItems = accordionItemParser(content)

    const defaultExpanded: number[] = []
    accodionItems.forEach((item, index) => {
      if (Number(item.expanded) === 1) {
        defaultExpanded.push(index)
      }
    })

    setTimeout(() => {
      const el = document.getElementById(elementId)
      if (el) {
        ReactDOM.render(
          <Accordion
            width={width}
            allowMultiple={allowMultiple}
            allowToggle={allowToggle}
            accodionItems={accodionItems}
            expandedStyle={expandedStyle}
            defaultIndex={defaultExpanded}
          />,
          el
        )
      }
    })
    return renderToString(<Box id={elementId} />)
  })
}

const accordionItemParser = (html: string = "") => {
  const all: any[] = []
  replace("item", html, args => {
    // @ts-ignore
    const content = args?.content || "no content"
    const itemObj = {
      ...args.attrs.named,
      content: content,
    }
    all.push(itemObj)

    return ""
  })

  return all
}
