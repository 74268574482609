import { Flex, Text, VStack } from "@chakra-ui/layout"
import { Badge } from "@chakra-ui/react"
import { ChangelogEntity } from "@jackfruit/common"
import { format } from "date-fns"
import React from "react"
import { Resources } from "services/api"
import ChangelogRow from "./ChangelogRow"
import ChangelogField from "./fields/ChangelogField"

interface Props {
  changelog: ChangelogEntity
}

const ChangelogCard: React.FC<Props> = ({ changelog }) => {
  const { method, entityType, entityId, user, changes } = changelog

  const borderColor = {
    create: "#319795",
    patch: "#2b6cb0",
    remove: "#f56565",
  }[method]

  const badgeColor = {
    create: "green",
    patch: "blue",
    remove: "red",
  }[method]

  const isACreation = method === "create"
  const isAnUpdate = method === "patch"
  const isARemoval = method === "remove"

  return (
    <Flex
      borderRadius="md"
      bg="gray.50"
      borderWidth={1}
      borderLeftWidth={8}
      borderLeftColor={borderColor}
      px={2}
      py={3}
      minW="full"
      direction="column"
    >
      <VStack alignItems="flex-start" spacing={0}>
        <Flex
          flex={1}
          w="full"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text>
            {entityType} [#{entityId}]
          </Text>
          <Badge colorScheme={badgeColor}>{method}</Badge>
        </Flex>
        <Text fontSize="xs" fontWeight="semibold" color="gray.500">
          {user.email}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {format(new Date(changelog.createdAt!), "yyyy-MM-dd HH:mm:ss")}
        </Text>
      </VStack>

      {isAnUpdate && (
        <Flex
          borderWidth={1}
          borderRadius="md"
          mt={4}
          bg="white"
          direction="column"
        >
          <Flex borderBottomWidth={1} w="full" px={4} py={2}>
            <ChangelogRow
              label={<Text></Text>}
              oldValue={
                <Text fontSize="xs" fontWeight="semibold">
                  OLD
                </Text>
              }
              newValue={
                <Text fontSize="xs" fontWeight="semibold">
                  NEW
                </Text>
              }
            />
          </Flex>
          {Object.entries(changes.updated).map((data, index) => {
            const [field, newValue] = data
            const oldValue = changes.oldValues[field]
            return (
              <Flex key={index} px={4} py={1}>
                <ChangelogRow
                  label={
                    <Text fontSize="sm" fontWeight="semibold">
                      {field}
                    </Text>
                  }
                  oldValue={
                    <ChangelogField
                      entityId={entityId}
                      entityType={entityType as Resources}
                      field={field}
                      value={oldValue}
                    />
                  }
                  newValue={
                    <ChangelogField
                      entityId={entityId}
                      entityType={entityType as Resources}
                      field={field}
                      value={newValue}
                    />
                  }
                />
              </Flex>
            )
          })}
        </Flex>
      )}

      {isACreation && (
        <Flex
          borderWidth={1}
          borderRadius="md"
          mt={4}
          bg="white"
          direction="column"
        >
          <Flex borderBottomWidth={1} w="full" px={4} py={2}>
            <ChangelogRow
              label={<Text></Text>}
              oldValue={
                <Text fontSize="xs" fontWeight="semibold">
                  OLD
                </Text>
              }
              newValue={
                <Text fontSize="xs" fontWeight="semibold">
                  NEW
                </Text>
              }
            />
          </Flex>
          {Object.entries(changes.added).map((data, index) => {
            const [field, newValue] = data
            return (
              <Flex key={index} px={4} py={1}>
                <ChangelogRow
                  label={
                    <Text fontSize="sm" fontWeight="semibold">
                      {field}
                    </Text>
                  }
                  oldValue={<Text>-</Text>}
                  newValue={
                    <ChangelogField
                      entityId={entityId}
                      entityType={entityType as Resources}
                      field={field}
                      value={newValue}
                    />
                  }
                />
              </Flex>
            )
          })}
        </Flex>
      )}

      {isARemoval && (
        <Flex
          borderWidth={1}
          borderRadius="md"
          mt={4}
          bg="white"
          direction="column"
        >
          <Flex borderBottomWidth={1} w="full" px={4} py={2}>
            <ChangelogRow
              label={<Text></Text>}
              oldValue={
                <Text fontSize="xs" fontWeight="semibold">
                  OLD
                </Text>
              }
              newValue={
                <Text fontSize="xs" fontWeight="semibold">
                  NEW
                </Text>
              }
            />
          </Flex>
          {Object.entries(changes.oldValues).map((data, index) => {
            const [field, oldValue] = data
            return (
              <Flex key={index} px={4} py={1}>
                <ChangelogRow
                  label={
                    <Text fontSize="sm" fontWeight="semibold">
                      {field}
                    </Text>
                  }
                  oldValue={
                    <ChangelogField
                      entityId={entityId}
                      entityType={entityType as Resources}
                      field={field}
                      value={oldValue}
                    />
                  }
                  newValue={<Text>-</Text>}
                />
              </Flex>
            )
          })}
        </Flex>
      )}
    </Flex>
  )
}

export default ChangelogCard
