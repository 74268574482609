import React from "react"
import { Box } from "@chakra-ui/react"
import BlockProductGridForm from "../forms/BlockProductGridForm"
import { BlockEntity, BlockProductGridEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import { usePatchEntity } from "hooks/usePatchEntity"

interface Props {
  block: BlockEntity
}

const BlockProductGrid: React.FC<Props> = ({ block }) => {
  const { data: entity, isLoading } = useEntity<BlockProductGridEntity>(
    "blocks-product-grid",
    block.childId
  )

  const { mutate: saveBlock, status: patchStatus } =
    usePatchEntity<BlockProductGridEntity>("blocks-product-grid")

  const isPatching = patchStatus === "loading"

  const onSubmit = (data: any) => {
    saveBlock({ id: entity.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockProductGridForm
      entity={entity}
      isLoading={isPatching}
      onSubmit={onSubmit}
    />
  )
}

export default BlockProductGrid
