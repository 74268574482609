import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
} from '@chakra-ui/react'
import { BlockSocialMediaEntity } from '@jackfruit/common'
import CodePreview from 'components/CodePreview/CodePreview'
import FormContainer from 'components/FormContainer'
import FormActions from 'forms/FormActions'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'

interface Props {
  entity: BlockSocialMediaEntity
  isLoading: boolean
  onSubmit: (data: BlockSocialMediaEntity) => void
}

const BlockSocialMediaForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const { handleSubmit, register, control } = useForm<BlockSocialMediaEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <FormLabel>Title</FormLabel>
        <InputGroup>
          <Input type="text" name="title" ref={register()} />
        </InputGroup>
        <FormHelperText fontStyle="italic">
          Leave blank for default
        </FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel>Body</FormLabel>
        <Controller as={CodePreview as any} name="body" control={control} />
        <FormHelperText fontStyle="italic">
          Leave blank for default. Usefull shortcodes: [link
          field="supportUrl"], [link field="supportEmail"]
        </FormHelperText>
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default BlockSocialMediaForm
