import {
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import React from "react"
import { FiEdit, FiLink } from "react-icons/fi"
import { Link as RouterLink } from "react-router-dom"
import { getLanguageFullName, getLocaleFullName } from "utils/helpers"
import AppCardDeployedVersion from "./AppCardDeployedVersion"
import AppCardLogo from "./AppCardLogo"

interface Props {
  app: AppEntity
}

const AppCard: React.FC<Props> = ({ app }) => {
  return (
    <LinkBox
      mr={6}
      mb={6}
      width="390px"
      bg="red"
      transition="all 0.1s"
      _hover={{
        borderColor: "primary.500",
      }}
      borderRadius="lg"
      backgroundColor="white"
      borderWidth={1}
      p={4}
      overflowY="hidden"
    >
      <HStack minHeight="200px" alignItems="stretch" spacing={6}>
        <Flex alignItems="center">
          <AppCardLogo id={app.faviconImageId} />
        </Flex>
        <Flex direction="column" w="full" flex={1} ml={4}>
          <HStack justifyContent="flex-end" mb={4} spacing={6}>
            <Text fontSize="sm" color="gray.500">
              {getLanguageFullName(app.language)}
            </Text>
            {app.defaultLocale && (
              <Text fontSize="sm" color="gray.500">
                {getLocaleFullName(app.defaultLocale)}
              </Text>
            )}
          </HStack>
          <Flex borderColor="red" flex={1} mb={4}>
            <Heading fontSize="xl">{app.name}</Heading>
          </Flex>
          <VStack alignItems="flex-start" spacing={4}>
            <VStack alignItems="flex-start" spacing={0}>
              <AppCardDeployedVersion app={app} />
            </VStack>
            <HStack spacing={4}>
              <LinkOverlay as={RouterLink} to={`/admin/apps/${app.id}`}>
                <Button colorScheme="primary" leftIcon={<FiEdit />}>
                  Edit
                </Button>
              </LinkOverlay>
              <Link
                isExternal={true}
                href={app.baseUrl}
                _hover={{ textDecoration: "none" }}
              >
                <Button
                  colorScheme="gray"
                  leftIcon={<FiLink />}
                  color="gray.600"
                >
                  Visit
                </Button>
              </Link>
            </HStack>
          </VStack>
        </Flex>
      </HStack>
    </LinkBox>
  )
}

export default AppCard
