import { Text } from "@chakra-ui/layout"
import { ChangelogEntity, EntityId } from "@jackfruit/common"
import { useEntityList } from "hooks/useEntityList"
import { flatMap } from "lodash"
import React, { useMemo, useState } from "react"
import CompiledChangelogsDetails from "./CompiledChangelogsDetails"
import CompiledChangelogsSummary from "./CompiledChangelogsSummary"
import { compileChangelogs } from "./utils"

interface Props {
  appId: EntityId
  deploymentIdFrom?: EntityId
  deploymentIdTo?: EntityId
}

const CompiledChangelogs: React.FC<Props> = ({
  appId,
  deploymentIdFrom,
  deploymentIdTo,
}) => {
  const [selectedRow, setSelectedRow] = useState("")

  const query = useMemo(() => {
    let defaultQuery: any = {
      appId,
    }

    if (Boolean(deploymentIdFrom) && Boolean(deploymentIdTo)) {
      defaultQuery.$and = [
        { latestDeploymentId: { $gte: deploymentIdFrom } },
        { latestDeploymentId: { $lt: deploymentIdTo } },
      ]
    } else if (Boolean(deploymentIdFrom) && !Boolean(deploymentIdTo)) {
      defaultQuery.latestDeploymentId = { $gte: deploymentIdFrom }
    } else if (!Boolean(deploymentIdFrom) && Boolean(deploymentIdTo)) {
      defaultQuery.latestDeploymentId = { $lt: deploymentIdTo }
    }

    return defaultQuery
  }, [appId, deploymentIdFrom, deploymentIdTo])

  const { data, isLoading } = useEntityList<ChangelogEntity>("changelogs", {
    query,
  })

  if (isLoading) {
    return null
  }

  const flatten = compileChangelogs(data.data)

  const hasChanges = Object.keys(flatten).length > 0

  if (!hasChanges) {
    return <Text>No changes</Text>
  }

  if (Boolean(selectedRow)) {
    const changelogs = flatMap(flatten[selectedRow])

    return (
      <CompiledChangelogsDetails
        changelogs={changelogs}
        onGoBack={() => setSelectedRow("")}
      />
    )
  }

  return (
    <>
      {selectedRow}
      <CompiledChangelogsSummary
        compileChangelogs={flatten}
        onSelect={setSelectedRow}
      />
    </>
  )
}

export default CompiledChangelogs
