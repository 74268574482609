import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import FormActions from 'forms/FormActions'
import FormSectionHeader from 'components/FormSectionHeader'
import FormContainer from 'components/FormContainer'
import { NavigationEntity } from '@jackfruit/common'

interface Props {
  entity?: NavigationEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppNavigationForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }
  const { handleSubmit, register, errors } = useForm<NavigationEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Navigation</FormSectionHeader>
      <FormControl>
        <FormLabel>Name</FormLabel>
        <InputGroup>
          <Input ref={register()} name="name" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      <FormActions isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppNavigationForm
