import { useResource } from "./useResource"
import { useResourceActions } from "./useResourceActions"
import { Entity, EntityId } from "@jackfruit/common"
import { Resources } from "services/api"
import { useQueryClient } from "react-query"

const useResourceForm = <T extends Entity>(
  resource: Resources,
  id?: EntityId
) => {
  const { isLoading, entity } = useResource<T>(resource, id)
  const { isPatching, patch, isCreating, create } = useResourceActions(resource)
  const queryClient = useQueryClient()

  const onSubmit = async (data: any): Promise<T> => {
    if (entity.id) {
      const resp = await patch(entity.id, data)
      queryClient.invalidateQueries(resource)

      return resp
    } else {
      const resp = await create(data)
      queryClient.invalidateQueries(resource)

      return resp
    }
  }

  return {
    entity,
    isLoading,
    isPatching,
    isCreating,
    onSubmit,
  }
}

export { useResourceForm }
