import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react"
import { BlockFooterEntity, NavigationMegaMenuEntity } from "@jackfruit/common"
import { useCreateEntity } from "hooks/useCreateEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { FiPlus } from "react-icons/fi"
import { useQueryClient } from "react-query"
import MegaMenuPreview from "screens/apps/navigation-links/megamenu/components/preview/MegaMenuPreview"
import MegaMenuBuilder from "screens/apps/navigation-links/megamenu/MegaMenuBuilder"

interface Props {
  blockFooter: BlockFooterEntity
}

const BlockFooterMegaMenuWrapper: React.FC<Props> = ({ blockFooter }) => {
  const queryClient = useQueryClient()

  const { mutateAsync: createMegaMenu, isLoading: isCreatingMegaMenu } =
    useCreateEntity<Partial<NavigationMegaMenuEntity>>("navigation-megamenus", {
      showNotification: true,
    })

  const { mutateAsync: patchBlockFooter, isLoading: isPatchingBlockFooter } =
    usePatchEntity<BlockFooterEntity>("blocks-footer")

  const handleCreateMegaMenu = async () => {
    const megaMenu = (await createMegaMenu({
      name: "new MegaMenu",
    })) as NavigationMegaMenuEntity

    await patchBlockFooter({
      id: blockFooter.id,
      navigationMegamenuId: megaMenu.id,
    })

    queryClient.invalidateQueries(["blocks"])
  }

  const isLoading = isCreatingMegaMenu || isPatchingBlockFooter

  if (!blockFooter.navigationMegamenuId) {
    return (
      <Flex
        borderWidth={1}
        borderRadius="md"
        minHeight="200px"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          colorScheme="gray"
          boxShadow="md"
          justifyContent="space-between"
          rightIcon={<FiPlus />}
          isLoading={isLoading}
          isDisabled={!Boolean(blockFooter.id)}
          onClick={handleCreateMegaMenu}
        >
          Create Mega Menu
        </Button>
      </Flex>
    )
  }

  return (
    <Flex gridGap={4} direction="column">
      <Tabs>
        <TabList>
          <Tab>Designer</Tab>
          <Tab>Preview</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <MegaMenuBuilder megaMenuId={blockFooter.navigationMegamenuId} />
          </TabPanel>
          <TabPanel px={0}>
            <MegaMenuPreview megaMenuId={blockFooter.navigationMegamenuId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

export default BlockFooterMegaMenuWrapper
