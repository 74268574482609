import {
  Tr,
  Td,
  Heading,
  IconButton,
  Link,
  HStack,
  VStack,
  Box,
} from "@chakra-ui/react"
import { AppEntity, DeploymentEntity } from "@jackfruit/common"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import React from "react"
import { FiEdit, FiLink } from "react-icons/fi"

import { format } from "date-fns"
import { Link as ReactRouterLink } from "react-router-dom"
import AppCardLogo from "components/AppGrid/AppCardLogo"
interface Props {
  app: AppEntity
  index: number
}

const AppRow: React.FC<Props> = ({ app, index }) => {
  const { data: deployment } = useFindOneEntity<DeploymentEntity>(
    "deployments",
    {
      query: {
        appId: app.id,
        type: "live",
        status: "complete",
        $limit: 1,
        $sort: { finishedAt: "desc" },
        $select: ["deploymentVersion"],
      },
    }
  )

  return (
    <Tr>
      <Td>
        <Link fontSize="sm" as={ReactRouterLink} to={`/admin/apps/${app.id}`}>
          #{index}
        </Link>
      </Td>
      <Td>
        <HStack>
          <Box w={"50px"} h={"50px"} mt={"-50px"}>
            <AppCardLogo id={app.faviconImageId} width={12} shadowW={0} />
          </Box>
          <VStack alignItems="flex-start" spacing={0}>
            <Heading fontSize="m">{app.name}</Heading>
          </VStack>
        </HStack>
      </Td>
      <Td>
        <Link fontSize="sm" href={app.baseUrl} isExternal>
          {app.baseUrl}
        </Link>
      </Td>
      <Td>{deployment?.deploymentVersion ?? "Unspecified"}</Td>
      <Td>
        {app.lastDeployedAt &&
          format(new Date(app.lastDeployedAt), "dd/MM/yyyy")}
      </Td>
      <Td>
        <IconButton
          as={ReactRouterLink}
          to={`/admin/apps/${app.id}`}
          isRound={true}
          variant="solid"
          aria-label="Done"
          fontSize="20px"
          icon={<FiEdit />}
          mr={2}
        />

        <IconButton
          as={Link}
          isExternal={true}
          href={app.baseUrl}
          isRound={true}
          variant="solid"
          aria-label="Visit"
          fontSize="20px"
          icon={<FiLink />}
        />
      </Td>
    </Tr>
  )
}

export default AppRow
