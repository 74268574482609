import { Button, Flex } from "@chakra-ui/react"
import { EntityId, NavigationMegaMenuColumnEntity } from "@jackfruit/common"
import { useCreateEntity } from "hooks/useCreateEntity"
import React from "react"
import { FiPlus } from "react-icons/fi"
import { useQueryClient } from "react-query"

interface Props {
  megaMenuId: EntityId
  isDisabled: boolean
}

const MegaMenuAddColumnButton: React.FC<Props> = ({
  megaMenuId,
  isDisabled,
}) => {
  const queryClient = useQueryClient()
  const { mutateAsync: createMegaMenuColumn } = useCreateEntity<
    Partial<NavigationMegaMenuColumnEntity>
  >("navigation-megamenu-columns")

  const handleCreateMegaMenuColumn = async () => {
    await createMegaMenuColumn({
      navigationMegamenuId: megaMenuId,
      title: "new column",
    })

    queryClient.invalidateQueries(["navigation-megamenus"])
  }

  return (
    <Flex flex={1}>
      <Button
        isDisabled={isDisabled}
        w="180px"
        colorScheme="gray"
        justifyContent="space-between"
        rightIcon={<FiPlus />}
        onClick={handleCreateMegaMenuColumn}
      >
        Add Column
      </Button>
    </Flex>
  )
}

export default MegaMenuAddColumnButton
