import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Checkbox,
  FormHelperText,
} from "@chakra-ui/react"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import FormActions from "forms/FormActions"
import FormSectionHeader from "components/FormSectionHeader"
import FormContainer from "components/FormContainer"
import { EntityFormProps } from "forms/EntityFormProps"
import { AppEntity } from "@jackfruit/common"

interface Props extends EntityFormProps<AppEntity> {}

const AppBrandingGeneralAppStoreURLsForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }
  const { handleSubmit, register, errors } = useForm<AppEntity>({
    defaultValues,
  })
  const enhancedOnSubmit = useCallback(
    (data: any) => {
      return onSubmit({
        ...data,
        iosStoreRating: isNaN(parseFloat(data.iosStoreRating))
          ? 5
          : data.iosStoreRating,
        androidStoreRating: isNaN(parseFloat(data.androidStoreRating))
          ? 5
          : data.androidStoreRating,
      })
    },
    [onSubmit]
  )

  return (
    <FormContainer onSubmit={handleSubmit(enhancedOnSubmit)}>
      <FormSectionHeader>App Store Details</FormSectionHeader>
      <FormControl isInvalid={!!errors.iosStoreName}>
        <FormLabel>Name</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="iosStoreName"
            type="text"
            placeholder="iOS store app name"
          />
        </InputGroup>
        <FormErrorMessage>{errors.iosStoreName?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.iosStoreId}>
        <FormLabel>App ID</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="iosStoreId"
            type="text"
            placeholder="iOS app store ID"
          />
        </InputGroup>
        <FormHelperText>
          This is a required field for Smart Banner.
        </FormHelperText>
        <FormErrorMessage>{errors.iosStoreId?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.iosStoreUrl}>
        <FormLabel>URL</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="iosStoreUrl"
            type="text"
            placeholder="iOS store url"
          />
        </InputGroup>
        <FormErrorMessage>{errors.iosStoreUrl?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.iosStoreRating}>
        <FormLabel>Rating (Stars)</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="iosStoreRating"
            type="text"
            placeholder="iOS store rating (default: 5)"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.iosStoreRating && errors.iosStoreRating.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.iosStoreRatingCount}>
        <FormLabel>Rating (Count)</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="iosStoreRatingCount"
            type="text"
            placeholder="iOS store rating count"
          />
        </InputGroup>
        <FormHelperText>
          Enter rating in thousands or millions. E.g: 123456 = 123k, 12345 =
          12.3k
        </FormHelperText>
        <FormErrorMessage>
          {errors.iosStoreRatingCount && errors.iosStoreRatingCount.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.iosDisplayStoreBannerOnMobile}>
        <InputGroup>
          <Checkbox
            ref={register}
            name="iosDisplayStoreBannerOnMobile"
            defaultChecked={entity?.iosDisplayStoreBannerOnMobile}
          >
            Display App Store Banner on Mobile
          </Checkbox>
        </InputGroup>
        <FormErrorMessage>
          {errors.iosDisplayStoreBannerOnMobile &&
            errors.iosDisplayStoreBannerOnMobile.message}
        </FormErrorMessage>
      </FormControl>
      <FormSectionHeader>Play Store Details</FormSectionHeader>
      <FormControl isInvalid={!!errors.androidStoreName}>
        <FormLabel>Name</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="androidStoreName"
            type="text"
            placeholder="Android store app name"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.androidStoreName && errors.androidStoreName.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.androidStoreUrl}>
        <FormLabel>URL</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="androidStoreUrl"
            type="text"
            placeholder="Android store url"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.androidStoreUrl && errors.androidStoreUrl.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.androidStoreRating}>
        <FormLabel>Rating (Stars)</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="androidStoreRating"
            type="text"
            placeholder="Android store rating (default: 5)"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.androidStoreRating && errors.androidStoreRating.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.androidStoreRatingCount}>
        <FormLabel>Rating (Count)</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="androidStoreRatingCount"
            type="text"
            placeholder="Android store rating count"
          />
        </InputGroup>
        <FormHelperText>
          Enter rating in thousands or millions. E.g: 123456 = 123k, 12345 =
          12.3k
        </FormHelperText>
        <FormErrorMessage>
          {errors.androidStoreRatingCount &&
            errors.androidStoreRatingCount.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.androidDisplayStoreBannerOnMobile}>
        <InputGroup>
          <Checkbox
            ref={register}
            name="androidDisplayStoreBannerOnMobile"
            defaultChecked={entity?.androidDisplayStoreBannerOnMobile}
          >
            Display Play Store Banner on Mobile
          </Checkbox>
        </InputGroup>
        <FormErrorMessage>
          {errors.androidDisplayStoreBannerOnMobile &&
            errors.androidDisplayStoreBannerOnMobile.message}
        </FormErrorMessage>
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppBrandingGeneralAppStoreURLsForm
