import PrintServiceConfigurationTable from 'components/ProductConfiguration/PrintServiceConfigurationTable'
import { RouteParams } from 'navigation/RouteParams'
import React from 'react'
import { useParams } from 'react-router-dom'

interface Props {}

const AppPageBlockPrintServiceListScreen: React.FC<Props> = () => {
  const { blockId } = useParams<RouteParams>()
  return (
    <PrintServiceConfigurationTable
      canGoBack={true}
      title="Block Print Services"
      entityId={+blockId}
      entityType="block"
    />
  )
}

export default AppPageBlockPrintServiceListScreen
