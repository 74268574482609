import { Box } from '@chakra-ui/react'
import {
  BlockEntity,
  BlockHeaderEntity,
  NavigationEntity,
} from '@jackfruit/common'
import { useHasOneResourceForm } from 'hooks/useHasOneResourceForm'
import { useResourceList } from 'hooks/useResourceList'
import { RouteParams } from 'navigation/RouteParams'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import BlockHeaderForm from '../forms/block-header/BlockHeaderForm'

interface Props {
  block: BlockEntity
  readOnly?: boolean
}

const BlockHeader: React.FC<Props> = ({ block, readOnly = false }) => {
  const { appId } = useParams<RouteParams>()
  const [params] = useState({ query: { appId } })
  const {
    entity,
    isLoading: isLoadingBlock,
    isCreating,
    isPatching,
    onSubmit,
  } = useHasOneResourceForm<BlockHeaderEntity>(
    'blocks-header',
    'blockId',
    block.id
  )
  const {
    data: navigations,
    isLoading: isLoadingNavigations,
  } = useResourceList<NavigationEntity>('navigations', params)

  const isLoading = isLoadingBlock || isLoadingNavigations

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockHeaderForm
      entity={entity}
      navigations={navigations.data}
      isLoading={isCreating || isPatching}
      onSubmit={onSubmit}
      readOnly={readOnly}
    />
  )
}

export default BlockHeader
