import React from 'react'
import { Heading } from '@chakra-ui/react'

interface Props {
  children: any
}

const FormSectionSubHeader: React.FC<Props> = ({ children }) => {
  return (
    <Heading as="h4" fontSize="md" pb={0}>
      {children}
    </Heading>
  )
}

export default FormSectionSubHeader
