import { Entity, EntityId } from "@jackfruit/common"
import { useQuery } from "react-query"
import api, { Resources } from "services/api"

export const useEntity = <T extends Entity>(
  resource: Resources,
  id: EntityId,
  options: Object = {},
  query: any = {}
) => {
  const result = useQuery<T>(
    [resource, id, query],
    () => api.get(resource, id, query),
    options
  )

  return {
    ...result,
    data: result.data || ({} as T),
  }
}
