import { Box } from '@chakra-ui/react'
import { BlockCheckoutEntity, BlockEntity, PageEntity } from '@jackfruit/common'
import { useEntity } from 'hooks/useEntity'
import { usePatchEntity } from 'hooks/usePatchEntity'
import { useResourceList } from 'hooks/useResourceList'
import { RouteParams } from 'navigation/RouteParams'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import BlockCheckoutForm from '../forms/block-checkout/BlockCheckoutForm'

interface Props {
  block: BlockEntity
  readOnly?: boolean
}

const BlockCheckout: React.FC<Props> = ({ block, readOnly = false }) => {
  const { appId } = useParams<RouteParams>()
  const [params] = useState({ query: { appId } })
  const {
    data: checkoutBlock,
    isLoading: isLoadingBlock,
  } = useEntity<BlockCheckoutEntity>('blocks-checkout', block.childId)

  const {
    mutate: saveCheckoutBlock,
    status: patchStatus,
  } = usePatchEntity<BlockCheckoutEntity>('blocks-checkout')

  const {
    data: pages,
    isLoading: isLoadingPages,
  } = useResourceList<PageEntity>('pages', params)

  const isPatching = patchStatus === 'loading'
  const isLoading = isLoadingBlock || isLoadingPages

  const onSubmit = (data: any) => {
    saveCheckoutBlock({ id: checkoutBlock.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockCheckoutForm
      entity={checkoutBlock}
      pages={pages.data}
      isLoading={isPatching}
      readOnly={readOnly}
      onSubmit={onSubmit}
    />
  )
}

export default BlockCheckout
