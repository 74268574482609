import { Flex } from "@chakra-ui/layout"
import { BlockEntity, EntityId } from "@jackfruit/common"
import FormSectionHeader from "components/FormSectionHeader"
import { useEntity } from "hooks/useEntity"
import React, { Suspense } from "react"
import BlockContent from "./BlockContent"
import { allBlockGroups } from "./BlockToolBox"

interface Props {
  id: EntityId
}

const BlockEditor: React.FC<Props> = ({ id }) => {
  const { data: block } = useEntity<BlockEntity>("blocks", id)
  const label = allBlockGroups
    .flatMap(group => group.blocks)
    .find(b => b.type === block.type)?.label

  return (
    <Suspense fallback={<Flex>Loading</Flex>}>
      <FormSectionHeader>{label}</FormSectionHeader>
      <BlockContent block={block} />
    </Suspense>
  )
}

export default BlockEditor
