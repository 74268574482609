import {
  Flex,
  Heading,
  HStack,
  IconButton,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { NavigationMegaMenuCellEntityHydrated } from "@jackfruit/common"
import ConfirmDialog from "components/ConfirmDialog"
import { useDeleteEntity } from "hooks/useDeleteEntity"
import React, { useRef } from "react"
import { Draggable } from "react-beautiful-dnd"
import { FiEdit, FiTrash2 } from "react-icons/fi"
import { useQueryClient } from "react-query"
import MegaMenuCellEditModal from "./MegaMenuCellEditModal"
import MegaMenuePreviewCellHtml from "./preview/MegaMenuPreviewCellHtml"
import MegaMenuPreviewCellLinks from "./preview/MegaMenuPreviewCellLinks"

interface Props {
  cell: NavigationMegaMenuCellEntityHydrated
  index: number
}

const MegaMenuCell: React.FC<Props> = ({ cell, index }) => {
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure()

  const { mutateAsync: deleteCell } = useDeleteEntity(
    "navigation-megamenu-cells"
  )

  const cancelRef = useRef<HTMLButtonElement>(null)

  const handleDelete = async () => {
    await deleteCell(cell.id)
    queryClient.invalidateQueries(["navigation-megamenus"])

    onCloseDeleteModal()
  }

  return (
    <>
      <Draggable draggableId={`drag-cell#${cell.id}`} index={index}>
        {(provided, snapshot) => {
          const { isDragging } = snapshot

          return (
            <Flex
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              borderWidth={1}
              borderRadius="md"
              justifyContent="space-between"
              alignItems="center"
              backgroundColor="white"
              mb={3}
              boxShadow={isDragging ? "md" : "none"}
              flexDir="column"
            >
              <Flex
                w="full"
                justifyContent="flex-end"
                p={1}
                bg="gray.50"
                borderTopRadius="md"
              >
                <HStack spacing={1}>
                  <IconButton
                    icon={<FiEdit />}
                    aria-label="edit"
                    size="xs"
                    rounded="full"
                    variant="ghost"
                    onClick={onOpen}
                  />
                  <IconButton
                    icon={<FiTrash2 />}
                    aria-label="edit"
                    size="xs"
                    rounded="full"
                    variant="ghost"
                    colorScheme="red"
                    onClick={onOpenDeleteModal}
                  />
                </HStack>
              </Flex>
              <VStack p={3} spacing={3} alignItems="flex-start" w="full">
                <Flex
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Heading fontSize="md" fontWeight="medium">
                    {cell.title}
                  </Heading>
                </Flex>
                {cell.type === "navigation" && (
                  <MegaMenuPreviewCellLinks cell={cell} />
                )}
                {cell.type === "html" && (
                  <MegaMenuePreviewCellHtml cell={cell} />
                )}
              </VStack>
            </Flex>
          )
        }}
      </Draggable>
      <MegaMenuCellEditModal isOpen={isOpen} onClose={onClose} cell={cell} />
      <ConfirmDialog
        text={{
          title: "Are you sure you want to delete this cell?",
          submit: "Delete",
        }}
        isOpen={isOpenDeleteModal}
        onCancel={onCloseDeleteModal}
        onConfirm={handleDelete}
        cancelRef={cancelRef}
      />
    </>
  )
}

export default MegaMenuCell
