// https://stackoverflow.com/questions/43978105/how-do-you-retain-the-custom-attributes-for-a-paragraph-in-quilljs
import { Quill } from "react-quill"

const Parchment = Quill.import("parchment")
export const IDAttribute = new Parchment.Attributor.Attribute(
  "id-attribute",
  "id",
  {
    scope: Parchment.Scope.BLOCK,
  }
)

const Block = Quill.import("blots/block")

class BlockBlot extends Block {
  constructor(domNode: any) {
    super(domNode)
    domNode.setAttribute("id", "")
    this.cache = {}
  }
}

BlockBlot.blotName = "block"
