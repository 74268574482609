import { FormControl, FormLabel, InputGroup, Select } from "@chakra-ui/react"
import { BlockCheckoutEntity, PageEntity } from "@jackfruit/common"
import PageSelectInput from "components/AutocompleteDropDown/PageSelectInput"
import React, { useCallback } from "react"
import { useFormContext } from "react-hook-form"

const nestedPrefix = "checkout"

interface Props {
  readOnly?: boolean
  prefix?: boolean
  entity: BlockCheckoutEntity
  pages: PageEntity[]
}

const BlockCheckoutFields: React.FC<Props> = ({
  readOnly = false,
  prefix = true,
  entity,
  pages,
}) => {
  const { register } = useFormContext()

  const setName = useCallback(
    (name: string) => {
      return prefix ? `${nestedPrefix}.${name}` : name
    },
    [prefix]
  )

  return (
    <>
      <FormControl>
        <FormLabel>Order Success Delivery Page</FormLabel>
        <PageSelectInput
          name={setName("orderSuccessDeliveryPageId")}
          pages={pages}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Order Success Pickup Page</FormLabel>
        <PageSelectInput
          name={setName("orderSuccessPickupPageId")}
          pages={pages}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Order Summary Display Type</FormLabel>
        <InputGroup>
          <Select
            ref={register}
            name={setName("orderSummaryDisplayType")}
            placeholder="Select a display type"
            disabled={readOnly}
          >
            <option key="hiddenByDefault" value="hiddenByDefault">
              Hidden by default
            </option>
            <option key="visibleByDefault" value="visibleByDefault">
              Visible by default
            </option>
            <option key="mcDonald" value="mcDonald">
              Mc Donald's
            </option>
          </Select>
        </InputGroup>
      </FormControl>
    </>
  )
}

export default BlockCheckoutFields
