import feathers from "@feathersjs/client"
// import socketio from '@feathersjs/socketio-client'
import rest from "@feathersjs/rest-client"
import { AssetEntity, EntityId } from "@jackfruit/common"
import CryptoJS from "crypto-js"
import { removeKeys } from "utils/helpers"
import { paramsForServer } from "feathers-hooks-common"

// import io from 'socket.io-client'

export interface EntityPage<T> {
  data: T[]
  limit: number
  skip: number
  total: number
}
export interface Credentials {
  username: string
  password: string
}

export interface UploadArgs {
  file: File
  onProgress?: (hash: string, progress: number) => void
  onSuccess?: (data: AssetEntity) => void
  onError?: (hash: string, error: Error) => void
}

export type Resources =
  | "configuration"
  | "users"
  | "apps"
  | "meta"
  | "navigation-links"
  | "navigation-megamenus"
  | "navigation-megamenu-columns"
  | "navigation-megamenu-cells"
  | "navigation-megamenu-cell-links"
  | "navigations"
  | "pages"
  | "sections"
  | "settings"
  | "seo"
  | "changelogs"
  | "common"
  | "uploads"
  | "assets"
  | "assets-resources"
  | "deployments"
  | "blocks"
  | "blocks-clone"
  | "blocks-image-grid"
  | "blocks-image-grid-assets"
  | "blocks-footer"
  | "blocks-rich-text"
  | "blocks-html"
  | "blocks-header"
  | "blocks-image-upload"
  | "blocks-fulfillment"
  | "blocks-cart"
  | "blocks-checkout"
  | "blocks-store-search"
  | "blocks-subpage-grid"
  | "blocks-order-complete"
  | "blocks-photos-uploaded"
  | "blocks-order-thank-you"
  | "blocks-template"
  | "blocks-product-grid"
  | "blocks-graph"
  | "print-services"
  | "print-service-products"
  | "print-service-product-images"
  | "print-service-configurations"
  | "print-service-product-configurations"
  | "blocks-template-products"
  | "blocks-social-media"
  | "blocks-video"
  | "page-seo"
  | "page-print-services"
  | "print-service-product-app-overrides"
  | "print-service-product-page-overrides"
  | "app-products"
  | "page-products"
  | "sync-product-configurations-for-entity"
  | "sync-territories-with-remote-api"
  | "sync-app-with-remote-api"
  | "reset-print-service-product-configuration"
  | "products"
  | "snippets"
  | "snippet-categories"
  | "territories"
  | "page-territories"
  | "integrations"
  | "global-deployment-settings"
  | "lambda-ab-variants"
  | "page-interactions"

const app = feathers()

// const socket = io(process.env.REACT_APP_API || '')
// app.configure(socketio(socket))

const restClient = rest(process.env.REACT_APP_API)
app.configure(restClient.fetch(window.fetch))

app.configure(
  feathers.authentication({
    storage: window.localStorage as any,
    storageKey: "autopilot-builder-jwt",
  })
)
export interface ApiQuery {
  query: Object
}

export interface ApiResultList<T> {
  data: T[]
  limit: number
  skip: number
  total: number
}

export { app }

const api = {
  login: async (credentials: Credentials) => {
    try {
      // First try to log in with an existing JWT
      return await app.reAuthenticate()
    } catch (error: any) {
      // If that errors, log in with email/password
      // Here we would normally show a login page
      // to get the login information
      return await app.authenticate({
        strategy: "local",
        email: credentials.username,
        password: credentials.password,
      })
    }
  },
  logout: async () => await app.logout(),
  find: async (resource: Resources, params?: any) => {
    const result = await app.service(resource).find(params)
    return result
  },
  findOne: async <T>(resource: Resources, params?: any) => {
    const result = await app.service(resource).find(params)
    return result.data && (result.data[0] as T)
  },
  get: async (resource: Resources, id: EntityId, query: any = {}) => {
    const result = await app.service(resource).get(id, query)
    return result
  },
  create: async (resource: Resources, data?: any, params?: any) => {
    const result = await app
      .service(resource)
      .create(data, paramsForServer(params))
    return result
  },
  update: async (
    resource: Resources,
    id: EntityId,
    data?: any,
    params?: any
  ) => {
    const refreshedEntity = await app
      .service(resource)
      .update(id, data, paramsForServer(params))
    return refreshedEntity
  },
  patch: async (
    resource: Resources,
    id?: EntityId | null,
    data?: any,
    params?: any
  ) => {
    const dataToSend = removeKeys(data, ["updatedAt", "createdAt"])
    const result = await app
      .service(resource)
      .patch(id, dataToSend, paramsForServer(params))
    return result
  },
  remove: async (resource: Resources, id: EntityId | null, params?: any) => {
    const result = await app
      .service(resource)
      .remove(id, paramsForServer(params))
    return result
  },
  uploadOneFile: async (file: File): Promise<AssetEntity> => {
    const fileContent = await file.text()
    const hash = CryptoJS.MD5(fileContent).toString()

    const { accessToken } = await app.reAuthenticate()

    const service = app.service("uploads")
    const endpoint = service.base

    const formData = new FormData()
    formData.append("file", file)
    formData.append("hash", hash)

    const response = await fetch(endpoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    const entity = await response.json()

    return entity
  },
  listen: (
    resource: Resources,
    event: string,
    callback: (entity: any) => void
  ) => {
    app.service(resource).on(event, callback)
  },
  stopListening: (resource: Resources, event: string, callback: any) => {
    app.service(resource).off(event, callback)
  },
}

export default api
