import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { NavigationMegaMenuCellLinkEntity, PageEntity } from "@jackfruit/common"
import PageSelectInput from "components/AutocompleteDropDown/PageSelectInput"
import FormContainer from "components/FormContainer"
import ColorPickerInput from "forms/inputs/ColorPickerInput"
import { useDeleteEntity } from "hooks/useDeleteEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import { pickBy } from "lodash"
import React from "react"
import { Draggable } from "react-beautiful-dnd"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { FiEdit } from "react-icons/fi"
import { MdDragIndicator } from "react-icons/md"
import { useQueryClient } from "react-query"

interface Props {
  link: NavigationMegaMenuCellLinkEntity
  pages: PageEntity[]
  index: number
}

const MegaMenuCellLink: React.FC<Props> = ({ link, pages, index }) => {
  const { isOpen, onToggle } = useDisclosure()
  const queryClient = useQueryClient()
  const methods = useForm<NavigationMegaMenuCellLinkEntity>({
    defaultValues: link,
  })

  const { control, handleSubmit, register, watch, reset, setError, errors } =
    methods

  const { mutateAsync: patchLink, isLoading: isPatching } =
    usePatchEntity<NavigationMegaMenuCellLinkEntity>(
      "navigation-megamenu-cell-links"
    )

  const { mutateAsync: deleteLink } = useDeleteEntity(
    "navigation-megamenu-cell-links"
  )

  const onSubmit = async (data: NavigationMegaMenuCellLinkEntity) => {
    if (data.type === "page" && !data.pageId) {
      setError("pageId", { type: "required" })
      return
    }
    await patchLink(
      pickBy(
        {
          id: link.id,
          label: data.label,
          badgeText: data.badgeText,
          badgeColor: data.badgeColor,
          type: data.type,
          pageId: data.pageId,
          path: data.path,
          external: Boolean(data.external),
        },
        value => value !== undefined
      )
    )
    queryClient.invalidateQueries(["navigation-megamenus"])
    reset({
      label: data.label,
      badgeText: data.badgeText,
      badgeColor: data.badgeColor,
      type: data.type,
      pageId: data.pageId,
      path: data.path,
      external: Boolean(data.external),
    })
  }

  const handleDelete = async () => {
    await deleteLink(link.id)
    queryClient.invalidateQueries(["navigation-megamenus"])
  }

  const type = watch("type")

  return (
    <Draggable draggableId={`drag-link#${link.id}`} index={index}>
      {(provided, snapshot) => {
        const { isDragging } = snapshot

        return (
          <Flex
            as="details"
            open={isOpen}
            ref={provided.innerRef}
            {...provided.draggableProps}
            px={3}
            py={2}
            mb={3}
            borderWidth={1}
            borderRadius="md"
            backgroundColor="white"
            boxShadow={isDragging ? "md" : "none"}
            direction="column"
          >
            <Flex
              as="summary"
              justifyContent="space-between"
              {...provided.dragHandleProps}
            >
              <Flex justifyContent="space-between" w="full">
                <Flex alignItems="center">
                  <Icon as={MdDragIndicator} color="gray.500" mr={3} />
                  <Text>{link.label}</Text>
                </Flex>
                <Flex alignItems="center" mr={3}>
                  <Badge
                    colorScheme={link.type === "link" ? "gray" : "primary"}
                  >
                    {link.type}
                  </Badge>
                </Flex>
              </Flex>
              <IconButton
                size="sm"
                rounded="full"
                variant="ghost"
                onClick={onToggle}
                icon={<FiEdit />}
                aria-label="open"
              />
            </Flex>
            <FormProvider {...methods}>
              <FormContainer onSubmit={handleSubmit(onSubmit)} mt={4}>
                <FormControl isInvalid={Boolean(errors.label)}>
                  <FormLabel>Label</FormLabel>
                  <InputGroup>
                    <Input
                      ref={register({ required: true })}
                      name="label"
                      type="text"
                    />
                  </InputGroup>
                  {Boolean(errors.label) && (
                    <FormErrorMessage>Label is required.</FormErrorMessage>
                  )}
                </FormControl>

                <HStack alignItems="flex-start">
                  <FormControl>
                    <FormLabel>Badge Text</FormLabel>
                    <InputGroup>
                      <Input ref={register} name="badgeText" type="text" />
                    </InputGroup>
                  </FormControl>
                  <FormControl isInvalid={Boolean(errors.label)}>
                    <FormLabel>Badge Color</FormLabel>
                    <Controller
                      render={({ name, value, onChange }) => (
                        <ColorPickerInput
                          onChange={onChange}
                          value={value}
                          name={name}
                          placeholder="#424242"
                        />
                      )}
                      name="badgeColor"
                      register={register}
                      control={control}
                    />
                  </FormControl>
                </HStack>
                <FormControl>
                  <FormLabel>Type</FormLabel>
                  <InputGroup>
                    <Select ref={register} name="type">
                      <option value="page">page</option>
                      <option value="link">link</option>
                    </Select>
                  </InputGroup>
                </FormControl>
                {type === "page" && (
                  <FormControl>
                    <FormLabel>Page</FormLabel>
                    <Box>
                      <PageSelectInput
                        name="pageId"
                        pages={pages}
                        isInvalid={Boolean(errors.pageId)}
                      />
                    </Box>
                  </FormControl>
                )}
                {type === "link" && (
                  <>
                    <FormControl>
                      <FormLabel>Path / Url</FormLabel>
                      <InputGroup>
                        <Input ref={register} name="path" type="text" />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <InputGroup>
                        <Controller
                          control={control}
                          name="external"
                          render={({ onChange, onBlur, value, name }) => {
                            return (
                              <Checkbox
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.checked)}
                                isChecked={Boolean(value)}
                                name={name}
                              >
                                External
                              </Checkbox>
                            )
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                  </>
                )}
                <HStack>
                  <Button size="sm" onClick={handleDelete} colorScheme="red">
                    Delete
                  </Button>
                  <Button
                    size="sm"
                    isLoading={isPatching}
                    onClick={handleSubmit(onSubmit)}
                    colorScheme="blue"
                  >
                    Save
                  </Button>
                </HStack>
              </FormContainer>
            </FormProvider>
          </Flex>
        )
      }}
    </Draggable>
  )
}

export default MegaMenuCellLink
