import { IconButton } from '@chakra-ui/react'
import { usePatchEntity } from 'hooks/usePatchEntity'
import React from 'react'
import { useCallback } from 'react'
import { FiRotateCcw } from 'react-icons/fi'
import { Resources } from 'services/api'

interface Props {
  resource: Resources
  entity: any
  fields: string[]
}
const TableResetField: React.FC<Props> = ({ resource, entity, fields }) => {
  const { mutate: patch } = usePatchEntity<any>(resource)

  const handleRevert = useCallback(() => {
    const fieldCleanUp: any = {}
    fields.forEach((f) => {
      fieldCleanUp[f] = null
    })

    patch({
      id: entity.id,
      ...fieldCleanUp,
    })
  }, [entity.id, fields, patch])

  return (
    <IconButton
      icon={<FiRotateCcw />}
      variant="ghost"
      borderRadius="full"
      colorScheme="primary"
      aria-label="revert"
      onClick={handleRevert}
    />
  )
}

export default TableResetField
