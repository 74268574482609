import { Box, Checkbox, Flex, Icon, Text } from "@chakra-ui/react"
import { TreeNode } from "@jackfruit/common"
import React, { useCallback, useMemo } from "react"
import { NodeApi } from "react-arborist"
import { FiMinus, FiPlus } from "react-icons/fi"

interface Props {
  node: NodeApi<TreeNode>
  style: React.CSSProperties
  isSelected: boolean
  onToggle: (value: string, selected: boolean) => void
}

const AppCommonPageTreeNode = React.memo(
  ({ node, style, isSelected, onToggle }: Props) => {
    const handleCheckboxChange = useCallback(() => {
      onToggle(node.data.value, !isSelected)
    }, [isSelected, node.data.value, onToggle])

    const handleToggle = useCallback(
      (e: React.MouseEvent) => {
        node.toggle()
      },
      [node]
    )

    const showListingChild = useMemo(() => {
      const nodes = node.data.children ?? []
      return !node.isLeaf && nodes.length > 0
    }, [node])

    return (
      <Flex
        align="center"
        style={style}
        cursor="pointer"
        onClick={handleToggle}
      >
        <Box width={5}>
          {showListingChild && (
            <Icon as={node.isOpen ? FiMinus : FiPlus} fontWeight={"bold"} />
          )}
        </Box>
        <Checkbox
          isChecked={isSelected}
          onChange={handleCheckboxChange}
          mr={2}
        />
        <Text>{node.data.label}</Text>
      </Flex>
    )
  }
)

export default AppCommonPageTreeNode
