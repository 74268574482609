import {
  Box,
  Container,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  Tooltip,
} from "@chakra-ui/react"
import { AssetResourceEntity, EntityId } from "@jackfruit/common"
import { Sortable } from "@jackfruit/common/build/interfaces"
import { useDeleteEntity } from "hooks/useDeleteEntity"
import { debounce } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { FiMove, FiTrash } from "react-icons/fi"
import { ReactSortable } from "react-sortablejs"
import AssetsDropUploader from "./AssetsDropUploader"

interface AssetResourceEntitySortable extends AssetResourceEntity, Sortable {}

type AttrType = "alt" | "link"
interface Props {
  value: AssetResourceEntity[]
  onChange: (assets: AssetResourceEntity[]) => void
}

const MultipleAssets: React.FC<Props> = ({ value, onChange }) => {
  const { mutate: remove } = useDeleteEntity("assets-resources")
  const [isDeleting, setIsDeleting] = useState(false)
  const [assets, setAssets] = useState<AssetResourceEntity[]>(value || [])

  useEffect(() => {
    onChange(assets)
  }, [onChange, assets])

  const setOrders = useCallback(
    (data: AssetResourceEntity[]) => {
      const newAssetsOrder = data.map((asset, index) => {
        asset.order = index
        return asset
      })
      setAssets(newAssetsOrder)
    },
    [setAssets]
  )

  const onAssetUploaded = useCallback(
    (asset: Partial<AssetResourceEntitySortable>) => {
      const { createdAt, updatedAt, chosen, selected, ...rest } = asset

      setAssets(oldAssets => {
        rest.order = oldAssets.length + 1
        return [...oldAssets, rest as AssetResourceEntity]
      })
    },
    [setAssets]
  )

  const handleChangeInput = debounce(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      assetId: EntityId,
      attrType: AttrType
    ) => {
      const data = assets.map(asset => {
        if (asset.id === assetId) {
          asset[attrType] = event.target.value
        }

        return asset
      })
      setAssets(data)
    }
  )

  const onDelete = useCallback(
    async (assetId: EntityId) => {
      setIsDeleting(true)
      const data = assets.filter(asset => asset.id !== assetId)
      const assetResource = assets.find(asset => asset.id === assetId)

      if (assetResource?.assetsResourcesId) {
        remove(assetResource.assetsResourcesId)
      }

      setOrders(data)
      setIsDeleting(false)
    },
    [assets, setOrders, remove]
  )

  return (
    <>
      <AssetsDropUploader onComplete={onAssetUploaded} />

      {assets.length > 0 && (
        <Box padding="0" margin="0" textAlign="center" marginTop={2}>
          <ReactSortable
            list={assets}
            setList={setAssets}
            animation={300}
            onEnd={() => setOrders(assets)}
          >
            {assets?.map(asset => (
              <Box
                m={2}
                border={1}
                bg="gray.50"
                key={asset.id}
                borderColor="gray.200"
                borderRadius=".25rem"
                boxShadow="base"
                width="95%"
                position="relative"
                overflow="hidden"
                display="inline-flex"
              >
                <Tooltip
                  hasArrow
                  label="Change image order"
                  bg="gray.300"
                  color="black"
                >
                  <IconButton
                    size="sm"
                    aria-label="move"
                    icon={<FiMove />}
                    rounded="full"
                    variant="ghost"
                    bg="gray.100"
                    top="10px"
                    left="10px"
                    position="absolute"
                    zIndex="1"
                    colorScheme="primary"
                    isDisabled={isDeleting}
                  />
                </Tooltip>

                <Tooltip
                  hasArrow
                  label="Remove image"
                  bg="gray.300"
                  color="black"
                >
                  <IconButton
                    size="sm"
                    aria-label="delete"
                    icon={<FiTrash />}
                    rounded="full"
                    variant="ghost"
                    bg="gray.100"
                    top="10px"
                    right="10px"
                    position="absolute"
                    zIndex="1"
                    colorScheme="red"
                    isDisabled={isDeleting}
                    onClick={() => onDelete(asset.id)}
                  />
                </Tooltip>

                <FormControl>
                  <Image
                    boxSize="100%"
                    height="160px"
                    fallbackSrc={asset.path}
                    objectFit="contain"
                    background="gray.50"
                    padding="10px"
                  />
                  <Container p={4} paddingTop={0}>
                    <FormLabel
                      fontSize="xs"
                      overflow="hidden"
                      width="100%"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      Alt attribute - {asset.filename}
                    </FormLabel>
                    <Input
                      type="text"
                      defaultValue={asset.alt}
                      name={`alt-${asset.id}`}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        event.persist()
                        handleChangeInput(event, asset.id, "alt")
                      }}
                    />
                  </Container>

                  <Container p={4} paddingTop={0}>
                    <FormLabel
                      fontSize="xs"
                      overflow="hidden"
                      width="100%"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      Link
                    </FormLabel>
                    <Input
                      type="text"
                      defaultValue={asset.link}
                      name={`link-${asset.id}`}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        event.persist()
                        handleChangeInput(event, asset.id, "link")
                      }}
                    />
                  </Container>
                </FormControl>
              </Box>
            ))}
          </ReactSortable>
        </Box>
      )}
    </>
  )
}

export default MultipleAssets
