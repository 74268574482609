import { Box } from "@chakra-ui/react"
import { BlockEntity, BlockSubpageGridEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import React from "react"
import { usePatchEntity } from "hooks/usePatchEntity"
import BlockSubpageGridForm from "../forms/BlockSubpageGridForm"

interface Props {
  block: BlockEntity
}

const BlockSubpageGrid: React.FC<Props> = ({ block }) => {
  const { data: entity, isLoading } = useEntity<BlockSubpageGridEntity>(
    "blocks-subpage-grid",
    block.childId
  )

  const { mutate: saveBlock, status: patchStatus } =
    usePatchEntity<BlockSubpageGridEntity>("blocks-subpage-grid")

  const isPatching = patchStatus === "loading"

  const onSubmit = (data: any) => {
    saveBlock({ id: entity.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockSubpageGridForm
      entity={entity}
      isLoading={isPatching}
      onSubmit={onSubmit}
    />
  )
}

export default BlockSubpageGrid
