import { Box, Button, HStack, VStack } from "@chakra-ui/react"
import { ChangelogEntity } from "@jackfruit/common"
import { isBefore } from "date-fns"
import React from "react"
import { FiArrowLeft } from "react-icons/fi"
import ChangelogCard from "./ChangelogCard"

interface Props {
  changelogs: ChangelogEntity[]
  onGoBack: () => void
}

const CompiledChangelogsDetails: React.FC<Props> = ({
  changelogs,
  onGoBack,
}) => {
  const sortedChangeLogs = [...changelogs].sort((a, b) => {
    return isBefore(new Date(a.createdAt!), new Date(b.createdAt!)) ? 1 : -1
  })
  return (
    <Box>
      <HStack onClick={onGoBack} _hover={{ cursor: "pointer" }} mb={4}>
        <Button
          variant="ghost"
          size="sm"
          onClick={onGoBack}
          borderRadius="full"
          leftIcon={<FiArrowLeft />}
          aria-label="go back"
        >
          go back
        </Button>
      </HStack>
      <VStack alignItems="flex-start">
        {sortedChangeLogs.map(changelog => {
          return <ChangelogCard key={changelog.id} changelog={changelog} />
        })}
      </VStack>
    </Box>
  )
}

export default CompiledChangelogsDetails
