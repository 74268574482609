import React, { useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import Body from "components/Body"
import { useResourceForm } from "hooks/useResourceForm"
import { useResourceList } from "hooks/useResourceList"
import { RouteParams } from "navigation/RouteParams"
import { NavigationLinkEntity, PageEntity } from "@jackfruit/common"

interface Props {
  form: React.FC<any>
}

const AppNavigationLinkCRUDScreen: React.FC<Props> = ({ form }) => {
  const history = useHistory()
  const { appId, navigationId, navigationLinkId } = useParams<RouteParams>()
  const {
    entity,
    isPatching,
    isLoading: isLoadingNavigationLink,
    onSubmit,
  } = useResourceForm<NavigationLinkEntity>(
    "navigation-links",
    parseInt(navigationLinkId)
  )

  const [pageQuery] = useState({
    query: {
      appId,
      $limit: 1000,
      $sort: {
        name: 1,
      },
    },
  })

  const { data, isLoading: isLoadingPages } = useResourceList<PageEntity>(
    "pages",
    pageQuery
  )

  const enhancedOnSubmit = async (data: any) => {
    const updatedData = {
      ...data,
      navigationId,
    }
    const navigationLink = await onSubmit(updatedData)
    if (!navigationLinkId) {
      history.push(
        `/admin/apps/${appId}/navigations/${navigationId}/navigation-links/${navigationLink.id}/edit`
      )
    }
  }

  const formComponent = React.createElement(form, {
    entity,
    isLoading: isPatching,
    onSubmit: enhancedOnSubmit,
    pages: data.data,
  })

  const isLoading = isLoadingNavigationLink || isLoadingPages

  return <Body isLoading={isLoading}>{formComponent}</Body>
}

export default AppNavigationLinkCRUDScreen
