import React from "react"
import { Button, Icon, Stack } from "@chakra-ui/react"
import { FiSave, FiX } from "react-icons/fi"
import { useHistory } from "react-router-dom"

interface Props {
  entity?: any
  isLoading: boolean
  onSubmit?: () => void
  canCancel?: boolean
  readOnly?: boolean
  isDisabled?: boolean
}

const FormActions: React.FC<Props> = ({
  entity,
  isLoading,
  onSubmit = () => {},
  canCancel = false,
  readOnly = false,
  isDisabled = false,
}) => {
  const history = useHistory()
  if (readOnly) {
    return null
  }

  return (
    <Stack direction="row" spacing={3}>
      <Button
        onClick={onSubmit}
        leftIcon={<Icon as={FiSave} />}
        colorScheme="blue"
        type="submit"
        isLoading={isLoading}
        isDisabled={isDisabled}
        boxShadow="md"
      >
        {entity && entity.id ? "Update" : "Create"}
      </Button>
      {canCancel && (
        <Button
          leftIcon={<Icon as={FiX} />}
          colorScheme="gray"
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
      )}
    </Stack>
  )
}

export default FormActions
