import { FormControl, FormLabel } from '@chakra-ui/react'
import { BlockHtmlEntity, BlockImageUploadEntity } from '@jackfruit/common'
import 'ace-builds'
import 'ace-builds/webpack-resolver'
import CodePreview from 'components/CodePreview/CodePreview'
import FormContainer from 'components/FormContainer'
import FormActions from 'forms/FormActions'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'

interface Props {
  entity: BlockImageUploadEntity
  isLoading: boolean
  onSubmit: (data: BlockHtmlEntity) => void
}

const BlockImageUploadForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const { handleSubmit, control } = useForm<BlockImageUploadEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <FormLabel>Top HTML Code</FormLabel>
        <Controller as={CodePreview as any} name="topHtml" control={control} />
      </FormControl>

      <FormControl>
        <FormLabel>Bottom HTML Code</FormLabel>
        <Controller
          as={CodePreview as any}
          name="bottomHtml"
          control={control}
        />
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default BlockImageUploadForm
