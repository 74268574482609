// @ts-ignore
import { combineReducers, configureStore, EntityState } from "@reduxjs/toolkit"
import { auth, AuthState } from "./state/auth"
import { configuration, ConfigurationState } from "./state/configuration"

export interface EnhancedEntityState<T> extends EntityState<T> {
  isLoading: boolean
  hasError: boolean
  error: string
}

export interface RootState {
  auth: AuthState
  configuration: ConfigurationState
}

export const createStore = () => {
  const rootReducer = combineReducers({
    auth: auth.reducer,
    configuration: configuration.reducer,
  })

  const store = configureStore({
    reducer: rootReducer,
  })

  return store
}
