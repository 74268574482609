import { PageEntity } from "@jackfruit/common"
import Body from "components/Body"
import { useResourceForm } from "hooks/useResourceForm"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import { useParams } from "react-router-dom"

interface Props {
  form: React.FC<any>
}

const AppPageMiscScreen: React.FC<Props> = ({ form }) => {
  const { pageId } = useParams<RouteParams>()
  const { entity, isPatching, isLoading, onSubmit } =
    useResourceForm<PageEntity>("pages", parseInt(pageId))

  const formComponent = React.createElement(form, {
    entity,
    isLoading: isPatching,
    onSubmit,
  })

  return <Body isLoading={isLoading}>{formComponent}</Body>
}

export default AppPageMiscScreen
