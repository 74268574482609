import { Box } from "@chakra-ui/react"
import { BlockEntity, BlockStoreSearchEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import BlockStoreSearchForm from "../forms/block-store-search/BlockStoreSearchForm"

interface Props {
  block: BlockEntity
  readOnly?: boolean
}

const BlockStoreSearch: React.FC<Props> = ({ block, readOnly = false }) => {
  const { data: entity, isLoading } = useEntity<BlockStoreSearchEntity>(
    "blocks-store-search",
    block.childId
  )

  const { mutate: saveStoreSearchBlock, status: patchStatus } =
    usePatchEntity<BlockStoreSearchEntity>("blocks-store-search")

  const isPatching = patchStatus === "loading"

  const onSubmit = (data: any) => {
    saveStoreSearchBlock({ id: entity.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockStoreSearchForm
      entity={entity}
      isLoading={isPatching}
      onSubmit={onSubmit}
      readOnly={readOnly}
    />
  )
}

export default BlockStoreSearch
