import { Select, SelectProps } from "@chakra-ui/select"
import { DeploymentEntity, EntityId } from "@jackfruit/common"
import { useEntityList } from "hooks/useEntityList"
import React, { useMemo } from "react"
import semver from "semver"

const START_VERSION = "6.1.0"

export type DeploymentSelectorValues = EntityId | "current" | "creation"

interface Props extends SelectProps {
  appId: EntityId
  onSelectionChange: (deploymentId: EntityId) => void
}

const DeploymentSelector: React.FC<Props> = ({
  appId,
  onSelectionChange,
  ...rest
}) => {
  const query = {
    $sort: {
      createdAt: -1,
    },
    status: "complete",
    appId,
    type: "live",
    $select: [
      "id",
      "createdAt",
      "deploymentVersion",
      "gitReference",
      "url",
      "testResults",
    ],
    $limit: 1_000_000,
  }

  const { data: deployments, isLoading } = useEntityList<DeploymentEntity>(
    "deployments",
    {
      query,
    }
  )

  const filteredDeployments = useMemo(() => {
    return deployments.data
      .filter(deployment => Boolean(deployment.deploymentVersion))
      .filter(deployment =>
        semver.gt(deployment.deploymentVersion, START_VERSION)
      )
  }, [deployments.data])

  if (isLoading) {
    return null
  }

  return (
    <Select {...rest} onChange={e => onSelectionChange(e.target.value)}>
      <option value="current">Current</option>
      {filteredDeployments.map(deployment => {
        return (
          <option key={deployment.id} value={deployment.id}>
            {deployment.deploymentVersion}
          </option>
        )
      })}
      <option value="creation">Creation</option>
    </Select>
  )
}

export default DeploymentSelector
