import { AppEntity } from "@jackfruit/common"
import { RouteParams } from "navigation/RouteParams"
import { useParams } from "react-router-dom"
import { useEntity } from "./useEntity"

export const usePageTitle = () => {
  const { appId } = useParams<RouteParams>()
  const { data: app } = useEntity<AppEntity>("apps", parseInt(appId), {
    enabled: Boolean(appId),
  })

  const titleName = Boolean(app?.name)
    ? `${app?.name} | Builder V4`
    : "Builder V4"

  return titleName
}
