import { Stack } from "@chakra-ui/react"
import React from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import AppPageBlockScreen from "../AppPageBlockScreen"
import AppPageCRUDScreen from "../AppPageCRUDScreen"
import AppPageMiscScreen from "../AppPageMiscScreen"
import AppPageBlockNavigation from "../blocks/navigation/AppPageBlockNavigation"
import AppPageExperimentForm from "../forms/AppPageExperimentForm"
import AppPageMobileBannerForm from "../forms/AppPageMobileBannerForm"
import AppPageInteractivityNavigation from "../interaction/navigation/AppPageInteractivityNavigation"
import AppPageProductSetupNavigation from "../product-configurations/navigation/AppPageProductSetupNavigation"
import AppPageNav from "./AppPageNav"

interface Props {}

const AppPageNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <Stack direction="column" alignItems="start" spacing={8}>
      {/* <AppPageNav /> */}
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/general`} />
        <Redirect
          exact
          from={`${match.url}/edit`}
          to={`${match.url}/general`}
        />
        <Route path={`${match.path}/general`}>
          <AppPageNav />
          <AppPageCRUDScreen />
        </Route>
        <Route path={`${match.path}/blocks`} exact>
          <AppPageBlockScreen />
        </Route>
        <Route path={`${match.path}/blocks/:blockId`}>
          <AppPageNav />
          <AppPageBlockNavigation />
        </Route>
        <Route path={`${match.path}/page-interactions`}>
          <AppPageNav />
          <AppPageInteractivityNavigation />
        </Route>
        <Route path={`${match.path}/miscellaneous`}>
          <AppPageNav />
          <AppPageMiscScreen form={AppPageMobileBannerForm} />
          <AppPageMiscScreen form={AppPageExperimentForm} />
        </Route>
        <Route path={`${match.path}/product-setup`}>
          <AppPageNav />
          <AppPageProductSetupNavigation />
        </Route>
      </Switch>
    </Stack>
  )
}

export default AppPageNavigation
