import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { BlockSubpageGridEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"

interface Props {
  entity: BlockSubpageGridEntity
  isLoading: boolean
  onSubmit: (data: BlockSubpageGridEntity) => void
}

const BlockSubpageGridForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const { handleSubmit, register } = useForm<BlockSubpageGridEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <FormLabel>Title (leave blank for no title)</FormLabel>
        <InputGroup>
          <Input type="text" name="title" ref={register} />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Pagination Limit Per Page</FormLabel>
        <NumberInput step={1} defaultValue={12} min={12} max={21}>
          <NumberInputField ref={register} name="paginationLimit" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <FormControl>
        <InputGroup>
          <Checkbox ref={register} name="displayTitles" defaultChecked>
            Display Page Titles
          </Checkbox>
        </InputGroup>
      </FormControl>
      <FormControl>
        <InputGroup>
          <Checkbox
            ref={register}
            name="displayPublishedAtDates"
            defaultChecked
          >
            Display Page PublishedAt Dates
          </Checkbox>
        </InputGroup>
      </FormControl>
      <Box>
        <Text fontWeight="medium">Notes</Text>
        <UnorderedList ml={5}>
          <ListItem>
            {
              "The image used in the subpage card can be set at Pages > SEO > Page Feature Image"
            }
          </ListItem>
          <ListItem>
            {
              "The title used in the subpage card comes from Pages > Main > Name"
            }
          </ListItem>
          <ListItem>
            {
              "The date used in the subpage card comes from Pages > Main > Published At Date"
            }
          </ListItem>
          <ListItem>
            {
              "The subpage cards will be sorted in descending order by published date"
            }
          </ListItem>
        </UnorderedList>
      </Box>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default BlockSubpageGridForm
