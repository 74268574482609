import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { AssetEntity } from '@jackfruit/common'
import { useUploadAsset } from 'hooks/useUploadAsset'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import FilePreview from './FilePreview'

interface Props {
  asset?: AssetEntity
  onComplete: (data: any) => void
  onStart?: () => void
  readOnly?: boolean
}

const AssetUploader: React.FC<Props> = ({
  asset,
  onComplete,
  onStart,
  readOnly = false,
}) => {
  const [filename, setFilename] = useState<string>(asset?.filename || '')
  const [size, setSize] = useState(asset?.size || 0)
  const [mimeType, setMimeType] = useState(asset?.mimeType || '')
  const [path, setPath] = useState<string>(asset?.path || '')

  const [hasAnAsset, setHasAnAsset] = useState(!!asset?.id)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setFilename(asset?.filename || '')
    setSize(asset?.size || 0)
    setMimeType(asset?.mimeType || '')
    setPath(asset?.path || '')
  }, [asset])

  const { upload } = useUploadAsset()

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        setIsLoading(true)
        const file: File = acceptedFiles[0]
        setPath('')
        setFilename(file.name)
        setSize(file.size)
        setMimeType(file.type)
        onStart && onStart()

        const newAsset = await upload({
          file,
        })

        setPath(newAsset.path)
        setHasAnAsset(true)
        onComplete(newAsset)
        setIsLoading(false)
      } catch (error: any) {
        console.error(error)
      }
    },
    [onStart, upload, onComplete]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    disabled: readOnly,
  })

  const handleRemove = useCallback(() => {
    setPath('')
    setFilename('')
    setSize(0)
    setMimeType('')
    onComplete({ id: null })
  }, [onComplete])

  return (
    <Flex direction="row" w="100%">
      <Flex {...getRootProps()}>
        <input {...getInputProps()} />
        <FilePreview
          size={100}
          path={path}
          mimeType={mimeType}
          isLoading={isLoading}
        />
      </Flex>
      <Flex ml={3} direction="column" justify="space-between" w="100%">
        <Box>
          {!filename && <Text isTruncated>No file chosen...</Text>}
          {filename && <Text isTruncated>{filename}</Text>}
        </Box>
        <Box>
          {!hasAnAsset && (
            <Text color="gray.500">
              Click left or drop a file here to upload
            </Text>
          )}
          {hasAnAsset && (
            <Text color="gray.500">
              {mimeType} | {(size / 1000).toFixed(2)} KB
            </Text>
          )}
        </Box>
        <Box>
          {!hasAnAsset && (
            <Button
              variant="link"
              size="sm"
              colorScheme="teal"
              {...getRootProps()}
            >
              Upload
            </Button>
          )}
          {hasAnAsset && !readOnly && (
            <Button
              variant="link"
              size="sm"
              colorScheme="red"
              onClick={handleRemove}
            >
              Remove
            </Button>
          )}
          {readOnly && (
            <Text fontStyle="italic" fontSize="md">
              The file is not editable...
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}

export default AssetUploader
