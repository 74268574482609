import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react"
import { usePatchEntity } from "hooks/usePatchEntity"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { FiCheck, FiEdit } from "react-icons/fi"
import { Resources } from "services/api"

interface Props {
  resource: Resources
  entity: any
  field: string
  width?: number
}

const TableEditableField: React.FC<Props> = ({
  resource,
  entity,
  field,
  width = "100%",
}) => {
  const inputRef = useRef<HTMLInputElement>(null!)
  const { mutate: patch } = usePatchEntity<any>(resource)
  const initialValue = entity[field] ?? ""
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value)

  const handleEdit = useCallback(() => {
    onOpen()
    inputRef.current.focus()
  }, [onOpen])

  const handleSave = useCallback(() => {
    patch({
      id: entity.id,
      [field]: value,
    })
    onClose()
  }, [entity.id, field, onClose, patch, value])

  const handleCancel = useCallback(() => {
    setValue(initialValue)
    onClose()
  }, [initialValue, onClose])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleSave()
      }
      if (e.key === "Escape") {
        handleCancel()
      }
    },
    [handleCancel, handleSave]
  )

  return (
    <Flex width={width}>
      <InputGroup size="md">
        <Input
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          ref={inputRef}
          isReadOnly={!isOpen}
        />
        <InputRightElement>
          <IconButton
            borderLeftRadius={0}
            icon={isOpen ? <FiCheck /> : <FiEdit />}
            variant="outline"
            colorScheme={isOpen ? "primary" : "gray"}
            aria-label="edit/save"
            onClick={isOpen ? handleSave : handleEdit}
          />
        </InputRightElement>
      </InputGroup>
    </Flex>
  )
}

export default TableEditableField
