import React from 'react'
import { Stack, StackProps } from '@chakra-ui/react'

interface Props extends StackProps {
  onSubmit: () => void
}

const FormContainer: React.FC<Props> = ({ children, onSubmit, ...rest }) => {
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={4} {...rest}>{children}</Stack>
    </form>
  )
}

export default FormContainer
