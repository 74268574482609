import {
  Alert,
  AlertDescription,
  AlertIcon,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Link,
} from "@chakra-ui/react"
import { SettingsEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"

interface FormData {
  deploymentS3BucketName: string
  deploymentS3Prefix: string
  deploymentCloudFrontDistributionId: string
}

interface Props {
  entity: SettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppSettingsDeploymentForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: FormData = {
    deploymentS3BucketName: entity.deploymentS3BucketName,
    deploymentS3Prefix: entity.deploymentS3Prefix,
    deploymentCloudFrontDistributionId:
      entity.deploymentCloudFrontDistributionId,
  }

  const { handleSubmit, register, errors } = useForm<FormData>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Deployment</FormSectionHeader>
      <FormControl isInvalid={Boolean(errors.deploymentS3BucketName)}>
        <FormLabel>S3 Bucket Name</FormLabel>
        <InputGroup>
          <Input ref={register} name="deploymentS3BucketName" />
        </InputGroup>
      </FormControl>
      {Boolean(entity.lambdaAbEnabled) && (
        <Alert status="warning">
          <AlertIcon />
          <AlertDescription>
            Lambda A/B testing is enabled, set the bucket config{" "}
            <Link href={`/admin/apps/${entity.appId}/settings/lambda-ab-tests`}>
              here
            </Link>
            .
          </AlertDescription>
        </Alert>
      )}
      <FormControl
        isInvalid={Boolean(errors.deploymentCloudFrontDistributionId)}
      >
        <FormLabel>CloudFront Distribution Id</FormLabel>
        <InputGroup>
          <Input ref={register} name="deploymentCloudFrontDistributionId" />
        </InputGroup>
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsDeploymentForm
