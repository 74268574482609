import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  ListItem,
  OrderedList,
} from "@chakra-ui/react"
import { SettingsEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"

interface Props {
  entity?: SettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppSettingsExperimentsForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Google Optimize Experiments</FormSectionHeader>

      <FormControl isInvalid={errors.googleOptimizeExperimentIdOne}>
        <FormLabel>Footer Variant - Experiment ID</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="googleOptimizeExperimentIdOne"
            type="text"
          />
        </InputGroup>
        <FormHelperText maxW="500px">
          Display different Footer variants.
        </FormHelperText>
        <FormErrorMessage>
          {errors.googleOptimizeExperimentIdOne &&
            errors.googleOptimizeExperimentIdOne.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.googleOptimizeExperimentIdTwo}>
        <FormLabel>Order Summary - Experiment ID</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="googleOptimizeExperimentIdTwo"
            type="text"
          />
        </InputGroup>
        <FormHelperText maxW="500px">
          This experiment is tied to the Order Summary component, two versions
          are available, default is waiting for the user data to be completed
          before showing the summary, variant is showing the summary at all time
          (no need for the user to enter any data).
        </FormHelperText>
        <FormErrorMessage>
          {errors.googleOptimizeExperimentIdTwo &&
            errors.googleOptimizeExperimentIdTwo.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.googleOptimizeExperimentIdFive}>
        <FormLabel>Line Item Price Visibility - Experiment ID</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="googleOptimizeExperimentIdFive"
            type="text"
          />
        </InputGroup>
        <FormHelperText maxW="500px" mb={3}>
          This experiment is tied to the Line component and drive the price
          visibility based on 3 variants
        </FormHelperText>
        <OrderedList>
          <ListItem>Hide price at all times</ListItem>
          <ListItem>Display price per products</ListItem>
          <ListItem>Display price per products and total</ListItem>
        </OrderedList>
      </FormControl>

      <FormControl isInvalid={errors.googleOptimizeExperimentIdSix}>
        <FormLabel>Print Editor Toolbar - Experiment ID</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="googleOptimizeExperimentIdSix"
            type="text"
          />
        </InputGroup>
        <FormHelperText maxW="500px">
          Changes the position of the image editing tools from the sidebar to a
          topbar.
        </FormHelperText>
        <FormErrorMessage>
          {errors.googleOptimizeExperimentIdSix &&
            errors.googleOptimizeExperimentIdSix.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.googleOptimizeExperimentIdSeven}>
        <FormLabel>Product Template Drawer - Experiment ID</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="googleOptimizeExperimentIdSeven"
            type="text"
          />
        </InputGroup>
        <FormHelperText maxW="500px">
          Create a new drawer to display the product template tags.
        </FormHelperText>
        <FormErrorMessage>
          {errors.googleOptimizeExperimentIdSeven &&
            errors.googleOptimizeExperimentIdSeven.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.googleOptimizeExperimentIdEight}>
        <FormLabel>Address Field Inputs - Experiment ID</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="googleOptimizeExperimentIdEight"
            type="text"
          />
        </InputGroup>
        <FormHelperText maxW="500px">
          Implement a new UI for the Checkout form for both pick-up and
          delivery. (Only applies for US and NZ)
        </FormHelperText>
        <FormErrorMessage>
          {errors.googleOptimizeExperimentIdEight &&
            errors.googleOptimizeExperimentIdEight.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.googleOptimizeExperimentIdNine}>
        <FormLabel>Product Selector Drawer - Experiment ID</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="googleOptimizeExperimentIdNine"
            type="text"
          />
        </InputGroup>
        <FormHelperText maxW="500px">
          Create a new drawer to display the product selector UI.
        </FormHelperText>
        <FormErrorMessage>
          {errors.googleOptimizeExperimentIdNine &&
            errors.googleOptimizeExperimentIdNine.message}
        </FormErrorMessage>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsExperimentsForm
