import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  FormControl,
  HStack,
  Switch,
  FormLabel,
  Spacer,
} from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import React, { useState } from "react"
import AppRow from "./AppRow"

interface Props {
  apps: AppEntity[]
}

const AppList: React.FC<Props> = ({ apps }) => {
  const deployedApps = apps.filter(app => app.lastDeployedAt)
  const [isFilterDeploy, setIsFilterDeploy] = useState<boolean>(false)
  const filterDeployedApps = isFilterDeploy ? deployedApps : apps

  return (
    <>
      <FormControl alignItems="center">
        <HStack p={5} background="#fff" my={2}>
          <Spacer />
          <Switch
            aria-label="filter-deploy"
            onChange={e => setIsFilterDeploy(e.target.checked)}
            isChecked={isFilterDeploy}
          />
          <FormLabel htmlFor="display-previews" mb={0} ml={2}>
            Hide No deployed
          </FormLabel>
        </HStack>
      </FormControl>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>App</Th>
              <Th>URL</Th>
              <Th>Version</Th>
              <Th>Dated Deployed</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filterDeployedApps.map((app, index) => {
              return <AppRow app={app} key={app.id} index={index + 1} />
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}

export default AppList
