import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react"
import {
  NavigationLinkEntity,
  NavigationMegaMenuEntity,
} from "@jackfruit/common"
import { useCreateEntity } from "hooks/useCreateEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { FiPlus } from "react-icons/fi"
import MegaMenuPreview from "./components/preview/MegaMenuPreview"
import MegaMenuBuilder from "./MegaMenuBuilder"

interface Props {
  navigationLink: NavigationLinkEntity
}

const MegaMenuWrapper: React.FC<Props> = ({ navigationLink }) => {
  const { mutateAsync: createMegaMenu, isLoading: isCreatingMegaMenu } =
    useCreateEntity<Partial<NavigationMegaMenuEntity>>("navigation-megamenus", {
      showNotification: true,
    })

  const {
    mutateAsync: patchNavigationLink,
    isLoading: isPatchingNavigationLink,
  } = usePatchEntity<NavigationLinkEntity>("navigation-links")

  const handleCreateMegaMenu = async () => {
    const megaMenu = (await createMegaMenu({
      name: "new MegaMenu",
    })) as NavigationMegaMenuEntity

    await patchNavigationLink({
      id: navigationLink.id,
      navigationMegamenuId: megaMenu.id,
    })
  }

  const isLoading = isCreatingMegaMenu || isPatchingNavigationLink

  if (!navigationLink.navigationMegamenuId) {
    return (
      <Flex
        borderWidth={1}
        borderRadius="md"
        minHeight="200px"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          colorScheme="gray"
          boxShadow="md"
          justifyContent="space-between"
          rightIcon={<FiPlus />}
          isLoading={isLoading}
          isDisabled={!Boolean(navigationLink.id)}
          onClick={handleCreateMegaMenu}
        >
          Create Mega Menu
        </Button>
      </Flex>
    )
  }

  return (
    <Flex gridGap={4} direction="column">
      <Tabs>
        <TabList>
          <Tab>Designer</Tab>
          <Tab>Preview</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <MegaMenuBuilder megaMenuId={navigationLink.navigationMegamenuId} />
          </TabPanel>
          <TabPanel px={0}>
            <MegaMenuPreview megaMenuId={navigationLink.navigationMegamenuId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

export default MegaMenuWrapper
