import { Button, HStack, useDisclosure } from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import AppSelectInput from "components/AutocompleteDropDown/AppSelectInput"
import ConfirmDialog from "components/ConfirmDialog"
import { useEntity } from "hooks/useEntity"
import { useEntityList } from "hooks/useEntityList"
import { usePatchEntity } from "hooks/usePatchEntity"
import { RouteParams } from "navigation/RouteParams"
import React, { useMemo, useRef } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FiEye, FiLock, FiSliders, FiUnlock } from "react-icons/fi"
import { useQueryClient } from "react-query"
import { Link, useHistory, useParams } from "react-router-dom"
import { useUpdateEffect } from "react-use"
import api from "services/api"

const NODE_ENV = process.env.NODE_ENV
interface Props {}

const confirmLockApp = {
  title: `Lock App`,
  content: `Are you sure you want to lock the web app`,
}

const confirmUnLockApp = {
  title: "Unlock App",
  content: `Are you sure you want to unlock
  the web app`,
}

const AppHeaderActions: React.FC<Props> = () => {
  const history = useHistory()
  const { appId } = useParams<RouteParams>()
  const { data: app } = useEntity<AppEntity>("apps", Number(appId))
  const { mutateAsync: patchApp, isLoading: isPatching } =
    usePatchEntity<AppEntity>("apps")

  const isLockedApp = app.isLocked
  const cancelRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onOpen: openConfirmLockApp, onClose } = useDisclosure()

  const confirmStatus = useMemo(() => {
    return isLockedApp ? confirmUnLockApp : confirmLockApp
  }, [isLockedApp])

  const defaultValues = { id: appId }
  const methods = useForm<AppEntity>({ defaultValues })
  const { watch } = methods

  const {
    data: { data },
    isLoading,
  } = useEntityList<AppEntity>("apps", {
    query: {
      $limit: 1000,
      $sort: {
        name: 1,
      },
    },
  })

  const selectedAppId = watch("id")

  useUpdateEffect(() => {
    if (selectedAppId !== app.id) {
      history.push(`/admin/apps/${selectedAppId}`)
    }
  }, [selectedAppId])

  const queryClient = useQueryClient()

  const handlPrefetchGitReference = async () => {
    await queryClient.prefetchQuery(
      [
        "deployments",
        {
          appId: appId,
          $limit: 1,
          status: NODE_ENV === "development" ? "pending" : "complete",
          type: "live",
          $sort: { createdAt: "desc" },
        },
      ],
      async () => {
        try {
          const data = await api.findOne("deployments", {
            query: {
              appId: appId,
              $limit: 1,
              status: NODE_ENV === "development" ? "pending" : "complete",
              type: "live",
              $sort: { createdAt: "desc" },
            },
          })

          if (data) {
            localStorage.setItem(
              "@autopilot:currentDeployment",
              JSON.stringify(data)
            )
          }
        } catch (error) {
          console.error("Error prefetching deployment data")
        }
      }
    )
  }

  const handleLockApp = async () => {
    await patchApp({ id: app.id, isLocked: !isLockedApp })
    onClose()
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      <HStack spacing={4}>
        <FormProvider {...methods}>
          <AppSelectInput name="id" apps={data} />
        </FormProvider>

        <Button
          isLoading={isPatching}
          leftIcon={isLockedApp ? <FiLock /> : <FiUnlock />}
          variant={"solid"}
          onClick={openConfirmLockApp}
          colorScheme={isLockedApp ? "red" : "green"}
          w={32}
        >
          {isLockedApp ? "Locked" : "UnLocked"}
        </Button>
        <Button
          as={Link}
          to={`/admin/apps/${appId}/preview`}
          bg="white"
          _hover={{ bg: "gray.50" }}
          _active={{ bg: "gray.50" }}
          leftIcon={<FiSliders />}
        >
          Config
        </Button>
        <Button
          as={Link}
          to={`/admin/apps/${appId}/preview/new`}
          leftIcon={<FiEye />}
          colorScheme="blue"
          onMouseEnter={handlPrefetchGitReference}
        >
          Preview
        </Button>
      </HStack>

      <ConfirmDialog
        cancelRef={cancelRef}
        text={confirmStatus}
        isOpen={isOpen}
        onCancel={onClose}
        onConfirm={handleLockApp}
      />
    </>
  )
}

export default AppHeaderActions
