import { extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

const options = {
  styles: {
    global: (props: any) => ({
      color: mode("gray.800", "whiteAlpha.900")(props),
      bg: mode("white", "gray.800")(props),
      lineHeight: "base",
      "*::placeholder": {
        color: mode("gray.400", "whiteAlpha.400")(props),
      },
      "*, *::before, &::after": {
        borderColor: mode("gray.200", "whiteAlpha.300")(props),
        wordWrap: "break-word",
      },
      "html, body": {
        color: "gray.600",
        backgroundColor: "gray.100",
      },
      "a.chakra-link": {
        color: "primary.500",
      },
      fontFeatureSettings: `"pnum"`,
      fontVariantNumeric: "proportional-nums",
    }),
  },
  colors: {
    background: "#ebedf0",
    info: "#F6E05E",
    warning: "#63B3ED",
    danger: "#E53E3E",
    codePreviewHeader: "#526AB5",
    tertiary: {
      50: "#fdedf3",
      100: "#fbdce7",
      200: "#f7b9ce",
      300: "#f395b6",
      400: "#ef729d",
      500: "#eb4f85", // default
      600: "#bc3f6a",
      700: "#8d2f50",
      800: "#5e2035",
      900: "#2f101b",
    },
    primary: {
      50: "#EBF8FF",
      100: "#BEE3F8",
      200: "#90CDF4",
      300: "#63B3ED",
      400: "#4299E1",
      500: "#3182CE", // default
      600: "#2B6CB0",
      700: "#2C5282",
      800: "#2A4365",
      900: "#1A365D",
    },
    secondary: {
      50: "#9ba7b5",
      100: "#8695a6",
      200: "#728497",
      300: "#5e7289",
      400: "#4a617a",
      500: "#364f6b	", // default
      600: "#314760",
      700: "#2b3f56",
      800: "#26374b",
      900: "#202f40",
    },
    accent: "#290B50",
    gradient: {
      top: "#4F159A",
      bottom: "#290B50",
    },
  },
}

const theme = extendTheme(options)

export default theme
