import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import FormContainer from "components/FormContainer"
import React from "react"
import { useForm } from "react-hook-form"
import { FiArrowRight } from "react-icons/fi"
import { Credentials } from "services/api"

interface Props {
  login: (credentials: Credentials) => void
  isError: boolean
  errorMessage?: string
}

const LoginForm: React.FC<Props> = ({ login, isError, errorMessage }) => {
  const { handleSubmit, register, formState } = useForm()

  return (
    <FormContainer onSubmit={handleSubmit(login)}>
      <FormControl isInvalid={isError}>
        <FormLabel fontWeight="semibold">Email Address</FormLabel>
        <Input
          ref={register()}
          autoComplete="username"
          name="username"
          type="text"
        />
      </FormControl>

      <FormControl isInvalid={isError}>
        <FormLabel fontWeight="semibold">Password</FormLabel>
        <Input
          ref={register()}
          autoComplete="current-password"
          name="password"
          type="password"
        />
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>

      <Box textAlign="left">
        <Button
          rightIcon={<FiArrowRight />}
          colorScheme="primary"
          type="submit"
          isLoading={formState.isSubmitting}
          mt={2}
          px={4}
          fontSize="md"
          fontWeight="semibold"
        >
          Sign In
        </Button>
      </Box>
    </FormContainer>
  )
}

export default LoginForm
