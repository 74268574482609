import { Flex, Text, VStack } from "@chakra-ui/react"
import { DeploymentTestResults as DeploymentTestResultsData } from "@jackfruit/common"
import { intervalToDuration } from "date-fns"
import React from "react"

interface Props {
  testResults: DeploymentTestResultsData
  width?: string
}

const defaultTestResults: DeploymentTestResultsData = {
  testReportUrl: "",
  testPipelineUrl: "",
  testReportSummary: {
    reportName: "Allure Report",
    testRuns: [],
    statistic: {
      failed: 0,
      broken: 0,
      skipped: 0,
      passed: 0,
      unknown: 0,
      total: 0,
    },
    time: {
      start: 0,
      stop: 0,
      duration: 0,
      minDuration: 0,
      maxDuration: 0,
      sumDuration: 0,
    },
  },
}

const DeploymentTestResults: React.FC<Props> = ({
  testResults,
  width = "150px",
}) => {
  const result = testResults || defaultTestResults

  const {
    broken = 0,
    failed = 0,
    passed = 0,
    skipped = 0,
    total = 0,
    unknown = 0,
  } = result?.testReportSummary?.statistic

  const durationInMilliseconds = result.testReportSummary.time?.duration ?? 0

  const duration = intervalToDuration({
    start: 0,
    end: durationInMilliseconds,
  })

  const failedOrBroken = failed + broken
  const skippedOrUnknown = skipped + unknown

  const ratioPassed = passed / total
  const ratioFailed = failedOrBroken / total
  const ratioSkipped = skippedOrUnknown / total

  return (
    <VStack spacing={1} alignItems="flex-start">
      {total > 0 ? (
        <>
          <Flex
            w={width}
            h="20px"
            borderRadius="md"
            overflow="hidden"
            bg="blackAlpha.100"
          >
            <Flex
              flex={ratioPassed}
              bg="green.300"
              justifyContent="center"
              px={1}
            >
              <Text fontSize="xs" fontWeight="semibold" color="white">
                {passed}
              </Text>
            </Flex>
            <Flex
              flex={ratioFailed}
              bg="red.400"
              justifyContent="center"
              px={1}
            >
              <Text fontSize="xs" fontWeight="semibold" color="white">
                {failedOrBroken}
              </Text>
            </Flex>
            <Flex flex={ratioSkipped} justifyContent="center" px={1}>
              <Text fontSize="xs" fontWeight="semibold" color="gray.400">
                {skippedOrUnknown}
              </Text>
            </Flex>
          </Flex>
          <Text fontSize="xs" fontWeight="semibold" color="gray.400">
            {duration.hours}h {duration.minutes}m {duration.seconds}s
          </Text>
        </>
      ) : (
        <Flex h="20px" />
      )}
    </VStack>
  )
}

export default DeploymentTestResults
