import { Entity } from "@jackfruit/common"
import { useQuery } from "react-query"
import api, { ApiQuery, ApiResultList, Resources } from "services/api"

const emptyResult = {
  data: [],
  limit: 0,
  skip: 0,
  total: 0,
}

export const useEntityList = <T extends Entity>(
  resource: Resources,
  query?: ApiQuery,
  options: Object = {}
) => {
  const result = useQuery<ApiResultList<T>>(
    [resource, query],
    () => api.find(resource, query),
    {
      keepPreviousData: true,
      ...options,
    }
  )
  return {
    ...result,
    data: result.data || emptyResult,
  }
}
