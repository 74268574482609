import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import AppPrintServiceListScreen from "../AppPrintServiceListScreen"
import AppProductSetupListScreen from "../AppProductSetupListScreen"

interface Props {}

const AppProductSetupNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}`} exact={true}>
        <AppPrintServiceListScreen />
      </Route>
      <Route
        path={`${match.path}/print-service-configurations/:printServiceId`}
      >
        <AppProductSetupListScreen />
      </Route>
    </Switch>
  )
}

export default AppProductSetupNavigation
