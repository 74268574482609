import { AssetEntity } from "@jackfruit/common"
import { useQueryClient } from "react-query"
import api from "../services/api"

export const useUploadAsset = () => {
  const queryClient = useQueryClient()

  const upload = async ({ file }: { file: File }): Promise<AssetEntity> => {
    const assetEntity = await api.uploadOneFile(file)

    queryClient.invalidateQueries("assets")

    return assetEntity
  }

  return { upload }
}
