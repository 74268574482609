import { Entity, EntityId } from "@jackfruit/common"
import { useCallback, useState } from "react"
import api, { Resources } from "services/api"
import { useAppRevision } from "./useAppRevision"
import { useCurrentAppId } from "./useCurrentAppId"
import { useCustomToast } from "./useCustomToast"

export const useResourceActions = <T extends Entity>(
  resource: Resources,
  setEntity?: (data: any) => void
) => {
  const toast = useCustomToast()
  const { bumpRevision } = useAppRevision()
  const appId = useCurrentAppId()

  const [isCreating, setIsCreating] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isPatching, setIsPatching] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)

  const create = useCallback(
    async (data: any, params?: any) => {
      let result = null
      setIsCreating(true)
      try {
        result = await api.create(resource, data, { appId })
        const freshEntity = await api.find(resource, {
          query: { id: result.id, ...params },
        })
        if (setEntity) {
          setEntity(freshEntity.data[0])
        }
        toast({
          title: `${resource} created.`,
          description: `${resource} has been succesfully created`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        bumpRevision()
      } catch (error: any) {
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      } finally {
        setIsCreating(false)
      }

      return result
    },
    [resource, toast, setEntity, appId, bumpRevision]
  )

  const update = useCallback(
    async (id: EntityId, data: any, params?: any) => {
      let result = null
      setIsUpdating(true)
      try {
        result = await api.update(resource, id, data, { ...params, appId })
        if (setEntity) {
          setEntity(result)
        }
        toast({
          title: `${resource} updated.`,
          description: `${resource} has been succesfully updated`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        bumpRevision()
      } catch (error: any) {
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      } finally {
        setIsUpdating(false)
      }

      return result
    },
    [resource, toast, setEntity, appId, bumpRevision]
  )

  const patch = useCallback(
    async (id: EntityId, data: any, params?: any) => {
      let result = null
      setIsPatching(true)
      try {
        result = await api.patch(resource, id, data, { appId })
        const freshEntity = await api.find(resource, {
          query: { id, ...params },
        })

        if (setEntity) {
          setEntity(freshEntity.data[0])
        }
        toast({
          title: `${resource} patched.`,
          description: `${resource} has been succesfully patched`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      } catch (error: any) {
        console.error(error)
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
        bumpRevision()
      } finally {
        setIsPatching(false)
      }

      return result
    },
    [resource, toast, setEntity, appId, bumpRevision]
  )

  const patchMultiple = useCallback(
    async (entities: T[]) => {
      let result = null
      setIsPatching(true)
      try {
        const promises = entities.map(e =>
          api.patch(resource, e.id, e, { appId })
        )
        result = await Promise.all(promises)
        toast({
          title: `Multiple ${resource} patched.`,
          description: `${resource} has been succesfully patched`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      } catch (error: any) {
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
        bumpRevision()
      } finally {
        setIsPatching(false)
      }
      return result
    },
    [resource, toast, appId, bumpRevision]
  )

  const remove = useCallback(
    async (id: EntityId, params?: any) => {
      let result = null
      setIsRemoving(true)
      try {
        result = await api.remove(resource, id, { ...params, appId })
        toast({
          title: `${resource} deleted.`,
          description: `${resource} has been succesfully deleted`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      } catch (error: any) {
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
        bumpRevision()
      } finally {
        setIsRemoving(false)
      }

      return result
    },
    [resource, toast, appId, bumpRevision]
  )

  return {
    isCreating,
    isUpdating,
    isPatching,
    isRemoving,
    create,
    update,
    patch,
    patchMultiple,
    remove,
  }
}
