import InteractivityTable from "components/Interactivity/InteractivityTable"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import { useParams } from "react-router-dom"

interface Props {}

const AppPageInteractivityListScreen: React.FC<Props> = () => {
  const { pageId } = useParams<RouteParams>()

  return <InteractivityTable title="Floating CTAs" entityId={Number(pageId)} />
}

export default AppPageInteractivityListScreen
