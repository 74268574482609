import { Box, FormControl, Input, Radio, RadioGroup } from "@chakra-ui/react"
import { PageEntity } from "@jackfruit/common"
import React, { useCallback, useMemo, useState } from "react"
import { NodeApi, Tree } from "react-arborist"
import { useSearchTerm } from "../hooks/useSearchTerm"
import { useSelectedPage } from "../hooks/useSelectedPage"
import AppCommonPageTreeNode from "./AppCommonPageTreeNode"
import AppCommonPageTreeTags from "./AppCommonPageTreeTags"
import { createRootHierarchicalPages } from "utils/helpers"

interface Props {
  pages: PageEntity[]
  onChange: (pages: string[]) => void
}

type SelectedPageTa = "all" | "custom"

const AppCommonPageTreeSelect: React.FC<Props> = React.memo(
  ({ pages, onChange }) => {
    const [selectedPageTa, setSelectedPageTa] = useState<SelectedPageTa>("all")
    const { searchTerm, handleSearchChange } = useSearchTerm()
    const {
      selectedPages,
      addInSelectedPages,
      removeInSelectedPages,
      resetSelectedPages,
    } = useSelectedPage(onChange)

    const pagesHierarchy = useMemo(() => {
      const configPages = pages.filter(page => page.status !== "draft")

      const rootHierarchicalTreePages = createRootHierarchicalPages(configPages)

      return rootHierarchicalTreePages
    }, [pages])

    const handleOnChangeSelectedPageTa = useCallback(
      (value: SelectedPageTa) => {
        setSelectedPageTa(value)
        if (value === "all") {
          resetSelectedPages()
        }
      },
      [resetSelectedPages]
    )

    const handleToggle = useCallback(
      (value: string, selected: boolean) => {
        selected ? addInSelectedPages(value) : removeInSelectedPages(value)
      },
      [addInSelectedPages, removeInSelectedPages]
    )

    const renderNode = useCallback(
      (props: { node: NodeApi; style: React.CSSProperties }) => (
        <AppCommonPageTreeNode
          {...props}
          isSelected={selectedPages.includes(props.node.data.value)}
          onToggle={handleToggle}
        />
      ),
      [selectedPages, handleToggle]
    )

    const showPageTreeNodes = selectedPageTa === "custom"

    return (
      <Box pt={4}>
        <FormControl>
          <RadioGroup
            name="selectedPageTa"
            value={selectedPageTa}
            onChange={handleOnChangeSelectedPageTa}
            mb={5}
          >
            <Radio value="all" mr={5}>
              All Pages
            </Radio>
            <Radio value="custom" mr={5}>
              Custom Pages
            </Radio>
          </RadioGroup>
        </FormControl>

        {showPageTreeNodes && (
          <>
            <Input
              placeholder="Search Pages"
              value={searchTerm}
              onChange={handleSearchChange}
              mb={4}
            />
            <Box
              mb={5}
              border={"1px"}
              p={4}
              borderColor={"gray.100"}
              rounded={"md"}
            >
              <Tree
                initialData={pagesHierarchy}
                disableDrag={true}
                disableDrop={true}
                rowHeight={36}
                width={"100%"}
                height={250}
                idAccessor="value"
                childrenAccessor="children"
                searchTerm={searchTerm}
                searchMatch={(node, term) =>
                  node.data.label.toLowerCase().includes(term.toLowerCase())
                }
              >
                {renderNode}
              </Tree>
            </Box>

            <AppCommonPageTreeTags
              selectedPages={selectedPages}
              onRemove={removeInSelectedPages}
              pages={pages}
            />
          </>
        )}
      </Box>
    )
  }
)

export default AppCommonPageTreeSelect
