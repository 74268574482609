import { HStack, Link, Spinner, Text, VStack } from "@chakra-ui/react"
import { AppEntity, DeploymentEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import React from "react"
import DeploymentLambdaAbVariant from "./DeploymentLambdaAbVariant"
import DeploymentTypeBadge from "./DeploymentTypeBadge"

interface Props {
  deployment: DeploymentEntity
}

const TableDeployedApplicationName: React.FC<Props> = ({ deployment }) => {
  const { data: application, isLoading: isLoadingApplication } =
    useEntity<AppEntity>("apps", deployment.appId)

  return isLoadingApplication ? (
    <Spinner size="sm" />
  ) : (
    <VStack alignItems="flex-start" spacing={1}>
      <HStack>
        <Text fontSize="md">{application.name}</Text>
        <DeploymentTypeBadge type={deployment.type} />
        {deployment.lambdaAbVariantId && (
          <DeploymentLambdaAbVariant
            lambdaAbVariantId={deployment.lambdaAbVariantId}
          />
        )}
      </HStack>
      <Link
        fontSize="xs"
        noOfLines={1}
        maxW="240px"
        href={deployment.url}
        isExternal={true}
      >
        {deployment.url}
      </Link>
    </VStack>
  )
}

export default TableDeployedApplicationName
