import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  InputGroup,
  VStack,
} from "@chakra-ui/react"
import { BlockEntity, PageInteractionsEntity } from "@jackfruit/common"

import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"

interface Props {
  entity?: any
  blocksEntities: any
  isLoading: boolean
}

const AppPageFloatCtaActivationBlocksForm: React.FC<Props> = ({
  entity,
  isLoading,
  blocksEntities,
}) => {
  const { mutate: patchPageInteraction, status: patchStatus } =
    usePatchEntity<PageInteractionsEntity>("page-interactions")
  const isPatching = patchStatus === "loading"
  const defaultValues = entity

  const methods = useForm({ defaultValues })
  const { handleSubmit, control } = methods

  const enhancedSubmit = (data: any) => {
    patchPageInteraction({
      id: entity.id,
      ...data,
    })
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      <FormSectionHeader>Activation Blocks</FormSectionHeader>
      <FormProvider {...methods}>
        <FormContainer onSubmit={handleSubmit(enhancedSubmit)} mt={4}>
          <FormControl>
            <FormLabel>Options</FormLabel>
            <InputGroup>
              <Controller
                name="isAlwaysVisible"
                control={control}
                render={({ onChange, value }) => (
                  <Checkbox
                    checked={value}
                    defaultChecked={value}
                    onChange={e => onChange(e.target.checked)}
                  >
                    All Blocks
                  </Checkbox>
                )}
              />
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>Custom Selection</FormLabel>

            <Controller
              name="activationBlocks"
              control={control}
              render={({ onChange, value }) => (
                <CheckboxGroup value={value} onChange={onChange}>
                  <VStack alignItems="start">
                    {blocksEntities.data.map((block: BlockEntity) => (
                      <>
                        <Checkbox
                          key={block.id}
                          value={`p-${block.type}-${block.id}`}
                        >
                          {block.type}
                        </Checkbox>
                        <FormHelperText pb={4}>
                          Element ID: {block.elementId}
                        </FormHelperText>
                      </>
                    ))}
                  </VStack>
                </CheckboxGroup>
              )}
            />
          </FormControl>

          <FormActions isLoading={isLoading || isPatching} entity={entity} />
        </FormContainer>
      </FormProvider>
    </>
  )
}

export default AppPageFloatCtaActivationBlocksForm
