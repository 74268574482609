export const webappThemeConfig = {
  fonts: {
    heading: `"Poppins", "FontFallback", sans-serif`,
    body: `"Poppins", "FontFallback", sans-serif`,
  },
  shadows: {
    outline: "none",
  },
  colors: {
    background: "#ebedf0",
    warning: "#63B3ED",
    danger: "#E53E3E",
    info: "#F6E05E",
    upload: {
      50: "#e0f3fb",
      100: "#b0e0f4",
      200: "#7ecced",
      300: "#4db8e5",
      400: "#2aaae1",
      500: "#039cdc",
      600: "#008fce", // default
      700: "#007cbb",
      800: "#006ca7",
      900: "#004d86",
    },
    lightGray: {
      50: "#f9f9fa",
      100: "#eceef0",
      200: "#dee1e5",
      300: "#cfd3da",
      400: "#bec4cd",
      500: "#acb4be",
      600: "#97a1ae", // default
      700: "#7e8a9b",
      800: "#626d7c",
      900: "#394049",
    },
    primary: {
      50: "#bee7f7",
      100: "#94D6F1",
      200: "#79CBED",
      300: "#5FC0E9",
      400: "#44B5E5",
      500: "#2AAAE1", // default
      600: "#1F92CA",
      700: "#157BB3",
      800: "#0A639C",
      900: "#004C86",
      text: "white",
    },
    secondary: {
      50: "#9ba7b5",
      100: "#8695a6",
      200: "#728497",
      300: "#5e7289",
      400: "#4a617a",
      500: "#364f6b",
      600: "#314760", // default
      700: "#2b3f56",
      800: "#26374b",
      900: "#202f40",
    },
  },

  components: {
    Heading: {
      baseStyle: {
        color: "gray.800",
        fontWeight: "semibold",
      },
    },
    FormLabel: {
      baseStyle: {
        color: "gray.600",
        fontWeight: "semibold",
      },
    },
    Select: {
      baseStyle: {
        color: "gray.600",
      },
    },
    Text: {
      baseStyle: {
        color: "gray.800",
        fontWeight: "normal",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "semibold",
      },
      variants: {
        ghost: {
          textColor: "gray.600",
        },
      },
    },
  },
}
