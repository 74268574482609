import { Badge } from "@chakra-ui/react"
import { EntityId, LambdaAbVariantEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import React from "react"

interface Props {
  lambdaAbVariantId: EntityId
}

const DeploymentLambdaAbVariant: React.FC<Props> = ({ lambdaAbVariantId }) => {
  const { data: lambdaAbVariant } = useEntity<LambdaAbVariantEntity>(
    "lambda-ab-variants",
    lambdaAbVariantId
  )

  return <Badge colorScheme="yellow">λ {lambdaAbVariant?.name}</Badge>
}

export default DeploymentLambdaAbVariant
