import {
  Alert,
  AlertDescription,
  AlertIcon,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  Text,
} from "@chakra-ui/react"
import { FiPlus } from "react-icons/fi"

import { LambdaAbVariantEntity, SettingsEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import LambdaABTestConfigurationTable from "components/lambdaABTest/LambdaABTestConfigurationTable"
import FormActions from "forms/FormActions"
import { useCreateEntity } from "hooks/useCreateEntity"
import { useEntityList } from "hooks/useEntityList"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"

interface Props {
  entity?: SettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppSettingsLambdaAbTestForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      ...entity,
    },
  })

  const { mutate: createLambdaVariant } = useCreateEntity("lambda-ab-variants")
  const handleCreateLambdaVariant = useCallback(() => {
    createLambdaVariant({ settingId: entity!.id })
  }, [createLambdaVariant, entity])

  const { data: lambdaAbVariants } = useEntityList<LambdaAbVariantEntity>(
    "lambda-ab-variants",
    {
      query: {
        settingId: entity!.id,
      },
    },
    {
      refetchInterval: 3000,
    }
  )

  const totalPercent = lambdaAbVariants.data.reduce((acc, curr) => {
    return acc + curr.percent
  }, 0)

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Lambda A/B Test</FormSectionHeader>
      <Text mt={1} mb={3} color="gray.500" fontSize="sm" maxW="600px">
        Setup up an A/B test using Lambda redirect tests. Webapp must be
        deployed to at least 2 different buckets/domains. Please make sure you
        have entered the GrowthBook Experiment Id below or to the pages you want
        to test.
      </Text>

      <FormControl>
        <Checkbox ref={register} name="lambdaAbEnabled">
          Enable Lambda A/B Tests
        </Checkbox>
        <FormErrorMessage>
          {errors.lambdaAbEnabled && errors.lambdaAbEnabled.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>GrowthBook Experiment ID (Global)</FormLabel>
        <InputGroup>
          <Input ref={register} name="lambdaAbExperimentId" type="text" />
        </InputGroup>
        <FormHelperText maxW="600px" mb={3}>
          This is the global experiment ID that will be used for all pages. It
          will override any page specific experiment IDs.
        </FormHelperText>
        <FormErrorMessage>
          {errors.lambdaAbExperimentId && errors.lambdaAbExperimentId.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>Cookie Name</FormLabel>
        <InputGroup>
          <Input ref={register} name="lambdaAbCookieName" type="text" />
        </InputGroup>
        <FormHelperText maxW="600px" mb={3}>
          The name injected into the cookie. Names should be changed for each
          new experiment to allow users to be re-targeted into the 2 versions.
        </FormHelperText>
        <FormErrorMessage>
          {errors.lambdaAbCookieName && errors.lambdaAbCookieName.message}
        </FormErrorMessage>
      </FormControl>

      {Boolean(entity?.id) && (
        <>
          <FormSectionHeader>
            <Flex justifyContent="space-between">
              Variants
              <IconButton
                aria-label="add variant"
                icon={<FiPlus />}
                size="xs"
                rounded="full"
                colorScheme="primary"
                onClick={handleCreateLambdaVariant}
              />
            </Flex>
          </FormSectionHeader>
          <LambdaABTestConfigurationTable settingId={entity!.id} />
        </>
      )}
      {totalPercent !== 100 && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            Total percentage does not add up to 100%, currently {totalPercent}%.
          </AlertDescription>
        </Alert>
      )}

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsLambdaAbTestForm
