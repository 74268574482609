import { Box, Image, Text, Flex } from "@chakra-ui/react"
import React from "react"
import { DeviceMockupConfig } from "./deviceMockupConfig"

interface Props {
  url: string
  config: DeviceMockupConfig
  scale?: number
}

const DeviceMockup: React.FC<Props> = ({ url, config, scale = 0.7 }) => {
  const { mockup, frame, name, type } = config
  const { width: frameWidth, height: frameHeight } = frame
  const { height, width, y } = frame.window

  const frameHeightPx = `${frameHeight}px`
  const frameWidthPx = `${frameWidth}px`
  const heightPx = `${height}px`
  const widthPx = `${width}px`
  const yPx = `${y}px`

  const containerWidthPx = `${frameWidth * scale}px`
  const containerHeightPx = `${frameHeight * scale}px`
  const imageFolder = type === "mobile" ? "mobiles" : "tablets"

  return (
    <Flex direction="column" alignItems="center">
      <Box width={containerWidthPx} height={containerHeightPx} boxShadow="lg">
        <Box
          borderWidth={0}
          borderColor="red"
          position="relative"
          transform={`scale(${scale})`}
          transformOrigin="top left"
          width={frameWidthPx}
          height={frameHeightPx}
        >
          <Image
            position="absolute"
            top={0}
            src={`/assets/devices/${imageFolder}/${mockup}`}
            width={frameWidthPx}
            height={frameHeightPx}
          />
          <Box
            as="iframe"
            position="absolute"
            src={url}
            top={yPx}
            height={heightPx}
            width={widthPx}
          />
        </Box>
      </Box>
      <Text
        textAlign="center"
        fontSize="xs"
        mt={4}
        bg="gray.100"
        borderRadius="full"
        px={3}
        py={1}
      >
        {name}
      </Text>
    </Flex>
  )
}

export default DeviceMockup
