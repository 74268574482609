import { Text } from "@chakra-ui/layout"
import { Spinner } from "@chakra-ui/spinner"
import { EntityId, PageEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import React from "react"

interface Props {
  value: EntityId
}

const PageField = ({ value }: Props) => {
  const { data: page, isLoading } = useEntity<PageEntity>("pages", value)
  if (isLoading) {
    return <Spinner size="xs" color="blue.400" />
  }

  return (
    <Text>
      {page.name} [#{page.id}]
    </Text>
  )
}

const EmptyField = () => {
  return <Text>null</Text>
}

const ChangelogFieldPageId: React.FC<Props> = ({ value }) => {
  if (Boolean(value)) {
    return <PageField value={value} />
  } else {
    return <EmptyField />
  }
}

export default ChangelogFieldPageId
