import { Stack, VStack } from "@chakra-ui/react"
import React from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import AppCommonCRUDScreen from "../screens/AppCommonCRUDScreen"
import AppCommonHeaderGeneralForm from "../forms/AppCommonHeaderGeneralForm"
import AppCommonHeaderNavigationsForm from "../forms/AppCommonHeaderNavigationsForm"
import AppCommonBlockScreen from "../screens/AppCommonBlockScreen"
import AppCommonFooterScreen from "../screens/AppCommonFooterScreen"
import AppCommonHeaderScreen from "../screens/AppCommonHeaderScreen"

interface Props {}

const AppCommonNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <Stack direction="row" alignItems="start" spacing={6}>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/checkout`} />
        <Route path={`${match.path}/header`}>
          <VStack>
            <AppCommonCRUDScreen form={AppCommonHeaderGeneralForm} />
            <AppCommonCRUDScreen form={AppCommonHeaderNavigationsForm} />
            <AppCommonHeaderScreen />
          </VStack>
        </Route>
        <Route path={`${match.path}/footer`}>
          <AppCommonFooterScreen />
        </Route>
        <Route path={`${match.path}/fulfillment`}>
          <AppCommonBlockScreen type="fulfillment" />
        </Route>
        <Route path={`${match.path}/checkout`}>
          <AppCommonBlockScreen type="checkout" />
        </Route>
      </Switch>
    </Stack>
  )
}

export default AppCommonNavigation
