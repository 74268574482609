import { Box } from "@chakra-ui/layout"
import "ace-builds"
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/webpack-resolver"
import React, { useState } from "react"
import AceEditor from "react-ace"

const editorProps = {
  $blockScrolling: false,
}

const setOptions = {
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: true,
  autoScrollEditorIntoView: true,
  indentedSoftWrap: true,
  enableSnippets: true,
  wrap: true,
  tabSize: 2,
}

interface Props {
  value: string
  mode: string
  name: string
  height?: string
  onChange?: (value: string) => void
}

const AceEditorEnhanced: React.FC<Props> = ({
  value = "",
  mode,
  name,
  height = "400px",
  onChange,
  ...props
}) => {
  const [content, setContent] = useState(value)

  const onContentChange = (newValue: string) => {
    setContent(newValue)
    onChange?.(newValue)
  }

  return (
    <Box
      boxShadow="xs"
      borderRadius={6}
      overflow="hidden"
      width="100%"
      height="100%"
    >
      <AceEditor
        mode={mode}
        theme="tomorrow"
        height="400px"
        width="100%"
        value={content}
        fontSize={13}
        onChange={onContentChange}
        name={name}
        setOptions={setOptions}
        editorProps={editorProps}
        debounceChangePeriod={100}
        {...props}
      />
    </Box>
  )
}

export default AceEditorEnhanced
