import { Stack } from "@chakra-ui/react"
import React from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import AppDeploymentDetailsScreen from "../AppDeploymentDetailsScreen"
import AppDeploymentUpgradePreviewScreen from "../AppDeploymentUpgradePreviewScreen"
import AppDeploymentUpgradeScreen from "../AppDeploymentUpgradeScreen"

interface Props {}

const AppNavigationNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <Stack direction="row" alignItems="start" spacing={6}>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/general`} />
        <Redirect
          exact
          from={`${match.url}/edit`}
          to={`${match.url}/general`}
        />
        <Route path={`${match.path}/general`}>
          <AppDeploymentDetailsScreen />
        </Route>
        <Route path={`${match.path}/upgrade`}>
          <AppDeploymentUpgradeScreen />
        </Route>
        <Route path={`${match.path}/upgrade-preview`}>
          <AppDeploymentUpgradePreviewScreen />
        </Route>
      </Switch>
    </Stack>
  )
}

export default AppNavigationNavigation
