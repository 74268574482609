import { Stack } from "@chakra-ui/react"
import { BlockEntity, NavigationEntity } from "@jackfruit/common"
import Body from "components/Body"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import { useResourceList } from "hooks/useResourceList"
import { RouteParams } from "navigation/RouteParams"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useAppCommon } from "../hooks/useAppCommon"

interface Props {
  form: React.FC<any>
}

const AppCommonCRUDScreen: React.FC<Props> = ({ form }) => {
  const { appId } = useParams<RouteParams>()
  const {
    entity: commonEntity,
    isLoading: commonEntityIsLoading,
    isPatching,
    isCreating,
    onSubmit,
  } = useAppCommon(parseInt(appId))

  const [navigationQuery] = useState({
    query: {
      appId,
    },
  })

  const { data: navigationEntities, isLoading: navigationEntitiesIsLoading } =
    useResourceList<NavigationEntity>("navigations", navigationQuery)

  const { data: blockEntity, isLoading: blockEntityIsLoading } =
    useFindOneEntity<BlockEntity>("blocks", {
      query: {
        appId,
        pageId: { $null: true },
        type: "header",
        $eager: "[header]",
      },
    })

  const isLoading =
    commonEntityIsLoading || navigationEntitiesIsLoading || blockEntityIsLoading

  const formComponent = React.createElement(form, {
    entity: commonEntity,
    navigations: navigationEntities.data,
    blockEntity: blockEntity,
    isLoading: isCreating || isPatching,
    onSubmit,
  })

  return (
    <Stack spacing={4}>
      <Body isLoading={isLoading}>{formComponent}</Body>
    </Stack>
  )
}

export default AppCommonCRUDScreen
