import { Icon, Image } from "@chakra-ui/react"
import { AssetEntity, EntityId } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import React from "react"
import { FaRegLightbulb } from "react-icons/fa"

interface Props {
  id: EntityId
  size?: number
}

const AppHeaderFavicon: React.FC<Props> = ({ id, size = 8 }) => {
  const {
    data: { path },
  } = useEntity<AssetEntity>("assets", id)

  return path ? (
    <Image src={path} ml={3} mr={2} my={2} boxSize={size} />
  ) : (
    <Icon as={FaRegLightbulb} ml={3} mr={2} my={2} boxSize={size} />
  )
}

export default AppHeaderFavicon
