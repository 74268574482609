import LayoutAdmin from "layouts/LayoutAdmin"
import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import AppNavigation from "screens/apps/AppNavigation"
import AssetListScreen from "screens/assets/AssetListScreen"
import SnippetsNavigation from "screens/snippets/SnippetsNavigation"
import SettingsNavigation from "screens/settings/SettingsNavigation"
import UserNavigation from "screens/users/UserNavigation"
import DeploymentNavigation from "screens/deployments/DeploymentNavigation"

interface Props {}

const AdminNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()

  return (
    <LayoutAdmin>
      <Switch>
        <Route path={`${match.path}/users`}>
          <UserNavigation />
        </Route>
        <Route path={`${match.path}/apps`}>
          <AppNavigation />
        </Route>
        <Route path={`${match.path}/deployments`}>
          <DeploymentNavigation />
        </Route>
        <Route path={`${match.path}/settings`}>
          <SettingsNavigation />
        </Route>
        <Route path={`${match.path}/snippets`}>
          <SnippetsNavigation />
        </Route>
        <Route path={`${match.path}/files`}>
          <AssetListScreen />
        </Route>
      </Switch>
    </LayoutAdmin>
  )
}

export default AdminNavigation
