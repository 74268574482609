import { useQuery } from "react-query"
import api, { Resources } from "services/api"
import { ApiQuery } from "services/api"
import { Entity } from "@jackfruit/common"

export const useFindOneEntity = <T extends Entity>(
  resource: Resources,
  query: ApiQuery,
  options: Object = {}
) => {
  const queryToUse = {
    ...query,
    query: {
      ...query.query,
      $limit: 1,
    },
  }
  return useQuery<T>(
    [resource, queryToUse],
    () => api.findOne<T>(resource, queryToUse),
    options
  )
}
