import { Button, HStack, Icon, useDisclosure } from "@chakra-ui/react"
import ConfirmDialog from "components/ConfirmDialog"
import { useCustomToast } from "hooks/useCustomToast"
import React, { useRef, useState } from "react"
import { FiRefreshCw } from "react-icons/fi"
import { useQueryClient } from "react-query"
import api from "services/api"
import { EntityType } from "./PrintServiceConfigurationTable"
import { productsConfigurationContent } from "./PrintServiceConfigurationTableAction"

interface Props {
  entityType: EntityType
  entityId: number
  printServiceId: number
}

const PrintServiceProductConfigurationTableAction: React.FC<Props> = ({
  entityType,
  entityId,
  printServiceId,
}) => {
  const toast = useCustomToast()
  const [isSyncing, setIsSyncing] = useState(false)
  const [isResetting, setIsResetting] = useState(false)
  const {
    isOpen: isOpenSync,
    onOpen: onOpenSync,
    onClose: onCloseSync,
  } = useDisclosure()
  const {
    isOpen: isOpenReset,
    onOpen: onOpenReset,
    onClose: onCloseReset,
  } = useDisclosure()
  const cancelSyncRef = useRef<HTMLButtonElement>(null)
  const cancelResetRef = useRef<HTMLButtonElement>(null)

  const queryClient = useQueryClient()

  const onSync = async () => {
    onCloseSync()
    setIsSyncing(true)
    try {
      if (entityType === "app") {
        await api.create("sync-app-with-remote-api", { id: entityId })
      }
      await api.find("sync-product-configurations-for-entity", {
        query: {
          entityType: entityType,
          entityId: entityId,
        },
      })
    } catch (error: any) {
      console.error(error)
      toast({
        title: `Unable to sync`,
        description: ``,
        status: "error",
        duration: 10000,
        isClosable: true,
      })
    }
    queryClient.invalidateQueries("print-service-product-configurations")
    setIsSyncing(false)
  }

  const onReset = async () => {
    onCloseReset()
    setIsResetting(true)
    try {
      await api.patch("reset-print-service-product-configuration", null, {
        query: { entityType, entityId, printServiceId },
      })
    } catch (error: any) {
      console.error(error)
      toast({
        title: `Unable to reset`,
        description: ``,
        status: "error",
        duration: 10000,
        isClosable: true,
      })
    }
    queryClient.invalidateQueries("print-service-product-configurations")
    setIsResetting(false)
  }

  return (
    <>
      <HStack spacing={2}>
        <Button colorScheme="red" onClick={onOpenReset} isLoading={isResetting}>
          Reset
        </Button>
        <Button
          colorScheme="primary"
          leftIcon={<Icon as={FiRefreshCw} />}
          onClick={onOpenSync}
          isLoading={isSyncing}
        >
          {productsConfigurationContent[entityType].button}
        </Button>
      </HStack>

      <ConfirmDialog
        text={{
          title: `Reset ${entityType} product configuration`,
          content: `We are going to reset the ${entityType} product configuration. Are you sure?`,
        }}
        isOpen={isOpenReset}
        cancelRef={cancelResetRef}
        onCancel={onCloseReset}
        onConfirm={onReset}
      />
      <ConfirmDialog
        text={{
          title: productsConfigurationContent[entityType].title,
          content: productsConfigurationContent[entityType].content,
        }}
        isOpen={isOpenSync}
        cancelRef={cancelSyncRef}
        onCancel={onCloseSync}
        onConfirm={onSync}
      />
    </>
  )
}

export default PrintServiceProductConfigurationTableAction
