import { Entity, EntityId } from "./Entity"
import { PrintServiceProductEntityHydrated } from "./PrintServiceProductEntity"

export type FulfillmentTypes = "pickup" | "delivery"

export const allFulfillments: FulfillmentTypes[] = ["pickup", "delivery"]

export interface PrintServiceMetaData {
  displayAutopilotOrderId: boolean
  orderCancellation: {
    emailTemplateKey: string
    toEmailAddress: string
  }
}

export interface PrintServiceEntity extends Entity {
  appId: number
  remoteId: number
  displayName: string
  defaultCurrency: string
  fulfillmentType: FulfillmentTypes
  printQuantityLimit?: number
  printQuantityLimitPerLineItem?: number
  printServiceProducts: EntityId[]
  clientId: number
  enforceCurrency: boolean
  enforceRetailerPricing: boolean
  enforceUserAddress: boolean
  hasCoupon: boolean
  displayTax: boolean
  isStoresGenerable: boolean
  metaData: PrintServiceMetaData
  products?: EntityId[]
}

export interface PrintServiceEntityHydrated
  extends Omit<PrintServiceEntity, "printServiceProducts" | "products"> {
  printServiceProducts: PrintServiceProductEntityHydrated[]
  products?: PrintServiceProductEntityHydrated[]
}
