import { Kind, SchemaOptions, Type, TypeRegistry } from '@sinclair/typebox'

// create a custom type on the type registry
TypeRegistry.Set('StringEnum', (schema: { enum: string[] }, value: unknown) => {
    return typeof value === 'string' && schema.enum.includes(value)
})

export function StringEnum<T extends string[]>(
    values: [...T],
    options: SchemaOptions = {}
) {
    return Type.Unsafe<T[number]>({
        ...options,
        [Kind]: 'StringEnum',
        enum: values,
    })
}
export default StringEnum
