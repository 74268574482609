import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
} from "@chakra-ui/react"
import { BlockFooterEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import CodePreview from "components/CodePreview/CodePreview"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"

interface Props {
  entity?: any
  onSubmit: (data: any) => void
  isLoading: boolean
  blockEntity: any
}

const AppCommonHeaderGeneralForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
  blockEntity,
}) => {
  const { header: dataBlockHeader } = blockEntity
  const defaultValues = { ...entity, header: dataBlockHeader }

  const methods = useForm({ defaultValues })
  const { handleSubmit, register, errors, control } = methods

  const { mutate: saveBlockHeader } =
    usePatchEntity<BlockFooterEntity>("blocks-header")
  const enhancedSubmit = (data: any) => {
    // Save block header
    const blockHeader = { id: dataBlockHeader.id, ...data.header }
    saveBlockHeader(blockHeader)

    // Save common entity
    const { header, ...rest } = data
    const commonData = rest
    onSubmit(commonData)
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
        <FormSectionHeader>General</FormSectionHeader>
        <FormControl isInvalid={errors.hideHeader}>
          <InputGroup>
            <Checkbox
              ref={register}
              name="hideHeader"
              defaultChecked={entity?.hideHeader}
            >
              Hide header
            </Checkbox>
          </InputGroup>
          <FormErrorMessage>
            {errors.hideHeader && errors.hideHeader.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel>Call To Action Button (Custom HTML)</FormLabel>
          <Controller
            as={CodePreview as any}
            name="header.callToActionHtml"
            control={control}
          />
          <FormHelperText maxW="600px" mb={3}>
            {`Example for copy-paste: <button class="chakra-button header-call-to-action-button" id="p-nav-get-started" onclick="api.scrollToNextActionableBlock()">Get Started</button>`}
          </FormHelperText>
        </FormControl>

        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default AppCommonHeaderGeneralForm
