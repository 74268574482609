import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Flex } from "@chakra-ui/layout"
import { Switch } from "@chakra-ui/switch"
import { BlockCartEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import AssetInput from "forms/inputs/AssetInput"
import React from "react"
import { Controller, useForm } from "react-hook-form"

interface Props {
  entity: BlockCartEntity
  isLoading: boolean
  readOnly: boolean
  onSubmit: (data: BlockCartEntity) => void
}

const BlockCartForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
  readOnly = false,
}) => {
  const defaultValues = {
    ...entity,
  }

  const { handleSubmit, register, control } = useForm<BlockCartEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormControl display="flex" alignItems="center">
        <Flex flexDirection="column">
          <FormLabel htmlFor="enable-dropzone" mb={0} cursor="pointer">
            Enable dropzone?
          </FormLabel>

          <Switch
            ref={register}
            id="enable-dropzone"
            name="isDropzoneEnabled"
            isDisabled={readOnly}
            size="md"
            mt={2}
          />
        </Flex>
      </FormControl>

      <FormControl display="flex" alignItems="center">
        <Flex flexDirection="column">
          <FormLabel mb={0}>Custom dropzone image</FormLabel>

          <Controller
            as={AssetInput as any}
            name="customDropzoneImageId"
            control={control}
          />
        </Flex>
      </FormControl>

      <FormActions
        canCancel={false}
        isLoading={isLoading}
        entity={entity}
        readOnly={readOnly}
      />
    </FormContainer>
  )
}

export default BlockCartForm
