import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  VStack,
} from "@chakra-ui/react"
import CodePreview from "components/CodePreview/CodePreview"
import ColorPickerInput from "forms/inputs/ColorPickerInput"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"

const columnsPrefix = ["pickup", "delivery"]
const allStyles = ["Full-width", "Boxed"]
const layouts = ["Desktop", "Mobile"]

interface Props {
  readOnly?: boolean
}

const BlockFulfillmentFields: React.FC<Props> = ({ readOnly = false }) => {
  const { register, control, getValues } = useFormContext()

  const styleOptions = allStyles.map(style => (
    <option key={style} value={style}>
      {style}
    </option>
  ))

  return (
    <>
      <FormControl isRequired>
        <FormLabel>Main title</FormLabel>
        <Input
          type="text"
          name="mainTitle"
          ref={register()}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Layout Style</FormLabel>
        <InputGroup>
          <Select
            ref={register}
            name="fulfillmentStyle"
            placeholder="Select a layout"
            disabled={readOnly}
          >
            {styleOptions}
          </Select>
        </InputGroup>
      </FormControl>

      <Tabs>
        <TabList>
          {layouts.map((layout, index) => (
            <Tab key={index}>{layout}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {layouts.map((layout, index) => {
            const setName = (name: string) =>
              layout === "Desktop" ? name : `${name}${layout}`

            return (
              <VStack as={TabPanel} key={index}>
                <FormControl>
                  <FormLabel>Container background color</FormLabel>
                  <Controller
                    render={({ name, value, onChange }) => (
                      <ColorPickerInput
                        onChange={onChange}
                        value={value}
                        name={name}
                        disabled={readOnly}
                      />
                    )}
                    name={setName("containerBgColor")}
                    control={control}
                    defaultValue={getValues("containerBgColor") || "#272727"}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Font color</FormLabel>
                  <Controller
                    render={({ name, value, onChange }) => (
                      <ColorPickerInput
                        onChange={onChange}
                        value={value}
                        name={name}
                        disabled={readOnly}
                      />
                    )}
                    name={setName("fontColor")}
                    control={control}
                    defaultValue={getValues("fontColor") || "#eee"}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Check icon - Background color</FormLabel>
                  <Controller
                    render={({ name, value, onChange }) => (
                      <ColorPickerInput
                        onChange={onChange}
                        value={value}
                        name={name}
                        disabled={readOnly}
                      />
                    )}
                    name={setName("checkBgColor")}
                    control={control}
                    defaultValue={getValues("checkBgColor") || "#fd8181"}
                  />
                </FormControl>

                <Tabs
                  w="full"
                  borderWidth={1}
                  borderStyle="solid"
                  borderColor="#efefef"
                  borderRadius={4}
                >
                  <TabList>
                    {columnsPrefix.map((prefix, index) => (
                      <Tab key={index}>
                        {prefix.charAt(0).toUpperCase() + prefix.slice(1)}{" "}
                        column
                      </Tab>
                    ))}
                  </TabList>

                  <TabPanels>
                    {columnsPrefix.map((prefix, index) => (
                      <TabPanel key={index}>
                        <FormControl>
                          <FormLabel>Title</FormLabel>
                          <Input
                            type="text"
                            name={setName(`${prefix}Title`)}
                            ref={register()}
                            disabled={readOnly}
                          />
                        </FormControl>
                        <FormControl marginTop={2}>
                          <FormLabel>Description</FormLabel>
                          <Textarea
                            height={10}
                            resize="vertical"
                            name={setName(`${prefix}Description`)}
                            ref={register()}
                            disabled={readOnly}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Svg icon (optional)</FormLabel>
                          <Controller
                            as={CodePreview}
                            name={setName(`${prefix}SvgIcon`)}
                            control={control}
                            readOnly={readOnly}
                          />
                        </FormControl>
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              </VStack>
            )
          })}
        </TabPanels>
      </Tabs>
    </>
  )
}

export default React.memo(BlockFulfillmentFields)
