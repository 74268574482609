import { FormControl, FormLabel } from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import { EntityFormProps } from "forms/EntityFormProps"
import FormActions from "forms/FormActions"
import AssetInput from "forms/inputs/AssetInput"
import React from "react"
import { Controller, useForm } from "react-hook-form"

interface Props extends EntityFormProps<AppEntity> {}

const AppBrandingGeneralImageryForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }
  const { handleSubmit, control } = useForm<AppEntity>({
    defaultValues,
  })
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Imagery</FormSectionHeader>
      <FormControl>
        <FormLabel>Favicon Image</FormLabel>
        <Controller
          as={AssetInput as any}
          name="faviconImageId"
          control={control}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Logo Image</FormLabel>
        <Controller
          as={AssetInput as any}
          name="logoImageId"
          control={control}
        />
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppBrandingGeneralImageryForm
