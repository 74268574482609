import { Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { AssetEntity } from '@jackfruit/common'
import { useUploadAsset } from 'hooks/useUploadAsset'
import React, { useCallback, useEffect, useState } from 'react'

import { useDropzone } from 'react-dropzone'
import { FiUpload } from 'react-icons/fi'
interface Props {
  onComplete: (data: AssetEntity) => void
  onStart?: () => void
  onRemove?: () => void
  onCancel?: () => void
}

const AssetsDropUploader: React.FC<Props> = ({ onComplete }) => {
  const { upload } = useUploadAsset()
  const [isUploading, setIsUploading] = useState(false)
  const [totalUploaded, setTotalUploaded] = useState(0)
  const [totalFiles, setTotalFiles] = useState(0)

  useEffect(() => {
    if (totalUploaded === totalFiles) {
      setIsUploading(false)
      setTotalUploaded(0)
      setTotalFiles(0)
    }
  }, [totalFiles, totalUploaded])

  const onDrop = useCallback(
    async (files) => {
      try {
        setTotalFiles(files.length)
        setIsUploading(true)

        await Promise.all(
          files.map(async (file: File) => {
            const asset = await upload({ file })
            onComplete(asset)
            setTotalUploaded((value: number) => value + 1)
          })
        )

        setIsUploading(false)
      } catch (error: any) {
        console.error(error)
      }
    },
    [upload, onComplete]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    noClick: true,
    multiple: true,
    onDrop,
  })

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        border={1}
        borderColor="gray.200"
        borderStyle="dashed"
        borderRadius={4}
        backgroundColor="#fafafa"
        p={15}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Text marginBottom={2} color="gray.400">
          Drag and drop images here
        </Text>

        <Button
          leftIcon={<FiUpload />}
          color="gray.500"
          variant="solid"
          onClick={open}
        >
          Open file dialog
        </Button>

        <Flex
          flexDirection="column"
          alignItems="start"
          alignContent="center"
          justifyContent="center"
          w="100%"
        >
          {isUploading && (
            <>
              <Spinner color="red.500" mx="auto" mt={4} />
              <Text
                textAlign="center"
                fontStyle="italic"
                text-align="center"
                display="block"
                width="100%"
                marginTop="10px"
                fontSize="sm"
              >
                <strong>{totalUploaded} </strong> image(s) uploaded /{' '}
                <strong>{totalFiles}</strong>
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default AssetsDropUploader
