export type DeviceMockupConfig = {
  name: string
  mockup: string
  type: "mobile" | "tablet"
  frame: {
    width: number
    height: number
    window: {
      x: number
      y: number
      width: number
      height: number
    }
  }
}

export const mobileDevicesMockupConfig: DeviceMockupConfig[] = [
  {
    name: "iPhone 14 - Safari",
    mockup: "iPhone 14 - Safari.png",
    type: "mobile",
    frame: {
      width: 390,
      height: 844,
      window: {
        width: 390,
        height: 663,
        x: 0,
        y: 47,
      },
    },
  },
  {
    name: "iPhone 14 Plus - Safari",
    mockup: "iPhone 14 Plus - Safari.png",
    type: "mobile",
    frame: {
      width: 428,
      height: 926,
      window: {
        width: 428,
        height: 745,
        x: 0,
        y: 47,
      },
    },
  },
  {
    name: "iPhone 11 Pro Max - Chrome",
    mockup: "iPhone 11 Pro Max - Chrome.png",
    type: "mobile",
    frame: {
      width: 414,
      height: 896,
      window: {
        width: 414,
        height: 720,
        x: 0,
        y: 98,
      },
    },
  },
  {
    name: "iPhone 11 Pro Max - Safari",
    mockup: "iPhone 11 Pro Max - Safari.png",
    type: "mobile",
    frame: {
      width: 414,
      height: 896,
      window: {
        width: 414,
        height: 718,
        x: 0,
        y: 44,
      },
    },
  },
  {
    name: "iPhone Iphone 14 Plus - Chrome",
    mockup: "iPhone Iphone 14 Plus - Chrome.png",
    type: "mobile",
    frame: {
      width: 428,
      height: 926,
      window: {
        width: 428,
        height: 722,
        x: 0,
        y: 116,
      },
    },
  },
  {
    name: "Galaxy S21 - Chrome",
    mockup: "Galaxy S21 - Chrome.png",
    type: "mobile",
    frame: {
      width: 360,
      height: 800,
      window: {
        width: 360,
        height: 683,
        x: 0,
        y: 70,
      },
    },
  },
  {
    name: "iPhone SE (3rd generation)",
    mockup: "iPhone SE (3rd generation).png",
    type: "mobile",
    frame: {
      width: 375,
      height: 667,
      window: {
        width: 375,
        height: 547,
        x: 0,
        y: 20,
      },
    },
  },
  {
    name: "Galaxy A03s Samsumg Internet",
    mockup: "Galaxy A03s Samsumg Internet.png",
    type: "mobile",
    frame: {
      width: 339,
      height: 753,
      window: {
        width: 339,
        height: 610,
        x: 0,
        y: 66,
      },
    },
  },
  {
    name: "iPhone 15 Pro Max - Safari",
    mockup: "iPhone 15 Pro Max - Safari.png",
    type: "mobile",
    frame: {
      width: 430,
      height: 932,
      window: {
        width: 430,
        height: 739,
        x: 0,
        y: 59,
      },
    },
  },
  {
    name: "iPhone 15 Pro Max - Chrome",
    mockup: "iPhone 15 Pro Max - Chrome.png",
    type: "mobile",
    frame: {
      width: 430,
      height: 932,
      window: {
        width: 430,
        height: 731,
        x: 0,
        y: 119,
      },
    },
  },
  {
    name: "Galaxy S23 Ultra - Chrome",
    mockup: "Galaxy S23 Ultra - Chrome.png",
    type: "mobile",
    frame: {
      width: 384,
      height: 824,
      window: {
        width: 384,
        height: 687,
        x: 0,
        y: 88,
      },
    },
  },
  {
    name: "iPhone 15 Pro - Chrome",
    mockup: "iPhone 15 Pro - Chrome.png",
    type: "mobile",
    frame: {
      width: 393,
      height: 852,
      window: {
        width: 393,
        height: 665,
        x: 0,
        y: 109,
      },
    },
  },
  {
    name: "iPhone 15 Pro - Safari",
    mockup: "iPhone 15 Pro - Safari.png",
    type: "mobile",
    frame: {
      width: 393,
      height: 852,
      window: {
        width: 393,
        height: 659,
        x: 0,
        y: 59,
      },
    },
  },
  {
    name: "iPhone 13 mini - Safari",
    mockup: "iPhone 13 mini - Safari.png",
    type: "mobile",
    frame: {
      width: 375,
      height: 812,
      window: {
        width: 375,
        height: 629,
        x: 0,
        y: 50,
      },
    },
  },
  {
    name: "iPhone Iphone 13 mini - Chrome",
    mockup: "iPhone Iphone 13 mini - Chrome.png",
    type: "mobile",
    frame: {
      width: 375,
      height: 812,
      window: {
        width: 375,
        height: 654,
        x: 0,
        y: 88,
      },
    },
  },
  {
    name: "iPhone Touch - 7th gen",
    mockup: "iPhone Touch - 7th gen.png",
    type: "mobile",
    frame: {
      width: 320,
      height: 568,
      window: {
        width: 320,
        height: 454,
        x: 0,
        y: 70,
      },
    },
  },
  {
    name: "Samsung Galaxy S21 - Chrome",
    mockup: "Samsung Galaxy S21 - Chrome.png",
    type: "mobile",
    frame: {
      width: 360,
      height: 800,
      window: {
        width: 360,
        height: 684,
        x: 0,
        y: 70,
      },
    },
  },
  {
    name: "iPhone 8 Plus - Safari",
    mockup: "iPhone 8 Plus - Safari.png",
    type: "mobile",
    frame: {
      width: 414,
      height: 736,
      window: {
        width: 414,
        height: 623,
        x: 0,
        y: 69,
      },
    },
  },
  {
    name: "iPhone Iphone 14 - Chrome",
    mockup: "iPhone Iphone 14 - Chrome.png",
    type: "mobile",
    frame: {
      width: 390,
      height: 844,
      window: {
        width: 390,
        height: 656,
        x: 0,
        y: 107,
      },
    },
  },
]

// Top 6 iPad, iPad Air, iPad Mini and iPad Pro models (combined)
// Top 4 Samsung Galaxy Tab and Note models
export const tabletDevicesMockupConfig: DeviceMockupConfig[] = [
  {
    name: "Blue-M8LPLUS-SamsungInternet",
    mockup: "Blue-M8LPLUS-SamsungInternet.png",
    type: "tablet",
    frame: {
      width: 534,
      height: 854,
      window: {
        width: 534,
        height: 776,
        x: 0,
        y: 78,
      },
    },
  },
  {
    name: "iPad-9thgen-Chrome",
    mockup: "iPad-9thgen-Chrome.png",
    type: "tablet",
    frame: {
      width: 810,
      height: 1080,
      window: {
        width: 810,
        height: 988,
        x: 0,
        y: 92,
      },
    },
  },
  {
    name: "iPad-9thgen-Safari",
    mockup: "iPad-9thgen-Safari.png",
    type: "tablet",
    frame: {
      width: 810,
      height: 1080,
      window: {
        width: 810,
        height: 1010,
        x: 0,
        y: 70,
      },
    },
  },
  {
    name: "iPadAir-3rdgen-Safari",
    mockup: "iPadAir-3rdgen-Safari.png",
    type: "tablet",
    frame: {
      width: 834,
      height: 1112,
      window: {
        width: 834,
        height: 1042,
        x: 0,
        y: 70,
      },
    },
  },
  {
    name: "iPadMini-5thgen-Safari",
    mockup: "iPadMini-5thgen-Safari.png",
    type: "tablet",
    frame: {
      width: 768,
      height: 1024,
      window: {
        width: 768,
        height: 954,
        x: 0,
        y: 70,
      },
    },
  },
  {
    name: "iPadPro-6thgen11-Chrome",
    mockup: "iPadPro-6thgen11-Chrome.png",
    type: "tablet",
    frame: {
      width: 834,
      height: 1194,
      window: {
        width: 834,
        height: 1101,
        x: 0,
        y: 93,
      },
    },
  },
  {
    name: "iPadPro-6thgen12-Chrome",
    mockup: "iPadPro-6thgen12-Chrome.png",
    type: "tablet",
    frame: {
      width: 1024,
      height: 1366,
      window: {
        width: 1024,
        height: 1251,
        x: 0,
        y: 115,
      },
    },
  },
  {
    name: "iPadPro-6thgen12-Safari",
    mockup: "iPadPro-6thgen12-Safari.png",
    type: "tablet",
    frame: {
      width: 1024,
      height: 1366,
      window: {
        width: 1024,
        height: 1292,
        x: 0,
        y: 74,
      },
    },
  },
  {
    name: "iPadPro-10thgen-Chrome",
    mockup: "iPadPro-10thgen-Chrome.png",
    type: "tablet",
    frame: {
      width: 820,
      height: 1180,
      window: {
        width: 820,
        height: 1088,
        x: 0,
        y: 92,
      },
    },
  },
  {
    name: "iPadPro-10thgen-Safari",
    mockup: "iPadPro-10thgen-Safari.png",
    type: "tablet",
    frame: {
      width: 820,
      height: 1180,
      window: {
        width: 820,
        height: 1106,
        x: 0,
        y: 74,
      },
    },
  },
]
