import { Entity, EntityId } from "./Entity"
import { PrintServiceProductImageEntityHydrated } from "./PrintServiceProductImageEntity"

export type ProductType = "template" | "frame" | "print"

export enum CategoryNames {
  giftCertificate = "gift certificate",
  print = "print",
  mug = "mug",
  canvas = "canvas",
  card = "card",
  book = "book",
  photoBook = "photo-book",
  magnet = "magnet",
  calendar = "calendar",
  poster = "poster",
  other = "other",
  panel = "panel",
  collage = "collage",
  wallet = "wallet",
  metal = "metal",
  floatingFrame = "floating frame",
  drinkware = "drinkware",
  badge = "badge",
  ediblePrint = "edible print",
  puzzle = "puzzle",
  blanket = "blanket",
  flooring = "flooring",
  apparel = "apparel",
  instax = "instax-style-print",
  ornament = "ornament",
  lenticularCard = "lenticular-card",
  wrappingPaper = "wrapping-paper",
  tile = "tile",
  passportPhoto = "passport-photo",
  homeAndOffice = "home and office",
  circleCookies = "circle-cookies",
  starCookies = "star-cookies",
  heartCookies = "heart-cookies",
  squareCookies = "square-cookies",
  rectangleCookies = "rectangle-cookies",
  artPrint = "art print",
  wallDecor = "wall decor",
  gift = "gift",
  playingCards = "playing cards",
  tileMetal = "tile-metal",
  petProducts = "pet products",
  mountedPrint = "mounted print",
  mattelBarbie = "mattel-barbie",
  keepsakes = "Keepsakes",
  acrylicPrint = "acrylic-print",
  pillowcase = "pillowcase",
}

export type OrientationType = "portrait" | "landscape"

export type categoryName =
  | "gift certificate"
  | "print"
  | "mug"
  | "canvas"
  | "card"
  | "book"
  | "photo-book"
  | "magnet"
  | "calendar"
  | "poster"
  | "other"
  | "panel"
  | "collage"
  | "wallet"
  | "metal"
  | "floating frame"
  | "drinkware"
  | "badge"
  | "edible print"
  | "puzzle"
  | "blanket"
  | "flooring"
  | "apparel"
  | "ornament"
  | "lenticular-card"
  | "wrapping-paper"
  | "instax-style-print"
  | "tile"
  | "passport-photo"
  | "home and office"
  | "circle-cookies"
  | "star-cookies"
  | "heart-cookies"
  | "square-cookies"
  | "rectangle-cookies"
  | "art print"
  | "wall decor"
  | "gift"
  | "playing cards"
  | "tile-metal"
  | "pet products"
  | "mounted print"
  | "mattel-barbie"
  | "Keepsakes"
  | "acrylic-print"
  | "pillowcase"

export type tObjectFit = "fit" | "fill" | "none"

interface PrintServiceProductMetaData {
  wrap: number
  frame?: boolean
  render: {
    width: number
    height: number
  }
  web?: {
    displayPricing?: boolean
    productListBadge?: {
      text: string
      background?: string
      color?: string
      fontSize?: string
      border?: string
    }
    editor?: {
      clamp?: boolean
      imageFit?: tObjectFit
    }
  }
  card?: {
    folded: boolean
    border: {
      height: number
      width: number
      spine: boolean
      size: number
      type: string
    }
  }
  orientationOverride?: OrientationType
}

export interface PrintServiceProductEntity extends Entity {
  printServiceId: number
  remoteId: number
  isUnavailable: boolean
  name: string
  shortDescription: string
  longDescription: string
  categoryName: categoryName
  productCode: string
  secondaryProductCode: string
  finish: string
  displayFinish: string
  displaySize: string
  size: string
  position: number
  minImages: number
  maxImages: number
  height: number
  width: number
  pixelHeight: number
  pixelWidth: number
  // category: EntityId
  // for compatibility with remote api
  productImages?: EntityId[]
  category: EntityId
  printService: string
  prices: EntityId[]
  metaData: PrintServiceProductMetaData
  isDefaultSquareProduct: boolean
  isDefaultRectangleProduct: boolean
  printServiceProductImages: EntityId[]
}

export interface PrintServiceProductEntityHydrated
  extends Omit<PrintServiceProductEntity, "printServiceProductImages"> {
  overridedId?: EntityId
  updated?: boolean
  printServiceProductImages?: PrintServiceProductImageEntityHydrated[]
}
