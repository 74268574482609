import { Container, forwardRef } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import { useEffectOnce } from "react-use"
import { CpContext, themes } from "./CpContext"
import CpEditor from "./CpEditor"
import CpModal from "./CpModal"

export interface ObjectValue {
  html: string
  css: string
}

interface Props {
  name: string
  value: string
  cssValue?: string
  cssIsGlobal?: boolean
  readOnly?: boolean
  editorHeight?: string
  enableSnippets?: boolean
  onChange: (value: any) => void
}

const CodePreview = forwardRef<Props, "input">(
  (
    {
      name,
      value,
      readOnly = false,
      editorHeight = "300px",
      onChange,
      enableSnippets = false,
    },
    ref
  ) => {
    const [maximizeMode, setMaximizeMode] = useState(false)
    const [modalState, setModalState] = useState(false)
    const [darkMode, setDarkMode] = useState(false)
    const [snippetsMode, setSnippetsMode] = useState(enableSnippets)
    const [theme, setTheme] = useState(themes.dark)
    const [htmlContent, setHtmlContent] = useState("")

    useEffectOnce(() => {
      setHtmlContent(value)
    })

    useEffect(() => {
      setTheme(themes[darkMode ? "dark" : "light"])
    }, [darkMode])

    const onToggleMode = useCallback(
      (mode: string) => {
        switch (mode) {
          case "snippets":
            setSnippetsMode(snippetsMode => !snippetsMode)
            break
          case "dark":
            setDarkMode(darkMode => !darkMode)
            break
          case "maximize":
            setMaximizeMode(maximizeMode => !maximizeMode)
            break
          case "fullscreen":
            if (modalState) {
              setMaximizeMode(false)
            }
            setModalState(modalState => !modalState)
            break
        }
      },
      [modalState]
    )

    const onChangeHandler = (editedValue: string) => {
      setHtmlContent(editedValue)
      onChange(editedValue)
    }

    return (
      <CpContext.Provider
        value={{
          htmlValue: htmlContent,
          onToggleMode,
          maximizeMode,
          editorHeight,
          snippetsMode,
          enableSnippets,
          modalState,
          darkMode,
          readOnly,
          theme,
          name,
        }}
      >
        <Container
          id="previewContainer"
          position="relative"
          maxW="100%"
          padding={0}
          borderRadius={5}
          borderWidth={1}
          borderColor={theme.background}
          ref={ref}
        >
          {!modalState && <CpEditor onChange={onChangeHandler} />}
        </Container>

        {modalState && <CpModal onChange={onChangeHandler} />}
      </CpContext.Provider>
    )
  }
)

export default CodePreview
