import { EntityId } from "@jackfruit/common"
import { BlockEntity } from "@jackfruit/common"
import React, { useCallback } from "react"
import BlockItem from "./BlockItem"

interface Props {
  blocks: BlockEntity[]
  referers: BlockEntity[]
  onDelete: () => void
  onOverride: () => void
  onSelect: (id: EntityId) => void
  selectedBlockId: EntityId | null
}

const BlocksEditor: React.FC<Props> = ({
  blocks,
  referers,
  onDelete,
  onOverride,
  onSelect,
  selectedBlockId,
}) => {
  const findReferer = useCallback(
    (type: string) => {
      const referer = referers.find(referer => referer.type === type)
      return referer ? referer : null
    },
    [referers]
  )

  return (
    <>
      {blocks.map((block: BlockEntity) => (
        <BlockItem
          block={block}
          key={block.id}
          onDelete={onDelete}
          onOverride={onOverride}
          referer={findReferer(block.type)}
          onSelect={onSelect}
          isSelected={selectedBlockId === block.id}
        />
      ))}
    </>
  )
}

export default React.memo(BlocksEditor)
