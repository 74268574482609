import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
} from "@chakra-ui/react"
import { SettingsEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"
import { apiUrls } from "@jackfruit/common"

interface Props {
  entity?: SettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppSettingsServicesAutopilotPrintForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }

  const { handleSubmit, register, errors, watch } = useForm({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Autopilot Print</FormSectionHeader>

      <FormControl isInvalid={errors.remoteApiUrl}>
        <FormLabel>Client API Environment</FormLabel>
        <InputGroup>
          <Select
            ref={register}
            name="remoteApiUrl"
            placeholder="Select option"
          >
            {apiUrls.map(value => {
              return (
                <option key={value.url} value={value.url}>
                  {value.name}
                </option>
              )
            })}
          </Select>
        </InputGroup>
        <FormHelperText>{watch("remoteApiUrl")}</FormHelperText>
        <FormErrorMessage>
          {errors.remoteApiUrl && errors.remoteApiUrl.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.remoteApiClientApiKey}>
        <FormLabel>Client Key</FormLabel>
        <InputGroup>
          <Input ref={register} name="remoteApiClientApiKey" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.remoteApiClientApiKey && errors.remoteApiClientApiKey.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>A/B Test Attribute</FormLabel>
        <InputGroup>
          <InputLeftAddon children="ab-test-" />
          <Input ref={register} name="abTestAttribute" type="text" />
        </InputGroup>
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsServicesAutopilotPrintForm
