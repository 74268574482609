import React from "react"
import Body from "components/Body"
import { IntegrationsEntity } from "@jackfruit/common"
import { useCreateEntity } from "hooks/useCreateEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import Page from "components/Page"
import SentryForm from "./forms/SentryForm"

interface Props {}

const IntegrationsScreen: React.FC<Props> = () => {
  const { mutate: createIntegrations, status: createStatus } =
    useCreateEntity<IntegrationsEntity>("integrations", {
      showNotification: true,
      onSuccess: () => {
        //do nothing
      },
    })

  const { data: integrationsEntity, isLoading: integrationsEntityIsLoading } =
    useFindOneEntity<IntegrationsEntity>("integrations", {
      query: {},
    })

  const { mutate: patchIntegrations, status: patchStatus } =
    usePatchEntity<IntegrationsEntity>("integrations")

  const isCreating = createStatus === "loading"
  const isPatching = patchStatus === "loading"

  const onSubmit = (data: any) => {
    if (!integrationsEntity) {
      createIntegrations(data)
    } else {
      patchIntegrations({ id: integrationsEntity.id, ...data })
    }
  }

  const enhancedOnSubmit = async (data: any) => {
    onSubmit(data)
  }

  const formComponent = React.createElement(SentryForm, {
    entity: integrationsEntity,
    isLoading: isPatching || isCreating || integrationsEntityIsLoading,
    onSubmit: enhancedOnSubmit,
  })

  return (
    <Page title="Integrations">
      <Body isLoading={integrationsEntityIsLoading}>{formComponent}</Body>
    </Page>
  )
}

export default IntegrationsScreen
