const noneTestTags = ""
const defaultTestTags = "@default"
const fullTestTags = "@full"
const customTestTags = "custom"

export const automatedTestOptions = [
  {
    value: noneTestTags,
    label: "None",
  },
  {
    value: defaultTestTags,
    label: "Default",
  },
  {
    value: fullTestTags,
    label: "Full",
  },
  {
    value: customTestTags,
    label: "Custom",
  },
]
