import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import "ace-builds"
import "ace-builds/webpack-resolver"
import React from "react"
import { useForm } from "react-hook-form"
import FormSectionHeader from "components/FormSectionHeader"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import { GlobalDeploymentSettingsEntity } from "@jackfruit/common"

interface Props {
  entity?: GlobalDeploymentSettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const BuildBackupsForm: React.FC<Props> = ({ entity, isLoading, onSubmit }) => {
  const defaultValues: any = {
    ...entity,
  }

  const { handleSubmit, register, errors } =
    useForm<GlobalDeploymentSettingsEntity>({
      defaultValues,
    })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Build Backups</FormSectionHeader>

      <FormControl isInvalid={Boolean(errors.buildBackupS3BucketName)}>
        <FormLabel>S3 bucket name</FormLabel>
        <InputGroup>
          <Input ref={register()} name="buildBackupS3BucketName" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.buildBackupS3BucketName &&
            errors.buildBackupS3BucketName.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={Boolean(errors.buildBackupS3BucketPrefix)}>
        <FormLabel>S3 bucket prefix</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="buildBackupS3BucketPrefix"
            type="text"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.buildBackupS3BucketPrefix &&
            errors.buildBackupS3BucketPrefix.message}
        </FormErrorMessage>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default BuildBackupsForm
