import { HStack, useDisclosure, IconButton } from "@chakra-ui/react"
import React, { useCallback, useRef } from "react"
import { FiEdit, FiEye, FiTrash } from "react-icons/fi"
import { Link, useRouteMatch } from "react-router-dom"
import { useDeleteEntity } from "hooks/useDeleteEntity"
import ConfirmDialog from "components/ConfirmDialog"
import { Resources } from "services/api"

interface Props {
  resource: Resources
  entity: any
  canView?: boolean
  canEdit?: boolean
  canDelete?: boolean
  onEdit?: () => void
  onView?: () => void
  onDelete?: () => void
  size?: string
}

const TableRowActions: React.FC<Props> = ({
  resource,
  entity,
  canView = false,
  canEdit = false,
  canDelete = false,
  onEdit,
  onView,
  onDelete,
  children,
  size = "md",
}) => {
  const match = useRouteMatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const requireResourceInUrl = !match.url.endsWith(resource)

  const baseUrl = requireResourceInUrl ? `${match.url}/${resource}` : match.url

  const { mutate: remove } = useDeleteEntity(resource, {
    showNotification: true,
    invalidateQuery: true,
    onSuccess: async () => {
      if (onDelete) {
        onDelete()
      }
    },
  })

  const onDeleteAction = useCallback(async () => {
    remove(entity.id)
    onClose()
  }, [entity.id, onClose, remove])

  const editButton = onEdit ? (
    <IconButton
      aria-label="edit"
      icon={<FiEdit />}
      size={size}
      rounded="full"
      variant="ghost"
      colorScheme="gray"
      onClick={onEdit}
    />
  ) : (
    <IconButton
      aria-label="edit"
      icon={<FiEdit />}
      as={Link}
      to={`${baseUrl}/${entity.id}/edit`}
      size={size}
      rounded="full"
      variant="ghost"
      colorScheme="gray"
    />
  )

  const viewButton = onView ? (
    <IconButton
      aria-label="view"
      icon={<FiEye />}
      size={size}
      rounded="full"
      variant="ghost"
      colorScheme="gray"
      onClick={onView}
    />
  ) : (
    <IconButton
      aria-label="view"
      icon={<FiEye />}
      as={Link}
      to={`${baseUrl}/${entity.id}`}
      size={size}
      rounded="full"
      variant="ghost"
      colorScheme="gray"
    />
  )

  return (
    <>
      <HStack spacing={0} justifyContent="flex-end">
        {children}
        {canView && viewButton}
        {canEdit && editButton}
        {canDelete && (
          <IconButton
            aria-label="delete"
            icon={<FiTrash />}
            size={size}
            rounded="full"
            variant="ghost"
            colorScheme="red"
            onClick={onOpen}
          />
        )}
      </HStack>

      <ConfirmDialog
        text={{ title: "Delete record" }}
        isOpen={isOpen}
        cancelRef={cancelRef}
        onCancel={onClose}
        onConfirm={onDeleteAction}
      />
    </>
  )
}

export default TableRowActions
