import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  Text,
} from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import AutoCompleteInput, {
  AutoCompleteRenderInput,
  AutoCompleteRenderItem,
} from "components/AutocompleteDropDown/AutoCompleteInput"
import { FiChevronDown, FiX } from "react-icons/fi"

interface Props {
  name: string
  apps: AppEntity[]
}

const renderEmpty = () => {
  return (
    <Flex justifyContent="center" alignItems="center" w="full" py={1}>
      <Text fontSize="sm" color="gray.400">
        App not found
      </Text>
    </Flex>
  )
}

const renderInput: AutoCompleteRenderInput<AppEntity> = ({
  isDirty,
  search,
  setSearchValue,
  selected,
  clear,
  toggle,
}) => {
  const shouldShowSelected = !isDirty && selected
  const value = shouldShowSelected ? selected.name : search

  return (
    <Flex
      h={8}
      w={60}
      pl={2}
      bg="white"
      direction="row"
      borderWidth={1}
      borderColor="gray.200"
      borderRadius="md"
      alignItems="center"
    >
      <Input
        size="xs"
        variant="unstyled"
        ml={2}
        placeholder="Search"
        borderWidth={0}
        fontWeight="semibold"
        value={value}
        onChange={e => setSearchValue(e.target.value)}
      />

      {Boolean(value) && (
        <IconButton
          icon={<Icon as={FiX} />}
          aria-label="open"
          variant="unstyled"
          _focus={{ shadow: "none" }}
          onClick={clear}
        />
      )}
      <IconButton
        icon={<Icon as={FiChevronDown} />}
        aria-label="open"
        variant="unstyled"
        _focus={{ shadow: "none" }}
        onClick={toggle}
      />
    </Flex>
  )
}

const renderItem: AutoCompleteRenderItem<AppEntity> = (app, isSelected) => {
  return (
    <Box
      as={Button}
      w="full"
      px={0}
      colorScheme="primary"
      color="gray.600"
      variant="ghost"
      bg={isSelected ? "primary.50" : "transparent"}
      borderRadius="md"
    >
      <Text
        px={2}
        w="full"
        textAlign="left"
        fontSize="xs"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {app.name}
      </Text>
    </Box>
  )
}

const AppFilterInput: React.FC<Props> = ({ name, apps }) => {
  return (
    <AutoCompleteInput<AppEntity>
      name={name}
      data={apps}
      filterFunction={(app, search) =>
        app.name.toLowerCase().includes(search.toLowerCase())
      }
      keyExtractor={app => app.id}
      valueExtractor={app => app.id}
      renderItem={renderItem}
      renderInput={renderInput}
      renderEmpty={renderEmpty}
    />
  )
}

export default AppFilterInput
