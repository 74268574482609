import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Flex } from "@chakra-ui/react"
import { CpContext, CpContextInterface, EditorSize } from "./CpContext"
import AceEditor from "react-ace"
import "ace-builds"
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/webpack-resolver"
import CpHeader from "./CpHeader"

const setOptions = {
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: true,
  autoScrollEditorIntoView: true,
  indentedSoftWrap: true,
  enableSnippets: true,
  wrap: true,
  tabSize: 2,
  fontSize: 11,
}

const editorProps = {
  $blockScrolling: false,
}

interface Props {
  onChange: (value: string, type: string) => void
  editorSize?: EditorSize
}

const CpEditor: React.FC<Props> = ({ onChange, editorSize }) => {
  const { theme, readOnly, editorHeight, modalState, name, htmlValue } =
    useContext<CpContextInterface>(CpContext)

  const { background, aceTheme } = theme
  const editorRef = useRef<any>(null!)
  const [editorWidth, setEditorWidth] = useState(editorSize?.width || "100%")

  const [htmlContent, setHtmlContent] = useState(htmlValue)

  useEffect(() => {
    setHtmlContent(htmlValue || "")
  }, [htmlValue])

  useEffect(() => {
    if (editorSize?.width) {
      setEditorWidth(editorSize.width)
    }
  }, [editorSize])

  const onChangeHandle = useCallback(
    (value: string, type: string) => {
      setHtmlContent(value)
      onChange(value, type)
    },
    [onChange]
  )

  return (
    <>
      <Flex
        flexDirection="column"
        zIndex="0"
        position="relative"
        p={0}
        w="100%"
        h="100%"
      >
        <CpHeader />
        <Flex
          w="100%"
          h="100%"
          padding={0}
          borderRight={1}
          borderRadius={0}
          position="relative"
          borderColor={background}
        >
          <AceEditor
            mode="html"
            theme={aceTheme}
            height={!modalState ? editorHeight : "100%"}
            width={!modalState ? "100%" : `${editorWidth}px`}
            fontSize={13}
            name={name}
            value={htmlContent || ""}
            debounceChangePeriod={100}
            onChange={value => onChangeHandle(value, "html")}
            readOnly={readOnly}
            setOptions={setOptions}
            editorProps={editorProps}
            ref={editorRef}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default CpEditor
