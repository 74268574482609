import React from 'react'
import { useParams } from 'react-router-dom'
import Body from 'components/Body'
import Page from 'components/Page'
import UserForm from 'forms/UserForm'
import { useResource } from 'hooks/useResource'
import { useResourceActions } from 'hooks/useResourceActions'
import { RouteParams } from 'navigation/RouteParams'
import { UserEntity } from '@jackfruit/common'

interface Props {}

const UserEditScreen: React.FC<Props> = () => {
  const { userId } = useParams<RouteParams>()
  const { isLoading, entity, setEntity } = useResource<UserEntity>(
    'users',
    parseInt(userId)
  )
  const { isPatching, patch } = useResourceActions('users', setEntity)

  const onSubmit = async (data: any) => {
    await patch(parseInt(userId), data)
  }

  return (
    <Page title={`Edit User #${userId}`}>
      <Body isLoading={isLoading}>
        <UserForm user={entity} onSubmit={onSubmit} isLoading={isPatching} />
      </Body>
    </Page>
  )
}

export default UserEditScreen
