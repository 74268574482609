import { useCallback, useState } from "react"

export const useSearchTerm = () => {
  const [searchTerm, setSearchTerm] = useState<string>("")

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value)
    },
    [setSearchTerm]
  )

  return {
    searchTerm,
    handleSearchChange,
  }
}
