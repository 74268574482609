import { FormControl, FormLabel, Input, InputGroup } from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import { EntityFormProps } from "forms/EntityFormProps"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"

interface Props extends EntityFormProps<AppEntity> {}

const AppBrandingSocialMediaForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const { handleSubmit, register } = useForm<AppEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Social Media</FormSectionHeader>
      <FormControl>
        <FormLabel>Facebook URL</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="facebookUrl"
            type="text"
            placeholder="https://example.com/"
          />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Twitter URL</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="twitterUrl"
            type="text"
            placeholder="https://example.com/"
          />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Instagram URL</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="instagramUrl"
            type="text"
            placeholder="https://example.com/"
          />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Youtube URL</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="youtubeUrl"
            type="text"
            placeholder="https://example.com/"
          />
        </InputGroup>
      </FormControl>

      <FormActions isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppBrandingSocialMediaForm
