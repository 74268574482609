import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import {
  Text,
  Select,
  InputGroup,
  FormControl,
  Button,
  Link,
  Box,
} from "@chakra-ui/react"
import { BlockGraphEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import { useDropzone } from "react-dropzone"
import LineChartComponent from "./LineChart"
import PieChartComponent from "./PieChart"
import DonutComponent from "./Donut"
import BarChartComponent from "./BarChart"
import StackedAreaChart from "./StackedAreaChart"
import ScatterGraph from "./ScatterGraph"

interface Props {
  entity: BlockGraphEntity
  isLoading: boolean
  onSubmit: (data: BlockGraphEntity) => void
}

const BlockGraphForm: React.FC<Props> = ({ entity, onSubmit, isLoading }) => {
  const defaultValues = {
    ...entity,
    data: JSON.stringify(entity.data ?? []),
  }

  const { handleSubmit, register, setValue, watch } = useForm<BlockGraphEntity>(
    {
      defaultValues,
    }
  )

  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const [chartOptionChanged, setChartOptionChanged] = useState<boolean>(false)

  const selectedChartType = watch("graphType")
  const data = watch("data")
  let jsonData = []

  try {
    if (data) {
      jsonData = JSON.parse(data)
    }
  } catch (error) {
    console.warn(error)
  }
  const shouldShowGraph = Boolean(data && selectedChartType)

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0 && selectedChartType) {
        const file = acceptedFiles[0]

        if (file.type === "text/csv") {
          setUploadedFileName(file.name)
          const reader = new FileReader()

          reader.onload = event => {
            if (event.target && typeof event.target.result === "string") {
              const csvData = event.target.result
              const parsedData = parseCSVData(csvData, selectedChartType)
              setValue("data", JSON.stringify(parsedData))
              setChartOptionChanged(false)
            }
          }

          reader.readAsText(file)
        } else {
          alert("Please upload a valid CSV file.")
        }
      }
    },
    [selectedChartType, setValue]
  )

  const parseCSVData = (csvData: string, graphType: string) => {
    const rows = csvData.split("\n")
    const headers = rows[0].split(",")
    let parsedData: (
      | { x: string; y: string }
      | { [key: string]: string | number }
    )[] = []

    switch (graphType) {
      case "LineGraph":
        parsedData = rows.map((row, index) => {
          const [x, y] = row.split(",")
          if (index === 0) {
            return { x, y }
          }
          const parsedY = parseFloat(y)
          return { x, y: parsedY }
        })
        break
      case "StackedAreaChart":
      case "BarGraph":
        parsedData = rows.slice(1).map(row => {
          const rowData = row.split(",")
          const rowDataObj: { [key: string]: string | number } = {}

          headers.forEach((columnName, i) => {
            const value = rowData[i]
            rowDataObj[columnName] = parseFloat(value) || value
          })
          return rowDataObj
        })
        break
      case "PieChart":
      case "Donut":
        rows.slice(1).forEach(row => {
          const [name, value] = row.split(",")
          const parsedValue = parseFloat(value)
          if (name && !isNaN(parsedValue)) {
            parsedData.push({ name, value: parsedValue })
          }
        })
        break
      case "ScatterGraph":
        parsedData = rows.map((row, index) => {
          if (index === 0) {
            const [x, y] = row.split(",")
            return { x, y }
          }
          const [x, y] = row.split(",")
          const parsedX = parseFloat(x)
          const parsedY = parseFloat(y)
          return { x: parsedX, y: parsedY }
        })
        break
      default:
        parsedData = []
    }

    return parsedData
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  })

  const downloadSampleDataset = (graphType: string) => {
    let sampleData

    switch (graphType) {
      case "LineGraph":
        sampleData = "x,y\n1,10\n2,20\n3,30"
        break
      case "PieChart":
        sampleData = "name,value\nCategory 1,30\nCategory 2,20\nCategory 3,50"
        break
      case "Donut":
        sampleData = "name,value\nCategory 1,30\nCategory 2,20\nCategory 3,50"
        break
      case "BarGraph":
        sampleData = "x,y1,y2\nname1,10,5\nname2,20,8"
        break
      case "StackedAreaChart":
        sampleData = "x,y1,y2,y3\nname1,10,5,15\nname2,20,8,16\nname3,30,12,67"
        break
      case "ScatterGraph":
        sampleData = "x,y\n1,10\n2,20\n3,30\n4,70\n5,80\n"
        break
      default:
        sampleData = "x,y\n0,0"
    }

    const blob = new Blob([sampleData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = url
    link.download = "sample.csv"
    link.click()

    window.URL.revokeObjectURL(url)
  }

  let graphComponent = null

  if (chartOptionChanged) {
    graphComponent = null
  } else {
    switch (selectedChartType) {
      case "LineGraph":
        graphComponent = <LineChartComponent data={jsonData} />
        break
      case "PieChart":
        graphComponent = <PieChartComponent data={jsonData} />
        break
      case "BarGraph":
        graphComponent = <BarChartComponent data={jsonData} />
        break
      case "Donut":
        graphComponent = <DonutComponent data={jsonData} />
        break
      case "StackedAreaChart":
        graphComponent = <StackedAreaChart data={jsonData} />
        break
      case "ScatterGraph":
        graphComponent = <ScatterGraph data={jsonData} />
        break
      default:
        graphComponent = null
    }
  }

  const handleChartTypeChange = (newValue: string) => {
    setValue("graphType", newValue)
    setValue("data", "")
    setUploadedFileName(null)
  }

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <Text>Graph Type</Text>
        <Select
          name="graphType"
          ref={register}
          placeholder="Select graph type"
          onChange={event => handleChartTypeChange(event.target.value)}
        >
          <option value="LineGraph">Line graph</option>
          <option value="BarGraph">Bar graph</option>
          <option value="Donut">Donut</option>
          <option value="PieChart">Pie Chart</option>
          <option value="StackedAreaChart">Stacked Area graph</option>
          <option value="ScatterGraph">Scatter graph</option>
        </Select>
      </FormControl>

      {selectedChartType && (
        <Box>
          <FormControl>
            <Text>Data File</Text>
            <InputGroup>
              <input
                type="file"
                id="data-file"
                accept=".csv"
                {...getInputProps()}
              />
            </InputGroup>
            <input type="hidden" id="data" name="data" ref={register} />
            <Box {...getRootProps()}>
              <Button>Upload File</Button>
              {uploadedFileName && (
                <Text>
                  Uploaded File: <strong>{uploadedFileName}</strong>
                </Text>
              )}
            </Box>
          </FormControl>
          <Text>
            <Link
              color="teal.500"
              href="#"
              onClick={() => downloadSampleDataset(selectedChartType)}
            >
              Click here
            </Link>{" "}
            to download a sample dataset.
          </Text>
        </Box>
      )}
      <br />
      {shouldShowGraph && <Box>{graphComponent}</Box>}

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default BlockGraphForm
