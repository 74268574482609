// https://stackoverflow.com/questions/43978105/how-do-you-retain-the-custom-attributes-for-a-paragraph-in-quilljs
import { Quill } from "react-quill"

const Parchment = Quill.import("parchment")

export const TypeAttribute = new Parchment.Attributor.Attribute("type", "type")
Parchment.register(TypeAttribute)

const Block = Quill.import("blots/block")

class ListContainer extends Block {
  constructor(domNode: any) {
    super(domNode)
    domNode.setAttribute("type", "")
    this.cache = {}
  }
}

ListContainer.tagName = "OL"
