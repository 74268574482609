import { useCallback, useState } from "react"
import { useEffectOnce } from "react-use"
import { app as feathers } from "services/api"

export const useGenerateAppConfig = (appId: number) => {
  const [isLoading, setIsLoading] = useState(true)
  const [configuration, setConfiguration] = useState({})

  const appConfigService = feathers.service("app-configs")

  const fetchData = useCallback(async () => {
    const appConfig = await appConfigService.get(appId)

    setConfiguration(appConfig)
    setIsLoading(false)
  }, [appConfigService, appId])

  useEffectOnce(() => {
    fetchData()
  })

  return {
    isLoading,
    configuration,
  }
}
