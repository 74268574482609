import PrintServiceProductConfigurationTable from 'components/ProductConfiguration/PrintServiceProductConfigurationTable'
import { useEntity } from 'hooks/useEntity'
import { RouteParams } from 'navigation/RouteParams'
import React from 'react'
import { useParams } from 'react-router-dom'
import { PrintServiceEntity } from '@jackfruit/common'

interface Props {}

const AppPageProductConfigurationListScreen: React.FC<Props> = () => {
  const { pageId, printServiceId } = useParams<RouteParams>()
  const { data: printService, isLoading } = useEntity<PrintServiceEntity>(
    'print-services',
    printServiceId
  )

  const title = isLoading ? 'loading...' : `${printService.displayName}`

  return (
    <PrintServiceProductConfigurationTable
      title={title}
      entityType="page"
      entityId={+pageId}
      printServiceId={+printServiceId}
    />
  )
}

export default AppPageProductConfigurationListScreen
