import { Box, SimpleGrid } from '@chakra-ui/react'
import {
  AssetEntity,
  BlockEntity,
  BlockRichTextEntity,
} from '@jackfruit/common'
import AssetUploader from 'components/AssetUploader'
import FilePreview from 'components/FilePreview'
import { useHasOneResourceForm } from 'hooks/useHasOneResourceForm'
import React, { useState } from 'react'
import BlockRichTextForm from '../forms/BlockRichTextForm'

interface Props {
  block: BlockEntity
}

const BlockRichText: React.FC<Props> = ({ block }) => {
  const [params] = useState({ $eager: '[assets]' })
  const {
    entity,
    patch,
    isLoading,
    isCreating,
    isPatching,
    onSubmit,
  } = useHasOneResourceForm<BlockRichTextEntity>(
    'blocks-rich-text',
    'blockId',
    block.id,
    params
  )

  const onNewImageUploaded = async (asset: AssetEntity) => {
    const { updatedAt, createdAt, ...rest } = asset
    patch(entity.id, { assets: [rest] }, params)
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <>
      {entity.id && (
        <>
          <Box mb={3}>
            <AssetUploader onComplete={onNewImageUploaded} />
          </Box>
          <Box mb={3}>
            <SimpleGrid columns={6} spacing={3}>
              {entity.assets.map((asset) => {
                return (
                  <FilePreview
                    key={asset.id}
                    size={100}
                    path={asset.path}
                    mimeType={asset.mimeType}
                  />
                )
              })}
            </SimpleGrid>
          </Box>
        </>
      )}
      <BlockRichTextForm
        entity={entity}
        isLoading={isCreating || isPatching}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default BlockRichText
