import { SettingsEntity } from "@jackfruit/common"
import { useHasOneResource } from "hooks/useHasOneResource"
import { useResourceActions } from "hooks/useResourceActions"

const useAppSettings = (appId: number) => {
  const { entity, setEntity, isLoading } = useHasOneResource<SettingsEntity>(
    "settings",
    "appId",
    appId
  )
  const { isPatching, patch, isCreating, create } = useResourceActions(
    "settings",
    setEntity
  )

  const onSubmit = async (data: SettingsEntity): Promise<SettingsEntity> => {
    if (entity.id) {
      return await patch(entity.id, data)
    } else {
      data.appId = appId
      return await create(data)
    }
  }

  return {
    entity,
    isLoading,
    isCreating,
    isPatching,
    onSubmit,
  }
}

export { useAppSettings }
