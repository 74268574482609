import { Button, Text } from "@chakra-ui/react"
import React from "react"
import { Link, useLocation } from "react-router-dom"

interface Props {
  label: string
  to: string
  exactMatch?: boolean
}

const SecondLevelButton: React.FC<Props> = ({ label, to, exactMatch }) => {
  const location = useLocation()

  const isActive = exactMatch
    ? Boolean(to && location.pathname.endsWith(to))
    : Boolean(to && location.pathname.includes(to ?? ""))

  return (
    <Button
      as={Link}
      to={to}
      size="md"
      w="full"
      py={3}
      px={4}
      height={12}
      variant="ghost"
      isActive={isActive}
      _hover={{ backgroundColor: "primary.50", color: "primary.600" }}
      _active={{ backgroundColor: "primary.50", color: "primary.600" }}
    >
      <Text w="full" textAlign="left" ml={10}>
        {label}
      </Text>
    </Button>
  )
}

export default SecondLevelButton
