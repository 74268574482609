import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import "ace-builds"
import "ace-builds/webpack-resolver"
import React from "react"
import { useForm } from "react-hook-form"
import { EntityFormProps } from "forms/EntityFormProps"
import { AppEntity } from "@jackfruit/common"
import FormSectionHeader from "components/FormSectionHeader"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"

interface Props extends EntityFormProps<AppEntity> {}

const AppBrandingSeoStoresForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }
  const { handleSubmit, register, errors } = useForm<AppEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Stores</FormSectionHeader>
      <FormControl isInvalid={!!errors.storesPageTitle}>
        <FormLabel>Stores Page Title</FormLabel>
        <InputGroup>
          <Input ref={register()} name="storesPageTitle" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.storesPageTitle && errors.storesPageTitle.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.storesPageDescription}>
        <FormLabel>Stores Page description</FormLabel>
        <InputGroup>
          <Input ref={register()} name="storesPageDescription" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.storesPageDescription && errors.storesPageDescription.message}
        </FormErrorMessage>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppBrandingSeoStoresForm
