import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react"
import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import { webappThemeConfig } from "@jackfruit/common"
import React, { useContext } from "react"
import Frame, { FrameContextConsumer } from "react-frame-component"
import { CpContext, CpContextInterface } from "./CpContext"
import { replaceShortCodes } from "./replaceShortCodes"

const theme = extendTheme(webappThemeConfig)

interface Props {}

const CpResult: React.FC<Props> = () => {
  const { htmlValue } = useContext<CpContextInterface>(CpContext)

  const result = htmlValue ? replaceShortCodes(htmlValue) : ""

  return (
    <Frame
      initialContent="<!DOCTYPE html><html><head></head><body><div></div></body></html>"
      style={{
        width: "100%",
      }}
    >
      <FrameContextConsumer>
        {({ document }) => {
          const cache = createCache({
            container: document!.head,
            key: "code-preview",
          })

          return (
            <CacheProvider value={cache}>
              <ChakraProvider theme={theme}>
                <Box
                  id="htmlPreview"
                  width="full"
                  height="full"
                  // required to force loading fonts for preview
                  // for some reason, everything loads correctly from the prodiver
                  // but the font variable does not get overriden in the browser
                  // here 'body' refers to theme.fonts.body
                  fontFamily="body"
                >
                  <Box
                    width="full"
                    height="full"
                    dangerouslySetInnerHTML={{ __html: result }}
                  ></Box>
                </Box>
              </ChakraProvider>
            </CacheProvider>
          )
        }}
      </FrameContextConsumer>
    </Frame>
  )
}

export default CpResult
