import React from "react"
import { useForm } from "react-hook-form"
import {
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  Textarea,
  FormHelperText,
} from "@chakra-ui/react"
import FormActions from "forms/FormActions"
import FormContainer from "components/FormContainer"
import { BlockOrderThankYouEntity } from "@jackfruit/common"

interface Props {
  entity: BlockOrderThankYouEntity
  isLoading: boolean
  onSubmit: (data: BlockOrderThankYouEntity) => void
}

const BlockOrderThankYouForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const { handleSubmit, register } = useForm<BlockOrderThankYouEntity>({
    defaultValues,
  })
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <FormLabel>Title (leave blank for default)</FormLabel>
        <InputGroup>
          <Input type="text" name="title" ref={register()} />
        </InputGroup>
      </FormControl>
      <FormControl marginTop={2}>
        <FormLabel>Body (leave blank for default)</FormLabel>
        <Textarea
          height={10}
          resize="vertical"
          name={"body"}
          ref={register()}
        />
        <FormHelperText>
          Shortcode example: [order field="firstName"] Available shortcodes:
          dayOrEvening, appName, appDisplayName, firstName, and other order
          properties
        </FormHelperText>
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default BlockOrderThankYouForm
