import { Badge, Box, Flex, Text, useUpdateEffect } from "@chakra-ui/react"
import { PageEntity } from "@jackfruit/common"
import TableRowActions from "components/TableRowActions"
import { useUpdateDebounce } from "hooks/useUpdateDebounce"
import React, { useCallback, useMemo, useState } from "react"
import SortableTree, { getDescendantCount, TreeItem } from "react-sortable-tree"
import { getEntitiesFromTree, getTreeFromEntities } from "services/tree"
import { removeKeys } from "utils/helpers"

interface Props {
  pages: PageEntity[]
  onSubmit: (data: PageEntity[]) => void
}

const AppPageTree: React.FC<Props> = ({ pages, onSubmit }) => {
  const tree = useMemo(() => getTreeFromEntities<PageEntity>(pages), [pages])
  const [currentVersion, setCurrentVersion] = useState(0)
  const [treeData, setTreeData] = useState<TreeItem[]>(tree)

  const handleUpdateSiteTreeData = useCallback((newTree: TreeItem[]) => {
    setTreeData(newTree)
    setCurrentVersion(v => v + 1)
  }, [])

  const enhancedOnSubmit = useCallback(() => {
    const flatData = getEntitiesFromTree<PageEntity>(treeData)
    onSubmit(removeKeys(flatData, ["expanded", "children"]))
  }, [onSubmit, treeData])

  useUpdateEffect(() => {
    setTreeData(tree)
  }, [pages])

  useUpdateDebounce(
    () => {
      enhancedOnSubmit()
    },
    500,
    [currentVersion]
  )

  const generateNodeProps = (data: any) => {
    const { node } = data

    return {
      title: (
        <Box w={96}>
          <Flex align="center">
            <Text textOverflow="ellipsis" overflow="hidden" fontWeight="light">
              {node.name}
            </Text>
            {node.status === "draft" && (
              <Badge
                px={2}
                py={1}
                ml={1}
                fontWeight={600}
                borderRadius="md"
                whiteSpace="nowrap"
                lineHeight={1}
                colorScheme="orange"
                textTransform="none"
              >
                Draft
              </Badge>
            )}
          </Flex>

          <Text fontSize="sm" textOverflow="ellipsis" overflow="hidden">
            /{node.slug}{" "}
          </Text>
        </Box>
      ),
      buttons: [
        <TableRowActions
          resource="pages"
          entity={node}
          canView={false}
          canEdit={true}
          canDelete={!getDescendantCount(data)}
        />,
      ],
    }
  }

  return (
    <Box mb={4}>
      <SortableTree
        isVirtualized={false}
        treeData={treeData}
        rowHeight={75}
        onChange={handleUpdateSiteTreeData}
        generateNodeProps={generateNodeProps}
      />
    </Box>
  )
}

export default AppPageTree
