import { ChakraProvider, extendTheme, Flex } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useMegaMenu } from "../../hooks/useMegaMenu"
import { webappThemeConfig } from "@jackfruit/common"
import MegaMenuePreviewColumn from "./MegaMenuPreviewColumn"

const theme = extendTheme(webappThemeConfig)

interface Props {
  megaMenuId: EntityId
}

const MegaMenuPreview: React.FC<Props> = ({ megaMenuId }) => {
  const { isLoading, data: megaMenu } = useMegaMenu(megaMenuId)

  if (isLoading) {
    return null
  }

  return (
    <ChakraProvider theme={theme} resetCSS={true} cssVarsRoot="#htmlPreview">
      <Flex
        overflowX="scroll"
        w="1000px"
        p={3}
        borderWidth={1}
        backgroundColor="blackAlpha.50"
        minH="300px"
        borderRadius="md"
        boxShadow="inner"
      >
        <Flex
          borderWidth={1}
          borderRadius="md"
          boxShadow="md"
          backgroundColor="white"
          p={4}
        >
          {megaMenu.columns.map((column, index) => {
            const isLast = index === megaMenu.columns.length - 1

            return (
              <MegaMenuePreviewColumn
                key={column.id}
                column={column}
                isLast={isLast}
              />
            )
          })}
        </Flex>
      </Flex>
    </ChakraProvider>
  )
}

export default MegaMenuPreview
