import React from "react"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import { useTheme } from "@chakra-ui/react"

interface StackedAreaChartProps {
  data: any[]
}

const StackedAreaChart: React.FC<StackedAreaChartProps> = ({ data }) => {
  const columns = data.length > 0 ? Object.keys(data[0]) : []
  const xAxisColumn = columns[0]
  const yColumns = columns.slice(1)

  const theme = useTheme()

  const colors = [
    theme.colors.red[500],
    theme.colors.blue[500],
    theme.colors.green[500],
    theme.colors.yellow[500],
    theme.colors.purple[500],
    theme.colors.teal[500],
    theme.colors.orange[500],
    theme.colors.pink[500],
    theme.colors.cyan[500],
    theme.colors.gray[500],
  ]

  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisColumn} />
        <YAxis />
        <Tooltip />
        {yColumns.map((column, index) => (
          <Area
            key={index}
            type="monotone"
            dataKey={column}
            stackId="1"
            fill={colors[index % colors.length]}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default StackedAreaChart
