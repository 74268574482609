import { Kind, SchemaOptions, Type, TypeRegistry } from '@sinclair/typebox'

// create a custom type on the type registry
TypeRegistry.Set('NumberEnum', (schema: { enum: number[] }, value: unknown) => {
    return typeof value === 'number' && schema.enum.includes(value)
})

export function NumberEnum<T extends number[]>(
    values: [...T],
    options: SchemaOptions = {}
) {
    return Type.Unsafe<T[number]>({
        ...options,
        [Kind]: 'NumberEnum',
        enum: values,
    })
}
export default NumberEnum
