import { Spinner, Text, VStack, Link as ChakraLink } from "@chakra-ui/react"
import { EntityId, UserEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import React from "react"
import { Link } from "react-router-dom"

interface Props {
  id: EntityId
}

const TableUserCell: React.FC<Props> = ({ id }) => {
  const { data: user, isLoading } = useEntity<UserEntity>("users", id)

  let userName = ""
  if (user.email) {
    userName = user.email.split("@")[0]
  }

  return isLoading ? (
    <Spinner size="sm" />
  ) : (
    <VStack spacing={1} alignItems="flex-start">
      <Text fontSize="sm">{userName}</Text>
      <ChakraLink as={Link} to={`/admin/users/${id}`} fontSize="xs">
        #{user.id}
      </ChakraLink>
    </VStack>
  )
}

export default TableUserCell
