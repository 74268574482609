import { Box, Icon, Image } from "@chakra-ui/react"
import { AssetEntity, EntityId } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import React from "react"
import { FaRegLightbulb } from "react-icons/fa"

const gradient = "linear-gradient(transparent 55%, white 100%)"

interface Props {
  id: EntityId
  width?: number
  shadowW?: number
}

const AppCardLogo: React.FC<Props> = ({ id, width = 32, shadowW = 24 }) => {
  const {
    data: { path },
  } = useEntity<AssetEntity>("assets", id)

  const image = path ? (
    <Box pl={4}>
      <Image boxSize={24} objectFit="contain" src={path} />
      <Image
        position="absolute"
        boxSize={shadowW}
        objectFit="contain"
        transform="rotate(180deg)"
        sx={{
          filter: "blur(3px)",

          WebkitMaskImage: gradient,
          maskImage: gradient,
        }}
        src={path}
      />
    </Box>
  ) : (
    <Icon
      as={FaRegLightbulb}
      boxSize={width}
      marginTop={5}
      marginBottom={5}
      color="gray.200"
    />
  )

  return (
    <Box position="relative" width={width} textAlign="center">
      {image}
    </Box>
  )
}

export default AppCardLogo
