import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  Autocomplete,
  AutoCompleteChoice,
  AutoCompleteOption,
} from 'components/Autocomplete'
import ConditionnalWrapper from 'components/ConditionnalWrapper'
import { usePageTerritoriesEntities } from 'hooks/usePageTerritoriesEntities'
import { RouteParams } from 'navigation/RouteParams'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BiTrash } from 'react-icons/bi'
import { ImRadioChecked, ImRadioUnchecked } from 'react-icons/im'
import { useParams } from 'react-router'

const defaultCountryCode = 'US'

const PageTerritoriesSelector: React.FC<{
  defaultCodes: string[]
  isPickup: boolean
  name: string
  defaultCountryName: string
}> = ({ defaultCodes, isPickup = false, name, defaultCountryName }) => {
  const { pageId, appId } = useParams<RouteParams>()
  const { register, setValue } = useFormContext()

  const [options, setOptions] = useState<AutoCompleteOption[]>([])
  const [choices, setChoices] = useState<AutoCompleteChoice[]>(defaultCodes)

  const [defaultCountry, setDefaultCountry] = useState<AutoCompleteChoice>(
    defaultCodes?.[0] ?? defaultCountryCode
  )

  const { territories, pageTerritories, isLoading } =
    usePageTerritoriesEntities({ pageId, appId, isPickup })

  useEffect(() => {
    if (options.length === 0) {
      const territoriesOptions = territories.data.map((territory) => {
        const { code, name } = territory
        return {
          value: code,
          label: `${name} - ${code}`,
        }
      })

      setOptions(territoriesOptions)
    }
  }, [options, territories])

  useEffect(() => {
    if (choices.length === 0) {
      setChoices(defaultCodes)
      setDefaultCountry(defaultCodes?.[0] ?? defaultCountryCode)
    }
  }, [defaultCodes, choices.length])

  useEffect(() => {
    const pageTerritoryData = pageTerritories.data
    if (pageTerritoryData.length > 0) {
      const codes = pageTerritoryData.map((pT) => pT.countryCode)
      setChoices(codes)

      const defaultCountryCode = pageTerritoryData.find(
        (pT) => pT.isDefault
      )!.countryCode
      setDefaultCountry(defaultCountryCode)
    }
  }, [pageTerritories])

  useEffect(() => {
    if (choices.length > 0 && !choices.includes(defaultCountry)) {
      setDefaultCountry(choices[0])
    }
    setValue(name, choices)
  }, [choices, defaultCountry, name, setValue])

  useEffect(() => {
    setValue(defaultCountryName, defaultCountry)
  }, [defaultCountry, defaultCountryName, setValue])

  const getLabelChoice = useCallback(
    (choice: AutoCompleteChoice) =>
      options.find((o) => o.value === choice)?.label,
    [options]
  )

  const onRemoveTerritory = useCallback(
    (code) => {
      const editedChoices = choices.filter((choice) => choice !== code)
      setChoices(editedChoices)
    },
    [choices]
  )

  return (
    <Box>
      <FormControl>
        <InputGroup>
          <Input ref={register} name={name} type="hidden" />
          <Input ref={register} name={defaultCountryName} type="hidden" />
        </InputGroup>

        <FormLabel>Select one or multiple countries</FormLabel>
        <Autocomplete
          options={options}
          choices={choices}
          setChoices={setChoices}
          placeholder="Search a country"
          suffixResult="countries found"
          isLoading={isLoading}
        />
      </FormControl>

      {!isLoading && options.length > 0 && (
        <>
          {choices.map((choice) => {
            const label = getLabelChoice(choice)

            return (
              <Flex
                w="100%"
                alignItems="center"
                justifyContent="center"
                key={choice}
                mb={2}
              >
                <Button
                  colorScheme="gray"
                  variant="ghost"
                  w="100%"
                  fontWeight="normal"
                  bg={defaultCountry === choice ? 'gray.100' : 'auto'}
                  onClick={() => setDefaultCountry(choice)}
                  marginTop={0}
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text>{label}</Text>
                    <Flex justifyContent="center" alignItems="center">
                      {defaultCountry === choice ? (
                        <Icon as={ImRadioChecked} mr={2} color="primary.500" />
                      ) : (
                        <Icon as={ImRadioUnchecked} mr={2} />
                      )}

                      <Text>Default</Text>
                    </Flex>
                  </Flex>
                </Button>

                <ConditionnalWrapper
                  condition={choices.length === 1}
                  wrapper={(children: any) => (
                    <Tooltip
                      label="At least one country is required"
                      aria-label="Remove country"
                    >
                      {children}
                    </Tooltip>
                  )}
                >
                  <Box>
                    <IconButton
                      aria-label="remove"
                      icon={<BiTrash />}
                      size="md"
                      rounded="full"
                      variant="ghost"
                      colorScheme="primary"
                      color="primary.500"
                      marginLeft="5px"
                      isDisabled={choices.length === 1}
                      onClick={() => onRemoveTerritory(choice)}
                    />
                  </Box>
                </ConditionnalWrapper>
              </Flex>
            )
          })}
        </>
      )}
    </Box>
  )
}

export default PageTerritoriesSelector
