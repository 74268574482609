import { BlockEntity, EntityId } from "@jackfruit/common"
import { useAppRevision } from "hooks/useAppRevision"
import { useBlocksEntities } from "hooks/useBlocksEntities"
import { usePatchEntityList } from "hooks/usePatchEntityList"
import { useUpdateDebounce } from "hooks/useUpdateDebounce"
import React, { useCallback, useEffect, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import BlocksEditor from "./BlocksEditor"

const sortableGroupDef = {
  name: "block-clone",
  pull: false,
  put: true,
}

interface Props {
  appId: EntityId
  pageId: EntityId
  onSelect: (id: EntityId) => void
  selectedBlockId: EntityId | null
}

const BlockSortableList: React.FC<Props> = ({
  appId,
  pageId,
  onSelect,
  selectedBlockId,
}) => {
  const { referers, blocks, isLoading } = useBlocksEntities({ appId, pageId })
  const [blocksList, setBlocksList] = useState<BlockEntity[]>([])
  const [referersList, setReferersList] = useState<BlockEntity[]>([])
  const [currentVersion, setCurrentVersion] = useState(0)
  const { bumpRevision } = useAppRevision()

  useEffect(() => {
    setReferersList(referers?.data || [])
  }, [referers])

  useEffect(() => {
    setBlocksList(blocks?.data || [])
  }, [blocks])

  const { mutate: patchMultiple } = usePatchEntityList("blocks")

  const orderedList = useCallback(() => {
    return blocksList.map(
      (block: BlockEntity & { chosen?: number; selected?: number }, index) => {
        block.order = index
        const { createdAt, updatedAt, chosen, selected, ...rest } = block
        return rest
      }
    )
  }, [blocksList])

  const updateBlocks = useCallback(
    (patch = false) => {
      const list = orderedList()
      setBlocksList(list)
      if (patch) {
        patchMultiple(list)
      }
      bumpRevision()
    },
    [bumpRevision, orderedList, patchMultiple]
  )

  const handleUpdateBlocks = useCallback(() => {
    setCurrentVersion(v => v + 1)
  }, [])

  useUpdateDebounce(
    () => {
      updateBlocks(true)
    },
    500,
    [currentVersion]
  )

  const onSort = useCallback(() => {
    const blockToAdd = blocksList.find(block => !block.pageId)

    if (blockToAdd) {
      const list = orderedList()
      setBlocksList(list)

      const dummyExcluded = list.filter(block => block.id > 0)
      patchMultiple(dummyExcluded)
    } else handleUpdateBlocks()
  }, [blocksList, handleUpdateBlocks, orderedList, patchMultiple])

  if (isLoading) {
    return null
  }

  return (
    <>
      {blocksList.length > 0 && (
        <ReactSortable
          list={blocksList}
          setList={setBlocksList}
          handle=".handle"
          animation={150}
          sort={true}
          direction="vertical"
          group={sortableGroupDef}
          onSort={onSort}
        >
          <BlocksEditor
            blocks={blocksList}
            referers={referersList}
            onDelete={updateBlocks}
            onOverride={updateBlocks}
            onSelect={onSelect}
            selectedBlockId={selectedBlockId}
          />
        </ReactSortable>
      )}
    </>
  )
}

export default BlockSortableList
