import { Box } from '@chakra-ui/react'
import { BlockEntity, BlockImageUploadEntity } from '@jackfruit/common'
import { useHasOneResourceForm } from 'hooks/useHasOneResourceForm'
import React from 'react'
import BlockImageUploadForm from '../forms/BlockImageUploadForm'

interface Props {
  block: BlockEntity
}

const BlockImageUpload: React.FC<Props> = ({ block }) => {
  const {
    entity,
    isLoading,
    isCreating,
    isPatching,
    onSubmit,
  } = useHasOneResourceForm<BlockImageUploadEntity>(
    'blocks-image-upload',
    'blockId',
    block.id
  )

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockImageUploadForm
      entity={entity}
      isLoading={isCreating || isPatching}
      onSubmit={onSubmit}
    />
  )
}

export default BlockImageUpload
