import Icon from "@chakra-ui/icon"
import { Badge, Flex, HStack, Text, VStack } from "@chakra-ui/layout"
import { ChangelogEntity } from "@jackfruit/common"
import { flatMap } from "lodash"
import React from "react"
import { FiChevronRight } from "react-icons/fi"
import { CompiledChangelogs } from "./utils"

interface Props {
  compileChangelogs: CompiledChangelogs
  onSelect?: (entityType: string) => void
}

const CompiledChangelogsSummary: React.FC<Props> = ({
  compileChangelogs,
  onSelect,
}) => {
  return (
    <VStack alignItems="flex-start" spacing={0}>
      {Object.entries(compileChangelogs).map(entry => {
        const [entityType, entities] = entry
        const flatten: ChangelogEntity[] = flatMap(entities)

        const countCreated = flatten.filter(d => d.method === "create")
        const countUpdated = flatten.filter(d => d.method === "patch")
        const countDeleted = flatten.filter(d => d.method === "remove")

        const hasCreated = countCreated.length > 0
        const hasUpdated = countUpdated.length > 0
        const hasDeleted = countDeleted.length > 0

        return (
          <Flex
            key={entityType}
            w="full"
            flex={1}
            py={2}
            px={3}
            borderRadius="md"
            _hover={{ cursor: "pointer", backgroundColor: "gray.50" }}
            onClick={() => onSelect && onSelect(entityType)}
          >
            <Flex flex={0.5}>
              <Text>{entityType}</Text>
            </Flex>
            <Flex flex={0.5}>
              <HStack>
                {hasCreated && (
                  <Badge colorScheme="green">{countCreated.length} new</Badge>
                )}
                {hasUpdated && (
                  <Badge colorScheme="blue">
                    {countUpdated.length} updates
                  </Badge>
                )}
                {hasDeleted && (
                  <Badge colorScheme="red">{countDeleted.length} removed</Badge>
                )}
              </HStack>
            </Flex>
            <Flex alignItems="center" justifyContent="flex-end" w="24px">
              <Icon as={FiChevronRight} />
            </Flex>
          </Flex>
        )
      })}
    </VStack>
  )
}

export default CompiledChangelogsSummary
