import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
} from "@chakra-ui/react"
import { ICustomVisibleBlock } from "@jackfruit/common"
import React, { useState } from "react"

interface Props {
  data: ICustomVisibleBlock
  defaultSelected: string
  handleSelectVisible: (key: string) => void
}
const VisibleBlockButton: React.FC<Props> = ({
  data,
  defaultSelected,
  handleSelectVisible,
}) => {
  const [selected, setSelected] = useState<string>(defaultSelected)

  const handleSelect = (key: string) => {
    setSelected(key)
    handleSelectVisible(key)
  }

  if (!defaultSelected) {
    return (
      <Box border="1px" px={4} py={2} borderRadius="md">
        Loading...
      </Box>
    )
  }
  return (
    <Menu>
      <MenuButton as={Button} rightIcon={data[selected]!.icon}>
        {data[selected]!.name}
      </MenuButton>
      <MenuList>
        {Object.entries(data).map(([key, item]) => {
          return (
            <MenuItem
              bg={key === selected ? "gray.100" : "none"}
              _focus={{ bg: "none" }}
              _hover={{ bg: "gray.50" }}
              key={key}
              onClick={() => handleSelect(key)}
            >
              {item.icon} <Box w="10px" /> {item.name}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default React.memo(VisibleBlockButton)
