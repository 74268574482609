import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import AppGrid from "components/AppGrid/AppGrid"
import AppList from "components/AppList/AppList"
import Page from "components/Page"
import SearchBar from "components/SearchBar"
import { useAppsFuzzySearch } from "hooks/useAppsFuzzySearch"
import { useEntityList } from "hooks/useEntityList"
import React, { useState } from "react"
import { TbApps, TbList, TbLayoutGrid } from "react-icons/tb"
import { Link, useRouteMatch } from "react-router-dom"

interface ActionsProps {
  onChangeSearch: (search: string) => void
  onChangeView: (key: ViewOptionsKey) => void
  viewOption: ViewOptionsKey
}

interface IViewOptions {
  list: {
    name: string
    icon: JSX.Element
  }
  grid: {
    name: string
    icon: JSX.Element
  }
}

const viewOptions: IViewOptions = {
  list: {
    name: "List",
    icon: <TbList />,
  },
  grid: {
    name: "Grid",
    icon: <TbLayoutGrid />,
  },
}

type ViewOptionsKey = "list" | "grid"

const defaultView: ViewOptionsKey = "grid"

const Actions: React.FC<ActionsProps> = ({
  onChangeSearch,
  onChangeView,
  viewOption,
}) => {
  const match = useRouteMatch()

  return (
    <HStack spacing={4} aria-label="App list action">
      <Menu>
        <MenuButton
          w={"100px"}
          as={Button}
          leftIcon={viewOptions[viewOption].icon}
          aria-label="View Options"
          bg="white"
        >
          {viewOptions[viewOption].name}
        </MenuButton>
        <MenuList>
          {Object.entries(viewOptions).map(([key, { name, icon }]) => (
            <MenuItem
              key={key}
              onClick={() => onChangeView(key as ViewOptionsKey)}
            >
              {icon} <Text ml={2}>{name}</Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <SearchBar onChangeSearch={onChangeSearch} />
      <Link to={`${match.url}/new`}>
        <Button colorScheme="primary" leftIcon={<Icon as={TbApps} />}>
          Create App
        </Button>
      </Link>
    </HStack>
  )
}

interface Props {}

const query = {
  $sort: {
    name: 1,
  },
  $limit: 1_000_000,
}

const AppListScreen: React.FC<Props> = () => {
  const {
    data: { data },
  } = useEntityList<AppEntity>("apps", { query })

  const { setSearch, apps } = useAppsFuzzySearch(data)
  const [view, setView] = useState<ViewOptionsKey>(defaultView)

  const template = {
    grid: AppGrid,
    list: AppList,
  }
  const childComponent = React.createElement(template[view], {
    apps: apps,
  })

  return (
    <Page
      title="Apps"
      headerActions={
        <Actions
          onChangeSearch={setSearch}
          onChangeView={setView}
          viewOption={view}
        />
      }
      aria-label="App list screen"
    >
      {childComponent}
    </Page>
  )
}

export default AppListScreen
