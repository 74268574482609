import {
  EntityId,
  NavigationMegaMenuCellEntity,
  NavigationMegaMenuCellEntityHydrated,
  NavigationMegaMenuCellLinkEntity,
  NavigationMegaMenuColumnEntity,
  NavigationMegaMenuEntityHydrated,
} from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import { usePatchEntityList } from "hooks/usePatchEntityList"
import { useEffect, useState } from "react"
import { DropResult } from "react-beautiful-dnd"
import { useQueryClient } from "react-query"
import { useUpdateEffect } from "react-use"

const eager = `
[
  columns(ordered).[
    cells(ordered).[
      links(ordered)
    ]
  ]
]
`

const swapLinks = (
  state: NavigationMegaMenuEntityHydrated,
  result: DropResult
) => {
  // clone the state for immutability
  const clonedState = JSON.parse(
    JSON.stringify(state)
  ) as NavigationMegaMenuEntityHydrated

  const { source, destination, draggableId } = result

  const linkId = Number(draggableId.split("#")[1])
  const sourceCellId = Number(source.droppableId.split("#")[1])

  let destinationCell: NavigationMegaMenuCellEntityHydrated | null = null

  clonedState.columns.forEach(column => {
    column.cells.forEach(cell => {
      if (cell.id === sourceCellId) {
        destinationCell = cell
      }
    })
  })

  const link = destinationCell!.links.find(link => link.id === linkId)

  // remove link from cell
  destinationCell!.links.splice(source.index, 1)
  // insert link to cell
  destinationCell!.links.splice(destination!.index, 0, link!)

  return clonedState
}

const swapCells = (
  state: NavigationMegaMenuEntityHydrated,
  result: DropResult
) => {
  // clone the state for immutability
  const clonedState = JSON.parse(
    JSON.stringify(state)
  ) as NavigationMegaMenuEntityHydrated

  const { source, destination, draggableId } = result

  const cellId = Number(draggableId.split("#")[1])
  const sourceColumnId = Number(source.droppableId.split("#")[1])
  const destinationColumnId = Number(destination!.droppableId.split("#")[1])

  const sourceColumn = clonedState.columns.find(
    column => column.id === sourceColumnId
  )
  const destinationColumn = clonedState.columns.find(
    column => column.id === destinationColumnId
  )
  const cell = sourceColumn!.cells.find(cell => cell.id === cellId)

  // remove cell from source
  sourceColumn!.cells.splice(source.index, 1)
  // insert cell to destination
  destinationColumn!.cells.splice(destination!.index, 0, cell!)

  // return new state
  const newState = {
    ...clonedState,
    columns: state.columns.map(column => {
      if (column.id === sourceColumn!.id) {
        return sourceColumn!
      }

      if (column.id === destinationColumn!.id) {
        return destinationColumn!
      }

      return column
    }),
  }

  return newState
}

const swapColumns = (
  state: NavigationMegaMenuEntityHydrated,
  result: DropResult
) => {
  // clone the state for immutability
  const clonedState = JSON.parse(
    JSON.stringify(state)
  ) as NavigationMegaMenuEntityHydrated

  const { source, destination, draggableId } = result

  const columnId = Number(draggableId.split("#")[1])
  const column = clonedState.columns.find(column => column.id === columnId)

  clonedState.columns.splice(source.index, 1)
  clonedState.columns.splice(destination!.index, 0, column!)

  return clonedState
}

export const useMegaMenu = (id: EntityId) => {
  const queryClient = useQueryClient()
  const [revisionLinks, setRevisionLinks] = useState(0)
  const [revisionCells, setRevisionCells] = useState(0)
  const [revisionColumns, setRevisionColumns] = useState(0)
  const [state, setState] = useState<NavigationMegaMenuEntityHydrated>(null!)
  const [isLoading, setIsLoading] = useState(true)

  const { mutateAsync: updateLinks } =
    usePatchEntityList<NavigationMegaMenuCellLinkEntity>(
      "navigation-megamenu-cell-links"
    )

  const { mutateAsync: updateCells } =
    usePatchEntityList<NavigationMegaMenuCellEntity>(
      "navigation-megamenu-cells"
    )

  const { mutateAsync: updateColumns } =
    usePatchEntityList<NavigationMegaMenuColumnEntity>(
      "navigation-megamenu-columns"
    )

  const {
    data: megaMenu,
    isLoading: isLoadingMegaMenu,
    isFetching,
  } = useEntity<NavigationMegaMenuEntityHydrated>(
    "navigation-megamenus",
    id,
    { refetchOnWindowFocus: false },
    { query: { $eager: eager } }
  )

  // set the initial state
  useEffect(() => {
    if (!isLoadingMegaMenu && !isFetching) {
      setState(megaMenu)
      setIsLoading(false)
    }
  }, [isFetching, isLoadingMegaMenu, megaMenu])

  // update links
  useUpdateEffect(() => {
    if (revisionLinks > 0) {
      const linksPerCells = state.columns.map(column => {
        return column.cells.map(cell => {
          return cell.links.map((link, index) => {
            return {
              ...link,
              order: index,
            }
          })
        })
      })
      const allLinks = linksPerCells.flat().flat()
      updateLinks(allLinks)
      queryClient.invalidateQueries(["navigation-megamenus"])
    }
  }, [revisionLinks])

  // update cells
  useUpdateEffect(() => {
    if (revisionCells > 0) {
      const cellsPerColumns = state.columns.map(column => {
        return column.cells.map((cell, index) => {
          return {
            ...cell,
            navigationMegamenuColumnId: column.id,
            order: index,
          }
        })
      })
      const allCells = cellsPerColumns.flat()
      updateCells(allCells)
      queryClient.invalidateQueries(["navigation-megamenus"])
    }
  }, [revisionCells])

  // update columns
  useUpdateEffect(() => {
    if (revisionColumns > 0) {
      const allColumns = state.columns.map((column, index) => {
        return {
          ...column,
          order: index,
        }
      })
      updateColumns(allColumns)
      queryClient.invalidateQueries(["navigation-megamenus"])
    }
  }, [revisionColumns])

  const handleReorder = (result: DropResult) => {
    setState(current => {
      const { destination, type } = result

      if (!destination) {
        return current
      }

      const isCell = type === "cell"
      const isColumn = type === "column"
      const isLink = type === "link"

      if (isLink) {
        const newState = swapLinks(current, result)
        setRevisionLinks(version => version + 1)

        return newState
      }

      if (isCell) {
        const newState = swapCells(current, result)
        setRevisionCells(version => version + 1)

        return newState
      }

      if (isColumn) {
        const newState = swapColumns(current, result)
        setRevisionColumns(version => version + 1)

        return newState
      }

      return current
    })
  }

  return { isLoading, data: state, onDragEnd: handleReorder }
}
