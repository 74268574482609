import PrintServiceConfigurationTable from "components/ProductConfiguration/PrintServiceConfigurationTable"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import { useParams } from "react-router-dom"

interface Props {}

const AppPrintServiceListScreen: React.FC<Props> = () => {
  const { appId } = useParams<RouteParams>()

  return (
    <PrintServiceConfigurationTable
      title="App Print Services"
      entityId={+appId}
      entityType="app"
    />
  )
}

export default AppPrintServiceListScreen
