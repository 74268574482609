import { Button, Icon } from "@chakra-ui/react"
import React from "react"
import { FiPlusCircle } from "react-icons/fi"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import Body from "components/Body"
import Table, { ColumnDefinition } from "components/Table"
import TableRowActions from "components/TableRowActions"
import TableRowId from "components/TableRowId"
import { RouteParams } from "navigation/RouteParams"

interface Props {}

const columns: ColumnDefinition[] = [
  {
    header: "Id",
    render: (navigation: any) => <TableRowId entity={navigation} />,
  },
  { header: "Name", accessor: "name" },

  {
    header: "",
    accessor: "actions",
    render: navigation => (
      <TableRowActions
        resource="navigations"
        entity={navigation}
        canView={false}
        canEdit={true}
        canDelete={true}
      />
    ),
  },
]

const Actions = () => {
  const match = useRouteMatch()
  return (
    <Link to={`${match.url}/new`}>
      <Button colorScheme="primary" leftIcon={<Icon as={FiPlusCircle} />}>
        Create Navigation
      </Button>
    </Link>
  )
}

const AppNavigationListScreen: React.FC<Props> = () => {
  const { appId } = useParams<RouteParams>()
  const query = {
    appId,
  }

  return (
    <Body padding={0} title="All Navigations" headerActions={<Actions />}>
      <Table resource="navigations" query={query} columns={columns} />
    </Body>
  )
}

export default AppNavigationListScreen
