import { Button, Icon } from "@chakra-ui/react"
import Body from "components/Body"
import Page from "components/Page"
import { useCreateEntity } from "hooks/useCreateEntity"
import React, { ReactNode } from "react"
import { FiX } from "react-icons/fi"
import { Link, useHistory } from "react-router-dom"
import { Resources } from "services/api"

interface Props {
  pagetitle: string
  resource: Resources
  render: ({
    onSubmit,
    isLoading,
  }: {
    onSubmit: (data: any) => void
    isLoading: boolean
  }) => ReactNode
}

const Actions = ({ resource }: { resource: Resources }) => {
  return (
    <Link to={`/admin/${resource}`}>
      <Button colorScheme="primary" leftIcon={<Icon as={FiX} />}>
        Cancel
      </Button>
    </Link>
  )
}

const FormScreen: React.FC<Props> = ({ pagetitle, resource, render }) => {
  const history = useHistory()

  const { mutate: create, status } = useCreateEntity(resource, {
    showNotification: true,
    onSuccess: result => {
      history.push(`/admin/${resource}/${result.id}`)
    },
  })

  const isLoading = status === "loading"

  return (
    <Page title={pagetitle} headerActions={<Actions resource={resource} />}>
      <Body isLoading={isLoading}>
        {render({
          onSubmit: create,
          isLoading,
        })}
      </Body>
    </Page>
  )
}

export default FormScreen
