import { Checkbox } from "@chakra-ui/checkbox"
import React from "react"

interface Props {
  value: boolean
}

const ChangelogFieldBoolean: React.FC<Props> = ({ value }) => {
  return <Checkbox checked={value} isChecked={value} readOnly={true} />
}

export default ChangelogFieldBoolean
