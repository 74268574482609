import { Flex } from "@chakra-ui/react"
import { NavigationMegaMenuColumnEntityHydrated } from "@jackfruit/common"
import React from "react"
import MegaMenuPreviewCell from "./MegaMenuPreviewCell"

interface Props {
  column: NavigationMegaMenuColumnEntityHydrated
  isLast: boolean
}

const MegaMenuePreviewColumn: React.FC<Props> = ({ column, isLast }) => {
  return (
    <Flex
      direction="column"
      borderRightWidth={!isLast ? 1 : 0}
      gridGap={12}
      p={4}
      width="220px"
    >
      {column.cells.map(cell => {
        return <MegaMenuPreviewCell key={cell.id} cell={cell} />
      })}
    </Flex>
  )
}

export default MegaMenuePreviewColumn
