import { Box, Input, useDisclosure, VStack } from "@chakra-ui/react"
import { clamp } from "lodash"
import React, { useRef, useState } from "react"
import Scrollbars from "react-custom-scrollbars"
import { useFormContext } from "react-hook-form"
import { useUpdateEffect } from "react-use"
import AutoCompleteInputKeyboardHandler from "./AutoCompleteInputKeyboardHandler"

export type AutoCompleteInputFilterFunction<T> = (
  item: T,
  search: string
) => Boolean
export type AutoCompleteRenderInput<T> = (props: {
  isDirty: boolean
  isInvalid?: boolean
  search: string
  setSearchValue: (search: string) => void
  selected?: T
  clear: () => void
  toggle: () => void
}) => React.ReactNode
export type AutoCompleteRenderItem<T> = (
  item: T,
  isSelected: boolean,
  isInvalid?: boolean
) => React.ReactNode
export type AutoCompleteRenderEmpty = () => React.ReactNode
export type AutoCompleteKeyExtractor<T> = (item: T) => string | number
export type AutoCompleteValueExtractor<T> = (item: T) => string | number

interface Props<T> {
  name: string
  data: T[]
  value?: T
  isInvalid?: boolean
  keyExtractor: AutoCompleteKeyExtractor<T>
  valueExtractor: AutoCompleteValueExtractor<T>
  filterFunction: AutoCompleteInputFilterFunction<T>
  renderInput: AutoCompleteRenderInput<T>
  renderItem: AutoCompleteRenderItem<T>
  renderEmpty: AutoCompleteRenderEmpty
}

function AutoCompleteInputInner<T>(props: Props<T>) {
  const {
    name,
    data,
    renderEmpty,
    renderInput,
    renderItem,
    value,
    isInvalid,
    filterFunction,
    keyExtractor,
    valueExtractor,
  } = props

  const scrollToRef = useRef<any>({})
  const { setValue, getValues, register, control } = useFormContext()

  const defaultValue = control.defaultValuesRef.current[name]
  const defaultSelectedItem = data.find(
    item => valueExtractor(item).toString() === String(defaultValue)
  )

  const [selectedIndex, setSelectedIndex] = useState(-1)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isDirty, setIsDirty] = useState(false)
  const [currentSearchValue, setCurrentSearchValue] = useState(
    getValues(name) ?? ""
  )
  const [currentValue, setCurrentValue] = useState<T | undefined>(
    defaultSelectedItem
  )

  const filteredData = data.filter(item =>
    filterFunction(item, currentSearchValue)
  )

  const inputKey = value ? keyExtractor(value) : "none"

  const handleUpdateSearch = (searchValue: string) => {
    setCurrentSearchValue(searchValue)
    setIsDirty(true)
    onOpen()
  }

  const cancel = () => {
    onClose()
    setIsDirty(false)
    setSelectedIndex(-1)
  }

  const handleClearValue = () => {
    setCurrentSearchValue("")
    setCurrentValue(undefined)
    setValue(name, "")
    cancel()
  }

  const handleSelect = (item: T) => {
    setCurrentValue(item)
    setCurrentSearchValue("")
    setValue(name, keyExtractor(item))
    cancel()
  }

  const handleToggle = () => {
    if (isOpen && currentValue) {
      cancel()
    } else if (isOpen) {
      onClose()
    } else {
      onOpen()
    }
  }

  useUpdateEffect(() => {
    const currentSelectedItem = filteredData[selectedIndex]
    if (currentSelectedItem) {
      const key = keyExtractor(filteredData[selectedIndex])
      const currentRefToScroll = scrollToRef.current[key]
      if (currentRefToScroll) {
        currentRefToScroll.scrollIntoView({ block: "center" })
      }
    }
  }, [filteredData, keyExtractor, selectedIndex])

  const onEnter = (e: KeyboardEvent) => {
    if (selectedIndex !== -1) {
      handleSelect(filteredData[selectedIndex])
    }
    e.preventDefault()
  }

  const onArrowUp = () =>
    setSelectedIndex(index => clamp(index - 1, 0, data.length - 1))

  const onArrowDown = () => {
    setSelectedIndex(index => clamp(index + 1, 0, data.length - 1))
  }

  return (
    <Box position="relative">
      <Input name={name} ref={register} type="hidden" />
      <React.Fragment key={inputKey}>
        {renderInput({
          isDirty,
          search: currentSearchValue,
          setSearchValue: handleUpdateSearch,
          selected: currentValue,
          toggle: handleToggle,
          clear: handleClearValue,
          isInvalid,
        })}
      </React.Fragment>
      {isOpen && (
        <Box
          shadow="lg"
          mt={2}
          borderWidth={1}
          borderRadius="md"
          position="absolute"
          zIndex={10}
          bg="white"
          w="full"
        >
          <AutoCompleteInputKeyboardHandler
            onEscape={cancel}
            onEnter={onEnter}
            onArrowUp={onArrowUp}
            onArrowDown={onArrowDown}
          />
          <Scrollbars autoHeight={true} autoHeightMin={0} autoHeightMax={400}>
            <VStack p={2} pr={4}>
              {filteredData.map((item, index) => {
                const isSelected = index === selectedIndex
                return (
                  <Box
                    w="full"
                    ref={el => {
                      scrollToRef.current[keyExtractor(item)] = el
                    }}
                    key={keyExtractor(item)}
                    onClick={() => handleSelect(item)}
                  >
                    {renderItem(item, isSelected, isInvalid)}
                  </Box>
                )
              })}
              {filteredData.length === 0 && renderEmpty()}
            </VStack>
          </Scrollbars>
        </Box>
      )}
    </Box>
  )
}

export default AutoCompleteInputInner
