import {
  Badge,
  Box,
  CircularProgress,
  HStack,
  IconButton,
  Select,
  Tooltip,
} from "@chakra-ui/react"
import Body from "components/Body"
import Page from "components/Page"
import Table, { ColumnDefinition } from "components/Table"
import { format } from "date-fns"
import { useCustomToast } from "hooks/useCustomToast"
import React, { useCallback, useState } from "react"
import { FiRefreshCw } from "react-icons/fi"
import { useQueryClient } from "react-query"
import api from "services/api"

interface Props {}

const columns: ColumnDefinition[] = [
  { header: "Id", accessor: "id" },
  { header: "Remote Id", accessor: "remoteId" },
  {
    header: "Environment",
    accessor: "environment",
    render: entity => {
      switch (entity.environment) {
        case "production":
          return <Badge colorScheme="red">Production</Badge>
        case "staging":
          return <Badge colorScheme="blue">Staging</Badge>
      }
      return <Badge colorScheme="green">Local</Badge>
    },
  },
  { header: "Country name", accessor: "name" },
  { header: "Country code", accessor: "code" },
  {
    header: "Updated",
    accessor: "updatedAt",
    render: entity => format(new Date(entity.updatedAt), "yyyy/MM/dd"),
  },
  {
    header: "Created",
    accessor: "createdAt",
    render: entity => format(new Date(entity.createdAt), "yyyy/MM/dd"),
  },
]

const TerritoriesScreen: React.FC<Props> = () => {
  const [isSyncing, setIsSyncing] = useState(false)
  const [query, setQuery] = useState<any>()
  const queryClient = useQueryClient()
  const toast = useCustomToast()

  const onSelectChange = useCallback(
    e => {
      const option = e.target.value

      if (option === "all" && query.environment !== undefined) {
        setQuery({
          $or: [
            { environment: "local" },
            { environment: "staging" },
            { environment: "production" },
          ],
        })
        return
      }

      setQuery({
        $or: {
          environment: option,
        },
      })
    },
    [setQuery, query]
  )

  const onSync = useCallback(async () => {
    setIsSyncing(true)
    try {
      await api.create("sync-territories-with-remote-api", {})
      toast({
        title: "Synchronisation completed.",
        description:
          "Production and staging territories has been successfully synchronized from Autopilot",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
    } catch (error: any) {
      toast({
        title: `Unable to synchronize with remote API`,
        description: error,
        status: "error",
        duration: 10000,
        isClosable: true,
      })
    }
    queryClient.invalidateQueries("territories")
    setIsSyncing(false)
  }, [setIsSyncing, toast, queryClient])

  const actions = (
    <HStack spacing={3} justifyContent="flex-end" alignItems="center">
      <Box>
        {isSyncing && (
          <CircularProgress
            thickness="5px"
            isIndeterminate
            color="primary.500"
            size="2rem"
          />
        )}
      </Box>

      <Select variant="filled" disabled={isSyncing} onChange={onSelectChange}>
        <option value="all">All environments</option>
        <option value="local">Local</option>
        <option value="staging">Staging</option>
        <option value="production">Production</option>
      </Select>

      <Tooltip
        label="Sync territories with remote APIs"
        hasArrow
        arrowSize={15}
      >
        <IconButton
          size="sm"
          aria-label="update"
          icon={<FiRefreshCw />}
          rounded="full"
          colorScheme="primary"
          onClick={onSync}
          disabled={isSyncing}
        />
      </Tooltip>
    </HStack>
  )

  return (
    <Page title="Territories">
      <Body padding={0} title="All territories" headerActions={actions}>
        <Box position="relative">
          {isSyncing && (
            <Box
              position="absolute"
              w="100%"
              h="100%"
              bg="#fff"
              left={0}
              top={0}
              zIndex={1}
              opacity={0.5}
              cursor="progress"
            />
          )}

          <Table
            resource="territories"
            columns={columns}
            query={query}
            sortByCol="name"
          />
        </Box>
      </Body>
    </Page>
  )
}

export default TerritoriesScreen
