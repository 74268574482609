import { Box } from "@chakra-ui/react"
import { BlockEntity, BlockPhotosUploadedEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import React from "react"

interface Props {
  block: BlockEntity
}

const BlockPhotosUploaded: React.FC<Props> = ({ block }) => {
  const { isLoading } = useEntity<BlockPhotosUploadedEntity>(
    "blocks-photos-uploaded",
    block.childId
  )

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return <Box>Nothing to configure</Box>
}

export default BlockPhotosUploaded
