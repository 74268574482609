import { Button, Icon, Stack } from "@chakra-ui/react"
import { PageEntity } from "@jackfruit/common"
import Body from "components/Body"
import { useEntityList } from "hooks/useEntityList"
import { useResourceActions } from "hooks/useResourceActions"
import { RouteParams } from "navigation/RouteParams"
import React, { useCallback, useState } from "react"
import { FiPlusCircle } from "react-icons/fi"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import AppPageTree from "./AppPageTree"

const Actions = () => {
  const match = useRouteMatch()
  return (
    <Stack direction="row">
      <Link to={`${match.url}/new`}>
        <Button colorScheme="primary" leftIcon={<Icon as={FiPlusCircle} />}>
          Create Page
        </Button>
      </Link>
    </Stack>
  )
}

interface Props {}

const AppPageTreeScreen: React.FC<Props> = () => {
  const { appId } = useParams<RouteParams>()
  const [query] = useState({
    query: {
      appId,
      $sort: {
        name: 1,
      },
      $limit: 1000,
    },
  })
  const { data, isLoading } = useEntityList<PageEntity>("pages", query, {
    refetchOnWindowFocus: false,
  })
  const { patchMultiple } = useResourceActions<PageEntity>("pages")
  const onSubmit = useCallback(
    async (pages: PageEntity[]) => {
      await patchMultiple(pages)
    },
    [patchMultiple]
  )

  return (
    <Body
      isLoading={isLoading}
      title="Page Hierachy"
      headerActions={<Actions />}
    >
      <AppPageTree pages={data.data} onSubmit={onSubmit} />
    </Body>
  )
}

export default AppPageTreeScreen
