import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import "ace-builds"
import "ace-builds/webpack-resolver"
import React from "react"
import { useForm } from "react-hook-form"
import FormSectionHeader from "components/FormSectionHeader"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import { IntegrationsEntity } from "@jackfruit/common"

interface Props {
  entity?: IntegrationsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const SentryForm: React.FC<Props> = ({ entity, isLoading, onSubmit }) => {
  const defaultValues: any = {
    ...entity,
  }

  const { handleSubmit, register, errors } = useForm<IntegrationsEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Sentry Integration</FormSectionHeader>
      <FormControl isInvalid={!!errors.sentryOrganisation}>
        <FormLabel>Sentry Organisation</FormLabel>
        <InputGroup>
          <Input ref={register()} name="sentryOrganisation" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.sentryOrganisation && errors.sentryOrganisation.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.sentryProject}>
        <FormLabel>Sentry Project</FormLabel>
        <InputGroup>
          <Input ref={register()} name="sentryProject" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.sentryProject && errors.sentryProject.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.sentryDSN}>
        <FormLabel>Sentry DSN</FormLabel>
        <InputGroup>
          <Input ref={register()} name="sentryDSN" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.sentryDSN && errors.sentryDSN.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.sentryAuthToken}>
        <FormLabel>Sentry Auth Token</FormLabel>
        <InputGroup>
          <Input ref={register()} name="sentryAuthToken" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.sentryAuthToken && errors.sentryAuthToken.message}
        </FormErrorMessage>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default SentryForm
