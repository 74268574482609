import { useAuthState } from "hooks/useAuthState"
import React from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom"
import LoginScreen from "screens/LoginScreen"
import AdminNavigation from "./AdminNavigation"

interface Props {}

const Navigation: React.FC<Props> = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login">
          <LoginScreen />
        </Route>
        <PrivateRoute path="/admin">
          <AdminNavigation />
        </PrivateRoute>
      </Switch>
    </Router>
  )
}

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isSuccess } = useAuthState()

  return (
    <Route
      {...rest}
      // @ts-ignore
      render={({ location }) =>
        isSuccess ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default Navigation
