import { Flex, HStack, VStack } from "@chakra-ui/react"
import { PageInteractionsEntity } from "@jackfruit/common"
import Body from "components/Body"
import Navbar, { NavbarConfig } from "components/Navbar/Navbar"
import { useBlocksEntities } from "hooks/useBlocksEntities"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import { RouteParams } from "navigation/RouteParams"
import React, { useMemo } from "react"
import { FiArrowLeft } from "react-icons/fi"
import { useHistory, useParams } from "react-router-dom"
import AppPageFloatCtaActivationBlocksForm from "../forms/AppPageFloatCtaActivationBlocksForm"
import AppPageFloatCtaDetailForm from "../forms/AppPageFloatCtaDetailForm"

interface Props {}

const AppPageInteractivityEdit: React.FC<Props> = () => {
  const { appId, pageId, pageInteractionId } = useParams<RouteParams>()
  const { location } = useHistory()
  const goBackPath = useMemo(
    () => location.pathname.split("/").slice(0, -1).join("/"),
    [location]
  )

  const { blocks: blocksEntities, isLoading: isLoadingBlockEntities } =
    useBlocksEntities({
      appId,
      pageId,
    })

  const { data: entity, isLoading } = useFindOneEntity<PageInteractionsEntity>(
    "page-interactions",
    {
      query: {
        pageId,
        id: pageInteractionId,
      },
    }
  )

  const navbarConfig: NavbarConfig = {
    items: [
      {
        type: "link",
        to: goBackPath,
        label: `Floating CTA #${pageInteractionId}`,
        icon: <FiArrowLeft />,
        canActivate: false,
      },
    ],
  }

  if (isLoading || isLoadingBlockEntities) {
    return null
  }

  return (
    <VStack spacing={4} alignItems="start" w="full">
      <Navbar config={navbarConfig} />
      <HStack alignItems="start" spacing={4} w="full">
        <VStack>
          <Body variant="transparent">
            <HStack alignItems="stretch" spacing={4}>
              <Body>
                <AppPageFloatCtaDetailForm
                  entity={entity}
                  isLoading={isLoading}
                />
              </Body>

              <Flex
                borderRadius="md"
                borderWidth={1}
                flexDirection="column"
                padding={4}
                bg="white"
                w={"350px"}
              >
                <AppPageFloatCtaActivationBlocksForm
                  entity={entity}
                  isLoading={isLoading || isLoadingBlockEntities}
                  blocksEntities={blocksEntities}
                />
              </Flex>
            </HStack>
          </Body>
        </VStack>
      </HStack>
    </VStack>
  )
}

export default AppPageInteractivityEdit
