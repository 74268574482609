import React, { useState, useCallback } from "react"
import { FiPlusCircle } from "react-icons/fi"
import { Stack, Button, Icon } from "@chakra-ui/react"
import { useParams, Link, useRouteMatch } from "react-router-dom"
import { useResourceList } from "hooks/useResourceList"
import { useResourceActions } from "hooks/useResourceActions"
import Body from "components/Body"
import AppNavigationTree from "./AppNavigationTree"
import { useResource } from "hooks/useResource"
import { RouteParams } from "navigation/RouteParams"
import {
  NavigationEntity,
  NavigationLinkEntity,
  PageEntity,
} from "@jackfruit/common"
import { useEntityList } from "hooks/useEntityList"

const Actions = () => {
  const match = useRouteMatch()
  return (
    <Stack direction="row">
      <Link to={`${match.url}/navigation-links/new`}>
        <Button colorScheme="primary" leftIcon={<Icon as={FiPlusCircle} />}>
          Create Link
        </Button>
      </Link>
    </Stack>
  )
}

interface Props {}

const AppNavigationTreeScreen: React.FC<Props> = () => {
  const { appId, navigationId } = useParams<RouteParams>()
  const [navigationLinkQuery] = useState({
    query: {
      navigationId,
      $sort: {
        order: 1,
      },
    },
  })
  const [pageQuery] = useState({
    query: {
      appId,
    },
  })

  const { entity: navigation, isLoading: isLoadingNavigation } =
    useResource<NavigationEntity>("navigations", parseInt(navigationId))

  const { data: navigationLinks, isLoading: isLoadingNavigationLinks } =
    useEntityList<NavigationLinkEntity>("navigation-links", navigationLinkQuery)

  const { data: pages, isLoading: isLoadingPages } =
    useResourceList<PageEntity>("pages", pageQuery)

  const { patchMultiple } =
    useResourceActions<NavigationLinkEntity>("navigation-links")
  const onSubmit = useCallback(
    async (navigationLink: NavigationLinkEntity[]) => {
      await patchMultiple(navigationLink)
    },
    [patchMultiple]
  )

  const isLoading =
    isLoadingNavigation || isLoadingNavigationLinks || isLoadingPages

  return (
    <Body
      fullWidth={true}
      isLoading={isLoading}
      title={navigation.name}
      headerActions={<Actions />}
    >
      <AppNavigationTree
        links={navigationLinks.data}
        pages={pages.data}
        onSubmit={onSubmit}
      />
    </Body>
  )
}

export default AppNavigationTreeScreen
