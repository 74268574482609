import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import AppPageBlockPrintServiceListScreen from "../product-configurations/AppPageBlockPrintServiceListScreen"
import AppPageBlockProductConfigurationListScreen from "../product-configurations/AppPageBlockProductConfigurationListScreen"

interface Props {}

const AppPageBlockNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/product-configuration`} exact>
        <AppPageBlockPrintServiceListScreen />
      </Route>
      <Route
        path={`${match.path}/product-configuration/print-service-configurations/:printServiceId`}
      >
        <AppPageBlockProductConfigurationListScreen />
      </Route>
    </Switch>
  )
}

export default AppPageBlockNavigation
