import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Tag,
  TagCloseButton,
  TagLabel,
  useClipboard,
  VStack,
} from "@chakra-ui/react"
import { uniq } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { IoMdCopy } from "react-icons/io"

interface Props extends InputProps {
  value?: string[]
  onChange: (tags: any) => void
  removeSpace?: boolean
}

const TagInput: React.FC<Props> = ({
  value = [],
  onChange,
  removeSpace = false,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = useState("")
  const [currentTags, setCurrentTags] = useState(value || [])

  const { onCopy } = useClipboard(currentTags.toString())

  const handleChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(e.currentTarget.value)
    },
    []
  )

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.value
      if (e.key === "Enter") {
        e.preventDefault()

        const newTags = newValue.split(/[\n\t,]/).filter(Boolean)

        const trimmedTags = removeSpace
          ? newTags.map(tag => tag.replace(/\s+/g, ""))
          : newTags

        const uniqueNewTags = uniq(trimmedTags).filter(
          tag => !currentTags.includes(tag)
        )

        if (uniqueNewTags.length > 0) {
          setCurrentTags(v => [...v, ...uniqueNewTags])
        }
        setCurrentValue("")
      }
    },
    [currentTags, removeSpace]
  )

  const handleRemove = useCallback(tag => {
    setCurrentTags(tags => tags.filter(t => t !== tag))
  }, [])

  useEffect(() => {
    onChange(currentTags)
  }, [currentTags, onChange])

  return (
    <VStack spacing={2} width="full">
      <InputGroup>
        <Input
          onKeyPress={handleKeyPress}
          onChange={handleChangeText}
          {...rest}
          value={currentValue}
        />
        <InputRightElement>
          <IconButton
            aria-label="copy tags"
            variant="ghost"
            icon={<IoMdCopy />}
            onClick={onCopy}
          />
        </InputRightElement>
      </InputGroup>
      <Flex width="full" justifyContent="start" flexWrap="wrap">
        {currentTags.map((tag, index) => {
          return (
            <Tag
              marginRight={2}
              marginBottom={2}
              size={"md"}
              key={index}
              borderRadius="full"
              variant="solid"
              colorScheme="primary"
            >
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton onClick={() => handleRemove(tag)} />
            </Tag>
          )
        })}
      </Flex>
    </VStack>
  )
}

export default TagInput
