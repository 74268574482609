import {
  Badge,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
} from "@chakra-ui/react"
import { AppEntity, PrintServiceEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormSectionSubHeader from "components/FormSectionSubHeader"
import DataTable, { ColumnDefinition } from "components/Table"
import TableBoolean from "components/TableBoolean"
import { EntityFormProps } from "forms/EntityFormProps"
import FormActions from "forms/FormActions"
import { usePatchEntity } from "hooks/usePatchEntity"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

interface Props extends EntityFormProps<AppEntity> {}

type FormData = Pick<AppEntity, "isStoresPagesEnabled">

const AppSettingsStorePagesForm: React.FC<Props> = ({
  entity,
  isLoading,
  onSubmit,
}) => {
  const { appId } = useParams<RouteParams>()

  const { handleSubmit, register, errors } = useForm<FormData>({
    defaultValues: {
      isStoresPagesEnabled: entity?.isStoresPagesEnabled,
    },
  })

  const columns: ColumnDefinition[] = [
    {
      header: "Generate stores",
      accessor: "isStoresGenerable",
      render: (printService: PrintServiceEntity) => (
        <ToggleIsStoresGenerable printService={printService} />
      ),
    },
    {
      header: "Remote Id",
      accessor: "remoteId",
    },
    {
      header: "Display Name",
      accessor: "displayName",
    },
    {
      header: "Fulfillment Type",
      accessor: "fulfillmentType",
      render: (printService: PrintServiceEntity) => (
        <Badge colorScheme="blue">{printService.fulfillmentType}</Badge>
      ),
    },
  ]

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Store Pages</FormSectionHeader>

      <FormControl
        isInvalid={!!errors.isStoresPagesEnabled}
        isDisabled={isLoading}
        display="flex"
        alignItems="center"
      >
        <FormLabel htmlFor="isStoresPagesEnabled" mb="0">
          Enable Store Page generation
        </FormLabel>

        <Switch
          id="isStoresPagesEnabled"
          ref={register}
          name="isStoresPagesEnabled"
        />
        {!!errors.isStoresPagesEnabled && (
          <FormErrorMessage>
            {errors.isStoresPagesEnabled.message}
          </FormErrorMessage>
        )}
      </FormControl>

      <Box>
        <Box mt={3} mb={2}>
          <FormSectionSubHeader>
            Select Print Services from below to generate store pages for
          </FormSectionSubHeader>
        </Box>

        <DataTable
          columns={columns}
          resource="print-services"
          query={{ appId }}
          limit={5}
        />
      </Box>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsStorePagesForm

const ToggleIsStoresGenerable: React.FC<any> = ({ printService }) => {
  const { id, isStoresGenerable } = printService

  const { mutate: patchPrintService } =
    usePatchEntity<PrintServiceEntity>("print-services")

  const toggleEnabled = () => {
    patchPrintService({
      id,
      isStoresGenerable: !isStoresGenerable,
    })
  }

  return <TableBoolean value={isStoresGenerable} onClick={toggleEnabled} />
}
