import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Body from 'components/Body'
import { useResourceForm } from 'hooks/useResourceForm'
import { RouteParams } from 'navigation/RouteParams'
import { NavigationEntity } from '@jackfruit/common'

interface Props {
  form: React.FC<any>
}

const AppNavigationCRUDScreen: React.FC<Props> = ({ form }) => {
  const history = useHistory()
  const { appId, navigationId } = useParams<RouteParams>()
  const {
    entity,
    isPatching,
    isLoading,
    onSubmit,
  } = useResourceForm<NavigationEntity>('navigations', parseInt(navigationId))

  const enhancedOnSubmit = async (data: any) => {
    const updatedData = {
      ...data,
      appId: appId,
    }
    const navigation = await onSubmit(updatedData)
    if (!navigationId) {
      history.push(`/admin/apps/${appId}/navigations/${navigation.id}/edit`)
    }
  }

  const formComponent = React.createElement(form, {
    entity,
    isLoading: isPatching,
    onSubmit: enhancedOnSubmit,
  })

  return <Body isLoading={isLoading}>{formComponent}</Body>
}

export default AppNavigationCRUDScreen
