import { VStack, SimpleGrid } from "@chakra-ui/react"
import { BlockType } from "@jackfruit/common"
import FormSectionSubHeader from "components/FormSectionSubHeader"
import React from "react"
import { IconType } from "react-icons"
import "./BlockToolBoxGroup.css"
import BlockToolBoxGroupItem from "./BlockToolBoxGroupItem"

interface Props {
  label: string
  blocks: BlockAdmin[]
  onAdd: (type: BlockType) => void
}

interface BlockAdmin {
  type: BlockType
  label: string
  icon: IconType
}

const BlockToolBoxGroup: React.FC<Props> = ({ label, blocks, onAdd }) => {
  return (
    <VStack w="full" alignItems="flex-start">
      <FormSectionSubHeader>{label}</FormSectionSubHeader>

      <SimpleGrid columns={5} spacing={2} w="full">
        {blocks.map((block, itemIndex) => (
          <BlockToolBoxGroupItem
            onAdd={onAdd}
            key={itemIndex}
            type={block.type}
            icon={block.icon}
            label={block.label}
          />
        ))}
      </SimpleGrid>
    </VStack>
  )
}

export default BlockToolBoxGroup
