import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Select,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react"
import { NavigationLinkEntity, PageEntity } from "@jackfruit/common"
import PageSelectInput from "components/AutocompleteDropDown/PageSelectInput"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import MegaMenuWrapper from "../megamenu/MegaMenuWrapper"

interface Props {
  entity?: NavigationLinkEntity
  onSubmit: (data: any) => void
  isLoading: boolean
  pages: PageEntity[]
}

const AppNavigationLinkForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
  pages,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<NavigationLinkEntity>({
    defaultValues,
  })

  const { control, handleSubmit, register, watch } = methods

  const type = watch("type")

  const placeholderText = `Example: [badge text="lorem" color="var(--chakra-colors-blue-400)" 
  bg="var(--chakra-colors-yellow-400)" 
  border="1px solid var(--chakra-colors-green-400) 
  fontSize="var(--chakra-fontSizes-xs)"]`

  return (
    <VStack alignItems="stretch" spacing={4}>
      <FormProvider {...methods}>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormSectionHeader>Navigation Link</FormSectionHeader>
          <FormControl>
            <FormLabel>Label</FormLabel>
            <InputGroup>
              <Input ref={register} name="label" type="text" />
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>Label Prefix HTML</FormLabel>
            <InputGroup>
              <Textarea h={50} ref={register} name="labelPrefixHtml" />
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>Label Suffix HTML</FormLabel>
            <InputGroup>
              <Textarea h={50} ref={register} name="labelSuffixHtml" />
            </InputGroup>
            <Text fontSize="xs" maxW="container.sm">
              {placeholderText}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>Type</FormLabel>
            <InputGroup>
              <Select ref={register} name="type">
                <option value="text">text</option>
                <option value="page">page</option>
                <option value="link">link</option>
                <option value="megamenu">mega menu</option>
              </Select>
            </InputGroup>
          </FormControl>

          {type === "page" && (
            <FormControl>
              <FormLabel>Page</FormLabel>
              <PageSelectInput name="pageId" pages={pages} />
            </FormControl>
          )}
          {type === "link" && (
            <>
              <FormControl>
                <FormLabel>Path / Url</FormLabel>
                <InputGroup>
                  <Input ref={register} name="path" type="text" />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <Controller
                    control={control}
                    name="external"
                    render={({ onChange, onBlur, value, name }) => {
                      return (
                        <Checkbox
                          onBlur={onBlur}
                          onChange={e => onChange(e.target.checked)}
                          isChecked={Boolean(value)}
                          name={name}
                        >
                          External
                        </Checkbox>
                      )
                    }}
                  />
                </InputGroup>
              </FormControl>
            </>
          )}
        </FormContainer>
      </FormProvider>
      {type === "megamenu" && (
        <>
          <FormLabel>Mega Menu</FormLabel>
          <MegaMenuWrapper navigationLink={entity!} />
        </>
      )}
      <FormActions
        isLoading={isLoading}
        entity={entity}
        onSubmit={handleSubmit(onSubmit)}
      />
    </VStack>
  )
}

export default AppNavigationLinkForm
