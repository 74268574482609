import { Badge } from "@chakra-ui/layout"
import React from "react"

interface Props {
  value: string
}

const ChangelogFieldBadge: React.FC<Props> = ({ value }) => {
  return <Badge>{value}</Badge>
}

export default ChangelogFieldBadge
