import { useCallback, useState } from "react"

export const useSelectedPage = (onChange: (pages: string[]) => void) => {
  const [selectedPages, setSelectedPages] = useState<string[]>([])

  const addInSelectedPages = useCallback(
    (value: string) => {
      setSelectedPages(prev => {
        const newSelection = [...prev, value]
        onChange(newSelection)
        return newSelection
      })
    },
    [onChange]
  )

  const removeInSelectedPages = useCallback(
    (value: string) => {
      setSelectedPages(prev => {
        const newSelection = prev.filter(v => v !== value)
        onChange(newSelection)
        return newSelection
      })
    },
    [onChange]
  )

  const resetSelectedPages = useCallback(() => {
    setSelectedPages([])

    setSelectedPages(() => {
      onChange([])
      return []
    })
  }, [onChange])

  return {
    selectedPages,
    addInSelectedPages,
    removeInSelectedPages,
    resetSelectedPages,
  }
}
