import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Select,
} from "@chakra-ui/react"
import CodePreview, { ObjectValue } from "components/CodePreview/CodePreview"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import { EntityFormProps } from "forms/EntityFormProps"
import FormActions from "forms/FormActions"
import AssetInput from "forms/inputs/AssetInput"
import { useResourceList } from "hooks/useResourceList"
import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { SnippetCategoryEntity } from "screens/snippets/categories/SnippetCategoriesListScreen"
import { removeKeys } from "utils/helpers"
import { SnippetEntity } from "./SnippetsListScreen"

interface SnippetEntityForm extends SnippetEntity {
  contentArea: ObjectValue
}

interface Props extends EntityFormProps<SnippetEntity> {}

const SnippetsForm: React.FC<Props> = ({ entity, onSubmit, isLoading }) => {
  // const history = useHistory()

  const defaultValues = {
    ...entity,
    contentArea: {
      html: entity?.html || "",
      css: entity?.css || "",
    },
  }

  const { handleSubmit, control, register, errors, setError, reset } =
    useForm<SnippetEntityForm>({
      defaultValues,
    })

  const { data: categories } =
    useResourceList<SnippetCategoryEntity>("snippet-categories")

  useEffect(() => {
    reset(defaultValues)
    // eslint-disable-next-line
  }, [reset, categories])

  const enhancedOnSubmit = (data: SnippetEntityForm) => {
    if (!data.assetId) {
      setError("assetId", {
        types: {
          required: "This field is required",
        },
      })
      return
    }

    const { html, css } = data.contentArea!
    const { contentArea, ...dataToSend } = removeKeys(
      {
        ...data,
        html,
        css,
      },
      ["updatedAt", "createdAt", "chosen", "selected"]
    )

    onSubmit(dataToSend)
    // history.goBack()
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <>
      <FormContainer onSubmit={handleSubmit(enhancedOnSubmit)}>
        <FormSectionHeader>Snippets</FormSectionHeader>
        <FormControl isInvalid={errors.name !== undefined}>
          <FormLabel>Title</FormLabel>
          <InputGroup>
            <Input
              ref={register({ required: true })}
              name="name"
              type="text"
              placeholder="Title"
            />
          </InputGroup>
          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.snippetCategoryId !== undefined}>
          <FormLabel>Snippet Category</FormLabel>
          <InputGroup>
            <Select
              ref={register({ required: true })}
              name="snippetCategoryId"
              placeholder="Select category"
            >
              {categories?.data?.map((category: SnippetCategoryEntity) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
          </InputGroup>
          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.snippetCategoryId !== undefined}>
          <FormLabel>Content</FormLabel>
          <Controller
            as={CodePreview as any}
            name="contentArea"
            control={control}
          />
        </FormControl>

        <FormControl isInvalid={errors.assetId !== undefined}>
          <FormLabel>Preview</FormLabel>
          <Controller
            as={AssetInput as any}
            name="assetId"
            control={control}
            defaultValue=""
          />
          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl>

        <FormActions isLoading={isLoading} entity={entity} />
      </FormContainer>
    </>
  )
}

export default SnippetsForm
