import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import "ace-builds"
import "ace-builds/webpack-resolver"
import React from "react"
import { useForm } from "react-hook-form"
import { EntityFormProps } from "forms/EntityFormProps"
import { AppEntity } from "@jackfruit/common"
import FormSectionHeader from "components/FormSectionHeader"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"

interface Props extends EntityFormProps<AppEntity> {}

const AppBrandingSeoAccountForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }
  const { handleSubmit, register, errors } = useForm<AppEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Account</FormSectionHeader>
      <FormControl isInvalid={!!errors.accountPageTitle}>
        <FormLabel>Account Page Title</FormLabel>
        <InputGroup>
          <Input ref={register()} name="accountPageTitle" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.accountPageTitle && errors.accountPageTitle.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.accountPageDescription}>
        <FormLabel>Account Page description</FormLabel>
        <InputGroup>
          <Input ref={register()} name="accountPageDescription" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.accountPageDescription &&
            errors.accountPageDescription.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.registerPageTitle}>
        <FormLabel>Register Page Title</FormLabel>
        <InputGroup>
          <Input ref={register()} name="registerPageTitle" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.registerPageTitle && errors.registerPageTitle.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.registerPageDescription}>
        <FormLabel>Register Page description</FormLabel>
        <InputGroup>
          <Input ref={register()} name="registerPageDescription" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.registerPageDescription &&
            errors.registerPageDescription.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.forgotPasswordPageTitle}>
        <FormLabel>Forgot Password Page Title</FormLabel>
        <InputGroup>
          <Input ref={register()} name="forgotPasswordPageTitle" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.forgotPasswordPageTitle &&
            errors.forgotPasswordPageTitle.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.forgotPasswordPageDescription}>
        <FormLabel>Forgot Password Page description</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="forgotPasswordPageDescription"
            type="text"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.forgotPasswordPageDescription &&
            errors.forgotPasswordPageDescription.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.changePasswordPageTitle}>
        <FormLabel>Change Password Page Title</FormLabel>
        <InputGroup>
          <Input ref={register()} name="changePasswordPageTitle" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.changePasswordPageTitle &&
            errors.changePasswordPageTitle.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.changePasswordPageDescription}>
        <FormLabel>Change Password Page description</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="changePasswordPageDescription"
            type="text"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.changePasswordPageDescription &&
            errors.changePasswordPageDescription.message}
        </FormErrorMessage>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppBrandingSeoAccountForm
