import { Select } from "@chakra-ui/react"
import React, { ChangeEvent } from "react"

interface Props {
  onChange: (value: number) => void
  value: number
}

const TableFilterBoolean: React.FC<Props> = ({ onChange, value }) => {
  return (
    <Select
      size="sm"
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value
        onChange(parseInt(newValue))
      }}
    >
      <option value="-1">Any</option>
      <option value="1">Yes</option>
      <option value="0">No</option>
    </Select>
  )
}

export default TableFilterBoolean
