import Navbar, { NavbarConfig } from "components/Navbar/Navbar"
import React from "react"
import { FiArrowLeft } from "react-icons/fi"
import { generatePath, useRouteMatch } from "react-router-dom"

interface Props {}

const AppNavigationLinkNav: React.FC<Props> = () => {
  const match = useRouteMatch()
  const rootPath = generatePath(
    `/admin/apps/:appId/navigations/:navigationId`,
    match.params as any
  )

  const config: NavbarConfig = {
    items: [
      {
        type: "link",
        to: rootPath,
        label: "Navigation",
        icon: <FiArrowLeft />,
        canActivate: false,
      },
    ],
  }
  return <Navbar config={config} />
}

export default AppNavigationLinkNav
