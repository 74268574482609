import { BlockFooterEntity, CommonEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import BlockFooterFields from "screens/apps/pages/blocks/forms/block-footer/BlockFooterFields"
interface Props {
  entity: CommonEntity
  isLoading: boolean
  blockEntity: any
}

const AppCommonFooterColumnsForm: React.FC<Props> = ({
  entity,
  isLoading,
  blockEntity,
}) => {
  const { footer: dataBlockFooter } = blockEntity
  const defaultValues = {
    ...entity,
    footer: { ...dataBlockFooter },
  }

  const methods = useForm<any>({ defaultValues })
  const { handleSubmit } = methods

  const { mutate: saveBlockFooter } =
    usePatchEntity<BlockFooterEntity>("blocks-footer")
  const enhancedSubmit = (data: any) => {
    const { footer } = data
    saveBlockFooter({ id: dataBlockFooter.id, ...footer })
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
        <FormSectionHeader>Footer Columns</FormSectionHeader>
        <BlockFooterFields entity={dataBlockFooter} />
        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default AppCommonFooterColumnsForm
