import Icon from "@chakra-ui/icon"
import { HStack } from "@chakra-ui/layout"
import { Text } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { FiCalendar } from "react-icons/fi"

interface Props {
  value: Date
}

const ChangelogFieldDate: React.FC<Props> = ({ value }) => {
  return (
    <HStack>
      <Icon as={FiCalendar} />
      <Text fontSize="xs">
        {format(new Date(value), "yyyy/MM/dd HH:mm:ss")}
      </Text>
    </HStack>
  )
}

export default ChangelogFieldDate
