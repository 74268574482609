import {
  Alert,
  AlertIcon,
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  InputGroup,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Textarea,
  VStack,
} from "@chakra-ui/react"
import {
  automatedTestOptions,
  DeploymentEntity,
  LambdaAbVariantEntity,
  PageEntity,
  SettingsEntity,
} from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import GitVersionInput, {
  REACT_APP_VERSION,
} from "components/inputs/GitVersionInput"
import FormActions from "forms/FormActions"
import { useEntityList } from "hooks/useEntityList"
import React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import AppCommonPageTreeSelect from "screens/apps/common/forms/AppCommonPageTreeSelect"

interface Props {
  entity?: DeploymentEntity
  settings: SettingsEntity
  latestDeployment: DeploymentEntity
  onSubmit: (data: DeploymentEntity) => void
  isLoading: boolean
  pages: PageEntity[]
}

const AppDeploymentForm: React.FC<Props> = ({
  entity,
  settings,
  latestDeployment,
  onSubmit,
  isLoading,
  pages,
}) => {
  const defaultValues = {
    ...entity,
    customTestTags: [],
    postDeploymentNotificationEmailBody: "",
    gitReference: latestDeployment?.gitReference ?? REACT_APP_VERSION,
    selectedPages: [],
  }

  const liveDeploymentTestTags = entity?.liveDeploymentTestTags || []
  const methods = useForm<DeploymentEntity>({
    defaultValues,
  })
  const { handleSubmit, register, errors, watch, clearErrors } = methods
  const testTags = watch("testTags")

  const { data: lambdaAbVariants } = useEntityList<LambdaAbVariantEntity>(
    "lambda-ab-variants",
    {
      query: {
        settingId: settings.id,
      },
    }
  )

  const enhancedSubmit = (data: any) => {
    if (data?.customTestTags?.length > 0) {
      data.testTags = data.customTestTags.join("|")
    }

    delete data.customTestTags

    onSubmit(data)
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
        <FormSectionHeader>Live Deployment</FormSectionHeader>

        <FormControl isInvalid={Boolean(errors.gitReference)}>
          <FormLabel>Git Reference</FormLabel>
          <GitVersionInput
            ref={register({ required: true })}
            name="gitReference"
            clearErrors={clearErrors}
            currentVersion={latestDeployment?.gitReference}
          />
          <FormErrorMessage>
            {errors.gitReference && errors.gitReference.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Automated Tests</FormLabel>
          <RadioGroup name="testTags" defaultValue={defaultValues.testTags}>
            {automatedTestOptions.map((option, index) => (
              <Radio key={index} ref={register()} value={option.value} mr={5}>
                {option.label}
              </Radio>
            ))}
          </RadioGroup>

          {testTags === "custom" && (
            <Box my={5}>
              <Divider my={3} />
              <FormControl isInvalid={Boolean(errors.customTestTags)}>
                <Controller
                  name="customTestTags"
                  control={methods.control}
                  defaultValue={defaultValues.customTestTags || []}
                  rules={{
                    validate: value => value.length > 0,
                  }}
                  render={({ onChange, value }) => (
                    <CheckboxGroup value={value} onChange={onChange}>
                      <Stack pl={6} mt={1} spacing={1}>
                        {liveDeploymentTestTags.map((tag, index) => (
                          <Checkbox key={index} value={`@${tag}`}>
                            {tag}
                          </Checkbox>
                        ))}
                      </Stack>
                    </CheckboxGroup>
                  )}
                />
                <FormErrorMessage>
                  {errors.customTestTags && (
                    <Alert
                      status="error"
                      mt={5}
                      color="gray.600"
                      borderRadius="md"
                    >
                      <AlertIcon />
                      At least one Test Tag must be selected
                    </Alert>
                  )}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}
        </FormControl>

        {testTags && (
          <FormControl>
            <FormLabel>Pages To Test</FormLabel>
            <Controller
              name="selectedPages"
              control={methods.control}
              defaultValue={[]}
              render={({ onChange }) => (
                <AppCommonPageTreeSelect pages={pages} onChange={onChange} />
              )}
            />
          </FormControl>
        )}

        {settings.lambdaAbEnabled && (
          <FormControl>
            <FormLabel>Lambda A/B Test Variant</FormLabel>
            <InputGroup>
              <Select ref={register} name="lambdaAbVariantId">
                {lambdaAbVariants.data.map(lambdaAbVariant => (
                  <option key={lambdaAbVariant.id} value={lambdaAbVariant.id}>
                    {lambdaAbVariant.name}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </FormControl>
        )}

        <FormControl isInvalid={Boolean(errors.comment)}>
          <FormLabel>Comment</FormLabel>
          <InputGroup>
            <Textarea
              h={250}
              ref={register({ required: true, minLength: 10 })}
              name="comment"
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.comment && "You must add a comment (10 characters min)"}
          </FormErrorMessage>
        </FormControl>

        <VStack
          backgroundColor="gray.50"
          p={4}
          borderRadius="md"
          alignItems="flex-start"
        >
          <Heading size="md" mb={3}>
            Notification Email
          </Heading>
          <FormControl
            isInvalid={Boolean(errors.postDeploymentNotificationEmailBody)}
          >
            <FormLabel>Body</FormLabel>
            <InputGroup>
              <Textarea
                backgroundColor="white"
                h={250}
                ref={register({ required: true, minLength: 10 })}
                name="postDeploymentNotificationEmailBody"
              />
            </InputGroup>

            <FormErrorMessage>
              {errors.postDeploymentNotificationEmailBody &&
                "You must add a comment (10 characters min)"}
            </FormErrorMessage>
          </FormControl>
        </VStack>
        <FormActions isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default AppDeploymentForm
