import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Select,
  Spinner,
  Text,
  useBoolean,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react"
import { FulfillmentTypes, AppEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormSectionSubHeader from "components/FormSectionSubHeader"
import "components/ReactDatePickerCustom.css"
import FormActions from "forms/FormActions"
import { useEntityList } from "hooks/useEntityList"
import { capitalize } from "lodash"
import { RouteParams } from "navigation/RouteParams"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form"
import { ImRadioChecked, ImRadioUnchecked } from "react-icons/im"
import { useParams } from "react-router"
import slugify from "slugify"
import { formatDateForApi } from "utils/helpers"
import PageTerritoriesSelector from "./fields/PageTerritoriesSelector"

interface Props {
  entity?: any
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppPageForm: React.FC<Props> = ({ entity, onSubmit, isLoading }) => {
  const { pageId, appId } = useParams<RouteParams>()

  let defaultValues = {
    ...entity,
  }

  if (!pageId) {
    // Default values on creation
    defaultValues = {
      ...defaultValues,
      status: "draft",
      isPickupEnabled: true,
      defaultFulfillment: "pickup",
      publishedAt: formatDateForApi(new Date()),
    }
  }

  const methods = useForm({ defaultValues })
  const { handleSubmit, register, errors, setValue, getValues, control } =
    methods

  const { data: app, isLoading: isLoadingSettings } = useEntityList<AppEntity>(
    "apps",
    {
      query: {
        id: appId,
      },
    }
  )

  const [defaultCountryCode, setDefaultCountryCode] = useState<string>()
  useEffect(() => {
    if (!isLoadingSettings) {
      setDefaultCountryCode(app?.data?.[0]?.defaultLocale ?? "US")
    }
  }, [isLoadingSettings, defaultCountryCode, app])

  const getSlug = (value: string) =>
    slugify(value, {
      lower: true,
      strict: true,
    })

  const onGenerateSlug = useCallback(() => {
    setValue("slug", getSlug(getValues("name")))
  }, [getValues, setValue])

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormSectionHeader>Page</FormSectionHeader>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <InputGroup>
            <Input ref={register} name="name" type="text" />
          </InputGroup>
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Slug</FormLabel>
          <InputGroup>
            <InputLeftAddon children="/" />
            <Input ref={register} name="slug" type="text" />
            <InputRightElement width="6rem">
              <Button
                h="1.75rem"
                size="sm"
                colorScheme="primary"
                onClick={onGenerateSlug}
              >
                Generate
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Status</FormLabel>
          <FormHelperText pb={4}>
            Draft pages will be purposefully omitted from Navigation list
            generation.
          </FormHelperText>
          <Select ref={register} name="status" defaultValue="draft">
            <option value="draft">Draft</option>
            <option value="active">Active</option>
          </Select>
        </FormControl>

        <FormSectionSubHeader>Fulfillment</FormSectionSubHeader>

        <VStack mt={0}>
          <FulfillmentSelection fulfillment="pickup" />
          <FulfillmentSelection fulfillment="delivery" />

          <Input
            // @ts-ignore
            {...register("defaultFulfillment")}
            type="hidden"
          />
        </VStack>

        {isLoadingSettings && <Spinner size="md" />}

        {!!defaultCountryCode && (
          <>
            <FormSectionSubHeader>Pickup Countries</FormSectionSubHeader>

            <PageTerritoriesSelector
              isPickup={true}
              defaultCodes={[defaultCountryCode]}
              name="pickupTerritories"
              defaultCountryName="defaultCountryPickup"
            />

            <FormSectionSubHeader>Delivery Countries</FormSectionSubHeader>
            <PageTerritoriesSelector
              isPickup={false}
              defaultCodes={[defaultCountryCode]}
              name="deliveryTerritories"
              defaultCountryName="defaultCountryDelivery"
            />
          </>
        )}

        <FormControl>
          <FormLabel>Published At Date</FormLabel>
          <FormHelperText pb={4}>
            Defaults to the created at date
          </FormHelperText>
          <Controller
            name="publishedAt"
            control={control}
            render={({ onChange, value }) => {
              return (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={new Date(value)}
                  onChange={newDate => {
                    onChange(formatDateForApi(newDate as Date))
                  }}
                  isClearable={true}
                  placeholderText="Select a date"
                />
              )
            }}
          />
        </FormControl>

        <FormActions isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default AppPageForm

const FulfillmentSelection: React.FC<{ fulfillment: FulfillmentTypes }> = ({
  fulfillment,
}) => {
  const isEnabledKey = useMemo(
    () => `is${capitalize(fulfillment)}Enabled`,
    [fulfillment]
  )

  const { register, watch, setValue } = useFormContext()
  const defaultFulfillment = watch("defaultFulfillment")
  const isEnabled = watch(isEnabledKey)

  const [isChecked, setIsChecked] = useBoolean(isEnabled ?? false)
  const [isDefault, setIsDefault] = useState(
    defaultFulfillment === fulfillment ? true : false
  )

  useUpdateEffect(() => {
    const isDefaultEnabled = defaultFulfillment === fulfillment ? true : false
    setIsDefault(isDefaultEnabled)

    if (isDefaultEnabled) {
      setIsChecked.on()
      setValue(isEnabledKey, true)
    }
  }, [defaultFulfillment, setIsDefault, setValue])

  return (
    <FormControl>
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        fontWeight="normal"
        marginTop={0}
        mb={2}
      >
        <Text textTransform="capitalize">{fulfillment}</Text>

        <Flex alignItems="center">
          <Checkbox
            ref={register}
            name={isEnabledKey}
            isChecked={isChecked}
            isDisabled={isDefault}
            onChange={setIsChecked.toggle}
            defaultChecked={isChecked}
          >
            Enabled
          </Checkbox>

          <Flex
            alignItems="center"
            cursor="pointer"
            marginLeft={4}
            onClick={() => setValue("defaultFulfillment", fulfillment)}
          >
            {isDefault ? (
              <Icon as={ImRadioChecked} mr={2} color="primary.500" />
            ) : (
              <Icon as={ImRadioUnchecked} mr={2} />
            )}

            <Text>Default</Text>
          </Flex>
        </Flex>
      </Flex>
    </FormControl>
  )
}
