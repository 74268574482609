import {
  TerritoryEntity,
  SettingsEntity,
  apiUrls,
  PageTerritoriesEntity,
} from '@jackfruit/common'
import { useEffect, useState } from 'react'
import { useEntityList } from './useEntityList'

const params = {
  $limit: 1000,
}

interface Options {
  appId: number | string
  pageId?: number | string
  isPickup: boolean
}

export const usePageTerritoriesEntities = ({
  appId,
  pageId,
  isPickup,
}: Options) => {
  const [isLoading, setIsLoading] = useState(true)
  const [environment, setEnvironment] = useState<string>()

  const { data: settings } = useEntityList<SettingsEntity>('settings', {
    query: {
      appId,
    },
  })

  useEffect(() => {
    if (settings?.total! > 0) {
      const apiConfig = apiUrls.find(
        (config) => config.url === settings?.data[0].remoteApiUrl
      )!
      setEnvironment(apiConfig.environment ?? 'staging')
    }
  }, [settings])

  const { data: territories, isLoading: isLoadingTerritories } =
    useEntityList<TerritoryEntity>(
      'territories',
      {
        query: {
          ...params,
          environment,
        },
      },
      {
        enabled: !!environment,
      }
    )

  const { data: pageTerritories, isLoading: isLoadingPageTerritories } =
    useEntityList<PageTerritoriesEntity>(
      'page-territories',
      {
        query: {
          ...params,
          pageId,
          isPickup: +isPickup,
        },
      },
      {
        enabled: !!pageId,
      }
    )

  useEffect(() => {
    if (!isLoadingTerritories && !isLoadingPageTerritories) {
      setIsLoading(false)
    }
  }, [isLoadingTerritories, isLoadingPageTerritories])

  return {
    territories,
    pageTerritories,
    environment,
    isLoading,
  }
}
