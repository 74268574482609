import React from "react"
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from "recharts"
import { useTheme } from "@chakra-ui/react"

interface PieChartProps {
  data: any[]
}

const PieChartComponent: React.FC<PieChartProps> = ({ data }) => {
  const theme = useTheme()
  const colors = [
    theme.colors.blue[500],
    theme.colors.green[500],
    theme.colors.purple[500],
    theme.colors.pink[500],
    theme.colors.orange[500],
    theme.colors.teal[500],
    theme.colors.red[500],
    theme.colors.cyan[500],
    theme.colors.yellow[500],
    theme.colors.gray[500],
  ]

  const renderCustomLabel = (entry: any) => {
    return `${entry.name} (${entry.value})`
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          dataKey="value"
          data={data}
          fill="#8884d8"
          labelLine={false}
          label={renderCustomLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default PieChartComponent
