import React from "react"
import { Badge } from "@chakra-ui/react"
import { DeploymentType } from "@jackfruit/common"

const colorSchemes = {
  live: "blue",
  preview: "gray",
}

interface Props {
  type: DeploymentType
}

const DeploymentTypeBadge: React.FC<Props> = ({ type }) => {
  const colorScheme = colorSchemes[type] ?? colorSchemes.preview

  return <Badge colorScheme={colorScheme}>{type}</Badge>
}

export default DeploymentTypeBadge
