import React, { useCallback } from "react"
import {
  FormControl,
  FormLabel,
  Text,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
} from "@chakra-ui/react"
import FormActions from "forms/FormActions"
import FormContainer from "components/FormContainer"
import { Controller, useForm } from "react-hook-form"
import MultipleAssets from "components/MultipleAssets"
import { BlockImageGridEntity } from "@jackfruit/common"
import { removeKeys } from "utils/helpers"

interface Props {
  entity: BlockImageGridEntity & {
    // fixeme this is not ideal but it prevent this line of code
    // entity?.[`columnWidth${device.prefix}`]
    // from breaking
    [key: string]: any
  }
  isLoading: boolean
  onSubmit: (data: BlockImageGridEntity) => void
  params: Object
}

const devices = [
  {
    title: "Mobile",
    prefix: "Xs",
    defaultWidth: 50,
  },
  {
    title: "Tablet",
    prefix: "Md",
    defaultWidth: 30,
  },
  {
    title: "Desktop",
    prefix: "",
    defaultWidth: 20,
  },
]

const BlockImageGridForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<BlockImageGridEntity>({
    defaultValues,
  })

  const { handleSubmit, register, control } = methods

  const enhancedOnSubmit = useCallback(
    data => {
      const assets = {
        assetsMobile: data.assetsMobile,
        assetsDesktop: data.assetsDesktop,
        assetsTablet: data.assetsTablet,
      }

      const values = removeKeys(data, [
        "assetsTablet",
        "assetsMobile",
        "assetsDesktop",
      ])

      const newData = {
        ...values,
        assets,
      }

      onSubmit(removeKeys(newData, ["chosen", "selected"]))
    },
    [onSubmit]
  )

  return (
    <FormContainer onSubmit={handleSubmit(enhancedOnSubmit)}>
      <Tabs
        border={1}
        borderColor="gray.100"
        borderStyle="solid"
        borderRadius={4}
      >
        <TabList>
          {devices.map((device, index) => (
            <Tab key={index}>{device.title}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {devices.map((device, index) => (
            <TabPanel key={index}>
              <FormControl isRequired>
                <FormLabel marginTop="15px">{device.title} alignment</FormLabel>
                <Select ref={register} name={`alignment${device.prefix}`}>
                  <option value="0">Center</option>
                  <option value="1">Left</option>
                  <option value="2">Right</option>
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel marginTop="15px">
                  {device.title} columns width
                  <Text fontSize="xs" as="i" marginLeft="5px" color="gray.400">
                    (between 20 - 100%)
                  </Text>
                </FormLabel>
                <InputGroup>
                  <NumberInput
                    defaultValue={
                      entity?.[`columnWidth${device.prefix}`] ||
                      device.defaultWidth
                    }
                    min={20}
                    max={100}
                    step={5}
                    keepWithinRange={true}
                    clampValueOnBlur={false}
                  >
                    <NumberInputField
                      ref={register}
                      name={`columnWidth${device.prefix}`}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Select
                    ref={register}
                    name={`columnUnit${device.prefix}`}
                    width="100px"
                  >
                    <option value="px">px</option>
                    <option value="%">%</option>
                  </Select>
                </InputGroup>

                <FormControl isRequired>
                  <FormLabel>Images {device.title}</FormLabel>
                  <Controller
                    as={MultipleAssets as any}
                    name={`assets${device.title}`}
                    defaultValue={entity[`assets${device.title}`]}
                    control={control}
                  />
                </FormControl>
              </FormControl>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default BlockImageGridForm
