import { Box, Flex } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import { useAppRevision } from "hooks/useAppRevision"
import React from "react"
import { getStorybookPreviewUrl } from "services/storybook"

interface Props {
  appId: EntityId
  pageId: EntityId
  lang?: string
}

const AppPreview: React.FC<Props> = ({ appId, pageId, lang = "en-US" }) => {
  const { appRevision } = useAppRevision()
  const url = getStorybookPreviewUrl({ appId, pageId, lang })

  return (
    <Flex
      px={4}
      w="full"
      alignItems="center"
      direction="column"
      position="relative"
    >
      <Flex w="full" h="calc(100vh - 180px)">
        <Box
          boxShadow="lg"
          key={appRevision}
          as="iframe"
          src={url}
          width="100%"
          height="100%"
          border="none"
        />
      </Flex>
    </Flex>
  )
}

export default AppPreview
