import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-use"

interface Direction {
  label: string
  route: string
}

interface Props {
  icon: React.JSXElementConstructor<any>
  menu: Direction[]
  children?: any
}

const HeaderMenu: React.FC<Props> = ({ icon, menu, children }) => {
  const location = useLocation()
  const history = useHistory()
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    const currentPath = location.pathname
    const option = menu.find(direction =>
      currentPath?.includes(direction.route)
    )
    setIsActive(option ? true : false)
  }, [location, menu, setIsActive])

  const navigateTo = useCallback(
    (url: string) => {
      history.push(`/admin/${url}`)
    },
    [history]
  )

  return (
    <Menu>
      <MenuButton
        as={Button}
        color={isActive ? "#fff" : "secondary.50"}
        variant="link"
        _active={{ color: "white" }}
        _focus={{
          outline: "none",
        }}
      >
        <Icon as={icon} boxSize={8} color="gray.500" />
      </MenuButton>

      <MenuList>
        {menu.map((direction, index) => (
          <MenuItem
            key={index}
            onClick={() => navigateTo(direction.route)}
            color={
              location.pathname === `/admin/${direction.route}`
                ? "primary.500"
                : "secondary.500"
            }
          >
            {direction.label}
          </MenuItem>
        ))}
        {children}
      </MenuList>
    </Menu>
  )
}

export default HeaderMenu
