import {
  BlockFooterEntity,
  CommonEntity,
  NavigationEntity,
} from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import BlockHeaderFields from "screens/apps/pages/blocks/forms/block-header/BlockHeaderFields"
import { forceNullable } from "utils/helpers"

interface Props {
  entity?: CommonEntity
  onSubmit: (data: any) => void
  isLoading: boolean
  navigations: NavigationEntity[]
  blockEntity: any
}

const AppCommonHeaderNavigationsForm: React.FC<Props> = ({
  entity,
  navigations,
  blockEntity,
  onSubmit,
  isLoading,
}) => {
  const { header: dataBlockHeader } = blockEntity
  const defaultValues = { ...entity, header: dataBlockHeader }
  const methods = useForm({ defaultValues })
  const { handleSubmit } = methods

  const navigationOptions = navigations.map(navigation => (
    <option key={navigation.id} value={navigation.id}>
      {navigation.name}
    </option>
  ))

  const { mutate: saveBlockHeader } =
    usePatchEntity<BlockFooterEntity>("blocks-header")

  const enhancedSubmit = (data: any) => {
    // Save block header
    const blockHeader = forceNullable(
      { id: dataBlockHeader.id, ...data.header },
      [
        "topNavigationId",
        "brandNavigationId",
        "mainNavigationId",
        "mobileNavigationId",
      ]
    )
    saveBlockHeader(blockHeader)

    // Save common entity
    const { header, ...rest } = data
    const commonData = rest
    onSubmit(commonData)
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
        <FormSectionHeader>Navigations</FormSectionHeader>
        <BlockHeaderFields navigationOptions={navigationOptions} />
        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default AppCommonHeaderNavigationsForm
