import { Box } from "@chakra-ui/react"
import { BlockEntity, BlockGraphEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import BlockGraphForm from "../forms/block-graph/BlockGraphForm"

interface Props {
  block: BlockEntity
}

const BlockGraph: React.FC<Props> = ({ block }) => {
  const { data: entity, isLoading } = useEntity<BlockGraphEntity>(
    "blocks-graph",
    block.childId
  )

  const { mutate: saveBlock, status: patchStatus } =
    usePatchEntity<BlockGraphEntity>("blocks-graph")

  const isPatching = patchStatus === "loading"

  const onSubmit = (data: any) => {
    saveBlock({ id: entity.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockGraphForm
      entity={entity}
      isLoading={isPatching}
      onSubmit={onSubmit}
    />
  )
}

export default BlockGraph
