export const apiUrls = [
  {
    url: "https://api.printicular.com",
    name: "Production",
    environment: "production",
    key: "production",
  },
  {
    url: "https://capi.printicular.com",
    name: "Production Cached",
    environment: "production",
    key: "production-cached",
  },
  {
    url: "https://stagingapi.printicular.com",
    name: "Staging",
    environment: "staging",
    key: "staging",
  },
  {
    url: "https://stagingcapi.printicular.com",
    name: "Staging Cached",
    environment: "staging",
    key: "staging-cached",
  },
  {
    url: "http://localhost:8000",
    name: "Local",
    environment: "local",
    key: "local",
  },
]
