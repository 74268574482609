import Navbar, { NavbarConfig } from "components/Navbar/Navbar"
import React from "react"
import { FiArrowLeft } from "react-icons/fi"
import { generatePath, useRouteMatch } from "react-router-dom"

interface Props {
  extra?: NavbarConfig
}

const AppPageNav: React.FC<Props> = ({ extra = { items: [] } }) => {
  const match = useRouteMatch<{
    appId?: string
    pageId?: string
  }>()

  const rootPath = generatePath(`/admin/apps/:appId/pages`, match.params as any)
  const basePath = generatePath(
    `/admin/apps/:appId/pages/:pageId`,
    match.params as any
  )

  const config: NavbarConfig = {
    items: [
      {
        type: "link",
        to: rootPath,
        label: "Back",
        icon: <FiArrowLeft />,
        canActivate: false,
      },
      {
        type: "separator",
      },
      {
        type: "link",
        to: `${basePath}/general`,
        label: "Main",
      },
      {
        type: "link",
        to: `${basePath}/blocks`,
        label: "Blocks",
      },
      {
        type: "link",
        to: `${basePath}/page-interactions`,
        label: "Interactivity",
      },
      {
        type: "link",
        to: `${basePath}/miscellaneous`,
        label: "Misc",
      },
      {
        type: "link",
        to: `${basePath}/product-setup`,
        label: "Product",
      },
      ...extra.items,
    ],
  }
  return <Navbar config={config} />
}

export default AppPageNav
