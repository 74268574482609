import SecondarySidebar, {
  SecondarySidebarNavigationItem,
} from "components/SecondarySidebar/SecondarySidebar"
import LayoutPage from "layouts/LayoutPage"
import React from "react"
import { BiCategory } from "react-icons/bi"
import { FiClipboard } from "react-icons/fi"
import { HiOutlineClipboardDocumentList } from "react-icons/hi2"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import CreateFormScreen from "screens/CreateFormScreen"
import EditFormScreen from "screens/EditFormScreen"
import SnippetCategoriesForm from "./categories/SnippetCategoriesForm"
import SnippetCategoriesListScreen from "./categories/SnippetCategoriesListScreen"
import SnippetsForm from "./all/SnippetsForm"
import SnippetsListScreen from "./all/SnippetsListScreen"

interface Props {}

const SnippetsNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()

  const navigation: SecondarySidebarNavigationItem[] = [
    {
      label: "All Snippets",
      to: `${match.url}/all`,
      icon: HiOutlineClipboardDocumentList,
    },
    {
      label: "Categories",
      to: `${match.url}/categories`,
      icon: BiCategory,
    },
  ]

  return (
    <LayoutPage
      secondaryNavigation={
        <SecondarySidebar
          icon={FiClipboard}
          title="Snippets"
          navigation={navigation}
        />
      }
    >
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/all`} />
        <Route exact={true} path={`${match.url}/all`}>
          <SnippetsListScreen />
        </Route>
        <Route path={`${match.path}/new`}>
          <CreateFormScreen
            pagetitle="Create a new snippet"
            resource="snippets"
            render={({ onSubmit, isLoading }) => (
              <SnippetsForm onSubmit={onSubmit} isLoading={isLoading} />
            )}
          />
        </Route>
        <Route path={`${match.path}/snippets/:snippetId/edit`}>
          <EditFormScreen
            pagetitle="Edit snippet"
            resource="snippets"
            routeParam="snippetId"
            render={({ onSubmit, isLoading, entity }) => (
              <SnippetsForm
                onSubmit={onSubmit}
                isLoading={isLoading}
                entity={entity}
              />
            )}
          />
        </Route>
        <Route exact={true} path={`${match.path}/categories`}>
          <SnippetCategoriesListScreen />
        </Route>
        <Route path={`${match.path}/categories/new`}>
          <CreateFormScreen
            pagetitle="Create a new snippet category"
            resource="snippet-categories"
            render={({ onSubmit, isLoading }) => (
              <SnippetCategoriesForm
                onSubmit={onSubmit}
                isLoading={isLoading}
              />
            )}
          />
        </Route>
        <Route path={`${match.path}/categories/:snippetCategoryId/edit`}>
          <EditFormScreen
            pagetitle="Edit snippet category"
            resource="snippet-categories"
            routeParam="snippetCategoryId"
            render={({ onSubmit, isLoading, entity }) => (
              <SnippetCategoriesForm
                onSubmit={onSubmit}
                isLoading={isLoading}
                entity={entity}
              />
            )}
          />
        </Route>
      </Switch>
    </LayoutPage>
  )
}

export default SnippetsNavigation
