import { Box, Button, Icon, Text } from "@chakra-ui/react"
import { PageInteractionsEntity } from "@jackfruit/common"
import Body from "components/Body"
import DataTable, { ColumnDefinition } from "components/Table"
import TableRowActions from "components/TableRowActions"
import TableRowId from "components/TableRowId"
import { format } from "date-fns"
import { useCreateEntity } from "hooks/useCreateEntity"
import React from "react"
import { FiPlusCircle } from "react-icons/fi"
import { useHistory, useRouteMatch } from "react-router-dom"

interface Props {
  canGoBack?: boolean
  title: string
  entityId: number
}

const InteractivityTable: React.FC<Props> = ({
  canGoBack = false,
  title,
  entityId,
}) => {
  const history = useHistory()
  const match = useRouteMatch()

  const { mutate: createPageInteraction, status: createStatus } =
    useCreateEntity<Partial<PageInteractionsEntity>>("page-interactions", {
      showNotification: true,
      onSuccess: result => {
        history.push(`${match.url}/${result.id}`)
      },
    })

  const columns: ColumnDefinition[] = [
    {
      header: "Id",
      render: (pageInteraction: PageInteractionsEntity) => {
        return <TableRowId entity={pageInteraction} />
      },
    },

    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Created At",
      render: (pageInteraction: PageInteractionsEntity) => {
        return (
          <Box>
            <Text fontSize="sm">
              {format(
                new Date(pageInteraction?.createdAt ?? ""),
                "yyyy/MM/dd HH:mm:ss"
              )}
            </Text>
          </Box>
        )
      },
    },
    {
      header: "Updated At",
      render: (pageInteraction: PageInteractionsEntity) => {
        return (
          <Box>
            <Text fontSize="sm">
              {format(
                new Date(pageInteraction?.updatedAt ?? ""),
                "yyyy/MM/dd HH:mm:ss"
              )}
            </Text>
          </Box>
        )
      },
    },
    {
      header: "Actions",
      render: (pageInteraction: PageInteractionsEntity) => {
        return (
          <TableRowActions
            resource="page-interactions"
            entity={pageInteraction}
            canView={true}
            canEdit={false}
            canDelete={true}
          />
        )
      },
    },
  ]

  const isCreating = createStatus === "loading"

  const handleCreateFloatCta = () => {
    createPageInteraction({
      pageId: Number(entityId),
      name: "Get Started",
      htmlBody: "",
      activationBlocks: [],
    })
  }

  return (
    <Body
      canGoBack={canGoBack}
      fullWidth={true}
      padding={0}
      title={title}
      headerActions={
        <Button
          colorScheme="primary"
          leftIcon={<Icon as={FiPlusCircle} />}
          onClick={handleCreateFloatCta}
          isLoading={isCreating}
        >
          Create Floating CTA
        </Button>
      }
    >
      <DataTable
        columns={columns}
        resource="page-interactions"
        query={{ pageId: entityId }}
        limit={5}
      />
    </Body>
  )
}

export default InteractivityTable
