import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Text,
} from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import React from "react"
import { FiChevronDown } from "react-icons/fi"
import AppHeaderFavicon from "screens/apps/AppHeaderFavicon"
import AutoCompleteInput, {
  AutoCompleteRenderInput,
  AutoCompleteRenderItem,
} from "./AutoCompleteInput"

const renderEmpty = () => {
  return (
    <Flex justifyContent="center" alignItems="center" w="full" py={4}>
      <Text color="gray.400">App not found</Text>
    </Flex>
  )
}

const renderInput: AutoCompleteRenderInput<AppEntity> = ({
  isDirty,
  search,
  setSearchValue,
  selected,
  toggle,
}) => {
  const shouldShowSelected = !isDirty && selected
  const value = shouldShowSelected ? selected.name : search

  return (
    <Flex
      h={10}
      w={80}
      pl={2}
      bg="white"
      direction="row"
      borderRadius="md"
      alignItems="center"
    >
      <AppHeaderFavicon id={selected?.faviconImageId ?? ""} />
      <Input
        variant="unstyled"
        ml={2}
        placeholder="Search"
        borderWidth={0}
        fontWeight="semibold"
        value={value}
        onChange={e => setSearchValue(e.target.value)}
      />
      <IconButton
        icon={<Icon as={FiChevronDown} />}
        aria-label="open"
        variant="unstyled"
        _focus={{ shadow: "none" }}
        onClick={toggle}
      />
    </Flex>
  )
}

const renderItem: AutoCompleteRenderItem<AppEntity> = (app, isSelected) => {
  return (
    <HStack
      as={Button}
      w="full"
      px={0}
      colorScheme="primary"
      color="gray.600"
      variant="ghost"
      align="center"
      justify="flex-start"
      bg={isSelected ? "primary.50" : "transparent"}
      borderRadius="md"
    >
      <AppHeaderFavicon id={app.faviconImageId} />
      <Flex
        direction="column"
        align="flex-start"
        overflowX="hidden"
        whiteSpace="nowrap"
        fontWeight="normal"
        ml={0}
      >
        <Box w={56} textAlign="left">
          <Text fontSize="sm" overflow="hidden" textOverflow="ellipsis">
            {app.name}
          </Text>
        </Box>
        <Box w={56} textAlign="left">
          <Text
            fontSize="xs"
            color="gray.500"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {app.baseUrl}
          </Text>
        </Box>
      </Flex>
    </HStack>
  )
}

interface Props {
  name: string
  apps: AppEntity[]
}

const AppSelectInput: React.FC<Props> = ({ apps, name }) => {
  return (
    <AutoCompleteInput<AppEntity>
      name={name}
      data={apps}
      filterFunction={(app, search) =>
        app.name.toLowerCase().includes(search.toLowerCase())
      }
      keyExtractor={app => app.id}
      valueExtractor={app => app.id}
      renderInput={renderInput}
      renderItem={renderItem}
      renderEmpty={renderEmpty}
    />
  )
}

export default AppSelectInput
