import React from 'react'

interface Props {
  condition: string | number | boolean
  wrapper: any
  children: any
}

const ConditionnalWrapper: React.FC<Props> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children)

export default ConditionnalWrapper
