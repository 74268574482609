import React, { useContext } from "react"
import { Flex, Spacer } from "@chakra-ui/react"
import CpButtons from "./CpButtons"
import CpFileUpload from "./CpFileUpload"
import { CpContext, CpContextInterface } from "./CpContext"

interface Props {}

const CpHeader: React.FC<Props> = () => {
  const { modalState } = useContext<CpContextInterface>(CpContext)
  return (
    <Flex
      flexDirection="row"
      backgroundColor="codePreviewHeader"
      px={3}
      py={modalState ? 4 : 0}
      roundedTop={modalState ? "none" : "sm"}
    >
      {modalState && <CpFileUpload />}
      <Spacer />
      <CpButtons />
    </Flex>
  )
}

export default CpHeader
