import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Spinner,
  Switch,
} from "@chakra-ui/react"
import { SettingsEntity, NavigationEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import { useEntityList } from "hooks/useEntityList"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"

type FormData = Pick<
  SettingsEntity,
  | "isSitemapEnabled"
  | "sitemapNavigationId"
  | "isSitemapHideFooter"
  | "sitemapLinkLabel"
>

interface Props {
  entity: SettingsEntity
  onSubmit: (data: FormData) => void
  isLoading: boolean
}

const AppSettingsSitemapForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const {
    isSitemapEnabled,
    sitemapNavigationId,
    isSitemapHideFooter,
    sitemapLinkLabel,
  } = entity

  const { handleSubmit, register, errors, setValue } = useForm<FormData>({
    defaultValues: {
      isSitemapEnabled,
      sitemapNavigationId,
      isSitemapHideFooter,
      sitemapLinkLabel,
    },
  })

  const {
    data: { data: navigations },
    isLoading: isLoadingNavigations,
  } = useEntityList<NavigationEntity>("navigations", {
    query: {
      appId: entity.appId,
    },
  })

  useEffect(() => {
    if (!isLoadingNavigations) {
      setValue("sitemapNavigationId", sitemapNavigationId)
    }
  }, [isLoadingNavigations, setValue, sitemapNavigationId])

  const enhancedSubmit = (data: FormData) => {
    if (data.sitemapNavigationId === "") {
      data.sitemapNavigationId = null
    }
    onSubmit(data)
  }

  return (
    <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
      <FormSectionHeader>Sitemap generation</FormSectionHeader>

      <FormControl
        isInvalid={!!errors.isSitemapEnabled}
        isDisabled={isLoading}
        display="flex"
        alignItems="center"
      >
        <FormLabel htmlFor="isSitemapEnabled" mb="0">
          Enable sitemap?
        </FormLabel>
        <Switch
          id="isSitemapEnabled"
          ref={register}
          name="isSitemapEnabled"
          defaultChecked
        />
        {!!errors.isSitemapEnabled && (
          <FormErrorMessage>{errors.isSitemapEnabled.message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl
        isInvalid={!!errors.sitemapNavigationId}
        isDisabled={isLoading}
      >
        <FormLabel>Navigation</FormLabel>

        {!isLoadingNavigations ? (
          <Select
            ref={register}
            name="sitemapNavigationId"
            placeholder="Select a navigation"
          >
            {navigations.map(navigation => {
              const { id, name } = navigation

              return (
                <option key={id} value={id}>
                  {name}
                </option>
              )
            })}
          </Select>
        ) : (
          <Spinner size="md" />
        )}
        {!!errors.sitemapNavigationId && (
          <FormErrorMessage>
            {errors.sitemapNavigationId.message}
          </FormErrorMessage>
        )}
      </FormControl>

      <FormSectionHeader>Sitemap visibility</FormSectionHeader>

      <FormControl
        isInvalid={!!errors.isSitemapHideFooter}
        isDisabled={isLoading}
        display="flex"
        alignItems="center"
      >
        <FormLabel htmlFor="isSitemapHideFooter" mb="0">
          Hide sitemap link on footer?
        </FormLabel>
        <Switch
          id="isSitemapHideFooter"
          ref={register}
          name="isSitemapHideFooter"
        />
        {!!errors.isSitemapHideFooter && (
          <FormErrorMessage>
            {errors.isSitemapHideFooter.message}
          </FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={!!errors.sitemapLinkLabel} isDisabled={isLoading}>
        <FormLabel>Sitemap link - Label</FormLabel>
        <Input
          type="text"
          placeholder="Label"
          ref={register}
          name="sitemapLinkLabel"
        />
        <FormHelperText>Defaults to `All Products`</FormHelperText>
        {!!errors.sitemapLinkLabel && (
          <FormErrorMessage>{errors.sitemapLinkLabel.message}</FormErrorMessage>
        )}
      </FormControl>

      <FormActions
        canCancel={false}
        isLoading={isLoading || isLoadingNavigations}
        entity={entity}
      />
    </FormContainer>
  )
}

export default AppSettingsSitemapForm
