import { Box, Flex } from "@chakra-ui/layout"
import {
  ChakraProvider,
  extendTheme,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import { webappThemeConfig } from "@jackfruit/common"
import { replaceShortCodes } from "components/CodePreview/replaceShortCodes"
import React from "react"
import { FiEye } from "react-icons/fi"

const theme = extendTheme(webappThemeConfig)

interface Props {
  value: string
}

const ChangelogFieldHTML: React.FC<Props> = ({ value }) => {
  const {
    isOpen: isPreviewOpen,
    onOpen: openPreview,
    onClose: closePreview,
  } = useDisclosure()

  const result = value ? replaceShortCodes(value) : ""

  return (
    <>
      <IconButton
        variant="ghost"
        rounded="full"
        icon={<Icon as={FiEye} />}
        aria-label="show"
        onClick={openPreview}
      />
      <Modal onClose={closePreview} size="full" isOpen={isPreviewOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody backgroundColor="gray.100" display="flex">
            <Flex flex={1} flexDirection="column" justifyContent="center">
              <Box
                id="htmlPreview"
                width="full"
                fontFamily="body"
                backgroundColor="white"
              >
                <ChakraProvider
                  theme={theme}
                  resetCSS={true}
                  cssVarsRoot="#htmlPreview"
                >
                  <Box
                    width="full"
                    dangerouslySetInnerHTML={{ __html: result }}
                  ></Box>
                </ChakraProvider>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ChangelogFieldHTML
