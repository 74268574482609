import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Switch,
} from "@chakra-ui/react"
import { PageEntity, SettingsEntity } from "@jackfruit/common"
import PageSelectInput from "components/AutocompleteDropDown/PageSelectInput"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import { useEntityList } from "hooks/useEntityList"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useParams } from "react-router"

type FormData = Pick<
  SettingsEntity,
  "isUserAccountEnabled" | "isReorderEnabled" | "helpPageLabel" | "helpPageId"
>

interface Props {
  entity: SettingsEntity
  onSubmit: (data: FormData) => void
  isLoading: boolean
}

const AppSettingsUserAccountsForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const { appId } = useParams<RouteParams>()
  const { isUserAccountEnabled, isReorderEnabled, helpPageLabel, helpPageId } =
    entity

  const methods = useForm<FormData>({
    defaultValues: {
      isUserAccountEnabled,
      isReorderEnabled,
      helpPageLabel,
      helpPageId,
    },
  })
  const { handleSubmit, register, errors } = methods

  const {
    data: { data: pages },
    isLoading: isLoadingPages,
  } = useEntityList<PageEntity>("pages", {
    query: {
      appId,
    },
  })

  const enhancedSubmit = (data: FormData) => {
    if (data.helpPageId === "") {
      data.helpPageId = null
    }
    onSubmit(data)
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
        <FormSectionHeader>User Accounts</FormSectionHeader>

        <FormControl
          isInvalid={!!errors.isUserAccountEnabled}
          display="flex"
          alignItems="center"
        >
          <FormLabel htmlFor="isUserAccountEnabled" mb="0">
            Enable User Accounts?
          </FormLabel>
          <Switch
            id="isUserAccountEnabled"
            ref={register}
            name="isUserAccountEnabled"
          />
          {!!errors.isUserAccountEnabled && (
            <FormErrorMessage>
              {errors.isUserAccountEnabled.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          isInvalid={!!errors.isReorderEnabled}
          display="flex"
          alignItems="center"
        >
          <FormLabel htmlFor="isReorderEnabled" mb="0">
            Enable Reorder Button?
          </FormLabel>
          <Switch
            id="isReorderEnabled"
            ref={register}
            name="isReorderEnabled"
          />
          {!!errors.isReorderEnabled && (
            <FormErrorMessage>
              {errors.isReorderEnabled.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors.helpPageLabel}>
          <FormLabel>Need Help - Link Label</FormLabel>
          <Input
            type="text"
            placeholder="Need Help - Label"
            ref={register}
            name="helpPageLabel"
          />
          <FormHelperText>Optional, default is `Need Help`.</FormHelperText>
          {!!errors.helpPageLabel && (
            <FormErrorMessage>{errors.helpPageLabel.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors.helpPageId}>
          <FormLabel>Need Help - Page</FormLabel>
          <PageSelectInput name="helpPageId" pages={pages} />
          <FormHelperText>
            Link will only appear if a page is selected.
          </FormHelperText>
          {!!errors.helpPageId && (
            <FormErrorMessage>{errors.helpPageId.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormActions
          canCancel={false}
          isLoading={isLoadingPages || isLoading}
          entity={entity}
        />
      </FormContainer>
    </FormProvider>
  )
}

export default AppSettingsUserAccountsForm
