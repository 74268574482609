import { Skeleton } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import AssetUploader from 'components/AssetUploader'
import { useResource } from 'hooks/useResource'
import { AssetEntity } from '@jackfruit/common'
interface Props {
  onChange: (data: any) => void
  value: any
  readOnly?: boolean
}

const AssetInput: React.FC<Props> = ({ onChange, value, readOnly = false }) => {
  const { entity, isLoading } = useResource<AssetEntity>('assets', value)

  const onComplete = useCallback(
    (asset: AssetEntity) => {
      onChange(asset.id)
    },

    [onChange]
  )
  if (isLoading) {
    return <Skeleton height="100px" width="100px" />
  }
  return (
    <AssetUploader asset={entity} onComplete={onComplete} readOnly={readOnly} />
  )
}

export default AssetInput
