import { Stack } from "@chakra-ui/react"
import React from "react"
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom"
import AppNavigationLinkCRUDScreen from "../AppNavigationLinkCRUDScreen"
import AppNavigationLinkForm from "../forms/AppNavigationLinkForm"
import AppNavigationLinkNav from "./AppNavigationLinkNav"

interface Props {}

const AppNavigationLinkNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <Stack direction="column" alignItems="start" spacing={6}>
      <AppNavigationLinkNav />
      <Switch>
        <Redirect
          exact
          from={`${match.url}/:navigationLinkId`}
          to={`${match.url}/:navigationLinkId/edit`}
        />
        <Route path={`${match.path}/new`}>
          <AppNavigationLinkCRUDScreen form={AppNavigationLinkForm} />
        </Route>
        <Route path={`${match.path}/:navigationLinkId/edit`}>
          <AppNavigationLinkCRUDScreen form={AppNavigationLinkForm} />
        </Route>
      </Switch>
    </Stack>
  )
}

export default AppNavigationLinkNavigation
