import { Button, Icon } from "@chakra-ui/react"
import Body from "components/Body"
import Page from "components/Page"
import { useEntity } from "hooks/useEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import { RouteParams } from "navigation/RouteParams"
import React, { ReactNode } from "react"
import { FiX } from "react-icons/fi"
import { Link, useParams } from "react-router-dom"
import { Resources } from "services/api"

interface Props {
  pagetitle: string
  resource: Resources
  routeParam: keyof RouteParams
  render: ({
    onSubmit,
    isLoading,
    entity,
  }: {
    onSubmit: (data: any) => void
    isLoading: boolean
    entity: any
  }) => ReactNode
}

const Actions = ({ resource }: { resource: Resources }) => {
  return (
    <Link to={`/admin/${resource}`}>
      <Button colorScheme="primary" leftIcon={<Icon as={FiX} />}>
        Cancel
      </Button>
    </Link>
  )
}

const EditFormScreen: React.FC<Props> = ({
  pagetitle,
  resource,
  routeParam,
  render,
}) => {
  const params = useParams<RouteParams>()
  const id = parseInt(params[routeParam])

  const { mutate: save, status } = usePatchEntity(resource)
  const { isLoading: isFetching, data: entity } = useEntity(resource, id)

  const onEnhancedSubmit = (data: any) => {
    data.id = id
    save(data)
  }

  const isLoading = isFetching || status === "loading"

  return (
    <Page title={pagetitle} headerActions={<Actions resource={resource} />}>
      <Body isLoading={isLoading}>
        {render({
          onSubmit: onEnhancedSubmit,
          isLoading,
          entity,
        })}
      </Body>
    </Page>
  )
}

export default EditFormScreen
