import {
  AppEntityHydrated,
  ClientEntity,
  PrinticularOrder,
} from "@jackfruit/common"

import { parser as getStartedParser } from "./shortCodeParsers/getStarted"
import { parser as badgeParser } from "./shortCodeParsers/badge"
import { parser as carouselParser } from "./shortCodeParsers/carousel"
import { parser as accordionParser } from "./shortCodeParsers/accordion"

export type ShortCode = "accordion" | "badges" | "carousel" | "getStarted"

export const allShortCodes: ShortCode[] = ["accordion","badges", "getStarted", "carousel"]



export interface ShortCodeStateEntities {
  order?: PrinticularOrder
  client?: ClientEntity
  appConfig?: AppEntityHydrated
}

export type ShortCodeParser = (html: string) => string

interface AllShortCodeParsers {
  [key: string]: ShortCodeParser
}

const shortCodeParsers: AllShortCodeParsers = {
  accordion: accordionParser,
  badges: badgeParser,
  getStarted: getStartedParser,
  carousel: carouselParser,
}

export const replaceShortCodes = (textWithShortCode: string = "") => {
  const parsed = allShortCodes.reduce((someText, code) => {
    const parser = shortCodeParsers[code]

    const newText = someText ? parser(someText) : someText

    return newText
  }, textWithShortCode)

  return parsed
}
