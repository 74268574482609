import React from "react"
import Body from "components/Body"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import { useParams } from "react-router-dom"
import BlockContent from "screens/apps/pages/blocks/BlockContent"
import FormSectionHeader from "components/FormSectionHeader"
import { Box } from "@chakra-ui/react"
import { RouteParams } from "navigation/RouteParams"
import { BlockEntity } from "@jackfruit/common"

interface Props {
  type: string
}

const AppCommonBlockScreen: React.FC<Props> = ({ type }) => {
  const { appId } = useParams<RouteParams>()
  const { data, isLoading } = useFindOneEntity<BlockEntity>("blocks", {
    query: {
      type,
      appId,
      pageId: { $null: true },
    },
  })

  return (
    <Body isLoading={isLoading}>
      <Box marginBottom={3}>
        <FormSectionHeader>
          {type === "fulfillment" ? "General" : `Global ${type} block`}
        </FormSectionHeader>
      </Box>

      <BlockContent block={data!} />
    </Body>
  )
}

export default AppCommonBlockScreen
