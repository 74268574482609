import {
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
  } from "@chakra-ui/react"
  import { BlockVideoEntity } from "@jackfruit/common"
  import FormContainer from "components/FormContainer"
  import FormActions from "forms/FormActions"
  import React from "react"
  import { useForm } from "react-hook-form"
  
  interface Props {
    entity: BlockVideoEntity
    isLoading: boolean
    onSubmit: (data: BlockVideoEntity) => void
  }
  
  const BlockVideoForm: React.FC<Props> = ({ entity, onSubmit, isLoading }) => {
    const defaultValues = {
      ...entity,
    }
  
    const { handleSubmit, register } = useForm<BlockVideoEntity>({
      defaultValues,
    })
  
    return (
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <FormLabel>YouTube URL</FormLabel>
          <InputGroup>
            <Input type="text" name="youtubeUrl" ref={register} />
          </InputGroup>
          <FormHelperText fontStyle="italic">
            The block supports YouTube URLs only.
          </FormHelperText>
        </FormControl>
        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    )
  }
  
  export default BlockVideoForm
  