import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import { SettingsEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"

interface Props {
  entity?: SettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppSettingsServicesGoogleForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Google</FormSectionHeader>
      <FormControl>
        <FormLabel>Google Tag Manager Project ID (GA4)</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="gaProjectId"
            type="text"
            isReadOnly
            bg="gray.100"
          />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Google Tag Manager Measurement ID (GA4)</FormLabel>
        <InputGroup>
          <Input ref={register} name="gaMeasurementId" type="text" />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Facebook Measurement ID (GA4)</FormLabel>
        <InputGroup>
          <Input ref={register} name="facebookMeasurementId" type="text" />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Microsoft Measurement ID (GA4)</FormLabel>
        <InputGroup>
          <Input ref={register} name="microsoftMeasurementId" type="text" />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Pinterest Measurement ID (GA4)</FormLabel>
        <InputGroup>
          <Input ref={register} name="pinterestMeasurementId" type="text" />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Tiktok Measurement ID (GA4)</FormLabel>
        <InputGroup>
          <Input ref={register} name="tiktokMeasurementId" type="text" />
        </InputGroup>
      </FormControl>

      <FormControl isInvalid={errors.recaptchaSiteKey}>
        <FormLabel>Google Recaptcha Site Key</FormLabel>
        <InputGroup>
          <Input ref={register} name="recaptchaSiteKey" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.recaptchaSiteKey && errors.recaptchaSiteKey.message}
        </FormErrorMessage>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsServicesGoogleForm
