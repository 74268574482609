import { Box } from '@chakra-ui/react'
import { BlockCartEntity, BlockEntity } from '@jackfruit/common'
import { useEntity } from 'hooks/useEntity'
import { usePatchEntity } from 'hooks/usePatchEntity'
import React from 'react'
import BlockCartForm from '../forms/BlockCartForm'

interface Props {
  block: BlockEntity
  readOnly?: boolean
}

const BlockCart: React.FC<Props> = ({ block, readOnly = false }) => {
  const { mutate: save } = usePatchEntity<BlockCartEntity>('blocks-cart')
  const { data: entity, isLoading } = useEntity<BlockCartEntity>(
    'blocks-cart',
    block.childId
  )

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  const onSubmit = (data: any) => {
    save({ id: entity.id, ...data })
  }

  return (
    <BlockCartForm
      entity={entity}
      isLoading={false}
      readOnly={readOnly}
      onSubmit={onSubmit}
    />
  )
}

export default BlockCart
