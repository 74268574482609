import { BlockEntity } from "@jackfruit/common"
import { useEntityList } from "./useEntityList"

interface Options {
  appId: number | string
  pageId: number | string
}

export const useBlocksEntities = ({ appId, pageId }: Options) => {
  const params = {
    $sort: {
      order: 1,
    },
    $limit: 1000,
  }

  const { data: referers, isLoading: isLoadingReferers } =
    useEntityList<BlockEntity>("blocks", {
      query: {
        ...params,
        appId,
      },
    })

  const { data: blocks, isLoading: isLoadingBlocks } =
    useEntityList<BlockEntity>(
      "blocks",
      {
        query: {
          ...params,
          $limit: 1000,
          pageId,
        },
      },
      {
        enabled: !!referers,
      }
    )

  return {
    referers,
    blocks,
    isLoading: isLoadingReferers || isLoadingBlocks,
  }
}
