import { Box, Flex } from "@chakra-ui/react"
import MainSidebar from "components/MainSidebar/MainSidebar"
import React from "react"
import { useLocation } from "react-router-dom"

const pathWithSubContext = [
  "/admin/settings",
  "/admin/apps/",
  "/admin/snippets",
]

export const MAIN_SIDEBAR_EXPANDED_WIDTH = 230
export const MAIN_SIDEBAR_SHRINKED_WIDTH = 80
export const HEADER_HEIGHT = 80

interface Props {
  children: React.ReactNode
}

const LayoutAdmin: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation()
  const sideBarExpanded = Boolean(
    !pathWithSubContext.find(path => pathname.includes(path))
  )

  return (
    <Flex height="100vh" overflowY="hidden">
      <Box
        width={`${
          sideBarExpanded
            ? MAIN_SIDEBAR_EXPANDED_WIDTH
            : MAIN_SIDEBAR_SHRINKED_WIDTH
        }px`}
        transition="all 0.1s"
      >
        <MainSidebar expanded={sideBarExpanded} />
      </Box>
      <Flex transition="all 0.1s" flex={1}>
        {children}
      </Flex>
    </Flex>
  )
}

export default LayoutAdmin
