import { Flex, VStack } from "@chakra-ui/react"
import React from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import AppProductSetupNavigation from "screens/apps/settings/product-setup/navigation/AppProductSetupNavigation"
import AppSettingsCRUDScreen from "../AppSettingsCRUDScreen"
import AppSettingsDeploymentForm from "../forms/AppSettingsDeploymentForm"
import AppSettingsDevelopmentCustomCSSAndJavascriptForm from "../forms/AppSettingsDevelopmentCustomCSSAndJavascriptForm"
import AppSettingsDevelopmentDebugForm from "../forms/AppSettingsDevelopmentDebugForm"
import AppSettingsExperimentsForm from "../forms/AppSettingsExperimentsForm"
import AppSettingsLambdaAbTestForm from "../forms/AppSettingsLambdaAbTestForm"
import AppSettingsMiscForm from "../forms/AppSettingsMiscForm"
import AppSettingsServicesAutopilotPrintForm from "../forms/AppSettingsServicesAutopilotPrintForm"
import AppSettingsServicesGoogleForm from "../forms/AppSettingsServicesGoogleForm"
import AppSettingsServicesMiscellaneousForm from "../forms/AppSettingsServicesMiscellaneousForm"
import AppSettingsServicesSentryForm from "../forms/AppSettingsServicesSentryForm"
import AppSettingsSitemapForm from "../forms/AppSettingsSitemapForm"
import AppSettingsStorePagesForm from "../forms/AppSettingsStorePagesForm"
import AppSettingsUserAccountsForm from "../forms/AppSettingsUserAccountsForm"

interface Props {}

const AppSettingsNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <Flex direction="row" alignItems="start">
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/deployment`} />
        <Route path={`${match.path}/deployment`}>
          <AppSettingsCRUDScreen form={AppSettingsDeploymentForm} />
        </Route>
        <Route path={`${match.path}/products-setup`}>
          <AppProductSetupNavigation />
        </Route>
        <Route path={`${match.path}/development`}>
          <VStack>
            <AppSettingsCRUDScreen form={AppSettingsDevelopmentDebugForm} />
            <AppSettingsCRUDScreen
              form={AppSettingsDevelopmentCustomCSSAndJavascriptForm}
            />
          </VStack>
        </Route>
        <Route path={`${match.path}/services`}>
          <VStack>
            <AppSettingsCRUDScreen
              form={AppSettingsServicesAutopilotPrintForm}
            />
            <AppSettingsCRUDScreen form={AppSettingsServicesGoogleForm} />
            <AppSettingsCRUDScreen form={AppSettingsServicesSentryForm} />
            <AppSettingsCRUDScreen
              form={AppSettingsServicesMiscellaneousForm}
            />
          </VStack>
        </Route>
        <Route path={`${match.path}/misc`}>
          <AppSettingsCRUDScreen form={AppSettingsMiscForm} />
        </Route>
        <Route path={`${match.path}/accounts`}>
          <AppSettingsCRUDScreen form={AppSettingsUserAccountsForm} />
        </Route>
        <Route path={`${match.path}/store-pages`}>
          <AppSettingsCRUDScreen form={AppSettingsStorePagesForm} />
        </Route>
        <Route path={`${match.path}/experiments`}>
          <AppSettingsCRUDScreen form={AppSettingsExperimentsForm} />
        </Route>
        <Route path={`${match.path}/lambda-ab-tests`}>
          <AppSettingsCRUDScreen form={AppSettingsLambdaAbTestForm} />
        </Route>
        <Route path={`${match.path}/sitemap`}>
          <AppSettingsCRUDScreen form={AppSettingsSitemapForm} />
        </Route>
      </Switch>
    </Flex>
  )
}

export default AppSettingsNavigation
