import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import AppPageInteractivityEdit from "../AppPageInteractivityEdit"
import AppPageInteractivityListScreen from "../AppPageInteractivityListScreen"

interface Props {}

const AppPageInteractivityNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}`} exact={true}>
        <AppPageInteractivityListScreen />
      </Route>
      <Route path={`${match.path}/:pageInteractionId`}>
        <AppPageInteractivityEdit />
      </Route>
    </Switch>
  )
}

export default AppPageInteractivityNavigation
