import { Text } from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import Body from "components/Body"
import Label from "components/Label"
import Page from "components/Page"
import { useResource } from "hooks/useResource"
import { RouteParams } from "navigation/RouteParams"
import { UserEntity } from "@jackfruit/common"

interface Props {}

const UserDetailsScreen: React.FC<Props> = () => {
  const { userId } = useParams<RouteParams>()
  const { entity, isLoading } = useResource<UserEntity>(
    "users",
    parseInt(userId)
  )

  if (isLoading) {
    return null
  }

  return (
    <Page title={`User #${userId}`}>
      <Body>
        <Label>Email</Label>
        <Text>{entity.email}</Text>
        <Label>Role</Label>
        <Text>{entity.role}</Text>
      </Body>
    </Page>
  )
}

export default UserDetailsScreen
