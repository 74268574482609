import React from "react"
import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
} from "@chakra-ui/react"

interface accodionItemsProps {
  title: string
  content: string
}
interface Props extends AccordionProps {
  accodionItems: accodionItemsProps[]
  expandedStyle?: React.CSSProperties
}

const Accordion: React.FC<Props> = props => {
  const {
    width,
    allowMultiple,
    allowToggle,
    accodionItems,
    expandedStyle,
    defaultIndex,
  } = props

  return (
    <Box w={width}>
      <ChakraAccordion
        defaultIndex={defaultIndex}
        allowMultiple={allowMultiple}
        allowToggle={allowToggle}
        className="s__accordion"
      >
        {accodionItems.map((item, index) => {
          return (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton
                  _expanded={expandedStyle}
                  className="s__accordion-button"
                >
                  <Box as="span" flex="1" className="s__accordion-title">
                    {item.title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="s__accordion-panel">
                <Box
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                />
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </ChakraAccordion>
    </Box>
  )
}

export default Accordion
