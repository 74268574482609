import { Flex, HStack, IconButton, Text, useDisclosure } from "@chakra-ui/react"
import { NavigationMegaMenuColumnEntityHydrated } from "@jackfruit/common"
import ConfirmDialog from "components/ConfirmDialog"
import { useDeleteEntity } from "hooks/useDeleteEntity"
import React, { useRef } from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import { FiEdit, FiTrash2 } from "react-icons/fi"
import { useQueryClient } from "react-query"
import MegaMenuAddCellButton from "./MegaMenuAddCellButton"
import MegaMenuCell from "./MegaMenuCell"
import MegaMenuColumnEditModal from "./MegaMenuColumnEditModal"

interface Props {
  column: NavigationMegaMenuColumnEntityHydrated
  index: number
}

const MegaMenuColumn: React.FC<Props> = ({ column, index }) => {
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure()

  const { mutateAsync: deleteCell } = useDeleteEntity(
    "navigation-megamenu-columns"
  )

  const cancelRef = useRef<HTMLButtonElement>(null)

  const handleDelete = async () => {
    await deleteCell(column.id)
    queryClient.invalidateQueries(["navigation-megamenus"])

    onCloseDeleteModal()
  }

  return (
    <>
      <Draggable draggableId={`drag-column#${column.id}`} index={index}>
        {(provided, snapshot) => {
          const { isDragging } = snapshot

          return (
            <Flex
              ref={provided.innerRef}
              {...provided.draggableProps}
              direction="column"
              borderWidth={1}
              borderRadius="md"
              minW="250px"
              mr={3}
              backgroundColor="white"
              boxShadow={isDragging ? "md" : "none"}
            >
              <Flex
                bg="gray.100"
                borderTopRadius="md"
                px={3}
                py={1}
                alignItems="center"
                justifyContent="space-between"
                {...provided.dragHandleProps}
              >
                <Text>{column.title}</Text>
                <HStack spacing={1}>
                  <IconButton
                    icon={<FiEdit />}
                    aria-label="edit column"
                    size="xs"
                    variant="ghost"
                    rounded="full"
                    onClick={onOpen}
                  />
                  <IconButton
                    icon={<FiTrash2 />}
                    aria-label="edit"
                    size="xs"
                    rounded="full"
                    variant="ghost"
                    colorScheme="red"
                    onClick={onOpenDeleteModal}
                  />
                </HStack>
              </Flex>
              <Droppable droppableId={`drop-column#${column.id}`} type="cell">
                {providedCell => {
                  return (
                    <Flex
                      {...providedCell.droppableProps}
                      ref={providedCell.innerRef}
                      flex={1}
                      direction="column"
                      p={3}
                    >
                      {column.cells.map((cell, cellIndex) => {
                        return (
                          <MegaMenuCell
                            key={cell.id}
                            cell={cell}
                            index={cellIndex}
                          />
                        )
                      })}
                      {providedCell.placeholder}
                    </Flex>
                  )
                }}
              </Droppable>
              <Flex p={3}>
                <MegaMenuAddCellButton megaMenuColumnId={column.id} />
              </Flex>
            </Flex>
          )
        }}
      </Draggable>
      <MegaMenuColumnEditModal
        isOpen={isOpen}
        onClose={onClose}
        column={column}
      />
      <ConfirmDialog
        text={{
          title: "Are you sure you want to delete this column?",
          submit: "Delete",
        }}
        isOpen={isOpenDeleteModal}
        onCancel={onCloseDeleteModal}
        onConfirm={handleDelete}
        cancelRef={cancelRef}
      />
    </>
  )
}

export default MegaMenuColumn
