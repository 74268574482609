import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  Link as ChakraLink,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  VStack,
} from "@chakra-ui/react"
import { SettingsEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import TagInput from "forms/inputs/TagInput"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"

interface Props {
  entity?: SettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppSettingsServicesSentryForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Sentry</FormSectionHeader>

      <FormControl>
        <InputGroup>
          <Checkbox
            ref={register}
            name="enableSentryIntegration"
            defaultChecked={entity?.enableSentryIntegration}
          >
            Enable Sentry Reporting
          </Checkbox>
        </InputGroup>
        <FormHelperText>
          <Box>
            Please ensure that Sentry project and authentication details are
            setup under
          </Box>
          <ChakraLink as={Link} to={"/admin/integrations/sentry"}>
            builder settings
          </ChakraLink>
        </FormHelperText>
      </FormControl>

      <FormControl isInvalid={errors.sentryOptionEnv}>
        <FormLabel>Sentry Environment</FormLabel>
        <InputGroup>
          <Select ref={register()} name="sentryOptionEnv">
            <option value="production">Production</option>
            <option value="staging">Staging</option>
            <option value="development">Development</option>
            <option value="local">Local</option>
          </Select>
        </InputGroup>
        <FormErrorMessage>
          {errors.sentryOptionEnv && errors.sentryOptionEnv.message}
        </FormErrorMessage>
      </FormControl>

      <Divider />

      <Flex alignItems="flex-start">
        <Flex flex={1}>
          <FormControl>
            <FormLabel>Session Replay</FormLabel>
            <InputGroup>
              <Checkbox
                ref={register}
                name="sentrySessionReplayEnable"
                defaultChecked={false}
              >
                Enabled
              </Checkbox>
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex flex={2} gap={6}>
          <FormControl>
            <FormLabel>Sample Rate (%)</FormLabel>
            <InputGroup>
              <NumberInput defaultValue={0} min={0} max={100}>
                <NumberInputField
                  ref={register}
                  name="sentrySessionReplaySampleRate"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </InputGroup>
            <FormHelperText>Overall sample rate.</FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel>OnError Rate (%)</FormLabel>
            <InputGroup>
              <NumberInput defaultValue={0} min={0} max={100}>
                <NumberInputField
                  ref={register}
                  name="sentrySessionReplayOnErrorSampleRate"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </InputGroup>
            <FormHelperText>Error sample rate.</FormHelperText>
          </FormControl>
        </Flex>
      </Flex>

      <Divider />

      <Flex alignItems="flex-start">
        <Flex flex={1}>
          <FormControl>
            <FormLabel>Traces Sampling</FormLabel>
            <InputGroup>
              <Checkbox
                ref={register}
                name="sentryTracesSampleEnable"
                defaultChecked={false}
              >
                Enabled
              </Checkbox>
            </InputGroup>
          </FormControl>
        </Flex>

        <Flex flex={2}>
          <VStack flex={1} alignItems="flex-start">
            <Flex flex={1} w="full" gap={6}>
              <Flex flex={1}>
                <FormControl>
                  <FormLabel>Sample Rate (%)</FormLabel>
                  <InputGroup>
                    <NumberInput defaultValue={0} min={0} max={100}>
                      <NumberInputField
                        ref={register}
                        name="sentryTracesSampleRate"
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </InputGroup>
                  <FormHelperText>Overall sample rate.</FormHelperText>
                </FormControl>
              </Flex>
              <Flex flex={1} />
            </Flex>
            <FormControl w="full" maxW="425px">
              <FormLabel>Propagation Targets</FormLabel>
              <InputGroup>
                <Controller
                  name="sentryTracePropagationTargets"
                  control={control}
                  render={({ onChange, value }) => {
                    return (
                      <TagInput
                        onChange={onChange}
                        value={value}
                        removeSpace={true}
                      />
                    )
                  }}
                />
              </InputGroup>
              <FormHelperText maxW="400px">
                The URLs enabled for distributed tracing. RegEx is supported.
                The options "localhost" and Autopilot Server API are enabled
                without any additional configuration.
              </FormHelperText>
            </FormControl>
          </VStack>
        </Flex>
      </Flex>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsServicesSentryForm
