import { Box, Flex, Text, Icon } from "@chakra-ui/react"
import React from "react"
import { FiAlertCircle } from "react-icons/fi"

interface Props {
  title?: string
  error: string
}

const Error: React.FC<Props> = ({ title = "Server Error", error }) => {
  return (
    <Box p={3} backgroundColor="red.200" rounded="md" marginBottom={6}>
      <Flex alignItems="center" marginBottom={3}>
        <Icon as={FiAlertCircle} color="red.700" />
        <Text marginLeft={3} color="red.700" fontSize="lg">
          {title}
        </Text>
      </Flex>
      <Text color="red.700">{error}</Text>
    </Box>
  )
}

export default Error
