import { Stack } from "@chakra-ui/react"
import { GlobalDeploymentSettingsEntity } from "@jackfruit/common"
import Body from "components/Body"
import Page from "components/Page"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import BuildBackupsForm from "./forms/BuildBackupsForm"
import PostDeploymentNotificationEmailAddresses from "./forms/PostDeploymentNotificationEmailAddresses"
import PreviewSettingsForm from "./forms/PreviewSettingsForm"
import TestAutomationForm from "./forms/TestAutomationForm"

interface Props {}

const DeploymentsScreen: React.FC<Props> = () => {
  const { data: entity, isLoading } =
    useFindOneEntity<GlobalDeploymentSettingsEntity>(
      "global-deployment-settings",
      {
        query: {},
      }
    )

  const { mutate: patch, status: patchStatus } =
    usePatchEntity<GlobalDeploymentSettingsEntity>("global-deployment-settings")

  const isPatching = patchStatus === "loading"

  const onSubmit = (data: any) => {
    patch({ id: entity!.id, ...data })
  }

  const enhancedOnSubmit = async (data: any) => {
    onSubmit(data)
  }

  const formComponents = [
    PreviewSettingsForm,
    BuildBackupsForm,
    TestAutomationForm,
    PostDeploymentNotificationEmailAddresses,
  ].map(form => {
    return React.createElement(form, {
      entity: entity,
      isLoading: isPatching || isLoading,
      onSubmit: enhancedOnSubmit,
    })
  })

  return (
    <Page title="Global Deployment Settings">
      <Stack spacing={4}>
        {formComponents.map((form, index) => {
          return (
            <Body key={index} isLoading={isLoading}>
              {form}
            </Body>
          )
        })}
      </Stack>
    </Page>
  )
}

export default DeploymentsScreen
