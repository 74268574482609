import {
  Accordion,
  Box,
  Divider,
  Flex,
  Heading,
  Icon,
  VStack,
  Button,
  chakra,
  shouldForwardProp,
  useDisclosure,
} from "@chakra-ui/react"
import React, { JSXElementConstructor } from "react"
import FirstLevelButton from "./FirstLevelButton"
import SecondLevelButton from "./SecondLevelButton"
import { Scrollbars } from "react-custom-scrollbars"
import { HEADER_HEIGHT } from "layouts/LayoutAdmin"
import { useLocation } from "react-router-dom"
import { motion, isValidMotionProp } from "framer-motion"
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi"

const MotionBox = chakra(motion.div, {
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export interface SecondarySidebarNavigationItem {
  label: string
  to?: string
  icon?: JSXElementConstructor<any>
  children?: SecondarySidebarNavigationItem[]
  exactMatch?: boolean
}

interface Props {
  icon: React.JSXElementConstructor<any>
  title: string
  navigation: SecondarySidebarNavigationItem[]
}

const animations = {
  hidden: {
    width: 0,
  },
  show: {
    width: 350,
  },
}

const SecondarySidebar: React.FC<Props> = ({ icon, title, navigation }) => {
  const location = useLocation()

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })

  const navWithAccordion = ["branding", "layout", "settings"]

  const index = navWithAccordion.findIndex(nav =>
    location.pathname.includes(nav)
  )
  const defaultIndex = index === -1 ? 0 : index

  return (
    <MotionBox
      variants={animations}
      initial="show"
      animate={isOpen ? "show" : "hidden"}
      h="100vh"
      bg="white"
      position="relative"
    >
      {isOpen && (
        <>
          <Flex alignItems="center" height={`${HEADER_HEIGHT}px`} px={4}>
            <Icon as={icon} boxSize={8} strokeWidth={1.5} />
            <Heading fontSize="xl" ml={3}>
              {title}
            </Heading>
          </Flex>

          <Box px={4}>
            <Divider />
          </Box>

          <Scrollbars
            style={{ height: `calc(100vh - ${HEADER_HEIGHT}px)` }}
            autoHide
          >
            <VStack alignItems="stretch" py={4} px={4}>
              <Accordion
                experimental_spaceY={2}
                defaultIndex={defaultIndex}
                allowToggle
              >
                {navigation.map((firstLevel, i) => {
                  const { children, to, icon, label, exactMatch } = firstLevel
                  return (
                    <FirstLevelButton
                      key={i}
                      icon={icon!}
                      label={label}
                      to={to}
                      exactMatch={exactMatch}
                    >
                      {children?.map((secondLevel, j) => {
                        const { to, label, exactMatch } = secondLevel
                        return (
                          <SecondLevelButton
                            key={j}
                            label={label}
                            to={to!}
                            exactMatch={exactMatch}
                          />
                        )
                      })}
                    </FirstLevelButton>
                  )
                })}
              </Accordion>
            </VStack>
          </Scrollbars>
        </>
      )}
      <Button
        onClick={onToggle}
        width="36px"
        height="36px"
        borderWidth={4}
        borderColor="gray.50"
        borderRadius="full"
        position="absolute"
        right="-20px"
        bottom="28px"
        zIndex={10}
        backgroundColor="white"
        _focus={{ boxShadow: "none" }}
        _active={{ boxShadow: "none" }}
        _hover={{ backgroundColor: "white" }}
      >
        <Icon
          as={isOpen ? FiChevronsLeft : FiChevronsRight}
          boxSize={6}
          strokeWidth={1.8}
          color="gray.400"
        />
      </Button>
    </MotionBox>
  )
}

export default React.memo(SecondarySidebar)
