import {
  Checkbox,
  Flex,
  FormControl,
  Icon,
  Input,
  Spinner,
  Text,
  useBoolean,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react"
import { AppEntity, FulfillmentTypes } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormSectionSubHeader from "components/FormSectionSubHeader"
import "components/ReactDatePickerCustom.css"
import FormActions from "forms/FormActions"
import { useEntityList } from "hooks/useEntityList"
import { capitalize } from "lodash"
import { RouteParams } from "navigation/RouteParams"
import React, { useEffect, useMemo, useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { ImRadioChecked, ImRadioUnchecked } from "react-icons/im"
import { useParams } from "react-router"

import PageTerritoriesSelector from "./fields/PageTerritoriesSelector"

interface Props {
  entity?: any
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppPageForm: React.FC<Props> = ({ entity, onSubmit, isLoading }) => {
  const { pageId, appId } = useParams<RouteParams>()

  let defaultValues = {
    ...entity,
  }

  if (!pageId) {
    // Default values on creation
    defaultValues = {
      ...defaultValues,
      isPickupEnabled: true,
      defaultFulfillment: "pickup",
    }
  }

  const methods = useForm({ defaultValues })
  const { handleSubmit, register } = methods

  const { data: app, isLoading: isLoadingSettings } = useEntityList<AppEntity>(
    "apps",
    {
      query: {
        id: appId,
      },
    }
  )

  const [defaultCountryCode, setDefaultCountryCode] = useState<string>()
  useEffect(() => {
    if (!isLoadingSettings) {
      setDefaultCountryCode(app?.data?.[0]?.defaultLocale ?? "US")
    }
  }, [isLoadingSettings, defaultCountryCode, app])

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormSectionHeader>Fulfillment</FormSectionHeader>

        <VStack mt={0}>
          <FulfillmentSelection fulfillment="pickup" />
          <FulfillmentSelection fulfillment="delivery" />

          <Input
            // @ts-ignore
            {...register("defaultFulfillment")}
            type="hidden"
          />
        </VStack>

        {isLoadingSettings && <Spinner size="md" />}

        {!!defaultCountryCode && (
          <>
            <FormSectionSubHeader>Pickup Countries</FormSectionSubHeader>

            <PageTerritoriesSelector
              isPickup={true}
              defaultCodes={[defaultCountryCode]}
              name="pickupTerritories"
              defaultCountryName="defaultCountryPickup"
            />

            <FormSectionSubHeader>Delivery Countries</FormSectionSubHeader>
            <PageTerritoriesSelector
              isPickup={false}
              defaultCodes={[defaultCountryCode]}
              name="deliveryTerritories"
              defaultCountryName="defaultCountryDelivery"
            />
          </>
        )}

        <FormActions isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default AppPageForm

const FulfillmentSelection: React.FC<{ fulfillment: FulfillmentTypes }> = ({
  fulfillment,
}) => {
  const isEnabledKey = useMemo(
    () => `is${capitalize(fulfillment)}Enabled`,
    [fulfillment]
  )

  const { register, watch, setValue } = useFormContext()
  const defaultFulfillment = watch("defaultFulfillment")
  const isEnabled = watch(isEnabledKey)

  const [isChecked, setIsChecked] = useBoolean(isEnabled ?? false)
  const [isDefault, setIsDefault] = useState(
    defaultFulfillment === fulfillment ? true : false
  )

  useUpdateEffect(() => {
    const isDefaultEnabled = defaultFulfillment === fulfillment ? true : false
    setIsDefault(isDefaultEnabled)

    if (isDefaultEnabled) {
      setIsChecked.on()
      setValue(isEnabledKey, true)
    }
  }, [defaultFulfillment, setIsDefault, setValue])

  return (
    <FormControl>
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        fontWeight="normal"
        marginTop={0}
        mb={2}
      >
        <Text textTransform="capitalize">{fulfillment}</Text>

        <Flex alignItems="center">
          <Checkbox
            ref={register}
            name={isEnabledKey}
            isChecked={isChecked}
            isDisabled={isDefault}
            onChange={setIsChecked.toggle}
            defaultChecked={isChecked}
          >
            Enabled
          </Checkbox>

          <Flex
            alignItems="center"
            cursor="pointer"
            marginLeft={4}
            onClick={() => setValue("defaultFulfillment", fulfillment)}
          >
            {isDefault ? (
              <Icon as={ImRadioChecked} mr={2} color="primary.500" />
            ) : (
              <Icon as={ImRadioUnchecked} mr={2} />
            )}

            <Text>Default</Text>
          </Flex>
        </Flex>
      </Flex>
    </FormControl>
  )
}
