import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react"
import React from "react"

const defaultText = {
  title: "Are you sure?",
  content: `You can't undo this action afterwards.`,
  cancel: "Cancel",
  submit: "Submit",
}

interface TextDialog {
  title?: string
  content?: string
  cancel?: string
  submit?: string
}

interface ConfirmDialogProps {
  text?: TextDialog
  isOpen: boolean
  cancelRef: any
  onCancel: () => void
  onConfirm: () => void
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  text,
  isOpen,
  cancelRef,
  onConfirm,
  onCancel,
}) => {
  const textDialog = { ...defaultText, ...text }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {textDialog.title}
          </AlertDialogHeader>
          <AlertDialogBody>{textDialog.content}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCancel}>
              {textDialog.cancel}
            </Button>
            <Button colorScheme="primary" onClick={onConfirm} ml={3}>
              {textDialog.submit}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default React.memo(ConfirmDialog)
