import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  Select,
} from "@chakra-ui/react"
import { apiUrls, AppEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import { EntityFormProps } from "forms/EntityFormProps"
import FormActions from "forms/FormActions"
import AssetInput from "forms/inputs/AssetInput"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import slugify from "slugify"

interface Props extends EntityFormProps<AppEntity> {}

const AppBrandingGeneralForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }
  const { handleSubmit, register, errors, control, watch, setValue } =
    useForm<AppEntity>({
      defaultValues,
    })
  const enhancedSubmit = (data: AppEntity) => {
    const { baseUrl } = data
    const lowerCaseBaseUrl = baseUrl.toLowerCase()
    const sanitized = {
      ...data,
      baseUrl: lowerCaseBaseUrl,
    }
    onSubmit(sanitized)

    setValue("baseUrl", lowerCaseBaseUrl)
  }

  return (
    <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
      <FormSectionHeader>General</FormSectionHeader>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel>Application Name</FormLabel>
        <InputGroup>
          <Input
            ref={register({
              required: "Application Name must be at least 2 characters",
              minLength: {
                value: 2,
                message: "Application Name must be at least 2 characters",
              },
              maxLength: {
                value: 255,
                message: "Application Name cannot be more than 255 characters",
              },
            })}
            name="name"
            type="text"
            placeholder="Application Name"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.displayName}>
        <FormLabel>Application Display Name</FormLabel>
        <InputGroup>
          <Input
            ref={register({
              required:
                "Application Display Name must be at least 2 characters",
              minLength: {
                value: 2,
                message:
                  "Application Display Name must be at least 2 characters",
              },
              maxLength: {
                value: 255,
                message:
                  "Application Display Name cannot be more than 255 characters",
              },
            })}
            name="displayName"
            type="text"
            placeholder="Application Display Name"
          />
        </InputGroup>
        <FormHelperText>
          This is the title we will display to the front-end user.
        </FormHelperText>
        <FormErrorMessage>
          {errors.displayName && errors.displayName.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.name}>
        <FormLabel>Slug</FormLabel>
        <InputGroup>
          <Input
            ref={register}
            name="slug"
            isReadOnly
            value={slugify(watch("name") || "", {
              lower: true,
              strict: true,
            })}
          />
        </InputGroup>
      </FormControl>

      <FormControl isInvalid={!!errors.baseUrl}>
        <FormLabel>Base URL</FormLabel>
        <InputGroup>
          <Controller
            control={control}
            name="baseUrl"
            render={({ onChange, value, name }) => {
              return (
                <Input
                  ref={register}
                  name={name}
                  onChange={e => onChange(e.target.value.toLowerCase())}
                  value={value}
                  type="text"
                  placeholder="https://example.com/"
                />
              )
            }}
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>

      {Object.keys(defaultValues).length === 0 && (
        <>
          <FormSectionHeader>Imagery</FormSectionHeader>
          <FormControl>
            <FormLabel>Favicon Image</FormLabel>
            <Controller
              as={AssetInput as any}
              name="faviconImageId"
              control={control}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Logo Image</FormLabel>
            <Controller
              as={AssetInput as any}
              name="logoImageId"
              control={control}
            />
          </FormControl>
          <FormSectionHeader>API</FormSectionHeader>
          <FormControl isInvalid={!!errors.remoteApiUrl} isRequired>
            <FormLabel>Client API Environment </FormLabel>
            <InputGroup>
              <Select
                ref={register}
                name="remoteApiUrl"
                placeholder="Select option"
              >
                {apiUrls.map(value => {
                  return (
                    <option key={value.url} value={value.url}>
                      {value.name}
                    </option>
                  )
                })}
              </Select>
            </InputGroup>
            <FormHelperText>{watch("remoteApiUrl")}</FormHelperText>
            <FormErrorMessage>
              {errors.remoteApiUrl && errors.remoteApiUrl.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.remoteApiClientApiKey} isRequired>
            <FormLabel>Client API Key</FormLabel>
            <InputGroup>
              <Input ref={register} name="remoteApiClientApiKey" type="text" />
            </InputGroup>
            <FormErrorMessage>
              {errors.remoteApiClientApiKey &&
                errors.remoteApiClientApiKey.message}
            </FormErrorMessage>
          </FormControl>
        </>
      )}
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppBrandingGeneralForm
