import React from "react"
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Label,
} from "recharts"

interface LineChartProps {
  data: any[]
}

const LineChartComponent: React.FC<LineChartProps> = ({ data }) => {
  const filteredData = data
    .slice(1)
    .filter(point => point.y !== null && point.y !== undefined)
  const name = data.length > 0 ? data[0] : []
  const xAxisName = name.x
  const yAxisName = name.y

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        margin={{
          top: 5,
          right: 20,
          left: 5,
          bottom: 10,
        }}
        data={filteredData}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x">
          <Label value={xAxisName} position="insideBottom" offset={-5} />
        </XAxis>
        <YAxis>
          <Label value={yAxisName} position="insideLeft" angle={-90} />
        </YAxis>
        <Tooltip />
        <Line type="monotone" dataKey="y" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineChartComponent
