import { Image, Flex, Spinner, Icon } from '@chakra-ui/react'
import React from 'react'
import { FiFile, FiUploadCloud } from 'react-icons/fi'

interface FilePreviewProps {
  size: number
  path: string
  mimeType: string
  isLoading?: boolean
}

const FilePreview: React.FC<FilePreviewProps> = ({
  path,
  mimeType,
  size,
  isLoading = false,
}) => {
  let preview = isLoading ? (
    <Spinner color="gray.400" />
  ) : (
    <Icon as={FiUploadCloud} boxSize={30} color="gray.400" />
  )

  if (path !== '') {
    if (mimeType.includes('image/')) {
      preview = (
        <Image boxSize={size} objectFit="cover" src={path} borderRadius="md" />
      )
    } else {
      preview = <Icon as={FiFile} boxSize={30} color="gray.400" />
    }
  }

  return (
    <Flex
      w={size}
      h={size}
      alignItems="center"
      justifyContent="center"
      borderWidth={1}
      borderColor="gray.300"
      borderRadius="md"
    >
      {preview}
    </Flex>
  )
}

export default FilePreview
