import { useCallback, useEffect, useState } from "react"
import api, { Resources, EntityPage } from "services/api"
import { debounce } from "lodash"

export const useResourceList = <T>(resource: Resources, params?: any) => {
  const [newDataAvailable, setNewDataAvailable] = useState(0)
  const [data, setData] = useState<EntityPage<T>>({
    data: [],
    limit: 0,
    skip: 0,
    total: 0,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [isFirstLoading, setIsFirstLoading] = useState(true)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onNewDataAvailable = useCallback(
    debounce(() => {
      setNewDataAvailable(state => state + 1)
    }, 200),
    [newDataAvailable, setNewDataAvailable, debounce]
  )

  // register change listeners
  useEffect(() => {
    api.listen(resource, "created", onNewDataAvailable)
    api.listen(resource, "removed", onNewDataAvailable)
    api.listen(resource, "updated", onNewDataAvailable)
    api.listen(resource, "patched", onNewDataAvailable)

    return () => {
      api.stopListening(resource, "created", onNewDataAvailable)
      api.stopListening(resource, "removed", onNewDataAvailable)
      api.stopListening(resource, "updated", onNewDataAvailable)
      api.stopListening(resource, "patched", onNewDataAvailable)
    }
  }, [onNewDataAvailable, resource])

  // trigger api request
  useEffect(() => {
    const find = async () => {
      setIsLoading(true)

      let queryParams = params
      if (!queryParams.query.$limit) {
        queryParams.query.$limit = 10000
      }

      const result = await api.find(resource, queryParams)
      setData(result)
      setIsLoading(false)
      setIsFirstLoading(false)
    }

    find()
  }, [resource, params])

  return {
    data,
    isLoading,
    isFirstLoading,
  }
}
