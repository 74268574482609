import { Stack } from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import Page from "components/Page"
import SecondarySidebar, {
  SecondarySidebarNavigationItem,
} from "components/SecondarySidebar/SecondarySidebar"
import { useEntity } from "hooks/useEntity"
import LayoutPage from "layouts/LayoutPage"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import { BsLayoutWtf } from "react-icons/bs"
import {
  FiDroplet,
  FiFeather,
  FiFileText,
  FiLayout,
  FiNavigation,
  FiSettings,
} from "react-icons/fi"
import { RxRocket } from "react-icons/rx"
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom"
import AppCreateScreen from "./AppCreateScreen"
import AppHeaderActions from "./AppHeaderActions"
import AppListScreen from "./AppListScreen"
import AppBrandingNavigation from "./branding/navigation/AppBrandingNavigation"
import AppChangelogScreen from "./changelog/AppChangelogScreen"
import AppCommonNavigation from "./common/navigation/AppCommonNavigation"
import AppDeploymentCRUDScreen from "./deployment/AppDeploymentCRUDScreen"
import AppDeploymentListScreen from "./deployment/AppDeploymentListScreen"
import AppDeploymentForm from "./deployment/forms/AppDeploymentForm"
import AppDeploymentNavigation from "./deployment/navigation/AppDeploymentNavigation"
import AppNavigationCRUDScreen from "./navigation/AppNavigationCRUDScreen"
import AppNavigationListScreen from "./navigation/AppNavigationListScreen"
import AppNavigationForm from "./navigation/forms/AppNavigationGeneralForm"
import AppNavigationNav from "./navigation/navigation/AppNavigationNav"
import AppNavigationNavigation from "./navigation/navigation/AppNavigationNavigation"
import AppPageCRUDScreen from "./pages/AppPageCRUDScreen"
import AppPageTreeScreen from "./pages/AppPageTreeScreen"
import AppCreatePageNav from "./pages/navigation/AppCreatePageNav"
import AppPageNavigation from "./pages/navigation/AppPageNavigation"
import AppPreviewCRUDScreen from "./preview/AppPreviewCRUDScreen"
import AppPreviewScreen from "./preview/AppPreviewScreen"
import AppPreviewForm from "./preview/forms/AppPreviewForm"
import AppSettingsNavigation from "./settings/navigation/AppSettingsNavigation"

interface Props {}

const AppNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        <LayoutPage>
          <AppCreateScreen />
        </LayoutPage>
      </Route>
      <Route path={`${match.path}/:appId`}>
        <AppSubNavigation />
      </Route>
      <Route path={`${match.path}/`}>
        <LayoutPage>
          <AppListScreen />
        </LayoutPage>
      </Route>
    </Switch>
  )
}

export const AppSubNavigation = () => {
  const match = useRouteMatch()
  const { appId } = useParams<RouteParams>()
  const { data: app, isLoading } = useEntity<AppEntity>("apps", parseInt(appId))
  const appName = isLoading ? "loading..." : app?.name

  const navigation: SecondarySidebarNavigationItem[] = [
    {
      label: "Branding",
      icon: FiDroplet,
      children: [
        {
          label: "General",
          to: `${match.url}/branding/general`,
        },
        {
          label: "Distribution",
          to: `${match.url}/branding/distribution`,
        },
        {
          label: "Localization",
          to: `${match.url}/branding/localization`,
        },
        {
          label: "SEO",
          to: `${match.url}/branding/seo`,
        },
        {
          label: "Social Media",
          to: `${match.url}/branding/social-medias`,
        },
        {
          label: "Theme",
          to: `${match.url}/branding/theme`,
        },
      ],
    },
    {
      label: "Layout",
      icon: FiLayout,
      children: [
        {
          label: "Checkout",
          to: `${match.url}/layout/checkout`,
        },
        {
          label: "Footer",
          to: `${match.url}/layout/footer`,
        },
        {
          label: "Fulfillment",
          to: `${match.url}/layout/fulfillment`,
        },
        {
          label: "Header",
          to: `${match.url}/layout/header`,
        },
      ],
    },
    {
      label: "Navigations",
      icon: FiNavigation,
      to: `${match.url}/navigations`,
    },
    {
      label: "Pages",
      icon: FiFileText,
      to: `${match.url}/pages`,
    },
    {
      label: "Settings",
      icon: FiSettings,

      children: [
        {
          label: "Deployment",
          to: `${match.url}/settings/deployment`,
        },

        {
          label: "Development",
          to: `${match.url}/settings/development`,
        },

        {
          label: "Experiments",
          to: `${match.url}/settings/experiments`,
        },

        {
          label: "Lambda Experiments",
          to: `${match.url}/settings/lambda-ab-tests`,
        },
        {
          label: "Miscellaneous",
          to: `${match.url}/settings/misc`,
        },
        {
          label: "Products",
          to: `${match.url}/settings/products-setup`,
        },
        {
          label: "Services",
          to: `${match.url}/settings/services`,
        },
        {
          label: "Sitemap",
          to: `${match.url}/settings/sitemap`,
        },
        {
          label: "Store Pages",
          to: `${match.url}/settings/store-pages`,
        },
        {
          label: "User Accounts",
          to: `${match.url}/settings/accounts`,
        },
      ],
    },
    {
      label: "Deployments",
      icon: RxRocket,
      to: `${match.url}/deployments`,
    },
    {
      label: "Changelogs",
      icon: BsLayoutWtf,
      to: `${match.url}/changelogs`,
    },
  ]

  return (
    <LayoutPage
      secondaryNavigation={
        <SecondarySidebar
          icon={FiFeather}
          title="Applications"
          navigation={navigation}
        />
      }
    >
      <Page title={`App - ${appName}`} headerActions={<AppHeaderActions />}>
        <Switch>
          <Redirect exact from={match.url} to={`${match.url}/branding`} />
          <Redirect
            exact
            from={`${match.url}/edit`}
            to={`${match.url}/branding`}
          />
          <Route path={`${match.path}/branding`}>
            <AppBrandingNavigation />
          </Route>
          <Route path={`${match.path}/layout`}>
            <AppCommonNavigation />
          </Route>
          <Route path={`${match.path}/settings`}>
            <AppSettingsNavigation />
          </Route>
          <Route path={`${match.path}/navigations`}>
            <NavigationSubNavigation />
          </Route>
          <Route path={`${match.path}/pages`}>
            <PagesSubNavigation />
          </Route>
          <Route path={`${match.path}/deployments`}>
            <DeploymentsSubNavigation />
          </Route>
          <Route path={`${match.path}/preview`}>
            <PreviewSubNavigation />
          </Route>
          <Route path={`${match.path}/changelogs`}>
            <AppChangelogScreen />
          </Route>
        </Switch>
      </Page>
    </LayoutPage>
  )
}

interface NavigationSubNavigationProps {
  navigation?: React.ReactNode
}

const NavigationSubNavigation: React.FC<NavigationSubNavigationProps> = ({
  navigation,
}) => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        <Stack direction="column" alignItems="start" spacing={6}>
          <AppNavigationNav />
          <AppNavigationCRUDScreen form={AppNavigationForm} />
        </Stack>
      </Route>
      <Route path={`${match.path}/:navigationId`}>
        <AppNavigationNavigation />
      </Route>
      <Route path={`${match.path}/`}>
        <AppNavigationListScreen />
      </Route>
    </Switch>
  )
}

interface PagesSubNavigationProps {
  navigation?: React.ReactNode
}

const PagesSubNavigation: React.FC<PagesSubNavigationProps> = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        <Stack direction="column" alignItems="start" spacing={6}>
          <AppCreatePageNav />
          <AppPageCRUDScreen />
        </Stack>
      </Route>
      <Route path={`${match.path}/:pageId`}>
        <AppPageNavigation />
      </Route>
      <Route path={`${match.path}/`}>
        <AppPageTreeScreen />
      </Route>
    </Switch>
  )
}

interface DeploymentsSubNavigationProps {
  navigation?: React.ReactNode
}

const DeploymentsSubNavigation: React.FC<DeploymentsSubNavigationProps> =
  () => {
    const match = useRouteMatch()
    return (
      <Switch>
        <Route path={`${match.path}/new`}>
          <AppDeploymentCRUDScreen form={AppDeploymentForm} />
        </Route>
        <Route path={`${match.path}/:deploymentId`}>
          <AppDeploymentNavigation />
        </Route>
        <Route path={`${match.path}/`}>
          <AppDeploymentListScreen />
        </Route>
      </Switch>
    )
  }

interface PreviewSubNavigationProps {
  navigation?: React.ReactNode
}

const PreviewSubNavigation: React.FC<PreviewSubNavigationProps> = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        <AppPreviewCRUDScreen form={AppPreviewForm} />
      </Route>
      <Route path={`${match.path}/`}>
        <AppPreviewScreen />
      </Route>
    </Switch>
  )
}

export default AppNavigation
