import { Box, HStack } from "@chakra-ui/react"
import {
  AppEntity,
  DeploymentEntity,
  GlobalDeploymentSettingsEntity,
  PageEntity,
  SettingsEntity,
} from "@jackfruit/common"
import Body from "components/Body"
import Card from "components/Card"
import CompiledChangelogs from "components/Changelogs/CompiledChangelogs"
import Error from "components/Error"
import FormSectionHeader from "components/FormSectionHeader"
import { useAuthState } from "hooks/useAuthState"
import { useConfigurationState } from "hooks/useConfigurationState"
import { useEntity } from "hooks/useEntity"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import { useHasOneResource } from "hooks/useHasOneResource"
import { useResourceForm } from "hooks/useResourceForm"
import { useResourceList } from "hooks/useResourceList"
import { RouteParams } from "navigation/RouteParams"
import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"

interface Props {
  form: React.FC<any>
}

const AppDeploymentCRUDScreen: React.FC<Props> = ({ form }) => {
  const history = useHistory()
  const { user } = useAuthState()
  const { configuration } = useConfigurationState()
  const { appId } = useParams<RouteParams>()
  const { data: app, isSuccess: hasLoadedApp } = useEntity<AppEntity>(
    "apps",
    appId
  )
  const [params] = useState({ query: { appId } })

  const { data: pages, isLoading: isLoadingPages } =
    useResourceList<PageEntity>("pages", params)

  const {
    data: globalDeploymentSettings,
    isLoading: isLoadingGlobalDeploymentSettings,
  } = useFindOneEntity<GlobalDeploymentSettingsEntity>(
    "global-deployment-settings",
    {
      query: {},
    }
  )

  const { data: latestDeployment, isLoading: isLoadingLatestDeployment } =
    useFindOneEntity<DeploymentEntity>("deployments", {
      query: {
        appId: appId,
        type: "live",
        status: "complete",
        $sort: {
          id: -1,
        },
      },
    })

  const { entity: settings, isLoading: isLoadingSettings } =
    useHasOneResource<SettingsEntity>("settings", "appId", parseInt(appId))

  const {
    entity,
    isCreating,
    isLoading: isLoadingDeployment,
    onSubmit,
  } = useResourceForm<DeploymentEntity>("deployments")

  const isLoading =
    isLoadingDeployment ||
    !hasLoadedApp ||
    isLoadingSettings ||
    isLoadingGlobalDeploymentSettings

  if (isLoading || isLoadingLatestDeployment) {
    return null
  }

  const gitReference =
    latestDeployment?.gitReference ||
    globalDeploymentSettings?.defaultVersion ||
    ""

  entity.gitReference = gitReference
  entity.liveDeploymentTestTags =
    configuration.deployment.liveDeploymentTestTags || []
  entity.testTags = configuration.deployment.testTags || ""

  const enhancedOnSubmit = async (data: any) => {
    const updatedData = {
      appId: appId,
      userId: user.id,
      status: "pending",
      type: "live",
      ...data,
      url: app.baseUrl,
      cloudFrontDistributionId: settings.deploymentCloudFrontDistributionId,
      s3BucketName: settings.deploymentS3BucketName,
      lambdaAbVariantId: data.lambdaAbVariantId
        ? parseInt(data.lambdaAbVariantId)
        : null,
    }

    const deployment = await onSubmit(updatedData)
    history.push(`/admin/apps/${appId}/deployments/${deployment.id}`)
  }

  const formComponent = React.createElement(form, {
    entity,
    settings,
    latestDeployment,
    isLoading: isCreating || isLoadingPages,
    pages: pages.data,
    onSubmit: enhancedOnSubmit,
  })

  if (
    !settings.deploymentCloudFrontDistributionId ||
    !settings.deploymentS3BucketName
  ) {
    return (
      <Body canGoBack={true} isLoading={isLoading}>
        <Error
          title="Operation not allowed"
          error="Please check your deployment configuration"
        />
      </Body>
    )
  }

  return (
    <HStack alignItems="flex-start" spacing={4}>
      <Body>{formComponent}</Body>

      <Card boxShadow="none" padding={4} flex={1}>
        <Box mb={3}>
          <FormSectionHeader>
            Changelog since{" "}
            {latestDeployment ? latestDeployment.deploymentVersion : "created"}
          </FormSectionHeader>
        </Box>
        <CompiledChangelogs
          appId={appId}
          deploymentIdFrom={latestDeployment ? latestDeployment.id : undefined}
        />
      </Card>
    </HStack>
  )
}

export default AppDeploymentCRUDScreen
