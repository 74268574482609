import { Box, FormLabel, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react"
import { PageEntity } from "@jackfruit/common"
import React, { useCallback, useMemo } from "react"

interface PageTagProps {
  label: string
  value: string
  onRemove: (value: string) => void
}

const PageTag = React.memo(({ label, value, onRemove }: PageTagProps) => {
  const handleRemove = useCallback(() => {
    onRemove(value)
  }, [onRemove, value])

  return (
    <Tag size="md" borderRadius="full" variant="solid" colorScheme="blue" m={1}>
      <TagLabel>{label}</TagLabel>
      <TagCloseButton onClick={handleRemove} />
    </Tag>
  )
})

interface AppCommonPageTreeTagsProps {
  pages: PageEntity[]
  selectedPages: string[]
  onRemove: (value: string) => void
}

const AppCommonPageTreeTags = React.memo(
  ({ pages, selectedPages, onRemove }: AppCommonPageTreeTagsProps) => {
    const configPages = useMemo(() => {
      const hierarchicalPageObject: { [key: string]: string } = {}

      pages.forEach(page => (hierarchicalPageObject[page.id] = page.name))

      return hierarchicalPageObject
    }, [pages])

    return (
      <Box width="full" maxW={"638px"}>
        <FormLabel>Selected Pages</FormLabel>
        <Box
          bg="gray.50"
          p={2}
          rounded="md"
          minH={14}
          maxHeight={28}
          overflowY="scroll"
        >
          {selectedPages.map(value => (
            <PageTag
              key={value}
              label={configPages[value]}
              value={value}
              onRemove={onRemove}
            />
          ))}
        </Box>
      </Box>
    )
  }
)

export default AppCommonPageTreeTags
