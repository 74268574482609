import React, { useState } from "react"
import { Box } from "@chakra-ui/react"
import { useHasOneResourceForm } from "hooks/useHasOneResourceForm"
import BlockImageGridForm from "../forms/BlockImageGridForm"
import { BlockEntity } from "@jackfruit/common"

interface Props {
  block: BlockEntity
}

const BlockImageGrid: React.FC<Props> = ({ block }) => {
  const [params] = useState({
    $eager: "[assetsMobile, assetsTablet, assetsDesktop]",
  })

  const { entity, isLoading, isCreating, isPatching, onSubmit } =
    useHasOneResourceForm<any>("blocks-image-grid", "blockId", block.id, params)

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockImageGridForm
      entity={entity}
      isLoading={isCreating || isPatching}
      onSubmit={onSubmit}
      params={params}
    />
  )
}

export default BlockImageGrid
