import { DependencyList, EffectCallback, useRef } from "react"
import { useDebounce } from "react-use"

export const useUpdateDebounce = (
  effect: EffectCallback,
  time: number,
  deps?: DependencyList
) => {
  const initialRender = useRef(true)

  return useDebounce(
    () => {
      if (!initialRender.current) {
        effect()
      }
      initialRender.current = false
    },
    time,
    deps
  )
}
