import { Box } from "@chakra-ui/react"
import { BlockEntity, BlockTemplateEntity } from "@jackfruit/common"
import { useHasOneResourceForm } from "hooks/useHasOneResourceForm"
import React from "react"
import BlockTemplateForm from "../forms/block-template/BlockTemplateForm"

interface Props {
  block: BlockEntity
}

const BlockTemplate: React.FC<Props> = ({ block }) => {
  const { entity, isLoading, isCreating, isPatching, onSubmit } =
    useHasOneResourceForm<BlockTemplateEntity>(
      "blocks-template",
      "blockId",
      block.id
    )

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockTemplateForm
      entity={entity}
      isLoading={isCreating || isPatching}
      onSubmit={onSubmit}
    />
  )
}

export default BlockTemplate
