import { Button } from "@chakra-ui/react"
import { EntityId, NavigationMegaMenuCellLinkEntity } from "@jackfruit/common"
import { useCreateEntity } from "hooks/useCreateEntity"
import React from "react"
import { FiPlus } from "react-icons/fi"
import { useQueryClient } from "react-query"

interface Props {
  megaMenuCellId: EntityId
}

const MegaMenuAddLinkButton: React.FC<Props> = ({ megaMenuCellId }) => {
  const queryClient = useQueryClient()
  const { mutateAsync: createMegaMenuCellLink } = useCreateEntity<
    Partial<NavigationMegaMenuCellLinkEntity>
  >("navigation-megamenu-cell-links")

  const handleCreateCellLink = async () => {
    await createMegaMenuCellLink({
      navigationMegamenuCellId: megaMenuCellId,
      type: "page",
      label: "new link",
    })

    queryClient.invalidateQueries(["navigation-megamenus"])
  }

  return (
    <Button
      w="full"
      colorScheme="gray"
      justifyContent="space-between"
      rightIcon={<FiPlus />}
      onClick={handleCreateCellLink}
    >
      Add Link
    </Button>
  )
}

export default MegaMenuAddLinkButton
