import { Button, Icon } from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import Body from "components/Body"
import Page from "components/Page"
import { useCreateEntity } from "hooks/useCreateEntity"

import React from "react"
import { FiX } from "react-icons/fi"
import { Link, useHistory } from "react-router-dom"
import AppBrandingGeneralForm from "./branding/forms/AppBrandingGeneralForm"

interface Props {}

const Actions = () => {
  return (
    <Link to={`/admin/apps`}>
      <Button colorScheme="primary" leftIcon={<Icon as={FiX} />}>
        Cancel
      </Button>
    </Link>
  )
}

const AppCreateScreen: React.FC<Props> = () => {
  const history = useHistory()

  const { mutate: createApp, status: createStatus } =
    useCreateEntity<AppEntity>("apps", {
      showNotification: true,
      onSuccess: (app: AppEntity) => {
        history.push(`/admin/apps/${app.id}`)
      },
    })

  const isCreating = createStatus === "loading"

  const onSubmit = (data: any) => {
    createApp(data)
  }

  return (
    <Page title="Create New App" headerActions={<Actions />}>
      <Body>
        <AppBrandingGeneralForm onSubmit={onSubmit} isLoading={isCreating} />
      </Body>
    </Page>
  )
}

export default AppCreateScreen
