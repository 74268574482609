import { CommonEntity } from "@jackfruit/common"
import { useHasOneResource } from "hooks/useHasOneResource"
import { useResourceActions } from "hooks/useResourceActions"

const useAppCommon = (appId: number) => {
  const { entity, setEntity, isLoading } = useHasOneResource<CommonEntity>(
    "common",
    "appId",
    appId
  )

  const { isPatching, patch, isCreating, create } = useResourceActions(
    "common",
    setEntity
  )

  const onSubmit = async (data: CommonEntity): Promise<CommonEntity> => {
    if (entity.id) {
      return await patch(entity.id, data)
    } else {
      data.appId = appId
      return await create(data)
    }
  }

  return {
    entity,
    isLoading,
    isCreating,
    isPatching,
    onSubmit,
  }
}

export { useAppCommon }
