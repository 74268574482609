import { useToast, UseToastOptions } from "@chakra-ui/react"
import { useCallback } from "react"

export const useCustomToast = () => {
  const toast = useToast()

  return useCallback(
    (options: UseToastOptions) => {
      toast({ ...options, position: "top-right", variant: "left-accent" })
    },
    [toast]
  )
}
