import { InputGroup, InputLeftAddon } from "@chakra-ui/input"
import { Box, HStack } from "@chakra-ui/layout"
import { DeploymentEntity, EntityId } from "@jackfruit/common"
import Body from "components/Body"
import CompiledChangelogs from "components/Changelogs/CompiledChangelogs"
import DeploymentSelector, {
  DeploymentSelectorValues,
} from "components/Changelogs/DeploymentSelector"
import { useEntityList } from "hooks/useEntityList"
import { last } from "lodash"
import { RouteParams } from "navigation/RouteParams"
import React, { useState } from "react"
import { useParams } from "react-router"

interface Props {}

const AppChangelogScreen: React.FC<Props> = () => {
  const { appId } = useParams<RouteParams>()

  const { data: deployments, isLoading: isLoadingDeployments } =
    useEntityList<DeploymentEntity>("deployments", {
      query: {
        appId,
        type: "live",
        status: "complete",
        $select: ["id"],
        $sort: {
          id: 1,
        },
      },
    })

  if (isLoadingDeployments) {
    return null
  }

  return (
    <AppChangelogScreenContent appId={appId} deployments={deployments.data} />
  )
}

interface AppChangelogScreenContentProps {
  appId: EntityId
  deployments: DeploymentEntity[]
}

const AppChangelogScreenContent: React.FC<AppChangelogScreenContentProps> = ({
  appId,
  deployments,
}) => {
  const latestDeployment =
    deployments.length > 0 ? last(deployments)?.id : "creation"

  const [deploymentIdFrom, setDeploymentIdFrom] =
    useState<DeploymentSelectorValues>(latestDeployment!)
  const [deploymentIdTo, setDeploymentIdTo] =
    useState<DeploymentSelectorValues>("current")

  const deploymentIdFromToUse =
    deploymentIdFrom === "creation" ? null : deploymentIdFrom

  const deploymentIdToToUse =
    deploymentIdTo === "current" ? null : deploymentIdTo

  return (
    <Body
      padding={0}
      title="Changelogs"
      fullWidth
      headerActions={
        <HStack>
          <InputGroup>
            <InputLeftAddon>From</InputLeftAddon>
            <DeploymentSelector
              appId={appId}
              borderLeftRadius={0}
              defaultValue={deploymentIdFrom}
              w="160px"
              onSelectionChange={setDeploymentIdFrom}
            />
          </InputGroup>
          <InputGroup>
            <InputLeftAddon>To</InputLeftAddon>
            <DeploymentSelector
              appId={appId}
              borderLeftRadius={0}
              defaultValue={deploymentIdTo}
              w="160px"
              onSelectionChange={setDeploymentIdTo}
            />
          </InputGroup>
        </HStack>
      }
    >
      <Box p={4} pt={0}>
        <CompiledChangelogs
          appId={appId}
          deploymentIdFrom={deploymentIdFromToUse!}
          deploymentIdTo={deploymentIdToToUse!}
        />
      </Box>
    </Body>
  )
}

export default AppChangelogScreen
