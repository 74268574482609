import { Stack } from "@chakra-ui/react"
import { AppEntity, PageEntity } from "@jackfruit/common"
import Body from "components/Body"
import { useEntity } from "hooks/useEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import { useResourceList } from "hooks/useResourceList"
import { RouteParams } from "navigation/RouteParams"
import React, { useState } from "react"
import { useParams } from "react-router-dom"

interface Props {
  form: React.FC<any>
}

const AppBrandingCRUDScreen: React.FC<Props> = ({ form }) => {
  const { appId } = useParams<RouteParams>()
  const [params] = useState({ query: { appId } })
  const { data: entity, isLoading } = useEntity<AppEntity>(
    "apps",
    parseInt(appId)
  )
  const { mutate: saveApp, status: patchStatus } =
    usePatchEntity<AppEntity>("apps")

  const onSubmit = (data: any) => {
    saveApp({ ...data, id: parseInt(appId) })
  }

  const { data: pages, isLoading: isLoadingPages } =
    useResourceList<PageEntity>("pages", params)

  const formComponent = React.createElement(form, {
    entity,
    isLoading: patchStatus === "loading",
    onSubmit,
    pages: pages.data,
  })

  return (
    <Stack spacing={4}>
      {!isLoadingPages && <Body isLoading={isLoading}>{formComponent}</Body>}
    </Stack>
  )
}

export default AppBrandingCRUDScreen
