import { Box, Divider, Flex, Heading, HStack, MenuItem } from "@chakra-ui/react"
import { useAuth } from "hooks/useAuth"
import { useAuthState } from "hooks/useAuthState"
import { HEADER_HEIGHT } from "layouts/LayoutAdmin"
import React, { useMemo } from "react"
import Scrollbars from "react-custom-scrollbars"
import { FiUser } from "react-icons/fi"
import { useLocation } from "react-router-dom"
import BreadCrumbs from "./BreadCrumbs"
import ContentTransition from "./ContentTransition"
import HeaderMenu from "./HeaderMenu"

interface Props {
  title?: string
  navigation?: React.ReactNode
  navigationAction?: React.ReactNode
  headerActions?: React.ReactNode
}

const Page: React.FC<Props> = ({
  children,
  title,
  navigation,
  navigationAction,
  headerActions,
}) => {
  const { logout } = useAuth()
  const { user } = useAuthState()
  const { pathname } = useLocation()

  const userMenu = useMemo(
    () => [
      {
        label: "Profile",
        route: `users/${user.id}`,
      },
      {
        label: "Change Password",
        route: `users/${user.id}/edit`,
      },
    ],
    [user]
  )

  return (
    <Flex flexDirection="column">
      <Flex height={`${HEADER_HEIGHT}px`} direction="column">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={6}
          zIndex={3}
          flex={1}
        >
          <Box>
            <Heading as="h1" fontSize="xl" color="gray.600" mb={2}>
              {title}
            </Heading>
            <BreadCrumbs />
          </Box>
          <HStack alignItems="center" spacing={6}>
            {headerActions}
            <HeaderMenu
              icon={FiUser}
              menu={userMenu}
              children={<MenuItem onClick={logout}>Logout</MenuItem>}
            />
          </HStack>
        </Flex>
        <Box px={6}>
          <Divider my={0} borderColor="gray.300" />
        </Box>
      </Flex>

      <Scrollbars
        style={{ height: `calc(100vh - ${HEADER_HEIGHT}px)` }}
        autoHide
      >
        <Box px={6} py={4}>
          <ContentTransition key={pathname}>{children}</ContentTransition>
        </Box>
      </Scrollbars>
    </Flex>
  )
}

export default Page
