import { DeploymentEntity, EntityId } from "@jackfruit/common"
import AceEditorEnhanced from "components/AceEditorEnhanced"
import { useEntity } from "hooks/useEntity"
import React from "react"

interface Props {
  deploymentId: EntityId
}

const DeploymentConfig: React.FC<Props> = ({ deploymentId }) => {
  const { data: entity, isLoading } = useEntity<DeploymentEntity>(
    "deployments",
    deploymentId,
    {},
    {
      query: {
        $select: ["id", "generatedConfig"],
      },
    }
  )

  if (isLoading) {
    return null
  }

  return (
    <AceEditorEnhanced
      height="600px"
      value={JSON.stringify(JSON.parse(entity.generatedConfig), undefined, 2)}
      mode="json"
      name="config"
    />
  )
}

export default DeploymentConfig
