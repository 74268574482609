import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  useDisclosure,
  Box,
} from "@chakra-ui/react"
import { Resizable } from "re-resizable"
import CpResult from "./CpResult"
import CpWidgets from "./CpWidgets"
import {
  EditorSize,
  snippetsWidth,
  CpContext,
  CpContextInterface,
} from "./CpContext"
import { useWindowSize } from "hooks/useWindowSize"
import CpEditor from "./CpEditor"

const halfSnippetsWidth = snippetsWidth / 2

interface Props {
  onChange: (value: string, type: string) => void
}

const CpModal: React.FC<Props> = ({ onChange }) => {
  const { modalState, snippetsMode, onToggleMode } =
    useContext<CpContextInterface>(CpContext)

  const resizableRef = useRef<any>(null!)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [resizableMaxWidth, setResizableMaxWidth] = useState(80)
  const [resizableMinWidth, setResizableMinWidth] = useState(20)
  const [isResizable, setIsResizable] = useState(true)

  const {
    windowSize: { width: windowWidth },
  } = useWindowSize()

  const [editorSize, setEditorSize] = useState<EditorSize>({
    width: windowWidth,
    height: "100%",
  })

  useEffect(() => {
    if (modalState) {
      onOpen()
    } else {
      onClose()
    }
  }, [modalState, onOpen, onClose])

  useEffect(() => {
    if (isOpen) {
      const editorMaxWidth = 80
      let editorWidth = windowWidth / 2

      if (snippetsMode) {
        editorWidth = editorWidth + halfSnippetsWidth
      }

      setEditorSize({
        width: editorWidth,
        height: "100%",
      })

      setResizableMaxWidth(editorMaxWidth)

      setIsResizable(true)
    } else {
      setResizableMaxWidth(100)
    }
  }, [isOpen, snippetsMode, windowWidth])

  useEffect(() => {
    setResizableMinWidth(snippetsMode ? 30 : 20)
  }, [snippetsMode])

  const onResizeStop = useCallback(
    (e, direction, ref, d) => {
      setEditorSize({
        width: editorSize.width + d.width,
        height: "100%",
      })
    },
    [editorSize.width]
  )

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="full"
      blockScrollOnMount={true}
      onEsc={() => onToggleMode("fullscreen")}
    >
      <ModalOverlay m={0} p={0} zIndex={1400}>
        <ModalContent borderRadius={0} m={0} p={0} overflow="hidden">
          <ModalBody m={0} p={0}>
            <Flex position="relative" h="100vh">
              {snippetsMode && modalState && <CpWidgets onChange={onChange} />}
              <Resizable
                size={editorSize}
                maxWidth={`${resizableMaxWidth}%`}
                minWidth={`${resizableMinWidth}%`}
                style={{
                  position: "relative",
                  borderRightWidth: modalState ? 2 : 0,
                  borderColor: "gray",
                }}
                enable={{
                  top: false,
                  right: isResizable,
                  bottom: false,
                  left: false,
                  topRight: false,
                  bottomRight: false,
                  bottomLeft: false,
                  topLeft: false,
                }}
                ref={resizableRef}
                onResizeStop={onResizeStop}
              >
                <CpEditor onChange={onChange} editorSize={editorSize} />
              </Resizable>
              <Box
                bg="gray"
                height="65px"
                maxWidth="2px"
                minWidth="2px"
                alignSelf="center"
              ></Box>
              <CpResult />
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default CpModal
