import {
  FormControl,
  FormLabel,
  InputGroup,
  Select,
  Textarea,
} from "@chakra-ui/react"
import { SettingsEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"

type FormData = Pick<SettingsEntity, "loggingMode" | "rawHead" | "robotsTxt">

interface Props {
  entity: SettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppSettingsDevelopmentDebugForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const { loggingMode, rawHead, robotsTxt } = entity

  const { handleSubmit, register, errors } = useForm<FormData>({
    defaultValues: {
      loggingMode,
      rawHead,
      robotsTxt,
    },
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Debug</FormSectionHeader>
      <FormControl isInvalid={!!errors.loggingMode}>
        <FormLabel>Logging mode</FormLabel>
        <InputGroup>
          <Select ref={register()} name="loggingMode">
            <option value="disabled">Disabled</option>
            <option value="debug">Debug</option>
            <option value="verbose">Verbose</option>
          </Select>
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Robots Txt</FormLabel>
        <InputGroup>
          <Textarea h={25} ref={register} name="robotsTxt" />
        </InputGroup>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsDevelopmentDebugForm
