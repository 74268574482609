import { Box, Heading, Text } from "@chakra-ui/react"
import Card from "components/Card"
import LoginForm from "forms/LoginForm"
import { useAuth } from "hooks/useAuth"
import { useAuthState } from "hooks/useAuthState"
import LayoutCentered from "layouts/LayoutCentered"
import React from "react"
import { Redirect } from "react-router-dom"
import { useTitle } from "react-use"
import { ReactComponent as ApLogo } from "../images/ApLogo.svg"
import LoginScreenBg from "../images/LoginBgStarfruit.png"

interface Props {}

const LoginScreen: React.FC<Props> = () => {
  const { login } = useAuth()
  const { isSuccess } = useAuthState()
  const isError = false
  const errorMessage = ""

  useTitle("Builder V4")

  if (isSuccess) {
    let redirectSuccess = "/admin/apps"

    const fromPathname = window.history.state?.state?.from?.pathname ?? ""
    if (fromPathname.includes("/admin")) {
      redirectSuccess = fromPathname
    }

    return (
      <Redirect
        to={{
          pathname: redirectSuccess,
        }}
      />
    )
  }
  return (
    <LayoutCentered
      bgSize="cover"
      bgPos="center"
      bgRepeat="no-repeat"
      bgImage={`url(${LoginScreenBg})`}
    >
      <Box
        position="relative"
        w="100%"
        maxW="sm"
        pt={44}
        mt={{ base: "-2rem", sm: "-4rem" }}
      >
        <Card
          p={6}
          mx={{ base: 4, sm: 0 }}
          position="relative"
          zIndex={10}
          borderWidth={0}
        >
          <ApLogo width="40px" />
          <Box textAlign="left" my={4}>
            <Heading as="h1" fontSize="xl" fontWeight="semibold">
              Welcome
            </Heading>
            <Text fontSize="sm" color="gray.500">
              Enter your credentials below to log in.
            </Text>
          </Box>
          <LoginForm
            login={login}
            isError={isError}
            errorMessage={errorMessage}
          />
        </Card>
      </Box>
    </LayoutCentered>
  )
}

export default LoginScreen
