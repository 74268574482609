import { Button, Icon } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { FiPlusCircle } from "react-icons/fi"
import Body from "components/Body"
import Table, { ColumnDefinition } from "components/Table"
import TableRowActions from "components/TableRowActions"
import TableRowId from "components/TableRowId"
import { useRouteMatch, Link } from "react-router-dom"
import Page from "components/Page"
import { Entity } from "@jackfruit/common"
import { SnippetEntity } from "screens/snippets/all/SnippetsListScreen"

export interface SnippetCategoryEntity extends Entity {
  name: string
  snippets?: SnippetEntity[]
}

const query = {
  $limit: 1000,
  $eager: "[snippets]",
}

const SnippetCategoriesListScreen: React.FC = () => {
  return (
    <Page title="Snippet Categories">
      <Body
        title="All Snippet Categories"
        headerActions={<Actions />}
        padding={0}
      >
        <Table resource="snippet-categories" columns={columns} query={query} />
      </Body>
    </Page>
  )
}

export default SnippetCategoriesListScreen

const Actions = () => {
  const match = useRouteMatch()
  return (
    <Link to={`${match.url}/new`}>
      <Button colorScheme="primary" leftIcon={<Icon as={FiPlusCircle} />}>
        New Category
      </Button>
    </Link>
  )
}

const columns: ColumnDefinition[] = [
  {
    header: "Id",
    render: (snippetCategory: SnippetCategoryEntity) => (
      <TableRowId entity={snippetCategory} />
    ),
  },
  { header: "Category", accessor: "name" },
  { header: "Is Default", accessor: "isDefault" },
  {
    header: "Updated",
    render: (snippetCategory: SnippetCategoryEntity) =>
      format(new Date(snippetCategory.createdAt!), "yyyy/MM/dd"),
  },
  {
    header: "Created",
    render: (snippetCategory: SnippetCategoryEntity) =>
      format(new Date(snippetCategory.updatedAt!), "yyyy/MM/dd"),
  },
  {
    header: "",
    accessor: "actions",
    render: (snippetCategory: SnippetCategoryEntity) => (
      <TableRowActions
        resource="snippet-categories"
        entity={snippetCategory}
        canEdit={true}
        canDelete={
          snippetCategory.snippets?.some(
            (snippet: SnippetEntity) =>
              snippet.snippetCategoryId === snippetCategory.id
          )
            ? false
            : true
        }
      />
    ),
  },
]
