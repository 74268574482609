// Based on https://github.com/Fedeorlandau/chakra-ui-simple-autocomplete
import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { matchSorter } from 'match-sorter'
import React, { useCallback, useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { BiCheckCircle } from 'react-icons/bi'
import { FiSearch } from 'react-icons/fi'
import { GrFormClose } from 'react-icons/gr'

export interface AutoCompleteOption {
  value: string | number
  label: string
}

export type AutoCompleteChoice = string | number

export interface AutocompleteProps {
  options: AutoCompleteOption[]
  choices: AutoCompleteChoice[]
  placeholder?: string
  inputName?: string
  inputId?: string
  notFoundText?: string
  isInvalid?: boolean
  isLoading?: boolean
  suffixResult?: string
  isTagsVisible?: boolean
  setChoices: (choices: AutoCompleteChoice[]) => void
}

export const Autocomplete = ({
  options,
  choices,
  placeholder,
  inputName,
  inputId,
  notFoundText = 'No result found...',
  isInvalid,
  isLoading = true,
  suffixResult = 'record(s) found',
  setChoices,
  isTagsVisible = false,
}: AutocompleteProps) => {
  const [searchOptions, setSearchOptions] =
    useState<AutoCompleteOption[]>(options)

  const [isOptionsDisplay, setIsOptionsDisplay] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>()

  useEffect(() => {
    setSearchOptions(options)
  }, [options])

  useEffect(() => {
    setIsOptionsDisplay(inputValue ? true : false)
  }, [inputValue])

  const onInputChange = useCallback(
    (value: string) => {
      const editedOptions = value
        ? matchSorter(options, value, {
            keys: ['label'],
            threshold: matchSorter.rankings.CONTAINS,
          })
        : options

      setSearchOptions(editedOptions)
      setInputValue(value)
    },
    [options]
  )

  const onRemoveChoice = useCallback(
    (choiceToRemove: AutoCompleteChoice) => {
      const editedChoices = choices.filter(
        (choice) => choice !== choiceToRemove
      )
      setChoices(editedChoices)
    },
    [choices, setChoices]
  )

  const onClear = useCallback(() => {
    setInputValue('')
    setSearchOptions(options)
    setIsOptionsDisplay(false)
  }, [options])

  const onSelectOption = useCallback(
    (choice: AutoCompleteChoice) => {
      // Remove a choice
      const isOptionSelected = choices.includes(choice)
      if (isOptionSelected) {
        onRemoveChoice(choice)
        onClear()
        return
      }

      // Add a choice
      const editedChoices = [...choices, choice]
      setChoices(editedChoices)
      onClear()
    },
    [choices, onClear, onRemoveChoice, setChoices]
  )

  const getLabelChoice = useCallback(
    (choice: AutoCompleteChoice) =>
      options.find((o) => o.value === choice)!.label,
    [options]
  )

  if (isLoading || options.length === 0) {
    return <Spinner size="md" />
  }

  return (
    <Box my={2}>
      {isTagsVisible && choices.length > 0 && (
        <Wrap mb={2} spacing={0.5}>
          {choices.map((choice) => {
            const label = getLabelChoice(choice)

            return (
              <WrapItem
                d="inline-block"
                onClick={() => onRemoveChoice(choice)}
                key={choice}
              >
                <Badge colorScheme="blue" mx={1} cursor="pointer">
                  {label}
                  <Icon as={GrFormClose} mb="2px" />
                </Badge>
              </WrapItem>
            )
          })}
        </Wrap>
      )}

      <InputGroup size="md">
        <InputLeftElement
          pointerEvents="none"
          color="gray.400"
          children={<FiSearch size="1rem" />}
        />

        <Input
          name={inputName}
          id={inputId}
          placeholder={placeholder}
          onChange={(e) => onInputChange(e.currentTarget.value)}
          value={inputValue || ''}
          isInvalid={isInvalid}
          autoComplete="disabled" // value "off" no longer works on modern Chrome
        />

        {inputValue && (
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={onClear}>
              Clear
            </Button>
          </InputRightElement>
        )}
      </InputGroup>

      {isOptionsDisplay && (
        <List
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="md"
          boxShadow="6px 5px 8px rgba(0,50,30,0.02)"
          mt={2}
          maxHeight="270px"
          overflowY="auto"
        >
          <ListItem
            my={1}
            p={2}
            color="secondary.500"
            borderBottomWidth="1px"
            borderStyle="solid"
            borderColor="gray.100"
            paddingBottom="12px"
            fontSize="sm"
            textTransform="uppercase"
            fontWeight="bold"
          >
            {`${searchOptions.length} ${suffixResult}`}
          </ListItem>

          {searchOptions?.map((option) => {
            const { value } = option
            const isOptionSelected = choices.includes(value)

            return (
              <ListItem
                p={2}
                my={1}
                key={value}
                _hover={{ bg: 'gray.100' }}
                cursor={'pointer'}
                onClick={() => onSelectOption(value)}
              >
                <Flex align="center">
                  {isOptionSelected && (
                    <Icon as={BiCheckCircle} color="green.500" mr={2} />
                  )}

                  <Highlighter
                    autoEscape={true}
                    searchWords={[inputValue || '']}
                    textToHighlight={option.label}
                    highlightStyle={{
                      background: '#fbdce7',
                      color: '#eb4f85',
                    }}
                  />
                </Flex>
              </ListItem>
            )
          })}

          {!searchOptions?.length && (
            <ListItem my={1} p={2}>
              <Flex align="center">{notFoundText}</Flex>
            </ListItem>
          )}
        </List>
      )}
    </Box>
  )
}
