import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  VStack,
} from "@chakra-ui/react"
import { AdditionalLocaleUrl } from "@jackfruit/common"
import { useFieldArray, useFormContext } from "react-hook-form"
import { FiMinus, FiPlus } from "react-icons/fi"

interface Props {}

const newUrlCell: AdditionalLocaleUrl = {
  displayName: "",
  url: "",
}

const LocaleNavigationEditor: React.FC<Props> = () => {
  const { control, register } = useFormContext()

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "additionalLocaleUrls",
  })

  if (fields.length === 0) {
    return (
      <Button
        colorScheme="gray"
        rightIcon={<FiPlus />}
        onClick={() => append(newUrlCell)}
      >
        Add Additional Locale URL
      </Button>
    )
  }

  const handleInsert = (index: number) => {
    insert(index + 1, newUrlCell)
  }

  return (
    <VStack bg="blackAlpha.50" p={4}>
      {fields.map((item, index) => {
        const { id, displayName, url } = item
        const showHelperText = index === 0

        return (
          <VStack
            key={id}
            w="full"
            bg="white"
            p={2}
            borderWidth={1}
            borderRadius="md"
            spacing={4}
          >
            <FormControl>
              <HStack align="center" justify="space-between">
                <FormLabel fontSize="sm">Display Name</FormLabel>
                <HStack spacing={0}>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    icon={<FiPlus />}
                    aria-label="Add"
                    isRound
                    onClick={() => handleInsert(index)}
                  />
                  <IconButton
                    size="sm"
                    variant="ghost"
                    icon={<FiMinus />}
                    aria-label="Add"
                    isRound
                    onClick={() => remove(index)}
                  />
                </HStack>
              </HStack>
              <InputGroup>
                <Input
                  size="sm"
                  ref={register()}
                  name={`additionalLocaleUrls[${index}].displayName`}
                  defaultValue={displayName}
                  type="text"
                />
              </InputGroup>
              {showHelperText && (
                <FormHelperText fontSize="xs">
                  This is what is visible to front-end customers.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm">Full URL</FormLabel>
              <InputGroup>
                <Input
                  size="sm"
                  ref={register()}
                  name={`additionalLocaleUrls[${index}].url`}
                  defaultValue={url}
                  type="text"
                />
              </InputGroup>
              {showHelperText && (
                <FormHelperText fontSize="xs">
                  This is the URL the user will be taken to. It will open in a
                  new tab.
                </FormHelperText>
              )}
            </FormControl>
          </VStack>
        )
      })}
    </VStack>
  )
}

export default LocaleNavigationEditor
