import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
} from "@chakra-ui/react"
import { SettingsEntity } from "@jackfruit/common"
import {
  uniqueNamesGenerator,
  adjectives,
  animals,
} from "unique-names-generator"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import Compressor from "compressorjs"
import FormActions from "forms/FormActions"
import React, { useCallback, useState } from "react"
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider"
import { useForm } from "react-hook-form"
import { useEffectOnce } from "react-use"

const fileUrl =
  "https://images.unsplash.com/photo-1438401171849-74ac270044ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1784&q=70"
const originalFileUrl = fileUrl

const createFileFromUrl = async (url: string): Promise<File> => {
  const data = await fetch(url)
  const blob = await data.blob()

  const file: any = blob
  file.lastModifiedDate = new Date()
  file.name = `${Math.random()}`

  return file as File
}

const getRandomName = () => {
  return uniqueNamesGenerator({
    dictionaries: [adjectives, animals],
  })
}

const compress = async (file: File, rate: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    // @see https://github.com/fengyuanchen/compressorjs
    // for more options
    return new Compressor(file, {
      quality: rate,
      success(result: any) {
        resolve(result as File)
      },
      error(err: any) {
        reject(err)
      },
    })
  })
}

interface Props {
  entity?: SettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppSettingsMiscForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }

  if (!defaultValues.localStoragePrefix) {
    defaultValues.localStoragePrefix = getRandomName()
  }

  const { handleSubmit, register, errors, watch, setValue } = useForm({
    defaultValues,
  })

  const [currentCompressedFileUrl, setCurrentCompressedFileUrl] =
    useState(originalFileUrl)

  const compressExample = async (rate: number) => {
    const file = await createFileFromUrl(originalFileUrl)
    const compressedFile = await compress(file, rate)
    const compressedFileUrl = URL.createObjectURL(compressedFile)
    setCurrentCompressedFileUrl(compressedFileUrl)
  }

  const imageCompressionRate = watch("imageCompressionRate")
  useEffectOnce(() => {
    compressExample(imageCompressionRate)
  })

  const generateLocalStoragePrefix = useCallback(() => {
    setValue("localStoragePrefix", getRandomName())
  }, [setValue])

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Miscellaneous</FormSectionHeader>

      <FormControl isInvalid={errors.localStoragePrefix}>
        <FormLabel>Local storage prefix</FormLabel>
        <InputGroup>
          <Input ref={register} name="localStoragePrefix" />
          <InputRightElement width="6rem">
            <Button
              h="1.75rem"
              size="sm"
              colorScheme="primary"
              onClick={generateLocalStoragePrefix}
            >
              Generate
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          {errors.localStoragePrefix && errors.localStoragePrefix.message}
        </FormErrorMessage>
      </FormControl>

      <FormSectionHeader>Image Processing</FormSectionHeader>
      <FormControl isInvalid={errors.imageCompressionIsEnabled}>
        <InputGroup>
          <Checkbox
            ref={register}
            name="imageCompressionIsEnabled"
            defaultChecked={!!entity?.imageCompressionIsEnabled}
          >
            Compress images before upload
          </Checkbox>
        </InputGroup>
        <FormErrorMessage>
          {errors.imageCompressionIsEnabled &&
            errors.imageCompressionIsEnabled.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.imageCompressionSizeThreashold}>
        <FormLabel>Compress images bigger than</FormLabel>
        <InputGroup>
          <Input
            isDisabled={!watch("imageCompressionIsEnabled")}
            type="number"
            ref={register()}
            name="imageCompressionSizeThreashold"
          />
          <InputRightElement children={"MB"} />
        </InputGroup>
        <FormErrorMessage>
          {errors.imageCompressionSizeThreashold &&
            errors.imageCompressionSizeThreashold.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.imageCompressionRate}>
        <FormLabel>Image compression rate</FormLabel>
        <InputGroup>
          <NumberInput
            defaultValue={entity?.imageCompressionRate}
            min={0}
            max={1}
            step={0.01}
            keepWithinRange={true}
            clampValueOnBlur={false}
          >
            <NumberInputField ref={register()} name="imageCompressionRate" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </InputGroup>
        <FormErrorMessage>
          {errors.imageCompressionRate && errors.imageCompressionRate.message}
        </FormErrorMessage>
      </FormControl>

      {watch("imageCompressionIsEnabled") && (
        <Box>
          <Box marginBottom={3}>Refresh the page to see updated example</Box>
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage src={fileUrl} alt="Original image" />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={currentCompressedFileUrl}
                alt="Compressed image"
              />
            }
            style={{ width: "100%" }}
          />
        </Box>
      )}
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsMiscForm
