import PrintServiceProductConfigurationTable from 'components/ProductConfiguration/PrintServiceProductConfigurationTable'
import { useEntity } from 'hooks/useEntity'
import { RouteParams } from 'navigation/RouteParams'
import React from 'react'
import { useParams } from 'react-router-dom'
import { PrintServiceEntity } from '@jackfruit/common'

interface Props {}

const AppPageBlockProductConfigurationListScreen: React.FC<Props> = () => {
  const { blockId, printServiceId } = useParams<RouteParams>()
  const { data: printService, isLoading } = useEntity<PrintServiceEntity>(
    'print-services',
    printServiceId
  )

  const title = isLoading ? 'loading...' : `${printService.displayName}`

  return (
    <PrintServiceProductConfigurationTable
      title={title}
      canGoBack={true}
      entityType="block"
      entityId={+blockId}
      printServiceId={+printServiceId}
    />
  )
}

export default AppPageBlockProductConfigurationListScreen
