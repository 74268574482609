import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react"
import React from "react"
import { Link, useLocation } from "react-router-dom"

interface Props {
  label: string
  icon: React.JSXElementConstructor<any>
  to?: string
  exactMatch?: boolean
}

const FirstLevelButton: React.FC<Props> = ({
  label,
  icon,
  children,
  to,
  exactMatch,
}) => {
  const location = useLocation()

  const hasChildren = Boolean(children)

  const isActive = exactMatch
    ? Boolean(to && location.pathname.endsWith(to))
    : Boolean(to && location.pathname.includes(to ?? ""))

  if (!hasChildren) {
    return (
      <Button
        as={Link}
        to={to ?? ""}
        w="full"
        py={3}
        px={4}
        height="auto"
        borderRadius="md"
        backgroundColor="transparent"
        isActive={isActive}
        _hover={{ backgroundColor: "primary.50", color: "primary.600" }}
        _active={{
          backgroundColor: "primary.50",
          color: "primary.600",
          shadow: "none",
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          w="full"
        >
          <Flex alignItems="center">
            <Icon as={icon} boxSize={6} />
            <Text ml={3} fontWeight="semibold">
              {label}
            </Text>
          </Flex>
        </Flex>
      </Button>
    )
  }

  return (
    <AccordionItem border="none">
      <AccordionButton
        py={3}
        px={4}
        borderRadius="md"
        _hover={{ backgroundColor: "primary.50", color: "primary.600" }}
        _expanded={{ backgroundColor: "primary.50", color: "primary.600" }}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          w="full"
        >
          <Flex alignItems="center">
            <Icon as={icon} boxSize={6} />
            <Text ml={3} fontWeight="semibold">
              {label}
            </Text>
          </Flex>
          <AccordionIcon boxSize={6} />
        </Flex>
      </AccordionButton>

      <AccordionPanel px={0}>
        <VStack alignItems="flex-start">{children}</VStack>
      </AccordionPanel>
    </AccordionItem>
  )
}

export default FirstLevelButton
