import { Box } from "@chakra-ui/react"
import { DeploymentEntity } from "@jackfruit/common"
import { inRange } from "lodash"
import React from "react"
import DeploymentStep, { StepStatus, StepType } from "./DeploymentStep"

const steps: StepType[] = ["setup", "build", "deploy", "completed", "test"]

const getStepForProgress = (progress: number): StepType => {
  let stepType: StepType = "setup"

  switch (true) {
    case inRange(progress, 0, 9):
      stepType = "setup"
      break
    case inRange(progress, 9, 72):
      stepType = "build"
      break
    case inRange(progress, 72, 100):
      stepType = "deploy"
      break
    case progress === 100:
      stepType = "completed"
      break
  }

  return stepType
}

const getStatusForStep = (
  stepType: StepType,
  currentStepType: StepType,
  isDeploymentFailed: boolean,
  isDeploymentCanceled: boolean,
  hasTestResults: boolean
): StepStatus => {
  const stepIndex = steps.indexOf(stepType)
  const currentIndex = steps.indexOf(currentStepType)
  const shouldMarkAsFailed = isDeploymentFailed || isDeploymentCanceled
  let status: StepStatus = "pending"

  if (stepIndex === currentIndex) {
    status = shouldMarkAsFailed ? "failed" : "processing"
  } else if (stepIndex < currentIndex) {
    status = "completed"
  } else if (stepIndex > currentIndex) {
    status = "pending"
  }

  if (stepType === "completed" && currentStepType === "completed") {
    status = "completed"
  }

  if (
    stepType === "test" &&
    currentStepType === "completed" &&
    !hasTestResults
  ) {
    status = "processing"
  }

  if (
    stepType === "test" &&
    currentStepType === "completed" &&
    hasTestResults
  ) {
    status = "completed"
  }

  if (stepType === "test" && shouldMarkAsFailed) {
    status = "failed"
  }

  return status
}

interface Props {
  deployment: DeploymentEntity
}

const DeploymentSteps: React.FC<Props> = ({ deployment }) => {
  const { status, progress, testResults } = deployment

  const isDeploymentFailed = status === "failed"
  const isDeploymentCanceled = status === "canceled"

  const hasTestResults = Boolean(testResults)
  const currentStep = getStepForProgress(progress)

  return (
    <Box w="full">
      <DeploymentStep
        stepType="setup"
        index={1}
        status={getStatusForStep(
          "setup",
          currentStep,
          isDeploymentFailed,
          isDeploymentCanceled,
          hasTestResults
        )}
      />
      <DeploymentStep
        stepType="build"
        index={2}
        status={getStatusForStep(
          "build",
          currentStep,
          isDeploymentFailed,
          isDeploymentCanceled,
          hasTestResults
        )}
      />
      <DeploymentStep
        stepType="deploy"
        index={3}
        status={getStatusForStep(
          "deploy",
          currentStep,
          isDeploymentFailed,
          isDeploymentCanceled,
          hasTestResults
        )}
      />
      <DeploymentStep
        stepType="completed"
        index={4}
        status={getStatusForStep(
          "completed",
          currentStep,
          isDeploymentFailed,
          isDeploymentCanceled,
          hasTestResults
        )}
      />
      <DeploymentStep
        stepType="test"
        index={5}
        status={getStatusForStep(
          "test",
          currentStep,
          isDeploymentFailed,
          isDeploymentCanceled,
          hasTestResults
        )}
        isLast={true}
      />
    </Box>
  )
}

export default DeploymentSteps
