import { Box } from '@chakra-ui/react'
import { BlockEntity, BlockOrderThankYouEntity } from '@jackfruit/common'
import { useEntity } from 'hooks/useEntity'
import React from 'react'
import { usePatchEntity } from 'hooks/usePatchEntity'
import BlockOrderThankYouForm from '../forms/BlockOrderThankYouForm'

interface Props {
  block: BlockEntity
}

const BlockOrderThankYou: React.FC<Props> = ({ block }) => {
  const { data: entity, isLoading } = useEntity<BlockOrderThankYouEntity>(
    'blocks-order-thank-you',
    block.childId
  )

  const {
    mutate: saveBlock,
    status: patchStatus,
  } = usePatchEntity<BlockOrderThankYouEntity>('blocks-order-thank-you')

  const isPatching = patchStatus === 'loading'

  const onSubmit = (data: any) => {
    saveBlock({ id: entity.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockOrderThankYouForm
      entity={entity}
      isLoading={isPatching}
      onSubmit={onSubmit}
    />
  )
}

export default BlockOrderThankYou
