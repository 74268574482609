import { AssetEntity } from "./AssetEntity"
import { BlocksTemplateProductEntityHydrated } from "./BlocksTemplateProductEntity"
import { CommonEntity } from "./CommonEntity"
import { DeploymentEntityHydrated } from "./DeploymentEntity"
import { Entity, EntityId } from "./Entity"
import { LambdaAbVariantEntityHydrated } from "./LambdaAbVariantEntity"
import { NavigationEntityHydrated } from "./NavigationEntity"
import { PageEntityHydrated } from "./PageEntity"
import { PrintServiceEntityHydrated } from "./PrintServiceEntity"
import { SettingsEntityHydrated } from "./SettingsEntity"
import { TerritoryEntityHydrated } from "./TerritoryEntity"

export type AvailableLanguages = "en-US" | "fr-CA" | "ja-JP" | "es-US"
export type MobileAppBannerVariant = "top" | "minimal" | "full"
export const ALL_AVAILABLE_LANGUAGES: AvailableLanguages[] = [
  "en-US",
  "fr-CA",
  "ja-JP",
  "es-US",
]

export const DEFAULT_LOCALE: AvailableLanguages = "en-US"
export interface AdditionalLocaleUrl {
  displayName: string
  url: string
}

export interface AppEntity extends Entity {
  name: string
  displayName: string
  slug: string
  baseUrl: string
  remoteApiUrl: string
  remoteApiClientApiKey: string
  termsAndConditionsPageId: EntityId
  privacyPolicyPageId: EntityId
  logoImageId: EntityId
  faviconImageId: EntityId
  primaryColor: string
  secondaryColor: string
  defaultLocale: string
  defaultCurrency: string
  additionalLocaleUrls: AdditionalLocaleUrl[]
  language: AvailableLanguages
  iosStoreUrl: string
  iosStoreId: string
  iosStoreName: string
  iosStoreRating: number
  iosStoreRatingCount: string
  iosDisplayStoreBannerOnMobile: boolean
  androidStoreUrl: string
  androidStoreName: string
  androidStoreRating: number
  androidStoreRatingCount: number
  androidDisplayStoreBannerOnMobile: boolean
  lastDeployedAt: string
  showMobileAppInstallBanner: boolean
  blockOrderSummaryAppInstallEnabled: boolean
  blockOrderSummaryAppInstallQrCode: boolean
  blockOrderSummaryAppInstallQrCodeUrl: string
  blockOrderSummaryAppInstallAppScreenImageId: EntityId
  blockOrderSummaryAppInstallContent: string
  openGraphTitle: string
  openGraphDescription: string
  openGraphUrl: string
  openGraphLogo: string
  openGraphSitemapXml: string
  storesPageTitle: string
  storesPageDescription: string
  accountPageTitle: string
  accountPageDescription: string
  registerPageTitle: string
  registerPageDescription: string
  forgotPasswordPageTitle: string
  forgotPasswordPageDescription: string
  changePasswordPageTitle: string
  changePasswordPageDescription: string
  facebookUrl: string
  instagramUrl: string
  youtubeUrl: string
  twitterUrl: string
  isStoresPagesEnabled: boolean
  mobileAppBannerVariant: MobileAppBannerVariant
  isLocked: boolean
}

export interface AppEntityHydrated extends AppEntity {
  pages: PageEntityHydrated[]
  navigations: NavigationEntityHydrated[]
  common: CommonEntity
  faviconImage: AssetEntity
  logoImage: AssetEntity
  settings: SettingsEntityHydrated
  printServices: PrintServiceEntityHydrated[]
  territories: TerritoryEntityHydrated[]
  blocksTemplateProducts: BlocksTemplateProductEntityHydrated[]
  lambdaAbVariants: LambdaAbVariantEntityHydrated[]
  deployment: DeploymentEntityHydrated
  blockOrderSummaryAppInstallAppScreenImage: AssetEntity
  configurationVersion: number
}
