import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Body from "components/Body"
import { useResourceForm } from "hooks/useResourceForm"
import { RouteParams } from "navigation/RouteParams"
import { PageEntity, PageSeoEntity } from "@jackfruit/common"
import { useCreateEntity } from "hooks/useCreateEntity"
import { Stack } from "@chakra-ui/react"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import AppPageGeneralForm from "./forms/AppPageGeneralForm"
import AppPageFulfillmentForm from "./forms/AppPageFulfillmentForm"
import AppPageForm from "./forms/AppPageForm"

interface Props {}

const AppPageCRUDScreen: React.FC<Props> = () => {
  const history = useHistory()
  const { appId, pageId } = useParams<RouteParams>()
  const { entity, isPatching, isLoading, onSubmit } =
    useResourceForm<PageEntity>("pages", parseInt(pageId))

  const { data: pageSeoEntity, isLoading: pageSeoEntityIsLoading } =
    useFindOneEntity<PageSeoEntity>("page-seo", {
      query: {
        pageId: pageId,
      },
    })

  const [query, setQuery] = useState({})

  const { mutate: savePageTerritories } = useCreateEntity(
    "page-territories",
    { showNotification: false },
    { ...query }
  )

  const enhancedOnSubmit = async (data: any) => {
    const {
      pickupTerritories,
      deliveryTerritories,
      defaultCountryPickup,
      defaultCountryDelivery,
      ...rest
    } = data

    const pageData = {
      ...rest,
      appId,
    }

    const page = await onSubmit(pageData)
    const savedPageId = page.id

    setQuery({
      query: {
        pageId: savedPageId,
      },
    })

    const pageTerritoriesPickup = pickupTerritories
      .split(",")
      .map((code: string) => {
        return {
          pageId: savedPageId,
          countryCode: code,
          isPickup: true,
          isDefault: code === defaultCountryPickup ? true : false,
        }
      })

    const pageTerritoriesDelivery = deliveryTerritories
      .split(",")
      .map((code: string) => {
        return {
          pageId: savedPageId,
          countryCode: code,
          isPickup: false,
          isDefault: code === defaultCountryDelivery ? true : false,
        }
      })

    savePageTerritories([...pageTerritoriesPickup, ...pageTerritoriesDelivery])

    if (!pageId) {
      history.push(`/admin/apps/${appId}/pages/${savedPageId}/edit`)
    }
  }

  //existing page or new page
  return pageId ? (
    <Stack spacing={4}>
      <Body isLoading={isLoading || pageSeoEntityIsLoading}>
        <AppPageGeneralForm
          entity={entity}
          pageSeoEntity={pageSeoEntity}
          isLoading={isPatching}
          onSubmit={onSubmit}
        />
      </Body>
      <Body isLoading={isLoading}>
        <AppPageFulfillmentForm
          entity={entity}
          isLoading={isPatching}
          onSubmit={enhancedOnSubmit}
        />
      </Body>
    </Stack>
  ) : (
    <Body isLoading={isLoading}>
      <AppPageForm
        entity={entity}
        isLoading={isPatching}
        onSubmit={enhancedOnSubmit}
      />
    </Body>
  )
}

export default AppPageCRUDScreen
