import PrintServiceConfigurationTable from "components/ProductConfiguration/PrintServiceConfigurationTable"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import { useParams } from "react-router-dom"

interface Props {}

const AppPagePrintServiceListScreen: React.FC<Props> = () => {
  const { pageId } = useParams<RouteParams>()

  return (
    <PrintServiceConfigurationTable
      title="Product Setup"
      entityId={+pageId}
      entityType="page"
    />
  )
}

export default AppPagePrintServiceListScreen
