import { Box, Flex, Text } from "@chakra-ui/react"
import React from "react"
import { FiChevronRight } from "react-icons/fi"
import { Link } from "react-router-dom"
import useBreadcrumbs from "use-react-router-breadcrumbs"
import {
  DynamicNavigationLinksLabel,
  DynamicNavigationsName,
  DynamicPagesName,
  DynamicPrintServicesName,
} from "./BreadcrumbDynamicNames"

const replacedRoutes = [
  {
    path: "/admin/apps/:appId/pages/:pageId",
    breadcrumb: DynamicPagesName,
  },
  {
    path: "/admin/apps/:appId/navigations/:navigationId",
    breadcrumb: DynamicNavigationsName,
  },
  {
    path: "/admin/apps/:appId/navigations/:navigationId/Navigation-links",
    breadcrumb: null,
  },
  {
    path: "/admin/apps/:appId/navigations/:navigationId/Navigation-links/:navigationLinkId",
    breadcrumb: DynamicNavigationLinksLabel,
  },
  {
    path: "/admin/apps/:appId/navigations/:navigationId/Navigation-links/:navigationLinkId/edit",
    breadcrumb: null,
  },
  {
    path: "/admin/apps/:appId/settings/products-setup/print-service-configurations",
    breadcrumb: null,
  },
  {
    path: "/admin/apps/:appId/settings/products-setup/print-service-configurations/:printServiceId",
    breadcrumb: DynamicPrintServicesName,
  },
  {
    path: "/admin/apps/:appId/pages/:pageId/product-setup/print-service-configurations",
    breadcrumb: null,
  },
  {
    path: "/admin/apps/:appId/pages/:pageId/product-setup/print-service-configurations/:printServiceId",
    breadcrumb: DynamicPrintServicesName,
  },
  {
    path: "/admin/apps/:appId/pages/:pageId/blocks/:blockId",
    breadcrumb: null,
  },
  {
    path: "/admin/apps/:appId/pages/:pageId/blocks/:blockId/product-configuration/print-service-configurations",
    breadcrumb: null,
  },
  {
    path: "/admin/apps/:appId/pages/:pageId/blocks/:blockId/product-configuration/print-service-configurations/:printServiceId",
    breadcrumb: DynamicPrintServicesName,
  },
]

interface Props {}

const BreadCrumbs: React.FC<Props> = () => {
  const breadcrumbs = useBreadcrumbs(replacedRoutes)
  const withoutHome = breadcrumbs.slice(4, breadcrumbs.length)
  const lastIndex = withoutHome.length - 1

  return (
    <Flex direction="row" alignItems="center" color="gray.500">
      {withoutHome.map(({ match, breadcrumb }, index) => {
        return (
          <BreadCrumb
            key={match.url}
            to={match.url}
            isCurrent={index === lastIndex}
          >
            {breadcrumb}
          </BreadCrumb>
        )
      })}
    </Flex>
  )
}

interface BreadCrumbProp {
  to: string
  isCurrent: boolean
}

const BreadCrumb: React.FC<BreadCrumbProp> = ({ to, isCurrent, children }) => {
  return (
    <>
      <Link key={to} to={to}>
        <span>
          <Text fontSize="sm" mr={2}>
            {children}
          </Text>
        </span>
      </Link>
      {!isCurrent && (
        <Box mr={2}>
          <FiChevronRight />
        </Box>
      )}
    </>
  )
}

export default BreadCrumbs
