import React from "react"
import { useKey } from "react-use"

interface Props {
  onEnter: (e: KeyboardEvent) => void
  onEscape: (e: KeyboardEvent) => void
  onArrowUp: (e: KeyboardEvent) => void
  onArrowDown: (e: KeyboardEvent) => void
}

const AutoCompleteInputKeyboardHandler: React.FC<Props> = ({
  onArrowDown,
  onArrowUp,
  onEnter,
  onEscape,
}) => {
  useKey("Escape", onEscape, {}, [onEscape])
  useKey("Enter", onEnter, {}, [onEnter])
  useKey("ArrowUp", onArrowUp, {}, [onArrowUp])
  useKey("ArrowDown", onArrowDown, {}, [onArrowDown])

  return null
}

export default AutoCompleteInputKeyboardHandler
