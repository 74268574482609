import { Box, Link, Text, useUpdateEffect } from "@chakra-ui/react"
import { NavigationLinkEntity, PageEntity } from "@jackfruit/common"
import TableRowActions from "components/TableRowActions"
import { useUpdateDebounce } from "hooks/useUpdateDebounce"
import { RouteParams } from "navigation/RouteParams"
import React, { useCallback, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import SortableTree, { getDescendantCount, TreeItem } from "react-sortable-tree"
import { getEntitiesFromTree, getTreeFromEntities } from "services/tree"
import "./AppNavigationTree.scss"

interface Props {
  links: NavigationLinkEntity[]
  pages: PageEntity[]
  onSubmit: (data: NavigationLinkEntity[]) => void
}

const AppNavigationTree: React.FC<Props> = ({ links, pages, onSubmit }) => {
  const { appId } = useParams<RouteParams>()

  const tree = useMemo(
    () => getTreeFromEntities<NavigationLinkEntity>(links),
    [links]
  )
  const [treeData, setTreeData] = useState<TreeItem[]>(tree)
  const [currentVersion, setCurrentVersion] = useState(0)

  const handleUpdateNavigationTreeData = useCallback((newTree: TreeItem[]) => {
    setTreeData(newTree)
    setCurrentVersion(v => v + 1)
  }, [])

  const enhancedOnSubmit = useCallback(() => {
    const flatData = getEntitiesFromTree<NavigationLinkEntity>(treeData)
    onSubmit(flatData)
  }, [treeData, onSubmit])

  useUpdateEffect(() => {
    setTreeData(tree)
  }, [links])

  useUpdateDebounce(
    () => {
      enhancedOnSubmit()
    },
    500,
    [currentVersion]
  )

  const getPageName = (pageId: number) => {
    const found = pages.find(
      page => page.id === pageId && page.status === "active"
    ) as PageEntity

    if (found) {
      return (
        <Link href={`/admin/apps/${appId}/pages/${pageId}`}>{found?.name}</Link>
      )
    }
    return ""
  }

  const generateNodeProps = (data: any) => {
    const { node } = data
    const hadDescendent =
      getDescendantCount({ node, ignoreCollapsed: false }) > 0

    let label: any = ""
    switch (node.type) {
      case "text":
        label = ` - ${node.label}`
        break
      case "page":
        const pageDetail = getPageName(node.pageId) || "(No Name)"
        label = (
          <>
            {" - "}
            {pageDetail}
          </>
        )
        break
      case "link":
        label = ` - ${node.path}`
        break
    }

    return {
      title: (
        <Box>
          <Text fontWeight="light">{node.label}</Text>
          <Text fontSize="sm">
            {node.type} {label}
          </Text>
        </Box>
      ),
      buttons: [
        <TableRowActions
          resource="navigation-links"
          entity={node}
          canView={false}
          canEdit={true}
          canDelete={!hadDescendent}
        />,
      ],
    }
  }

  return (
    <Box>
      <Box mb={4}>
        <SortableTree
          isVirtualized={false}
          treeData={treeData}
          rowHeight={75}
          onChange={handleUpdateNavigationTreeData}
          generateNodeProps={generateNodeProps}
        />
      </Box>
    </Box>
  )
}

export default AppNavigationTree
