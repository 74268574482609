import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import { BlockOrderCompleteEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"

interface Props {
  entity: BlockOrderCompleteEntity
  isLoading: boolean
  onSubmit: (data: BlockOrderCompleteEntity) => void
}

const BlockOrderCompleteForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const { handleSubmit, register } = useForm<BlockOrderCompleteEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <Checkbox ref={register()} name="upsellEnabled">
          Enable upsell section
        </Checkbox>
      </FormControl>

      <FormControl>
        <FormLabel>Upsell Title (leave blank for default)</FormLabel>
        <InputGroup>
          <Input type="text" name="upsellTitle" ref={register()} />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Upsell Content ID (from server)</FormLabel>
        <InputGroup>
          <Input type="text" name="upsellContentId" ref={register()} />
        </InputGroup>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default BlockOrderCompleteForm
