import { Box, BoxProps, Circle, Image } from "@chakra-ui/react"
import React from "react"

interface ImageProps {
  src: string
  alt?: string
  srcthumbnail: string
}

interface indicatorStyleProps extends BoxProps {
  borderFocusColor: string
}

interface Props {
  isMobile: boolean
  image: ImageProps
  currentIndex: number
  index: number
  thumbnailStyle: indicatorStyleProps
  circleStyle: indicatorStyleProps
  mobileThumbnailType: string
}

const Indicator: React.FC<Props> = ({
  isMobile,
  image,
  currentIndex,
  index,
  thumbnailStyle,
  circleStyle,
  mobileThumbnailType,
}) => {
  const thumbnailColor = {
    color: thumbnailStyle.borderColor,
    focus: thumbnailStyle.borderFocusColor,
  }

  const circleIndicatorColor = {
    color: circleStyle.borderColor,
    focus: circleStyle.borderFocusColor,
  }

  if (isMobile && mobileThumbnailType === "circle") {
    return (
      <Circle
        aria-label="point indicator"
        key={`circle-${index}`}
        data-testid={`test-carousel-thumbnail-${index}`}
        size="8px"
        mx="2"
        bgColor={
          currentIndex === index
            ? circleIndicatorColor.focus
            : circleIndicatorColor.color
        }
        cursor="pointer"
        {...circleStyle}
      />
    )
  }

  return (
    <Box
      aria-label="image indicator"
      key={`image-${index}`}
      data-testid={`test-image-carousel-thumbnail-${index}`}
      mr={2}
      cursor="pointer"
      borderWidth={2}
      bgColor="white"
      position="relative"
      width="80px"
      height="60px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      {...thumbnailStyle}
      borderColor={
        currentIndex === index ? thumbnailColor.focus : thumbnailColor.color
      }
    >
      <Image
        className="thumbnail"
        src={image.srcthumbnail}
        alt={image.alt}
        objectFit="cover"
        width="100%"
        height="100%"
      />
    </Box>
  )
}

export default Indicator
