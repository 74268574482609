import { Flex } from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import React from "react"
import AppCard from "./AppCard"

interface Props {
  apps: AppEntity[]
}

const AppGrid: React.FC<Props> = ({ apps }) => {
  return (
    <Flex flexWrap="wrap" maxWidth="1680px" aria-label="AppGrid">
      {apps.map(app => {
        return <AppCard app={app} key={app.id} />
      })}
    </Flex>
  )
}

export default AppGrid
