import React from "react"
import Body from "components/Body"
import { useParams } from "react-router-dom"
import { useAppCommon } from "../hooks/useAppCommon"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import AppCommonFooterGeneralForm from "../forms/AppCommonFooterGeneralForm"
import AppCommonFooterColumnsForm from "../forms/AppCommonFooterColumnsForm"
import { RouteParams } from "navigation/RouteParams"
import { BlockEntity } from "@jackfruit/common"
import { Stack } from "@chakra-ui/react"

interface Props {}

const AppCommonFooterScreen: React.FC<Props> = () => {
  const { appId } = useParams<RouteParams>()
  const {
    entity: commonEntity,
    isLoading: commonEntityIsLoading,
    onSubmit,
  } = useAppCommon(parseInt(appId))

  const { data: blockEntity, isLoading: blockEntityIsLoading } =
    useFindOneEntity<BlockEntity>("blocks", {
      query: {
        appId,
        pageId: { $null: true },
        type: "footer",
        $eager: "[footer]",
      },
    })

  const isLoading = commonEntityIsLoading || blockEntityIsLoading

  return (
    <Stack spacing={4}>
      <Body isLoading={isLoading}>
        <AppCommonFooterGeneralForm
          entity={commonEntity}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      </Body>
      <Body isLoading={isLoading}>
        <AppCommonFooterColumnsForm
          entity={commonEntity}
          blockEntity={blockEntity}
          isLoading={isLoading}
        />
      </Body>
    </Stack>
  )
}

export default AppCommonFooterScreen
