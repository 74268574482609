import { appRevisionAtom } from "atoms"
import { useRecoilState } from "recoil"

export const useAppRevision = () => {
  const [appRevision, setAppRevision] = useRecoilState(appRevisionAtom)

  const bumpRevision = () => {
    setAppRevision(prev => prev + 1)
  }

  return { appRevision, bumpRevision }
}
