import React from 'react'
import { Box } from '@chakra-ui/react'
import { useHasOneResourceForm } from 'hooks/useHasOneResourceForm'
import BlockFooterForm from '../forms/block-footer/BlockFooterForm'
import { BlockEntity, BlockFooterEntity } from '@jackfruit/common'
interface Props {
  block: BlockEntity
  readOnly?: boolean
}

const BlockFooter: React.FC<Props> = ({ block, readOnly = false }) => {
  const {
    entity,
    isLoading,
    onSubmit,
  } = useHasOneResourceForm<BlockFooterEntity>(
    'blocks-footer',
    'blockId',
    block.id
  )

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockFooterForm
      entity={entity}
      isLoading={isLoading}
      onSubmit={onSubmit}
      readOnly={readOnly}
    />
  )
}

export default BlockFooter
