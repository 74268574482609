import {
  Flex,
  Button,
  IconButton,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react"
import { useUploadAsset } from "hooks/useUploadAsset"
import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { FiCopy, FiUpload } from "react-icons/fi"

interface Props {}

const CpFileUpload: React.FC<Props> = () => {
  const { upload } = useUploadAsset()
  const [isUploading, setIsUploading] = useState(false)
  const [filePath, setFilePath] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const { onCopy } = useClipboard(filePath)

  const onDrop = useCallback(
    async files => {
      try {
        const file: File = files[0]
        setIsUploading(true)
        const newAsset = await upload({
          file,
        })
        setFilePath(newAsset.path)
        setIsUploading(false)
        setErrorMessage("")
      } catch (error: any) {
        console.error(`File upload failed.`)
        setErrorMessage(error.message)
      }
    },
    [upload]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    noClick: true,
    multiple: false,
    onDrop,
  })

  const tooltipText = errorMessage ? errorMessage : filePath ? filePath : ""

  const buttonText = errorMessage
    ? "Upload Error..."
    : filePath
    ? filePath
    : "Upload Files..."

  return (
    <Flex {...getRootProps()} w={409} minW={0}>
      <input {...getInputProps()} />
      <Tooltip hasArrow label={tooltipText}>
        <Button
          justifyContent="left"
          h={35}
          flexGrow={1}
          isLoading={isUploading}
          loadingText="Uploading"
          onClick={open}
          overflow="hidden"
          roundedLeft="md"
          roundedRight="none"
          leftIcon={<FiUpload />}
        >
          <Text textAlign="left" isTruncated fontSize="sm">
            {buttonText}
          </Text>
        </Button>
      </Tooltip>
      <Tooltip label="Copy file path">
        <IconButton
          h={35}
          aria-label="Copy Link"
          icon={<FiCopy />}
          onClick={onCopy}
          roundedLeft="none"
          roundedRight="md"
          disabled={isUploading}
        />
      </Tooltip>
    </Flex>
  )
}

export default CpFileUpload
