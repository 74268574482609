import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Select,
} from "@chakra-ui/react"
import { SettingsEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"

interface Props {
  entity?: SettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppSettingsServicesMiscellaneousForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }

  const { handleSubmit, register, errors, watch } = useForm({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Miscellaneous</FormSectionHeader>

      <FormControl isInvalid={errors.mapProvider}>
        <FormLabel>Maps Provider</FormLabel>
        <InputGroup>
          <Select ref={register()} name="mapProvider">
            <option value="googleMaps">Google Maps</option>
            <option value="amazonLocation">Amazon Location Services</option>
          </Select>
        </InputGroup>
        <FormErrorMessage>
          {errors.mapProvider && errors.mapProvider.message}
        </FormErrorMessage>
      </FormControl>

      {watch("mapProvider") === "googleMaps" && (
        <FormControl isInvalid={errors.googleMapKey}>
          <FormLabel>Google Maps Key</FormLabel>
          <InputGroup>
            <Input ref={register} name="googleMapKey" type="text" />
          </InputGroup>
          <FormErrorMessage>
            {errors.googleMapKey && errors.googleMapKey.message}
          </FormErrorMessage>
        </FormControl>
      )}
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsServicesMiscellaneousForm
