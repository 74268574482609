import { Stack } from "@chakra-ui/react"
import Body from "components/Body"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import { useParams } from "react-router-dom"
import { useAppSettings } from "./hooks/useAppSettings"

interface Props {
  form: React.FC<any>
}

const AppCommonCRUDScreen: React.FC<Props> = ({ form }) => {
  const { appId } = useParams<RouteParams>()
  const { entity, isLoading, isPatching, isCreating, onSubmit } =
    useAppSettings(parseInt(appId))

  const formComponent = React.createElement(form, {
    entity,
    isLoading: isCreating || isPatching,
    onSubmit,
  })

  return (
    <Stack spacing={4}>
      <Body isLoading={isLoading}>{formComponent}</Body>
    </Stack>
  )
}

export default AppCommonCRUDScreen
