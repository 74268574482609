import React from 'react'
import { Box } from '@chakra-ui/react'
import { BlockEntity } from '@jackfruit/common'

interface Props {
  block: BlockEntity
}

const BlockDefault: React.FC<Props> = ({ block }) => {
  return <Box>Undefined Block Type</Box>
}

export default BlockDefault
