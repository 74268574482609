import { Tab } from "@chakra-ui/react"
import React from "react"

interface Props {
  rowsDef: number[]
}

const BlockFooterRows: React.FC<Props> = ({ rowsDef }) => {
  return (
    <>
      {rowsDef.map((rows, index) => (
        <Tab key={index}>Row {index + 1}</Tab>
      ))}
    </>
  )
}

export default React.memo(BlockFooterRows)
