import { Box, Flex, Text, VStack } from "@chakra-ui/react"
import { useAuthState } from "hooks/useAuthState"
import React from "react"
import { FiClipboard, FiFeather, FiSettings, FiUsers } from "react-icons/fi"
import { RxRocket } from "react-icons/rx"
import { Link } from "react-router-dom"
import { ReactComponent as APLogo } from "./APLogo.svg"
import { ReactComponent as APLogoFull } from "./APLogoFull.svg"
import MainSidebarButton from "./MainSidebarButton"

export const MainSidebarContext = React.createContext({ expanded: true })

interface Props {
  expanded: boolean
}

const MainSidebar: React.FC<Props> = ({ expanded }) => {
  const {
    user: { role },
  } = useAuthState()

  return (
    <MainSidebarContext.Provider value={{ expanded }}>
      <Flex
        width="full"
        direction="column"
        borderWidth={0}
        borderColor="orange"
        bg="gray.800"
        justifyContent="space-between"
        height="100vh"
        bgGradient={`linear(to-b, gradient.top, gradient.bottom)`}
      >
        <Box as={Link} to="/admin/apps" top={0} left={0} w="full" py={6} px={3}>
          {expanded ? (
            <APLogoFull height="35px" fill="white" viewBox=" 75 0 35 35" />
          ) : (
            <APLogo height="35px" fill="white" />
          )}
        </Box>
        <VStack spacing={2} mt={3.5} mx={expanded ? 0 : 3} mb="auto">
          <MainSidebarButton
            icon={<FiFeather />}
            label="Applications"
            to="/admin/apps"
          />
          <MainSidebarButton
            icon={<RxRocket stroke="transparent" />}
            label="Deployments"
            to="/admin/deployments"
          />
          <MainSidebarButton
            icon={<FiSettings />}
            label="Settings"
            to="/admin/settings"
          />
          <MainSidebarButton
            icon={<FiClipboard />}
            label="Snippets"
            to="/admin/snippets"
          />
          {role === "admin" && (
            <MainSidebarButton
              icon={<FiUsers />}
              label="Users"
              to="/admin/users"
            />
          )}
        </VStack>
        <Box py={2.5} textAlign="center" width="full">
          <Text color="white" fontSize="xs">
            v{process.env.REACT_APP_VERSION}
          </Text>
        </Box>
      </Flex>
    </MainSidebarContext.Provider>
  )
}

export default MainSidebar
