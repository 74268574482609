import { Box, Flex } from "@chakra-ui/layout"
import { EntityId } from "@jackfruit/common"
import { useAppRevision } from "hooks/useAppRevision"
import React from "react"
import { getStorybookPreviewUrl } from "services/storybook"
import DeviceMockup from "./DeviceMockup"
import { tabletDevicesMockupConfig } from "./deviceMockupConfig"

interface Props {
  appId: EntityId
  pageId: EntityId
  lang?: string
}

const AppPreviewTabletDevices: React.FC<Props> = ({
  appId,
  pageId,
  lang = "en-US",
}) => {
  const { appRevision } = useAppRevision()
  const url = getStorybookPreviewUrl({ appId, pageId, lang })

  return (
    <Flex w="full" key={appRevision} wrap="wrap" justifyContent="center">
      {tabletDevicesMockupConfig.map((device, index) => {
        return (
          <Box key={index} mx={3} mb={6}>
            <DeviceMockup config={device} url={url} scale={0.4} />
          </Box>
        )
      })}
    </Flex>
  )
}

export default AppPreviewTabletDevices
