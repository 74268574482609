import { Flex, Heading, IconButton, Stack } from "@chakra-ui/react"
import React, { useCallback } from "react"
import { FiArrowLeft } from "react-icons/fi"
import { useHistory } from "react-router-dom"

interface Props {
  canGoBack?: boolean
  goBackPath?: string
  title: string
  actions: React.ReactNode
}

const PanelHeader: React.FC<Props> = ({
  canGoBack = false,
  goBackPath = "",
  title,
  actions,
}) => {
  const history = useHistory()
  const onGoBack = useCallback(() => {
    Boolean(goBackPath) ? history.push(goBackPath) : history.goBack()
  }, [goBackPath, history])

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      borderBottomWidth={1}
      borderColor="gray.100"
      pb={4}
      mb={4}
    >
      <Stack direction="row" alignItems="center" spacing={3}>
        {canGoBack && (
          <IconButton
            variant="ghost"
            onClick={onGoBack}
            borderRadius="full"
            icon={<FiArrowLeft />}
            aria-label="go back"
          />
        )}
        <Heading as="h3" fontSize="xl" color="gray.600">
          {title}
        </Heading>
      </Stack>
      {actions}
    </Flex>
  )
}

export default PanelHeader
