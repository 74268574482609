import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import { BlockStoreSearchEntity } from "@jackfruit/common"
import BlockStoreSearchFields from "./BlockStoreSearchFields"

interface Props {
  entity: BlockStoreSearchEntity
  isLoading: boolean
  readOnly: boolean
  onSubmit: (data: BlockStoreSearchEntity) => void
}

const BlockStoreSearchForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
  readOnly = false,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<BlockStoreSearchEntity>({ defaultValues })
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <BlockStoreSearchFields />

        <FormActions
          canCancel={false}
          isLoading={isLoading}
          entity={entity}
          readOnly={readOnly}
        />
      </FormContainer>
    </FormProvider>
  )
}

export default BlockStoreSearchForm
