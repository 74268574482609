import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react"
import { NavigationMegaMenuColumnEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { useForm } from "react-hook-form"
import { useQueryClient } from "react-query"

interface Props extends Omit<ModalProps, "children"> {
  column: NavigationMegaMenuColumnEntity
}

const MegaMenuColumnEditModal: React.FC<Props> = ({
  column,
  onClose,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const { mutateAsync: patchColumn } =
    usePatchEntity<NavigationMegaMenuColumnEntity>(
      "navigation-megamenu-columns"
    )

  const { handleSubmit, register, reset } = useForm({
    defaultValues: column,
  })

  const enhancedSubmit = async (data: NavigationMegaMenuColumnEntity) => {
    await patchColumn({ id: column.id, title: data.title })
    queryClient.invalidateQueries(["navigation-megamenus"])
    reset({ title: data.title })
    onClose()
  }

  return (
    <Modal {...modalProps} onClose={onClose}>
      <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit column</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input type="text" ref={register} name="title" />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end" width="full">
              <HStack>
                <Button onClick={onClose}>Cancel</Button>
                <Button colorScheme="blue" type="submit">
                  Save
                </Button>
              </HStack>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </FormContainer>
    </Modal>
  )
}

export default MegaMenuColumnEditModal
