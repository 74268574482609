import React from "react"
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts"
import { useTheme } from "@chakra-ui/react"

interface BarChartProps {
  data: any[]
}

const BarChartComponent: React.FC<BarChartProps> = ({ data }) => {
  const columns = data.length > 0 ? Object.keys(data[0]) : []
  const XAxisColumn = columns[0]
  const yColumns = columns.slice(1)
  const theme = useTheme()
  const colors = [
    theme.colors.red[500],
    theme.colors.blue[500],
    theme.colors.green[500],
    theme.colors.yellow[500],
    theme.colors.purple[500],
    theme.colors.teal[500],
    theme.colors.orange[500],
    theme.colors.pink[500],
    theme.colors.cyan[500],
    theme.colors.gray[500],
  ]

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart margin={{ top: 5, right: 20, left: 5, bottom: 10 }} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={XAxisColumn} />
        <YAxis />
        <Tooltip />
        {yColumns.map((column, index) => (
          <Bar
            key={column}
            dataKey={column}
            fill={colors[index % colors.length]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarChartComponent
