import { Flex } from "@chakra-ui/layout"
import React from "react"

interface Props {
  label: React.ReactNode
  oldValue: React.ReactNode
  newValue: React.ReactNode
}

const ChangelogRow: React.FC<Props> = ({ label, newValue, oldValue }) => {
  return (
    <Flex flex={1}>
      <Flex flex={1} alignItems="center">
        {label}
      </Flex>
      <Flex flex={1} alignItems="center">
        {oldValue}
      </Flex>
      <Flex flex={1} alignItems="center">
        {newValue}
      </Flex>
    </Flex>
  )
}

export default ChangelogRow
