import { EntityId } from "@jackfruit/common"

interface GetStorybookPreviewUrlParams {
  appId: EntityId
  pageId: EntityId
  lang?: string
}

export const getStorybookPreviewUrl = ({
  appId,
  pageId,
  lang = "en-US",
}: GetStorybookPreviewUrlParams) => {
  return `${process.env.REACT_APP_STORYBOOK_URL}/iframe.html?globals=locale:${lang}&id=webapp-pageblocklayout--page&viewMode=story&appId=${appId}&pageId=${pageId}&secret=${process.env.REACT_APP_STORYBOOK_SECRET}`
}
