import { Entity } from "@jackfruit/common"
import {
  getFlatDataFromTree,
  getTreeFromFlatData,
  TreeItem,
} from "react-sortable-tree"

interface ParentableEntity extends Entity {
  parentId: number
}

export const getTreeFromEntities = <T extends ParentableEntity>(
  entities: T[]
): TreeItem[] => {
  const entitiesWithRootKey = entities.map(entity => {
    const entityWithrootKey = {
      ...entity,
      expanded: true,
      parentId: entity.parentId || 0,
    }
    return entityWithrootKey
  })

  const treeData = getTreeFromFlatData({
    flatData: entitiesWithRootKey,
    rootKey: 0,
  })

  return treeData
}

export const getEntitiesFromTree = <T>(treeData: TreeItem[]): T[] => {
  const flatData = getFlatDataFromTree<any>({
    treeData,
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
  }).map(({ node, path }, index) => {
    // remove title added by sortable tree
    // warning, this make entities with title attribute unusable in the tree
    const { title, expanded, createdAt, updatedAt, ...rest } = node
    return {
      ...rest,
      order: index,
      parentId: path.length > 1 ? path[path.length - 2] : null,
    }
  }) as any

  return flatData
}
