import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api, { Credentials } from "services/api"
import { AsyncState } from "./interfaces"

export interface AuthState extends AsyncState {
  user: {
    id: number
    createdAt: string
    updatedAt: string
    email: string
    role: string
  }
}

const login = createAsyncThunk(
  "auth/login",
  async (credentials: Credentials) => {
    return await api.login(credentials)
  }
)

const logout = createAsyncThunk("auth/logout", async () => {
  return await api.logout()
})

const initialState: AuthState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  user: {
    id: 0,
    createdAt: "",
    updatedAt: "",
    email: "",
    role: "",
  },
}

const auth = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.isLoading = true
      state.isError = false
      state.isSuccess = false
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = true
      state.user = action.payload.user
    })
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.isSuccess = false
      state.errorMessage = action.error.message
    })
    builder.addCase(logout.pending, state => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
    })
    builder.addCase(logout.fulfilled, state => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.user = {
        id: 0,
        createdAt: "",
        updatedAt: "",
        email: "",
        role: "",
      }
    })
    builder.addCase(logout.rejected, state => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
    })
  },
})

export { auth, login, logout }
