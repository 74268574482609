import { Checkbox, FormControl, InputGroup } from "@chakra-ui/react"
import { PageEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { useForm } from "react-hook-form"

interface Props {
  entity: PageEntity
  isLoading: boolean
  onSubmit: (data: any) => void
}

const AppPageMobileBannerForm: React.FC<Props> = ({
  entity,
  isLoading,
  onSubmit,
}) => {
  const methods = useForm({ defaultValues: { ...entity } })
  const { handleSubmit, register, errors } = methods

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>
        Mobile App-Install Banner Visibility
      </FormSectionHeader>
      <FormControl isInvalid={!!errors.hideMobileAppInstallBanner}>
        <InputGroup>
          <Checkbox
            ref={register}
            name="hideMobileAppInstallBanner"
            defaultChecked={entity?.hideMobileAppInstallBanner}
          >
            Hide mobile app-install banner
          </Checkbox>
        </InputGroup>
      </FormControl>
      <FormActions isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppPageMobileBannerForm
