import { Text } from "@chakra-ui/layout"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { Resources } from "services/api"
import ChangelogFieldBadge from "./ChangelogFieldBadge"
import ChangelogFieldBoolean from "./ChangelogFieldBoolean"
import ChangelogFieldColor from "./ChangelogFieldColor"
import ChangelogFieldDate from "./ChangelogFieldDate"
import ChangelogFieldHTML from "./ChangelogFieldHTML"
import ChangelogFieldPageId from "./ChangelogFieldPageId"

interface Props {
  entityType: Resources
  entityId: EntityId
  field: string
  value: string | number | Date | EntityId
}

const ChangelogField: React.FC<Props> = ({ field, value }) => {
  if (field.match(/is[A-Z]/)) {
    return <ChangelogFieldBoolean value={Boolean(value)} />
  }
  if (["hideMobileAppInstallBanner"].includes(field)) {
    return <ChangelogFieldBoolean value={Boolean(value)} />
  }
  if (field.match(/has[A-Z]/)) {
    return <ChangelogFieldBoolean value={Boolean(value)} />
  }
  if (field.match(/.*PageId$/)) {
    return <ChangelogFieldPageId value={value as EntityId} />
  }
  if (field.match(/.*[a-z]At$/)) {
    return <ChangelogFieldDate value={value as Date} />
  }
  if (["status"].includes(field)) {
    return <ChangelogFieldBadge value={value as string} />
  }
  if (field.match(/.*[a-z]Status$/)) {
    return <ChangelogFieldBadge value={value as string} />
  }
  if (field.match(/.*[a-z]Type$/)) {
    return <ChangelogFieldBadge value={value as string} />
  }
  if (field.match(/.*[a-z]Fulfillment$/)) {
    return <ChangelogFieldBadge value={value as string} />
  }
  if (field.match(/.*[a-z]Color$/)) {
    return <ChangelogFieldColor value={value as string} />
  }
  if (field.match(/.*html$/)) {
    return <ChangelogFieldHTML value={value as string} />
  }

  return <Text fontSize="sm">{JSON.stringify(value)}</Text>
}

export default ChangelogField
