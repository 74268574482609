import { Entity, EntityId } from "@jackfruit/common"
import { Resources } from "services/api"
import { useAppRevision } from "./useAppRevision"
import { useHasOneResource } from "./useHasOneResource"
import { useResourceActions } from "./useResourceActions"

const useHasOneResourceForm = <T extends Entity>(
  resource: Resources,
  reference: string,
  referenceId: EntityId,
  params: any = {}
) => {
  const { bumpRevision } = useAppRevision()
  const { isLoading, entity, setEntity } = useHasOneResource<T>(
    resource,
    reference,
    referenceId,
    params
  )
  const { isPatching, patch, isCreating, create } = useResourceActions(
    resource,
    setEntity
  )

  const onSubmit = async (data: any): Promise<T> => {
    if (entity.id) {
      const patched = await patch(entity.id, data, params)
      bumpRevision()
      return patched
    } else {
      const created = await create(
        { ...data, [reference]: referenceId },
        params
      )
      return created
    }
  }

  return {
    entity,
    isLoading,
    isPatching,
    isCreating,
    patch,
    create,
    onSubmit,
  }
}

export { useHasOneResourceForm }
