import { Box } from "@chakra-ui/react"
import { BlockEntity, BlockVideoEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import BlockVideoForm from "../forms/BlockVideoForm"

interface Props {
  block: BlockEntity
}

const BlockVideo: React.FC<Props> = ({ block }) => {
  const { data: entity, isLoading } = useEntity<BlockVideoEntity>(
    "blocks-video",
    block.childId
  )

  const { mutate: saveBlock, status: patchStatus } =
    usePatchEntity<BlockVideoEntity>("blocks-video")

  const isPatching = patchStatus === "loading"

  const onSubmit = (data: any) => {
    saveBlock({ id: entity.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockVideoForm
      entity={entity}
      isLoading={isPatching}
      onSubmit={onSubmit}
    />
  )
}

export default BlockVideo
