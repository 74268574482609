import { Stack } from "@chakra-ui/react"
import { BlockEntity, BlockHeaderEntity } from "@jackfruit/common"
import Body from "components/Body"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import { useParams } from "react-router-dom"
import AppCommonHeaderSideWideBannerForm from "../forms/AppCommonHeaderSideWideBannerForm"

interface Props {}

const AppCommonBlockScreen: React.FC<Props> = () => {
  const { appId } = useParams<RouteParams>()
  const { data: blockEntity, isLoading } = useFindOneEntity<
    BlockEntity & { header: BlockHeaderEntity }
  >("blocks", {
    query: {
      appId,
      pageId: { $null: true },
      type: "header",
      $eager: "[header]",
    },
  })

  const { mutate: saveBlockHeader, isLoading: isPatching } =
    usePatchEntity<BlockHeaderEntity>("blocks-header")

  const onSubmit = (data: any) => {
    const blockHeader = { id: blockEntity?.header?.id, ...data }
    saveBlockHeader(blockHeader)
  }

  return (
    <Stack spacing={4}>
      <Body isLoading={isLoading}>
        <AppCommonHeaderSideWideBannerForm
          entity={blockEntity}
          isLoading={isPatching}
          onSubmit={onSubmit}
        />
      </Body>
    </Stack>
  )
}

export default AppCommonBlockScreen
