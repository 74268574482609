import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import FormActions from "forms/FormActions"
import FormContainer from "components/FormContainer"
import BlockFooterFields from "./BlockFooterFields"
import { BlockFooterEntity } from "@jackfruit/common"

interface Props {
  entity: BlockFooterEntity
  isLoading: boolean
  readOnly?: boolean
  onSubmit: (data: BlockFooterEntity) => void
}

const BlockFooterForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
  readOnly = false,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<BlockFooterEntity>({ defaultValues })
  const { handleSubmit } = methods

  const enhancedSubmit = (data: BlockFooterEntity) => {
    onSubmit(data)
  }
  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
        <BlockFooterFields readOnly={readOnly} entity={entity} prefix={false} />

        <FormActions
          canCancel={false}
          isLoading={isLoading}
          entity={entity}
          readOnly={readOnly}
        />
      </FormContainer>
    </FormProvider>
  )
}

export default BlockFooterForm
