import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  Checkbox,
  FormLabel,
  Select,
} from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import FormActions from "forms/FormActions"
import FormSectionHeader from "components/FormSectionHeader"
import FormContainer from "components/FormContainer"
import { EntityFormProps } from "forms/EntityFormProps"
import { AppEntity } from "@jackfruit/common"

interface Props extends EntityFormProps<AppEntity> {}

const AppBrandingGeneralAppVisibilityForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }
  const { handleSubmit, register, errors } = useForm<AppEntity>({
    defaultValues,
  })
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>App-Install Block - General</FormSectionHeader>
      <FormControl isInvalid={!!errors.showMobileAppInstallBanner}>
        <InputGroup>
          <Checkbox
            ref={register}
            name="showMobileAppInstallBanner"
            defaultChecked={entity?.showMobileAppInstallBanner}
          >
            Show mobile app-install banner on header
          </Checkbox>
        </InputGroup>
        <FormErrorMessage>
          {errors.showMobileAppInstallBanner &&
            errors.showMobileAppInstallBanner.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.mobileAppBannerVariant}>
        <FormLabel>Design Variant</FormLabel>
        <InputGroup>
          <Select name="mobileAppBannerVariant" ref={register()}>
            <option value="top">Top</option>
            <option value="minimal">Bottom - Minimal</option>
            <option value="full">Bottom - Full</option>
          </Select>
        </InputGroup>
        <FormErrorMessage>
          {errors.mobileAppBannerVariant &&
            errors.mobileAppBannerVariant.message}
        </FormErrorMessage>
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppBrandingGeneralAppVisibilityForm
