import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Switch,
} from "@chakra-ui/react"
import { DeploymentType } from "@jackfruit/common"
import Body from "components/Body"
import DeploymentsTable from "components/Deployments/DeploymentsTable"
import { RouteParams } from "navigation/RouteParams"
import React, { useCallback, useState } from "react"
import { RxRocket } from "react-icons/rx"
import { useHistory, useParams } from "react-router-dom"
import { useQueryClient } from "react-query"
import api from "services/api"

interface Props {}
const NODE_ENV = process.env.NODE_ENV
interface ActionsProps {
  onFilterChange: (values: DeploymentType[]) => void
  filterValues: DeploymentType[]
}

const Actions = ({ onFilterChange, filterValues }: ActionsProps) => {
  const handleTogglePreviews = useCallback(
    e => {
      const isChecked = e.target.checked
      const newValue: DeploymentType[] = isChecked
        ? ["live"]
        : ["live", "preview"]
      onFilterChange(newValue)
    },
    [onFilterChange]
  )

  const { appId } = useParams<RouteParams>()
  const history = useHistory()
  const gotoDeploy = () => {
    history.push(`/admin/apps/${appId}/deployments/new`)
  }

  const queryClient = useQueryClient()

  const handlPrefetchGitReference = async () => {
    await queryClient.prefetchQuery(
      [
        "deployments",
        {
          appId: appId,
          $limit: 1,
          status: NODE_ENV === "development" ? "pending" : "complete",
          type: "live",
          $sort: { createdAt: "desc" },
        },
      ],
      async () => {
        try {
          const data = await api.findOne("deployments", {
            query: {
              appId: appId,
              $limit: 1,
              status: NODE_ENV === "development" ? "pending" : "complete",
              type: "live",
              $sort: { createdAt: "desc" },
            },
          })

          if (data) {
            localStorage.setItem(
              "@autopilot:currentDeployment",
              JSON.stringify(data)
            )
          }
        } catch (error) {
          console.error("Error prefetching deployment data")
        }
      }
    )
  }

  return (
    <HStack spacing={4} justifyContent="flex-start">
      <FormControl w="150px" alignItems="center">
        <HStack>
          <Switch
            id="display-previews"
            onChange={handleTogglePreviews}
            isChecked={filterValues.length === 1}
          />
          <FormLabel htmlFor="display-previews" mb={0} ml={2}>
            Hide Previews
          </FormLabel>
        </HStack>
      </FormControl>
      <Button
        colorScheme="primary"
        leftIcon={<RxRocket stroke="transparent" />}
        onClick={gotoDeploy}
        onMouseEnter={handlPrefetchGitReference}
      >
        Deploy
      </Button>
    </HStack>
  )
}

const AppDeploymentListScreen: React.FC<Props> = () => {
  const { appId } = useParams<RouteParams>()
  const [filterValues, setTypeFilter] = useState<DeploymentType[]>([
    "live",
    "preview",
  ])

  return (
    <Body
      padding={0}
      title="All deployments"
      headerActions={
        <Actions onFilterChange={setTypeFilter} filterValues={filterValues} />
      }
    >
      <DeploymentsTable appId={appId} deploymentTypes={filterValues} />
    </Body>
  )
}

export default AppDeploymentListScreen
