import React, { useCallback } from "react"
import {
  FormControl,
  FormLabel,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  InputGroup,
  Input,
  Checkbox,
  FormHelperText,
} from "@chakra-ui/react"
import FormActions from "forms/FormActions"
import FormContainer from "components/FormContainer"
import { useForm } from "react-hook-form"
import { BlockProductGridEntity } from "@jackfruit/common"
import { removeKeys } from "utils/helpers"
import { Link, useRouteMatch } from "react-router-dom"

interface Props {
  entity: BlockProductGridEntity
  isLoading: boolean
  onSubmit: (data: BlockProductGridEntity) => void
}

const BlockProductGridForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const match = useRouteMatch()
  const basePath = match.url
  const defaultValues = {
    ...entity,
  }

  const { handleSubmit, register } = useForm<BlockProductGridEntity>({
    defaultValues,
  })

  const enhancedOnSubmit = useCallback(
    data => {
      onSubmit(removeKeys(data, ["chosen", "selected"]))
    },
    [onSubmit]
  )

  return (
    <FormContainer onSubmit={handleSubmit(enhancedOnSubmit)}>
      <FormControl>
        <FormLabel>Title</FormLabel>
        <InputGroup>
          <Input type="text" name="title" ref={register} />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Subtitle</FormLabel>
        <InputGroup>
          <Input type="text" name="subtitle" ref={register} />
        </InputGroup>
      </FormControl>
      <FormControl>
        <Checkbox ref={register} name="isCTAButtonEnabled">
          Enable CTA Button
        </Checkbox>
        <InputGroup>
          <Input
            ref={register}
            type="text"
            name="ctaButtonText"
            placeholder="CTA button text"
          />
        </InputGroup>
        <FormHelperText>
          This button will only show up if the layout is horizontal
        </FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel>Layout style</FormLabel>
        <Select ref={register} name="layout">
          <option value="horizontal">Horizontal</option>
          <option value="vertical">Vertical</option>
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Products to show before paginating</FormLabel>
        <NumberInput defaultValue={entity?.itemsPerPage} step={1} min={1}>
          <NumberInputField ref={register} name="itemsPerPage" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <Button
        as={Link}
        to={`${basePath}/${entity.blockId}/product-configuration`}
      >
        Configure Products
      </Button>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default BlockProductGridForm
