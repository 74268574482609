import { FormControl, FormLabel, InputGroup } from "@chakra-ui/react"
import TagInput from "forms/inputs/TagInput"
import React from "react"
import { useFormContext, Controller } from "react-hook-form"

interface Props {}

const BlockStoreSearchFields: React.FC<Props> = () => {
  const { control } = useFormContext()

  return (
    <FormControl>
      <FormLabel>Retailer Ids</FormLabel>
      <InputGroup>
        <Controller
          name="retailerIds"
          control={control}
          render={({ onChange, value }) => {
            return <TagInput onChange={onChange} value={value} />
          }}
        />
      </InputGroup>
    </FormControl>
  )
}

export default React.memo(BlockStoreSearchFields)
