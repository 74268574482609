import { Flex } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import MegaMenuAddColumnButton from "./components/MegaMenuAddColumnButton"
import MegaMenuColumn from "./components/MegaMenuColumn"
import { useMegaMenu } from "./hooks/useMegaMenu"

interface Props {
  megaMenuId: EntityId
}

const MegaMenuBuilder: React.FC<Props> = ({ megaMenuId }) => {
  const { isLoading, data: megaMenu, onDragEnd } = useMegaMenu(megaMenuId)

  if (isLoading) {
    return null
  }

  const canAddMoreColumns = megaMenu.columns.length < 4

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="main" direction="horizontal" type="column">
        {provided => {
          return (
            <Flex
              ref={provided.innerRef}
              {...provided.droppableProps}
              borderWidth={1}
              borderRadius="md"
              minHeight="400px"
              p={3}
              w="1000px"
              overflowX="auto"
              boxShadow="inner"
              backgroundColor="blackAlpha.50"
            >
              {megaMenu.columns.map((column, index) => {
                return (
                  <MegaMenuColumn
                    key={column.id}
                    column={column}
                    index={index}
                  />
                )
              })}
              {provided.placeholder}
              <MegaMenuAddColumnButton
                megaMenuId={megaMenuId}
                isDisabled={!canAddMoreColumns}
              />
            </Flex>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

export default MegaMenuBuilder
