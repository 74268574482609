import React from "react"
import { formatDistance } from "date-fns"
import { DeploymentEntity } from "@jackfruit/common"
import { Text } from "@chakra-ui/react"

interface Props {
  deployment: DeploymentEntity
}

const DeploymentDuration: React.FC<Props> = ({ deployment }) => {
  const { startedAt, finishedAt } = deployment
  const startedAtDate = new Date(startedAt)
  const finishedAtDate = new Date(finishedAt)

  const distance = formatDistance(startedAtDate, finishedAtDate, {
    includeSeconds: true,
  })

  if (deployment.status !== "complete") {
    return null
  }

  return <Text fontSize="sm">{distance}</Text>
}

export default DeploymentDuration
