import { ChangelogEntity, EntityId } from "@jackfruit/common"

export interface CompiledChangelogs {
  [key: string]: {
    [key: EntityId]: any[]
  }
}

export const compileChangelogs = (changelogs: ChangelogEntity[]) => {
  const compiled: CompiledChangelogs = {}

  changelogs.forEach(changelog => {
    const { entityType, changes, entityId, user, latestDeployment, method } =
      changelog

    const deploymentVersion = latestDeployment
      ? latestDeployment.deploymentVersion
      : null

    if (!compiled[entityType]) {
      compiled[entityType] = {}
    }
    if (!compiled[entityType][entityId]) {
      compiled[entityType][entityId] = []
    }
    compiled[entityType][entityId].push({
      ...changelog,
      method,
      changes,
      user,
      deploymentVersion,
    })
  })

  return compiled
}
