import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import "ace-builds"
import "ace-builds/webpack-resolver"
import React from "react"
import { useForm } from "react-hook-form"
import FormSectionHeader from "components/FormSectionHeader"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import { GlobalDeploymentSettingsEntity } from "@jackfruit/common"

interface Props {
  entity?: GlobalDeploymentSettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const PreviewSettingsForm: React.FC<Props> = ({
  entity,
  isLoading,
  onSubmit,
}) => {
  const defaultValues: any = {
    ...entity,
  }

  const { handleSubmit, register, errors } =
    useForm<GlobalDeploymentSettingsEntity>({
      defaultValues,
    })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Preview Settings</FormSectionHeader>
      <FormControl isInvalid={Boolean(errors.previewDeploymentBaseUrl)}>
        <FormLabel>Base Url</FormLabel>
        <InputGroup>
          <Input ref={register()} name="previewDeploymentBaseUrl" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.previewDeploymentBaseUrl &&
            errors.previewDeploymentBaseUrl.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={Boolean(errors.previewDeploymentS3BucketName)}>
        <FormLabel>S3 bucket name</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="previewDeploymentS3BucketName"
            type="text"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.previewDeploymentS3BucketName &&
            errors.previewDeploymentS3BucketName.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={Boolean(errors.previewDeploymentCloudFrontDistributionId)}
      >
        <FormLabel>Cloud Front Distribution Id</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="previewDeploymentCloudFrontDistributionId"
            type="text"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.previewDeploymentCloudFrontDistributionId &&
            errors.previewDeploymentCloudFrontDistributionId.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={Boolean(errors.previewGaProjectId)}>
        <FormLabel>GA4 Container Id</FormLabel>
        <InputGroup>
          <Input
            ref={register()}
            name="previewGaProjectId"
            type="text"
            isReadOnly
            bg="gray.100"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.previewGaProjectId && errors.previewGaProjectId.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={Boolean(errors.previewGaMeasurementId)}>
        <FormLabel>GA4 Measurement Id</FormLabel>
        <InputGroup>
          <Input ref={register()} name="previewGaMeasurementId" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.previewGaMeasurementId &&
            errors.previewGaMeasurementId.message}
        </FormErrorMessage>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default PreviewSettingsForm
