import Navbar, { NavbarConfig } from "components/Navbar/Navbar"
import React from "react"
import { FiArrowLeft } from "react-icons/fi"
import { generatePath, useRouteMatch } from "react-router-dom"

interface Props {}

const AppCreatePageNav: React.FC<Props> = () => {
  const match = useRouteMatch()

  const rootPath = generatePath(`/admin/apps/:appId/pages`, match.params as any)

  const config: NavbarConfig = {
    items: [
      {
        type: "link",
        to: rootPath,
        label: "All Pages",
        icon: <FiArrowLeft />,
        canActivate: false,
      },
    ],
  }
  return <Navbar config={config} />
}

export default AppCreatePageNav
