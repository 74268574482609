import { EntityId } from "@jackfruit/common"
import { useCallback, useEffect, useState } from "react"
import api, { Resources } from "services/api"
import { useCustomToast } from "./useCustomToast"

export const useHasOneResource = <T>(
  resource: Resources,
  referenceKey: string,
  referenceId: EntityId,
  params: any = {}
) => {
  const toast = useCustomToast()
  const [entity, setEntity] = useState<T>({} as T)
  const [isLoading, setIsLoading] = useState(true)
  const [resourceEntity] = useState(resource)
  const [paramsQuery] = useState(params)

  const toastError = useCallback(
    error => {
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    },
    [toast]
  )

  const get = useCallback(async () => {
    setIsLoading(true)
    const query = {
      query: {
        $limit: 1,
        [referenceKey]: referenceId,
        ...paramsQuery,
      },
    }
    try {
      const result = await api.find(resourceEntity, query)
      if (result.total > 0) {
        setEntity(result.data[0])
      }
    } catch (error: any) {
      toastError(error)
    } finally {
      setIsLoading(false)
    }
  }, [paramsQuery, referenceId, referenceKey, resourceEntity, toastError])

  useEffect(() => {
    get()
  }, [get, referenceId])

  return {
    entity,
    setEntity,
    isLoading,
  }
}
