import { Type, FormatRegistry, Null, Optional } from "@sinclair/typebox"
import { TypeCompiler } from "@sinclair/typebox/compiler"
import { IsDateTime } from "./lib/date-time"

FormatRegistry.Set("date-time", value => IsDateTime(value))

import Nullable from "./lib/nullable"
import StringEnum from "./lib/string-enum"
import NumberEnum from "./lib/number-enum"

const TDateTime = Type.String({ format: "date-time" })
const TBoolean = NumberEnum([0, 1])

const TAsset = Type.Object({
  filename: Type.String(),
  path: Type.String(),
  hash: Type.String(),
  mimeType: Type.String(),
  width: Type.Integer(),
  height: Type.Integer(),
  size: Type.Integer(),
  alt: Type.String(),
  link: Type.String(),
})

const TProductConfiguration = Type.Object({
  pickup: Type.Record(
    Type.Integer(),
    Type.Record(
      Type.Integer(),
      Type.Object({
        id: Type.Integer(),
        internalId: Type.Integer(),
        position: Type.Integer(),
        isDefaultSquareProduct: TBoolean,
        isDefaultRectangleProduct: TBoolean,
      })
    )
  ),
  delivery: Type.Record(
    Type.Integer(),
    Type.Record(
      Type.Integer(),
      Type.Object({
        id: Type.Integer(),
        internalId: Type.Integer(),
        position: Type.Integer(),
        isDefaultSquareProduct: TBoolean,
        isDefaultRectangleProduct: TBoolean,
      })
    )
  ),
})

const TPrintServiceConfiguration = Type.Object({
  pickup: Type.Array(
    Type.Object({
      id: Type.Integer(),
      fulfillmentType: StringEnum(["pickup"]),
      internalId: Type.Integer(),
      hasProductsAutoEnabled: TBoolean,
    })
  ),
  delivery: Type.Array(
    Type.Object({
      id: Type.Integer(),
      fulfillmentType: StringEnum(["delivery"]),
      internalId: Type.Integer(),
      hasProductsAutoEnabled: TBoolean,
    })
  ),
})

export const T = Type.Object(
  {
    id: Type.Integer(),
    name: Type.String(),
    updatedAt: TDateTime,
    slug: Type.String(),
    baseUrl: Type.String(),
    termsAndConditionsUrl: Nullable(Type.String()),
    termsAndConditionsPageId: Nullable(Type.Integer()),
    privacyPolicyUrl: Nullable(Type.String()),
    privacyPolicyPageId: Nullable(Type.Integer()),
    faviconImageId: Type.Integer(),
    logoImageId: Type.Integer(),
    primaryColor: Nullable(Type.String()),
    secondaryColor: Nullable(Type.String()),
    defaultLocale: Nullable(Type.String()),
    defaultCurrency: Nullable(Type.String()),
    additionalLocaleUrls: Optional(
      Type.Array(
        Type.Object({
          displayName: Type.String(),
          url: Type.String(),
        })
      )
    ),
    language: Nullable(Type.String()),
    iosStoreUrl: Nullable(Type.String()),
    iosStoreId: Optional(Nullable(Type.String())),
    iosDisplayStoreBannerOnMobile: Nullable(Type.Integer()),
    androidStoreUrl: Nullable(Type.String()),
    androidDisplayStoreBannerOnMobile: Nullable(Type.Integer()),
    lastDeployedAt: Nullable(TDateTime),
    showAppsButtons: Nullable(Type.Integer()),
    openGraphTitle: Nullable(Type.String()),
    openGraphDescription: Nullable(Type.String()),
    openGraphUrl: Nullable(Type.String()),
    openGraphLogo: Nullable(Type.String()),
    openGraphSitemapXml: Nullable(Type.String()),
    storesPageTitle: Nullable(Type.String()),
    storesPageDescription: Nullable(Type.String()),
    accountPageTitle: Nullable(Type.String()),
    accountPageDescription: Nullable(Type.String()),
    registerPageTitle: Nullable(Type.String()),
    registerPageDescription: Nullable(Type.String()),
    forgotPasswordPageTitle: Nullable(Type.String()),
    forgotPasswordPageDescription: Nullable(Type.String()),
    changePasswordPageTitle: Nullable(Type.String()),
    changePasswordPageDescription: Nullable(Type.String()),
    twitterUrl: Nullable(Type.String()),
    facebookUrl: Nullable(Type.String()),
    instagramUrl: Nullable(Type.String()),
    youtubeUrl: Nullable(Type.String()),
    isSitemapEnabled: Nullable(Type.Integer()),
    isSitemapHideFooter: Nullable(Type.Integer()),
    sitemapLinkLabel: Nullable(Type.String()),
    sitemapNavigationId: Nullable(Type.Integer()),
    showMobileAppInstallBanner: Nullable(Type.Integer()),
    displayName: Type.String(),
    androidStoreName: Nullable(Type.String()),
    androidStoreRating: Type.Integer(),
    androidStoreRatingCount: Nullable(Type.String()),
    iosStoreName: Nullable(Type.String()),
    iosStoreRating: Type.Integer(),
    iosStoreRatingCount: Nullable(Type.String()),
    mobileAppBannerVariant: Nullable(
      StringEnum(["top", "minimal", "full", "smart"])
    ),
    blockOrderSummaryAppInstallEnabled: Nullable(Type.Integer()),
    blockOrderSummaryAppInstallQrCode: Nullable(TBoolean),
    blockOrderSummaryAppInstallQrCodeUrl: Nullable(Type.String()),
    blockOrderSummaryAppInstallAppScreenImageId: Nullable(Type.Integer()),
    blockOrderSummaryAppInstallContent: Nullable(Type.String()),

    navigations: Type.Array(
      Type.Object({
        id: Type.Integer(),
        name: Type.String(),
        updatedAt: TDateTime,
        appId: Type.Integer(),
        links: Type.Array(
          Type.Object({
            id: Type.Integer(),
            label: Type.String(),
            labelPrefixHtml: Nullable(Type.String()),
            labelSuffixHtml: Nullable(Type.String()),
            path: Nullable(Type.String()),
            aliases: Nullable(Type.String()),
            parentId: Nullable(Type.Integer()),
            navigationId: Type.Integer(),
            pageId: Nullable(Type.Integer()),
            navigationMegamenuId: Nullable(Type.Integer()),
            type: StringEnum(["text", "page", "link", "megamenu"]),
            external: Nullable(TBoolean),
            order: Nullable(Type.Integer()),
            megamenu: Nullable(Type.Integer()),
            page: Nullable(
              Type.Object({
                id: Type.Integer(),
                name: Type.String(),
                slug: Type.String(),
              })
            ),
          })
        ),
      })
    ),

    pages: Type.Array(
      Type.Object({
        id: Type.Integer(),
        name: Type.String(),
        appId: Type.Integer(),
        updatedAt: TDateTime,
        parentId: Nullable(Type.Integer()),
        order: Nullable(Type.Integer()),
        slug: Type.String(),
        status: StringEnum(["active", "draft"]),
        isDeliveryEnabled: TBoolean,
        isPickupEnabled: TBoolean,
        defaultFulfillment: StringEnum(["delivery", "pickup"]),
        hideMobileAppInstallBanner: TBoolean,
        lambdaAbExperimentId: Nullable(Type.String()),
        publishedAt: Nullable(TDateTime),
        blocks: Type.Array(
          Type.Object({
            id: Type.Integer(),
            pageId: Type.Integer(),
            type: StringEnum([
              "header",
              "checkout",
              "footer",
              "html",
              "graph",
              "image-grid",
              "order-complete",
              "subpage-grid",
              "rich-text",
              "social-media",
              "order-thank-you",
              "video",
              "cart",
              "image-upload",
              "fulfillment",
              "product-grid",
              "store-search",
              "template",
              "photos-uploaded",
            ]),
            elementId: Type.String(),
            order: Type.Integer(),
            appId: Nullable(Type.Integer()),
            inherit: Type.Integer(),
            header: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                topNavigationBackgroundColor: Nullable(Type.String()),
                topNavigationColor: Nullable(Type.String()),
                topNavigationId: Nullable(Type.Integer()),
                brandNavigationBackgroundColor: Nullable(Type.String()),
                brandNavigationColor: Nullable(Type.String()),
                brandNavigationId: Nullable(Type.Integer()),
                mainNavigationBackgroundColor: Nullable(Type.String()),
                mainNavigationColor: Nullable(Type.String()),
                mainNavigationId: Nullable(Type.Integer()),
                mobileNavigationBackgroundColor: Nullable(Type.String()),
                mobileNavigationColor: Nullable(Type.String()),
                mobileNavigationId: Nullable(Type.Integer()),
                callToActionText: Nullable(Type.String()),
                callToActionHtml: Nullable(Type.String()),
                displaySiteWideBanner: TBoolean,
                siteWideBannerContent: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            imageUpload: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                topHtml: Nullable(Type.String()),
                bottomHtml: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            cart: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                isDropzoneEnabled: TBoolean,
                customDropzoneImageId: Nullable(Type.Integer()),
                customDropzoneImage: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            storeSearch: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                retailerIds: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            checkout: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                orderSuccessDeliveryPageId: Nullable(Type.Integer()),
                orderSuccessPickupPageId: Nullable(Type.Integer()),
                orderSummaryDisplayType: StringEnum([
                  "vanilla",
                  "hiddenByDefault",
                  "visibleByDefault",
                  "mcDonald",
                ]),
                orderSuccessPickupPage: Nullable(
                  Type.Object({
                    id: Type.Integer(),
                    name: Type.String(),
                    appId: Type.Integer(),
                    parentId: Nullable(Type.Integer()),
                    order: Type.Integer(),
                    slug: Type.String(),
                    status: StringEnum(["active", "draft"]),
                    isDeliveryEnabled: TBoolean,
                    isPickupEnabled: TBoolean,
                    defaultFulfillment: StringEnum(["pickup", "delivery"]),
                    hideMobileAppInstallBanner: TBoolean,
                    lambdaAbExperimentId: Nullable(Type.Integer()),
                    publishedAt: TDateTime,
                    updatedAt: TDateTime,
                  })
                ),
                orderSuccessDeliveryPage: Nullable(
                  Type.Object({
                    id: Type.Integer(),
                    name: Type.String(),
                    appId: Type.Integer(),
                    parentId: Nullable(Type.Integer()),
                    order: Type.Integer(),
                    slug: Type.String(),
                    status: StringEnum(["active", "draft"]),
                    isDeliveryEnabled: TBoolean,
                    isPickupEnabled: TBoolean,
                    defaultFulfillment: StringEnum(["pickup", "delivery"]),
                    hideMobileAppInstallBanner: TBoolean,
                    lambdaAbExperimentId: Nullable(Type.Integer()),
                    publishedAt: TDateTime,
                    updatedAt: TDateTime,
                  })
                ),
                updatedAt: Nullable(TDateTime),
              })
            ),
            footer: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                row1Bg: Nullable(Type.String()),
                row1Col1Html: Nullable(Type.String()),
                row1Col2Html: Nullable(Type.String()),
                row2Bg: Nullable(Type.String()),
                row2Col1Html: Nullable(Type.String()),
                row2Col2Html: Nullable(Type.String()),
                row2Col3Html: Nullable(Type.String()),
                row2Col4Html: Nullable(Type.String()),
                row3Bg: Nullable(Type.String()),
                row3Col1Html: Nullable(Type.String()),
                row2Col1HtmlSecondaryVariant: Nullable(Type.String()),
                row2Col2HtmlSecondaryVariant: Nullable(Type.String()),
                row2Col3HtmlSecondaryVariant: Nullable(Type.String()),
                row2Col4HtmlSecondaryVariant: Nullable(Type.String()),
                row3Col1HtmlSecondaryVariant: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            html: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                html: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            graph: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                graphType: Nullable(Type.String()),
                data: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            imageGrid: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                alignment: Type.Integer(),
                columnWidth: Type.Integer(),
                columnUnit: StringEnum(["px", "%"]),
                alignmentMd: Type.Integer(),
                columnWidthMd: Type.Integer(),
                columnUnitMd: StringEnum(["px", "%"]),
                alignmentXs: Type.Integer(),
                columnWidthXs: Type.Integer(),
                columnUnitXs: StringEnum(["px", "%"]),
                htmlConfig: Nullable(Type.String()),
                galleryType: StringEnum(["grid"]),
                indicatorSize: Type.Integer(),
                indicatorSizeXs: Type.Integer(),
                delay: Type.Integer(),
                indicatorType: StringEnum(["thumbnail"]),
                indicatorTypeXs: StringEnum(["dot"]),
                additionalStyle: Nullable(Type.String()),
                additionalStyleXs: Nullable(Type.String()),
                assets: Type.Array(TAsset),
                assetsMobile: Type.Array(TAsset),
                assetsTablet: Type.Array(TAsset),
                assetsDesktop: Type.Array(TAsset),
                updatedAt: Nullable(TDateTime),
              })
            ),
            orderThankYou: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                title: Nullable(Type.String()),
                body: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            orderComplete: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                upsellEnabled: Nullable(Type.Integer()),
                upsellTitle: Nullable(Type.String()),
                upsellContentId: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            subpageGrid: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                title: Nullable(Type.String()),
                paginationLimit: Type.Integer(),
                displayTitles: TBoolean,
                displayPublishedAtDates: TBoolean,
                updatedAt: Nullable(TDateTime),
              })
            ),
            richText: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                html: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            socialMedia: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                title: Nullable(Type.String()),
                body: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            video: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                youtubeUrl: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            fulfillment: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                mainTitle: Nullable(Type.String()),
                fulfillmentStyle: StringEnum(["Full-width", "Boxed"]),
                containerBgColor: Nullable(Type.String()),
                checkBgColor: Nullable(Type.String()),
                fontColor: Nullable(Type.String()),
                pickupTitle: Nullable(Type.String()),
                pickupDescription: Nullable(Type.String()),
                pickupSvgIcon: Nullable(Type.String()),
                deliveryTitle: Nullable(Type.String()),
                deliveryDescription: Nullable(Type.String()),
                deliverySvgIcon: Nullable(Type.String()),
                updatedAt: Nullable(TDateTime),
              })
            ),
            productGrid: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                title: Nullable(Type.String()),
                subtitle: Nullable(Type.String()),
                isCTAButtonEnabled: TBoolean,
                ctaButtonText: Nullable(Type.String()),
                layout: StringEnum(["vertical", "horizontal"]),
                itemsPerPage: Type.Integer(),
                products: TProductConfiguration,
                printServices: TPrintServiceConfiguration,
                updatedAt: Nullable(TDateTime),
              })
            ),
            template: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                tags: Nullable(Type.Array(Type.String())),
                isRestrictedToActivePrintServices: TBoolean,
                isCTAButtonEnabled: TBoolean,
                ctaButtonText: Nullable(Type.String()),
                itemsPerPage: Type.Integer(),
                maxDesktopColumns: Type.Integer(),
                minMobileColumns: Type.Integer(),
                title: Nullable(Type.String()),
                subtitle: Nullable(Type.String()),
                blocksTemplateProducts: Type.Array(Nullable(Type.Integer())),
                printServices: TPrintServiceConfiguration,
                products: TProductConfiguration,
                updatedAt: Nullable(TDateTime),
              })
            ),
            photosUploaded: Type.Optional(
              Type.Object({
                id: Type.Integer(),
                blockId: Type.Integer(),
                updatedAt: Nullable(TDateTime),
              })
            ),
          })
        ),
        pageSeo: Nullable(
          Type.Object({
            id: Type.Integer(),
            pageId: Type.Integer(),
            openGraphTitle: Nullable(Type.String()),
            openGraphDescription: Nullable(Type.String()),
            openGraphUrl: Nullable(Type.String()),
            openGraphLogo: Nullable(Type.String()),
            openGraphFeatureImageId: Nullable(Type.Integer()),
            openGraphSitemapXml: Nullable(Type.String()),
            openGraphFeatureImage: Nullable(
              Type.Object({
                id: Type.Integer(),
                filename: Type.String(),
                path: Type.String(),
                hash: Type.String(),
                mimeType: Type.String(),
                width: Type.Integer(),
                height: Type.Integer(),
                size: Type.Integer(),
                updatedAt: TDateTime,
              })
            ),
            updatedAt: Nullable(TDateTime),
          })
        ),
        pageInteractions: Type.Array(
          Type.Object({
            pageId: Type.Integer(),
            name: Type.String(),
            htmlBody: Type.String(),
            activationBlocks: Type.Array(Type.String()),
            isAlwaysVisible: TBoolean,
          })
        ),
        territories: Type.Object({
          pickup: Type.Array(Type.String()),
          delivery: Type.Array(Type.String()),
        }),
        defaultPickupTerritory: Type.String(),
        defaultDeliveryTerritory: Type.String(),
        printServices: TPrintServiceConfiguration,
        products: TProductConfiguration,
        path: Type.String(),
      })
    ),
    faviconImage: Type.Object({
      id: Type.Integer(),
      filename: Type.String(),
      path: Type.String(),
      hash: Type.String(),
      mimeType: Type.String(),
      width: Nullable(Type.Integer()),
      height: Nullable(Type.Integer()),
      size: Type.Integer(),
      updatedAt: Nullable(TDateTime),
    }),
    common: Type.Object({
      id: Type.Integer(),
      appId: Type.Integer(),
      hideHeader: Nullable(TBoolean),
      hideFooter: Nullable(TBoolean),
      showPoweredByAutopilotBanner: TBoolean,
      poweredByAutopilotBannerText: Nullable(Type.String()),
      poweredByAutopilotRedirectUrl: Type.String(),
      poweredByAutopilotBg: Nullable(Type.String()),
      poweredByAutopilotTextColour: Nullable(Type.String()),
      showSocialMediaLinks: Nullable(TBoolean),
      updatedAt: Nullable(TDateTime),
    }),
    logoImage: Type.Object({
      id: Type.Integer(),
      filename: Type.String(),
      path: Type.String(),
      hash: Type.String(),
      mimeType: Type.String(),
      width: Type.Integer(),
      height: Type.Integer(),
      size: Type.Integer(),
      updatedAt: Nullable(TDateTime),
    }),
    blockOrderSummaryAppInstallAppScreenImage: Nullable(
      Type.Object({
        id: Type.Integer(),
        filename: Type.String(),
        path: Type.String(),
        hash: Type.String(),
        mimeType: Type.String(),
        width: Type.Integer(),
        height: Type.Integer(),
        size: Type.Integer(),
        updatedAt: Nullable(TDateTime),
      })
    ),
    settings: Type.Object({
      id: Type.Integer(),
      appId: Type.Integer(),
      remoteApiUrl: Type.String(),
      remoteApiClientApiKey: Type.String(),
      defaultLocale: Nullable(Type.String()),
      defaultCurrency: Nullable(Type.String()),
      defaultRegion: Nullable(Type.String()),
      defaultVersion: Nullable(Type.String()),
      recaptchaSiteKey: Nullable(Type.String()),
      googleMapKey: Nullable(Type.String()),
      rawJavascript: Nullable(Type.String()),
      rawStylesheet: Nullable(Type.String()),
      rawHead: Nullable(Type.String()),
      addressAutoComplete: Nullable(TBoolean),
      loggingMode: Nullable(StringEnum(["disabled", "debug", "verbose"])),
      AutoCropSquareImagesTo: Nullable(Type.String()),
      localStoragePrefix: Nullable(Type.String()),
      mapProvider: Nullable(StringEnum(["googleMaps", "amazonLocation"])),
      imageCompressionIsEnabled: TBoolean,
      imageCompressionRate: Type.Integer(),
      imageCompressionSizeThreashold: Type.Integer(),
      robotsTxt: Nullable(Type.String()),
      abTestAttribute: Nullable(Type.String()),
      enableSentryIntegration: TBoolean,
      sentryOptionEnv: Nullable(Type.String()),
      helpPageId: Nullable(Type.Integer()),
      helpPageLabel: Nullable(Type.String()),
      isUserAccountEnabled: TBoolean,
      gaProjectId: Nullable(Type.String()),
      gaMeasurementId: Nullable(Type.String()),
      tiktokMeasurementId: Nullable(Type.String()),
      pinterestMeasurementId: Nullable(Type.String()),
      microsoftMeasurementId: Nullable(Type.String()),
      facebookMeasurementId: Nullable(Type.String()),
      isReorderEnabled: TBoolean,
      deploymentS3BucketName: Nullable(Type.String()),
      deploymentS3Prefix: Nullable(Type.String()),
      deploymentCloudFrontDistributionId: Nullable(Type.String()),
      isStoresPagesEnabled: TBoolean,
      language: Type.String(),
      googleOptimizeExperimentIdOne: Nullable(Type.String()),
      googleOptimizeExperimentIdTwo: Nullable(Type.String()),
      isSitemapEnabled: TBoolean,
      isSitemapHideFooter: TBoolean,
      sitemapLinkLabel: Nullable(Type.String()),
      sitemapNavigationId: Nullable(Type.Integer()),
      googleOptimizeExperimentIdThree: Nullable(Type.String()),
      googleOptimizeExperimentIdFive: Nullable(Type.String()),
      googleOptimizeExperimentIdFour: Nullable(Type.String()),
      googleOptimizeExperimentIdSix: Nullable(Type.String()),
      googleOptimizeContainerId: Nullable(Type.String()),
      lambdaAbEnabled: Nullable(TBoolean),
      lambdaAbExperimentId: Nullable(Type.String()),
      lambdaAbCookieName: Nullable(Type.String()),
      googleOptimizeExperimentIdSeven: Nullable(Type.String()),
      googleOptimizeExperimentIdEight: Nullable(Type.String()),
      googleOptimizeExperimentIdNine: Nullable(Type.String()),
      lambdaAbVariants: Type.Array(Type.String()),
      updatedAt: Nullable(TDateTime),
    }),
    printServices: Type.Array(
      Type.Object({
        id: Type.Integer(),
        remoteId: Type.Integer(),
        isEnabled: TBoolean,
        isStoresGenerable: TBoolean,
        defaultCurrency: Type.String(),
        fulfillmentType: StringEnum(["pickup", "delivery"]),
      })
    ),
    territories: Type.Array(
      Type.Object({
        id: Type.Integer(),
        name: Type.String(),
        code: Type.String(),
        environment: StringEnum(["production", "staging"]),
        remoteId: Type.Integer(),
        updatedAt: TDateTime,
      })
    ),
    integrations: Type.Object({
      id: Type.Integer(),
      sentry: Type.Object({
        organisation: Type.String(),
        project: Type.String(),
        dsn: Type.String(),
        authToken: Type.String(),
      }),
    }),
  },
  { title: "BuilderConfig", additionalProperties: true }
)

export const C = TypeCompiler.Compile(T)
