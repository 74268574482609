import { FormControl, FormLabel, HStack, Switch } from "@chakra-ui/react"
import { DeploymentType } from "@jackfruit/common"
import Body from "components/Body"
import DeploymentsTable from "components/Deployments/DeploymentsTable"
import Page from "components/Page"
import React, { useCallback, useState } from "react"

interface Props {}

const DeploymentListScreen: React.FC<Props> = () => {
  const [filterValues, setTypeFilter] = useState<DeploymentType[]>([
    "live",
    "preview",
  ])

  const handleTogglePreviews = useCallback(e => {
    const isChecked = e.target.checked
    const newValue: DeploymentType[] = isChecked
      ? ["live"]
      : ["live", "preview"]
    setTypeFilter(newValue)
  }, [])

  return (
    <Page title="Deployments">
      <Body
        padding={0}
        title="All Deployments"
        headerActions={
          <FormControl w="150px" alignItems="center">
            <HStack>
              <Switch
                id="display-previews"
                onChange={handleTogglePreviews}
                isChecked={filterValues.length === 1}
              />
              <FormLabel htmlFor="display-previews" mb={0} ml={2}>
                Hide Previews
              </FormLabel>
            </HStack>
          </FormControl>
        }
      >
        <DeploymentsTable deploymentTypes={filterValues} />
      </Body>
    </Page>
  )
}

export default DeploymentListScreen
