import { ChakraProvider, ColorModeScript } from "@chakra-ui/react"
import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { Provider } from "react-redux"
import "react-sortable-tree/style.css"
import { RecoilRoot } from "recoil"
import Navigation from "./navigation/Navigation"
import { createStore } from "./redux/store"
import AuthGate from "./security/AuthGate"
import theme from "./theme"
import { ReactQueryDevtools } from "react-query-devtools"

const NODE_ENV = process.env.NODE_ENV

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
})

const store = createStore()

function App() {
  return (
    <ChakraProvider theme={theme} cssVarsRoot="body">
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <AuthGate>
              <Navigation />
            </AuthGate>
          </RecoilRoot>
          {NODE_ENV === "development" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </Provider>
    </ChakraProvider>
  )
}

export default App
