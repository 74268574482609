import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Select,
  Spinner,
} from "@chakra-ui/react"
import "ace-builds"
import "ace-builds/webpack-resolver"
import React from "react"
import { useForm } from "react-hook-form"
import currencyCodesAndSymbols from "data/currencyCodesAndSymbols"
import FormActions from "forms/FormActions"
import FormSectionHeader from "components/FormSectionHeader"
import FormContainer from "components/FormContainer"
import {
  AppEntity,
  AvailableLanguages,
  TerritoryEntityHydrated,
} from "@jackfruit/common"
import { useEntityList } from "hooks/useEntityList"
import { uniqBy } from "lodash"

const languages: AvailableLanguages[] = ["en-US", "fr-CA", "ja-JP", "es-US"]
interface Props {
  entity?: AppEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppBrandingLocalizationForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  })

  const { data: territories, isLoading: isLoadingTerritories } =
    useEntityList<TerritoryEntityHydrated>("territories", {
      query: {
        $limit: 1000,
      },
    })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Localization</FormSectionHeader>
      <FormControl isInvalid={errors.defaultLocale}>
        <FormLabel>Default locale</FormLabel>
        {!isLoadingTerritories ? (
          <InputGroup>
            <Select ref={register()} name="defaultLocale">
              {uniqBy(territories.data, "code").map(territory => {
                const { code, name } = territory
                return (
                  <option key={code} value={code}>
                    {name}
                  </option>
                )
              })}
            </Select>
          </InputGroup>
        ) : (
          <Spinner size="md" />
        )}

        <FormErrorMessage>
          {errors.defaultLocale && errors.defaultLocale.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.defaultCurrency}>
        <FormLabel>Default currency</FormLabel>
        <InputGroup>
          <Select ref={register()} name="defaultCurrency">
            {currencyCodesAndSymbols.map(value => {
              return (
                <option key={value.code} value={value.code}>
                  {value.name}
                </option>
              )
            })}
          </Select>
        </InputGroup>
        <FormErrorMessage>
          {errors.defaultCurrency && errors.defaultCurrency.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.language}>
        <FormLabel>Language</FormLabel>
        <InputGroup>
          <Select ref={register()} name="language">
            {languages.map(lang => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </Select>
        </InputGroup>

        <FormErrorMessage>
          {errors.language && errors.language.message}
        </FormErrorMessage>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppBrandingLocalizationForm
