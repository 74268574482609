import { Button, Icon, Text, VStack } from "@chakra-ui/react"
import { BlockType } from "@jackfruit/common"
import { useCallback } from "react"
import { IconType } from "react-icons"

interface Props {
  type: BlockType
  icon: IconType
  label: string
  onAdd: (type: BlockType) => void
}

const BlockToolBoxGroupItem: React.FC<Props> = ({
  type,
  icon,
  label,
  onAdd,
}) => {
  const addBlock = useCallback(() => {
    onAdd(type)
  }, [type, onAdd])

  return (
    <Button
      h={90}
      p={2}
      variant="outline"
      colorScheme="primary"
      onClick={addBlock}
      borderColor="gray.300"
      whiteSpace="normal"
    >
      <VStack>
        <Icon as={icon} boxSize={5} />
        <Text fontSize="sm" wordBreak="break-word">
          {label}
        </Text>
      </VStack>
    </Button>
  )
}

export default BlockToolBoxGroupItem
