import { Button } from "@chakra-ui/react"
import React from "react"
import { useLocation, Link } from "react-router-dom"

interface Props {
  icon?: React.ReactElement
  isExternal?: boolean
  label: string
  to: string
  expanded?: boolean
  canActivate?: boolean
}

const NavbarLink: React.FC<Props> = ({
  icon,
  label,
  to,
  isExternal = false,
  canActivate = true,
}) => {
  const location = useLocation()
  const isActive = canActivate && location.pathname.includes(to)

  return (
    <Button
      leftIcon={icon}
      isActive={isActive}
      as={Link}
      to={to}
      target={isExternal ? "_blank" : undefined}
      variant="ghost"
      size="sm"
      justifyContent="start"
      _focus={{
        outline: "none",
      }}
      _active={{
        color: "primary.500",
        bg: "primary.100",
        borderLeftColor: "primary.500",
      }}
      _hover={{
        color: "primary.500",
        bg: "primary.100",
        borderLeftColor: "primary.500",
      }}
    >
      {label}
    </Button>
  )
}

export default NavbarLink
