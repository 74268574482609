import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
} from "@chakra-ui/react"
import { GlobalDeploymentSettingsEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import TagInput from "forms/inputs/TagInput"
import React from "react"
import { Controller, useForm } from "react-hook-form"

interface Props {
  entity?: GlobalDeploymentSettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const TestAutomationForm: React.FC<Props> = ({
  entity,
  isLoading,
  onSubmit,
}) => {
  const defaultValues: any = {
    ...entity,
  }

  const { control, handleSubmit, errors } =
    useForm<GlobalDeploymentSettingsEntity>({
      defaultValues,
    })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Test Automation</FormSectionHeader>

      <FormControl isInvalid={Boolean(errors.liveDeploymentTestTags)}>
        <FormLabel>Available Test Tags</FormLabel>
        <InputGroup>
          <Controller
            name="liveDeploymentTestTags"
            control={control}
            render={({ onChange, value }) => {
              return (
                <TagInput
                  onChange={onChange}
                  value={value}
                  removeSpace={true}
                />
              )
            }}
          />
        </InputGroup>

        <FormErrorMessage>
          {errors.liveDeploymentTestTags &&
            errors.liveDeploymentTestTags.message}
        </FormErrorMessage>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default TestAutomationForm
