import { Box } from "@chakra-ui/react"
import Body from "components/Body"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import AceEditor from "react-ace"
import { useParams } from "react-router-dom"
import { useGenerateAppConfig } from "./hooks/useGenerateAppConfig"

interface Props {}

const AppPreviewScreen: React.FC<Props> = () => {
  const { appId } = useParams<RouteParams>()
  const { configuration, isLoading } = useGenerateAppConfig(parseInt(appId))

  return (
    <Body isLoading={isLoading} fullWidth={true}>
      <Box>
        <AceEditor
          mode="json"
          theme="github"
          height="600px"
          width="100%"
          fontSize={14}
          value={JSON.stringify(configuration, undefined, 2)}
          editorProps={{ $blockScrolling: false, tabSize: 2 }}
          setOptions={{
            tabSize: 2,
          }}
        />
      </Box>
    </Body>
  )
}

export default AppPreviewScreen
