import React from "react"
import { Box, FlexProps } from "@chakra-ui/react"

interface Props extends FlexProps {}

const Card: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Box
      bg="white"
      p={6}
      boxShadow="md"
      borderWidth={1}
      rounded="lg"
      overflow="hidden"
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Card
