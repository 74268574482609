import React, { useContext } from "react"
import { Button, Text } from "@chakra-ui/react"
import { useLocation, Link } from "react-router-dom"
import { MainSidebarContext } from "./MainSidebar"

interface Props {
  icon: React.ReactElement
  label: string
  to: string
}

const MainSidebarButton: React.FC<Props> = ({ icon, label, to }) => {
  const { expanded } = useContext(MainSidebarContext)
  const location = useLocation()
  const isActive = location.pathname.includes(to)

  const clonedIcon = React.cloneElement(icon, {
    size: 25,
    strokeWidth: 1.5,
  })

  return (
    <Button
      w="full"
      h={12}
      size="lg"
      justifyContent="flex-start"
      leftIcon={clonedIcon}
      px={expanded ? 7 : 4}
      isActive={isActive}
      backgroundColor="transparent"
      color="white"
      borderRadius={expanded ? "none" : "md"}
      _focusVisible={{
        shadow: "none",
      }}
      _hover={{
        backgroundColor: "whiteAlpha.200",
        color: "white",
        shadow: "lg",
      }}
      _active={{
        backgroundColor: "white",
        color: "accent",
        shadow: "lg",
      }}
      as={Link}
      to={to}
      transition="all 0.2s"
    >
      {expanded && <Text ml={1}>{label}</Text>}
    </Button>
  )
}

export default MainSidebarButton
