import React, { useEffect, useState } from 'react'
import { useAuth } from 'hooks/useAuth'

const AuthGate: React.FC = ({ children }) => {
  const [isReady, setIsReady] = useState(false)
  const { reLogin } = useAuth()

  // @todo only try to log again if local storage contains a token
  useEffect(() => {
    const reAuth = async () => {
      try {
        await reLogin()
      } catch (e) {
        console.error(e)
      } finally {
        setIsReady(true)
      }
    }

    reAuth()
  }, [reLogin])

  return !isReady ? null : <>{children}</>
}

export default AuthGate
