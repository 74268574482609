import { Icon, IconButton } from '@chakra-ui/react'
import React from 'react'
import { FiCheckCircle, FiCircle } from 'react-icons/fi'

interface Props {
  value: boolean
  onClick?: () => void
  size?: number
}

const TableBoolean: React.FC<Props> = ({ value, onClick, size = 6 }) => {
  const color = value ? 'green.500' : 'red.500'
  const icon = value ? FiCheckCircle : FiCircle

  return (
    <IconButton
      aria-label="toggle"
      variant="ghost"
      onClick={onClick}
      rounded={100}
      icon={<Icon boxSize={size} color={color} as={icon} />}
    />
  )
}

export default React.memo(TableBoolean)
