import { useMutation, useQueryClient } from "react-query"
import api, { Resources } from "services/api"
import { useCustomToast } from "./useCustomToast"

interface CreateHookOptions {
  showNotification: boolean
  onSuccess?: (data: any) => void
}

export const useCreateEntity = <T>(
  resource: Resources,
  options: CreateHookOptions = { showNotification: true, onSuccess: () => {} },
  params?: any
) => {
  const toast = useCustomToast()
  const queryClient = useQueryClient()
  return useMutation((data: T | T[]) => api.create(resource, data, params), {
    onSuccess: (data: T | T[]) => {
      if (options.showNotification) {
        const title = Array.isArray(data)
          ? `Multiple ${resource} created.`
          : `${resource} created.`

        const description = Array.isArray(data)
          ? `Multiple ${resource} have been succesfully created`
          : `${resource} has been succesfully created`

        toast({
          title,
          description,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      }
      queryClient.invalidateQueries(resource)
      if (options.onSuccess) {
        options.onSuccess(data)
      }
    },
  })
}
