import { Button } from "@chakra-ui/button"
import { Input, InputProps } from "@chakra-ui/input"
import {
  Flex,
  HStack,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
} from "@chakra-ui/react"
import { FiChevronDown } from "react-icons/fi"
import React, { useState, forwardRef } from "react"
import { useFormContext } from "react-hook-form"
import {
  DeploymentVersionOption,
  KeyDeploymentVersionOption,
  deployMessage,
} from "@jackfruit/common"

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION
const REACT_APP_ENV = process.env.REACT_APP_ENV
interface Props extends InputProps {
  clearErrors: (name?: string | string[]) => void
  currentVersion?: string
}

const schemeColors = {
  latest: "yellow",
  current: "green",
}

const GitVersionInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { name = "", clearErrors, currentVersion, ...rest } = props
  const defaultSelected = "current"
  const [isCustom, setIsCustom] = useState(false)
  const { setValue, getValues } = useFormContext()
  const [selectedVersion, setSelectedVersion] =
    useState<KeyDeploymentVersionOption>(defaultSelected)

  const gitVersionOptions = {
    latest:
      REACT_APP_ENV === "production" ? `v${REACT_APP_VERSION}` : "demo-staging",
    current: currentVersion || `v${REACT_APP_VERSION}`,
  }

  const message = isCustom
    ? deployMessage.custom
    : `${deployMessage[selectedVersion]} ${gitVersionOptions[selectedVersion]}`

  const handleCustom = () => {
    setValue(name, currentVersion)
    setIsCustom(true)
  }

  const handleCancelCustom = () => {
    clearErrors(name)
    setIsCustom(false)
    setValue(name, gitVersionOptions[selectedVersion])
  }

  const handleInputGitReference = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.value)
    setIsCustom(true)
  }

  const handleSelectGitReference = (
    gitVersionKey: KeyDeploymentVersionOption
  ) => {
    const gitVersion = gitVersionOptions[gitVersionKey]

    setSelectedVersion(gitVersionKey)
    setValue(name, gitVersion)
  }

  return (
    <>
      <Input name={name} type="hidden" ref={ref} {...rest} />

      {isCustom ? (
        <InputGroup>
          <Input
            defaultValue={getValues(name)}
            onChange={handleInputGitReference}
            maxW={"xs"}
          />
          <Button
            onClick={handleCancelCustom}
            ml={2}
            w={"150px"}
            minW={"150px"}
            bgColor={"red.100"}
          >
            Cancel
          </Button>
        </InputGroup>
      ) : (
        <>
          <HStack w={"full"}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<FiChevronDown />}
                minW={"xs"}
                textAlign={"left"}
              >
                <Flex alignItems="center">
                  {gitVersionOptions[selectedVersion]}
                  <Tag
                    size="md"
                    colorScheme={
                      schemeColors[
                        selectedVersion as keyof DeploymentVersionOption
                      ]
                    }
                    mx={2}
                  >
                    {selectedVersion}
                  </Tag>
                </Flex>
              </MenuButton>
              <MenuList minW={"xs"}>
                {Object.keys(gitVersionOptions).map(key => (
                  <MenuItem
                    key={key}
                    value={key}
                    onClick={() =>
                      handleSelectGitReference(
                        key as KeyDeploymentVersionOption
                      )
                    }
                  >
                    {gitVersionOptions[key as keyof DeploymentVersionOption]}
                    <Tag
                      size="md"
                      colorScheme={
                        schemeColors[key as keyof DeploymentVersionOption]
                      }
                      mx={2}
                    >
                      {key}
                    </Tag>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>

            <Button
              onClick={handleCustom}
              w={"150px"}
              minW={"150px"}
              bgColor={"blue.100"}
            >
              Custom
            </Button>
          </HStack>
          <Text mt={3}>{message}</Text>
        </>
      )}
    </>
  )
})

export default GitVersionInput
