import { Entity, EntityId } from "@jackfruit/common"
import { useCallback, useEffect, useState } from "react"
import api, { Resources } from "services/api"
import { useCustomToast } from "./useCustomToast"

export const useResource = <T>(resource: Resources, id?: EntityId) => {
  const toast = useCustomToast()
  const [newDataAvailable, setNewDataAvailable] = useState(0)
  const [entity, setEntity] = useState<T>({} as T)
  const [isLoading, setIsLoading] = useState(true)

  const onNewDataAvailable = useCallback(
    (entity: Entity) => {
      if (entity.id === id) {
        setNewDataAvailable(state => state + 1)
      }
    },
    [id]
  )

  // register change listeners
  useEffect(() => {
    api.listen(resource, "updated", onNewDataAvailable)
    api.listen(resource, "patched", onNewDataAvailable)

    return () => {
      api.stopListening(resource, "updated", onNewDataAvailable)
      api.stopListening(resource, "patched", onNewDataAvailable)
    }
  }, [resource, onNewDataAvailable])

  const toastError = useCallback(
    error => {
      toast({
        title: `[${resource}] An error occurred.`,
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    },
    [resource, toast]
  )

  useEffect(() => {
    const get = async () => {
      try {
        const result = await api.get(resource, id || 0)
        setEntity(result)
      } catch (error: any) {
        toastError(error)
      } finally {
        setIsLoading(false)
      }
    }
    if (id) {
      get()
    } else {
      setIsLoading(false)
    }
  }, [resource, id, newDataAvailable, toastError])

  return {
    onNewDataAvailable,
    setEntity,
    entity,
    isLoading,
  }
}
