import { Entity } from "@jackfruit/common"
import { useMutation, useQueryClient } from "react-query"
import api, { Resources } from "services/api"
import { useAppRevision } from "./useAppRevision"
import { useCustomToast } from "./useCustomToast"

export const usePatchEntityList = <T extends Entity>(resource: Resources) => {
  const toast = useCustomToast()
  const queryClient = useQueryClient()
  const { bumpRevision } = useAppRevision()

  const patchMultiple = async (data: T[]) => {
    const promises = data.map((e: any) => api.patch(resource, e.id, e))
    return await Promise.all(promises)
  }

  return useMutation((data: T[]) => patchMultiple(data), {
    onMutate: async (data: T[]) => {
      // eslint-disable-next-line array-callback-return
      const promises = data.map(item => {
        queryClient.setQueryData([resource, item.id], (old: any) => {
          return {
            ...old,
            ...data,
          }
        })
      })
      await Promise.all(promises)
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData(resource, previousValue)
    },
    onSuccess: (data: T[]) => {
      queryClient.invalidateQueries(resource)
      toast({
        title: `Multiple ${resource} updated.`,
        description: `${resource} has been succesfully updated`,
        status: "success",
        duration: 3000,
        isClosable: true,
      })
      bumpRevision()
    },
  })
}
