import { Stack } from "@chakra-ui/react"
import React from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import AppNavigationLinkNavigation from "../../navigation-links/navigation/AppNavigationLinkNavigation"
import AppNavigationCRUDScreen from "../AppNavigationCRUDScreen"
import AppNavigationTreeScreen from "../AppNavigationTreeScreen"
import AppNavigationGeneralForm from "../forms/AppNavigationGeneralForm"
import AppNavigationNav from "./AppNavigationNav"

interface Props {}

const AppNavigationNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()
  return (
    <Stack direction="column" alignItems="start" spacing={6}>
      <Switch>
        <Redirect exact from={`${match.url}/edit`} to={`${match.url}`} />
        <Route exact path={`${match.path}`}>
          <AppNavigationNav />
          <AppNavigationCRUDScreen form={AppNavigationGeneralForm} />
          <AppNavigationTreeScreen />
        </Route>
        <Route exact path={`${match.path}/navigation-links`}>
          <AppNavigationTreeScreen />
        </Route>
        <Route path={`${match.path}/navigation-links`}>
          <AppNavigationLinkNavigation />
        </Route>
      </Switch>
    </Stack>
  )
}

export default AppNavigationNavigation
