import { AppEntity } from "@jackfruit/common"
import Fuse from "fuse.js"
import { useMemo, useState } from "react"

const options = {
  keys: ["name"],
  threshold: 0.3,
  useExtendedSearch: true,
}

export const useAppsFuzzySearch = (data: AppEntity[]) => {
  const [search, setSearch] = useState("")

  const fuse = useMemo(() => new Fuse(data, options), [data])

  const apps = useMemo(() => {
    return Boolean(search)
      ? fuse.search(search).map(result => result.item)
      : data
  }, [data, fuse, search])

  return { search, setSearch, apps }
}
