import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import FormActions from "forms/FormActions"
import FormSectionHeader from "components/FormSectionHeader"
import FormContainer from "components/FormContainer"
import { useHistory } from "react-router-dom"
import { SnippetCategoryEntity } from "./SnippetCategoriesListScreen"
import { EntityFormProps } from "forms/EntityFormProps"

interface Props extends EntityFormProps<SnippetCategoryEntity> {}

const SnippetCategoriesForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const history = useHistory()

  const defaultValues = {
    ...entity,
  }

  const { handleSubmit, register, errors } = useForm<SnippetCategoryEntity>({
    defaultValues,
  })

  const enhancedOnSubmit = useCallback(
    (data: SnippetCategoryEntity) => {
      onSubmit(data)
      history.goBack()
    },
    [history, onSubmit]
  )

  return (
    <FormContainer onSubmit={handleSubmit(enhancedOnSubmit)}>
      <FormSectionHeader>Snippet Categories</FormSectionHeader>
      <FormControl isInvalid={errors.name !== undefined}>
        <FormLabel>Category name</FormLabel>
        <InputGroup>
          <Input
            ref={register({ required: true })}
            name="name"
            type="text"
            placeholder="Name"
          />
        </InputGroup>
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default SnippetCategoriesForm
