import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Spinner,
} from "@chakra-ui/react"
import {
  BlockEntity,
  editableBlocks,
  ICustomVisibleBlock,
  LambdaAbVariantEntity,
} from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import VisibleBlockButton from "components/VisibleBlockButton"
import FormActions from "forms/FormActions"
import { useEntityList } from "hooks/useEntityList"
import { usePatchEntity } from "hooks/usePatchEntity"
import { lowerCase } from "lodash-es"
import { RouteParams } from "navigation/RouteParams"
import React, { useMemo, useCallback } from "react"
import { useForm } from "react-hook-form"
import { FiEye, FiEyeOff, FiStar } from "react-icons/fi"
import { useParams } from "react-router"
import { useAppSettings } from "screens/apps/settings/hooks/useAppSettings"

interface Props {
  entity: BlockEntity
  children: React.ReactNode
}

const defaultOption: ICustomVisibleBlock = {
  hidden: { name: "Hidden", icon: <FiEyeOff /> },
  visible: { name: "Visible", icon: <FiEye /> },
}

const BlockContentWrapper: React.FC<Props> = ({ entity, children }) => {
  const { id, type } = entity

  const showBlockIdField = type !== "footer" && type !== "header"

  const defaultValues = {
    ...entity,
  }

  const { appId } = useParams<RouteParams>()
  const { entity: settingsEntity, isLoading: isAppLoading } = useAppSettings(
    parseInt(appId)
  )
  const { lambdaAbEnabled } = settingsEntity

  const {
    data: { data: lambdaAbVariants },
    isLoading: isLoadingAppEntity,
  } = useEntityList<LambdaAbVariantEntity>("lambda-ab-variants", {
    query: {
      settingId: settingsEntity.id,
    },
  })

  const { handleSubmit, register } = useForm<BlockEntity>({
    defaultValues,
  })
  const { mutate: patch } = usePatchEntity<BlockEntity>("blocks")

  const onSubmit = (data: Pick<BlockEntity, "elementId">) => {
    patch({ id, type, ...data })
  }

  let visibleOption: ICustomVisibleBlock = defaultOption

  let newOption: ICustomVisibleBlock = {}

  if (Boolean(lambdaAbEnabled) && lambdaAbVariants.length !== 0) {
    lambdaAbVariants.forEach(variant => {
      newOption[lowerCase(variant.name)] = {
        name: variant.name,
        icon: <FiStar />,
      }
    })
    visibleOption = { ...defaultOption, ...newOption }
  }

  const handleSelectVisible = useCallback(
    (visibleKey: string) => {
      patch({ id, visibility: visibleKey })
    },
    [id, patch]
  )

  const isVisible = useMemo(() => {
    const block = entity?.type?.toLowerCase() || ""
    return editableBlocks.includes(block)
  }, [entity])

  const defaultOptionValue = () => {
    return entity.visibility
  }

  return (
    <Stack spacing={4}>
      {showBlockIdField && (
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          {isVisible && (
            <FormControl>
              <FormLabel>Block Visibility</FormLabel>
              {(isAppLoading || isLoadingAppEntity) && (
                <Spinner color="blue" mx="auto" mt={4} />
              )}
              {!isAppLoading && !isLoadingAppEntity && (
                <VisibleBlockButton
                  data={visibleOption}
                  defaultSelected={defaultOptionValue()}
                  handleSelectVisible={handleSelectVisible}
                />
              )}
            </FormControl>
          )}
          <FormControl>
            <FormLabel>Block Element id</FormLabel>
            <Input type="text" name="elementId" ref={register} />
            <FormHelperText>
              {`An id will be generated in the format of <block_name>_<random_string_16chars> if leave blank`}
            </FormHelperText>
          </FormControl>
          <FormActions canCancel={false} isLoading={false} entity={entity} />
        </FormContainer>
      )}
      {children}
    </Stack>
  )
}

export default BlockContentWrapper
