import { Button, Icon, useDisclosure } from "@chakra-ui/react"
import ConfirmDialog from "components/ConfirmDialog"
import { useCustomToast } from "hooks/useCustomToast"
import React, { useRef, useState } from "react"
import { FiRefreshCw } from "react-icons/fi"
import { useQueryClient } from "react-query"
import api from "services/api"
import { EntityType } from "./PrintServiceConfigurationTable"

export const productsConfigurationContent = {
  app: {
    button: "Synchronize with Autopilot",
    title: "Synchronize print services and products with Autopilot?",
    content:
      "You are about to synchronize all products and print services in the app with Autopilot. This will have the effect of adding, removing or modifying any non-overrided products.\n\nOverrided products will see their main informations updated (as code, short description) but their position and state (enabled/disabled) will remain identical after the sync.",
  },
  page: {
    button: "Synchronize with settings",
    title: "Synchronize page's products configuration?",
    content:
      "You are about to synchronize this products configuration with the one present in the main settings area `Settings > Products Configuration`.",
  },
  block: {
    button: "Synchronize with page",
    title: "Synchronize block's products configuration?",
    content:
      "You are about to synchronize these products with those present on the page products configuration.",
  },
}

interface Props {
  entityType: EntityType
  entityId: number
}

const PrintServiceConfigurationTableAction: React.FC<Props> = ({
  entityType,
  entityId,
}) => {
  const toast = useCustomToast()
  const [isSyncing, setIsSyncing] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const queryClient = useQueryClient()

  const onSynchronize = async () => {
    onClose()
    setIsSyncing(true)
    try {
      if (entityType === "app") {
        await api.create("sync-app-with-remote-api", { id: entityId })
      }
      await api.find("sync-product-configurations-for-entity", {
        query: {
          entityType: entityType,
          entityId: entityId,
        },
      })
    } catch (error: any) {
      console.error(error)
      toast({
        title: `Unable to sync with remote API`,
        description: `Please make sure that Settings > API > Client Key and Base URL are correctly setup, or check the console log for more details`,
        status: "error",
        duration: 10000,
        isClosable: true,
      })
    }
    queryClient.invalidateQueries("print-service-configurations")
    queryClient.invalidateQueries("print-service-product-configurations")
    setIsSyncing(false)
  }

  return (
    <>
      <Button
        colorScheme="primary"
        leftIcon={<Icon as={FiRefreshCw} />}
        onClick={onOpen}
        isLoading={isSyncing}
      >
        {productsConfigurationContent[entityType].button}
      </Button>

      <ConfirmDialog
        text={{
          title: productsConfigurationContent[entityType].title,
          content: productsConfigurationContent[entityType].content,
        }}
        isOpen={isOpen}
        cancelRef={cancelRef}
        onCancel={onClose}
        onConfirm={onSynchronize}
      />
    </>
  )
}

export default PrintServiceConfigurationTableAction
