import { Entity, EntityId } from "../Entity"
import { BlockCartEntityHydrated } from "./BlockCartEntity"
import { BlockCheckoutEntityHydrated } from "./BlockCheckoutEntity"
import { BlockFooterEntityHydrated } from "./BlockFooterEntity"
import { BlockFulfillmentEntityHydrated } from "./BlockFulfillmentEntity"
import { BlockGraphEntityHydrated } from "./BlockGraphEntity"
import { BlockHeaderEntityHydrated } from "./BlockHeaderEntity"
import { BlockHtmlEntityHydrated } from "./BlockHtmlEntity"
import { BlockImageGridEntityHydrated } from "./BlockImageGridEntity"
import { BlockImageUploadEntityHydrated } from "./BlockImageUploadEntity"
import { BlockOrderCompleteEntityHydrated } from "./BlockOrderCompleteEntity"
import { BlockOrderThankYouEntityHydrated } from "./BlockOrderThankYouEntity"
import { BlockPhotosUploadedEntityHydrated } from "./BlockPhotosUploadedEntity"
import { BlockProductGridEntityHydrated } from "./BlockProductGridEntity"
import { BlockRichTextEntityHydrated } from "./BlockRichTextEntity"
import { BlockSocialMediaEntityHydrated } from "./BlockSocialMediaEntity"
import { BlockStoreSearchEntityHydrated } from "./BlockStoreSearchEntity"
import { BlockSubpageGridEntityHydrated } from "./BlockSubpageGridEntity"
import { BlockTemplateEntityHydrated } from "./BlockTemplateEntity"
import { BlockVideoEntityHydrated } from "./BlockVideoEntity"

export const allBlockTypes = [
  "header",
  "footer",
  "rich-text",
  "html",
  "image-upload",
  "cart",
  "fulfillment",
  "checkout",
  "store-search",
  "image-grid",
  "order-complete",
  "photos-uploaded",
  "order-thank-you",
  "template",
  "social-media",
  "subpage-grid",
  "product-grid",
  "graph",
  "video",
] as const

export type BlockType = typeof allBlockTypes[number]

export interface BlockEntity extends Entity {
  blockId: EntityId
  pageId: EntityId
  appId: EntityId
  childId: EntityId
  type: BlockType
  elementId: string
  order: number
  inherit: boolean
  visibility: string
}

export interface BlockEntityHydrated extends BlockEntity {
  header: BlockHeaderEntityHydrated
  html: BlockHtmlEntityHydrated
  imageUpload: BlockImageUploadEntityHydrated
  richText: BlockRichTextEntityHydrated
  cart: BlockCartEntityHydrated
  fulfillment: BlockFulfillmentEntityHydrated
  checkout: BlockCheckoutEntityHydrated
  storeSearch: BlockStoreSearchEntityHydrated
  imageGrid: BlockImageGridEntityHydrated
  footer: BlockFooterEntityHydrated
  orderComplete: BlockOrderCompleteEntityHydrated
  photosUploaded: BlockPhotosUploadedEntityHydrated
  orderThankYou: BlockOrderThankYouEntityHydrated
  template: BlockTemplateEntityHydrated
  socialMedia: BlockSocialMediaEntityHydrated
  subpageGrid: BlockSubpageGridEntityHydrated
  productGrid: BlockProductGridEntityHydrated
  graph: BlockGraphEntityHydrated
  video: BlockVideoEntityHydrated
}
