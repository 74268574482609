import { FormControl, FormErrorMessage, InputGroup } from "@chakra-ui/react"
import { SettingsEntity } from "@jackfruit/common"
import AceEditorEnhanced from "components/AceEditorEnhanced"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { Controller, useForm } from "react-hook-form"

type FormData = Pick<SettingsEntity, "loggingMode" | "rawHead" | "robotsTxt">

interface Props {
  entity: SettingsEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppSettingsDevelopmentCustomCSSAndJavascriptForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const { loggingMode, rawHead, robotsTxt } = entity

  const { handleSubmit, control, errors } = useForm<FormData>({
    defaultValues: {
      loggingMode,
      rawHead,
      robotsTxt,
    },
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Custom CSS and Javascript</FormSectionHeader>
      <FormControl isInvalid={!!errors.rawHead}>
        <InputGroup boxShadow="xs" borderRadius={8} overflow="hidden">
          <Controller
            control={control}
            name="rawHead"
            render={({ onChange, value, name }) => (
              <AceEditorEnhanced
                mode="html"
                name={name}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.rawHead && errors.rawHead.message}
        </FormErrorMessage>
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppSettingsDevelopmentCustomCSSAndJavascriptForm
