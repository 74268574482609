import { ValueError } from "@sinclair/typebox/build/cjs/errors"
import { C } from "./configSchema"

interface ValidationResult {
  isValid: boolean
  hasErrors: boolean
  errors: ValueError[]
}

export const validateConfig = (data: any): ValidationResult => {
  const result = C.Check(data)
  let errors: ValueError[] = []

  if (!result) {
    const currentErrors = [...C.Errors(data)]
    errors = currentErrors
  }

  return {
    isValid: result,
    hasErrors: errors.length > 0,
    errors,
  }
}
