import { Box, Flex } from "@chakra-ui/react"
import { usePageTitle } from "hooks/usePageTitle"
import React from "react"
import { useTitle } from "react-use"

interface Props {
  secondaryNavigation?: React.ReactNode
  children: React.ReactNode
}

const LayoutPage: React.FC<Props> = ({ children, secondaryNavigation }) => {
  const title = usePageTitle()
  useTitle(title)

  return (
    <Flex flex={1}>
      {secondaryNavigation}
      <Box w="full" bg="gray.50">
        {children}
      </Box>
    </Flex>
  )
}

export default LayoutPage
