import React from "react"
import { Link } from "@chakra-ui/react"
import { useRouteMatch, Link as ReactRouterLink } from "react-router-dom"

interface Props {
  entity: any
}

const TableRowId: React.FC<Props> = ({ entity }) => {
  const match = useRouteMatch()
  return (
    <Link fontSize="sm" as={ReactRouterLink} to={`${match.url}/${entity.id}`}>
      #{entity.id}
    </Link>
  )
}

export default TableRowId
