import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
} from "@chakra-ui/react"
import { BlockTemplateEntity, EntityId } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import TagInput from "forms/inputs/TagInput"
import React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { Link, useRouteMatch } from "react-router-dom"

interface Props {
  entity: BlockTemplateEntity
  isLoading: boolean
  onSubmit: (data: BlockTemplateEntity) => void
}

const BlockTemplateForm: React.FC<Props> = ({
  entity,
  isLoading,
  onSubmit,
}) => {
  const match = useRouteMatch()
  const basePath = match.url

  const defaultValues = {
    ...entity,
    productsIds: [],
  }

  const { blockId } = entity

  const hookFormMethods = useForm<BlockTemplateEntity>({
    defaultValues,
  })
  const { handleSubmit, control, register } = hookFormMethods

  const enhancedOnSubmit = (
    data: BlockTemplateEntity & { productsIds: EntityId[] }
  ) => {
    const { productsIds, ...rest } = data
    onSubmit(rest)
  }

  return (
    <FormProvider {...hookFormMethods}>
      <FormContainer onSubmit={handleSubmit(enhancedOnSubmit)}>
        <FormControl>
          <FormLabel>Title</FormLabel>
          <InputGroup>
            <Input type="text" name="title" ref={register} />
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Subtitle</FormLabel>
          <InputGroup>
            <Input type="text" name="subtitle" ref={register} />
          </InputGroup>
        </FormControl>
        <FormControl>
          <Checkbox ref={register} name="isCTAButtonEnabled">
            Enable CTA Button
          </Checkbox>
          <InputGroup>
            <Input
              ref={register}
              type="text"
              name="ctaButtonText"
              placeholder="CTA button text"
            />
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Tags</FormLabel>
          <InputGroup>
            <Controller
              name="tags"
              control={control}
              render={({ onChange, value }) => {
                return <TagInput onChange={onChange} value={value} />
              }}
            />
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Maximum items on a single page</FormLabel>
          <NumberInput defaultValue={entity?.itemsPerPage} step={1} min={1}>
            <NumberInputField ref={register} name="itemsPerPage" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>Maximum columns (for desktop breakpoint)</FormLabel>
          <NumberInput defaultValue={entity.maxDesktopColumns} step={1} min={1}>
            <NumberInputField ref={register} name="maxDesktopColumns" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>Minimum columns (for mobile breakpoint)</FormLabel>
          <InputGroup>
            <Select ref={register} name="minMobileColumns">
              <option value={1}>1</option>
              <option value={2}>2</option>
            </Select>
          </InputGroup>
        </FormControl>
        <FormControl>
          <Checkbox ref={register} name="isRestrictedToActivePrintServices">
            Restrict templates to active print services
          </Checkbox>
          <FormHelperText maxW="500px" mb={3}>
            Restrict templates to the active print service, useful for folded
            card as the back face can contains a specific logo (Walgreens, CVS
            etc...).
          </FormHelperText>
        </FormControl>
        <Button as={Link} to={`${basePath}/${blockId}/product-configuration`}>
          Configure Products
        </Button>
        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default BlockTemplateForm
