import { BlockCheckoutEntity, PageEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import BlockCheckoutFields from "./BlockCheckoutFields"

interface Props {
  entity: BlockCheckoutEntity
  pages: PageEntity[]
  isLoading: boolean
  readOnly: boolean
  onSubmit: (data: BlockCheckoutEntity) => void
}

const BlockCheckoutForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
  pages,
  readOnly = false,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<BlockCheckoutEntity>({ defaultValues })
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <BlockCheckoutFields
          readOnly={readOnly}
          entity={entity}
          prefix={false}
          pages={pages}
        />

        <FormActions
          canCancel={false}
          isLoading={isLoading}
          entity={entity}
          readOnly={readOnly}
        />
      </FormContainer>
    </FormProvider>
  )
}

export default BlockCheckoutForm
