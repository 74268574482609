import { Flex, Spinner } from "@chakra-ui/react"
import {
  NavigationMegaMenuCellEntityHydrated,
  PageEntity,
} from "@jackfruit/common"
import { useResourceList } from "hooks/useResourceList"
import React, { useState } from "react"
import { Droppable } from "react-beautiful-dnd"
import { useParams } from "react-router-dom"
import MegaMenuAddLinkButton from "./MegaMenuAddLinkButton"
import MegaMenuCellLink from "./MegaMenuCellLink"

interface RouteParams {
  appId: string
}

interface Props {
  cell: NavigationMegaMenuCellEntityHydrated
}

const MegaMenuCellLinks: React.FC<Props> = ({ cell }) => {
  const { appId } = useParams<RouteParams>()

  const [pageQuery] = useState({
    query: {
      appId,
      $limit: 1000,
      $sort: {
        name: 1,
      },
    },
  })

  const {
    data: { data: pages },
    isLoading,
  } = useResourceList<PageEntity>("pages", pageQuery)

  if (isLoading) {
    return (
      <Flex height="20vh">
        <Spinner />
      </Flex>
    )
  }

  return (
    <Droppable droppableId={`drop-cell#${cell.id}`} type="link">
      {provided => {
        return (
          <Flex
            ref={provided.innerRef}
            {...provided.droppableProps}
            direction="column"
            borderRadius="md"
          >
            {cell.links.map((link, index) => {
              return (
                <MegaMenuCellLink
                  key={link.id}
                  link={link}
                  pages={pages}
                  index={index}
                />
              )
            })}
            {provided.placeholder}

            <MegaMenuAddLinkButton megaMenuCellId={cell.id} />
          </Flex>
        )
      }}
    </Droppable>
  )
}

export default MegaMenuCellLinks
