import React from 'react'
import { Flex, Center, FlexProps } from '@chakra-ui/react'

interface Props extends FlexProps {}

const LayoutCentered: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Flex bg="gray.100" h="100vh" direction="column" justifyContent="center" {...rest}>
      <Center>{children}</Center>
    </Flex>
  )
}

export default LayoutCentered
