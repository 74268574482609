import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react"
import { FC, useCallback, useState } from "react"
import { BiSearch } from "react-icons/bi"
import { RiCloseLine } from "react-icons/ri"
import { useDebounce } from "react-use"

interface Props {
  onChangeSearch: (search: string) => void
}

const SearchBar: FC<Props> = ({ onChangeSearch }) => {
  const [value, setValue] = useState("")

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value)
    },
    [setValue]
  )

  const onClick = useCallback(() => {
    setValue("")
  }, [setValue])

  useDebounce(
    () => {
      onChangeSearch(value)
    },
    50,
    [value]
  )

  return (
    <InputGroup size="md" w="60%">
      <Input
        bg="white"
        paddingRight={10}
        placeholder="Search"
        focusBorderColor="gray.200"
        borderColor="gray.200"
        borderWidth={2}
        variant="outline"
        onChange={onChange}
        value={value}
      />
      <InputRightElement h="inherit">
        <IconButton
          aria-label="search"
          icon={
            <Icon
              as={value ? RiCloseLine : BiSearch}
              color="gray.300"
              h={8}
              w={5}
            />
          }
          variant="unstyled"
          _focus={{ focusBorderColor: "none" }}
          onClick={onClick}
        />
      </InputRightElement>
    </InputGroup>
  )
}

export default SearchBar
