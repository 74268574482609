import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react"
import { AppEntity, PageEntity } from "@jackfruit/common"
import PageSelectInput from "components/AutocompleteDropDown/PageSelectInput"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import { EntityFormProps } from "forms/EntityFormProps"
import FormActions from "forms/FormActions"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"

interface Props extends EntityFormProps<AppEntity> {
  pages: PageEntity[]
}

const AppBrandingGeneralLegalsForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
  pages,
}) => {
  const defaultValues = {
    ...entity,
  }
  const methods = useForm<AppEntity>({
    defaultValues,
  })
  const { handleSubmit, errors } = methods

  const enhancedOnSubmit = async (data: any) => {
    data.termsAndConditionsPageId = data.termsAndConditionsPageId || null
    data.privacyPolicyPageId = data.privacyPolicyPageId || null
    onSubmit(data)
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedOnSubmit)}>
        <FormSectionHeader>Legals</FormSectionHeader>
        <FormControl isInvalid={!!errors.termsAndConditionsPageId}>
          <FormLabel>Terms and Conditions URL</FormLabel>
          <PageSelectInput name="termsAndConditionsPageId" pages={pages} />
          <FormErrorMessage>
            {errors.termsAndConditionsPageId &&
              errors.termsAndConditionsPageId.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.privacyPolicyPageId}>
          <FormLabel>Privacy Policy URL</FormLabel>
          <PageSelectInput name="privacyPolicyPageId" pages={pages} />
          <FormErrorMessage>
            {errors.privacyPolicyPageId && errors.privacyPolicyPageId.message}
          </FormErrorMessage>
        </FormControl>
        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default AppBrandingGeneralLegalsForm
