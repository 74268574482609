import React from "react"
import { useHistory, Link } from "react-router-dom"
import { FiX } from "react-icons/fi"
import Body from "components/Body"
import Page from "components/Page"
import UserForm from "forms/UserForm"
import { useResourceActions } from "hooks/useResourceActions"
import { Button, Icon } from "@chakra-ui/react"

interface Props {}

const Actions = () => {
  return (
    <Link to={`/admin/users`}>
      <Button colorScheme="primary" leftIcon={<Icon as={FiX} />}>
        Cancel
      </Button>
    </Link>
  )
}

const UserCreateScreen: React.FC<Props> = () => {
  const { isCreating, create } = useResourceActions("users")
  const history = useHistory()

  const onSubmit = async (data: any) => {
    const user = await create(data)
    history.push(`/admin/users/${user.id}`)
  }

  return (
    <Page title="Create New User" headerActions={<Actions />}>
      <Body>
        <UserForm onSubmit={onSubmit} isLoading={isCreating} />
      </Body>
    </Page>
  )
}

export default UserCreateScreen
