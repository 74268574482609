import { Box } from '@chakra-ui/react'
import { BlockEntity, BlockSocialMediaEntity } from '@jackfruit/common'
import { useEntity } from 'hooks/useEntity'
import { usePatchEntity } from 'hooks/usePatchEntity'
import React from 'react'
import BlockSocialMediaForm from '../forms/BlockSocialMediaForm'

interface Props {
  block: BlockEntity
}

const BlockSocialMedia: React.FC<Props> = ({ block }) => {
  const { data: entity, isLoading } = useEntity<BlockSocialMediaEntity>(
    'blocks-social-media',
    block.childId
  )

  const { mutate: saveBlock, status: patchStatus } =
    usePatchEntity<BlockSocialMediaEntity>('blocks-social-media')

  const isPatching = patchStatus === 'loading'

  const onSubmit = (data: any) => {
    saveBlock({ id: entity.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockSocialMediaForm
      entity={entity}
      isLoading={isPatching}
      onSubmit={onSubmit}
    />
  )
}

export default BlockSocialMedia
