import { FeathersError } from "@feathersjs/errors"
import { EntityId } from "@jackfruit/common"
import { useMutation, useQueryClient } from "react-query"
import api, { Resources } from "services/api"
import { useCustomToast } from "./useCustomToast"
import { useCurrentAppId } from "./useCurrentAppId"

interface DeleteHookOptions {
  showNotification: boolean
  invalidateQuery?: boolean
  onSuccess: (resp?: any) => void
  params?: null | Object
}

export const useDeleteEntity = (
  resource: Resources,
  options: DeleteHookOptions = {
    showNotification: true,
    invalidateQuery: true,
    onSuccess: () => {},
    params: null,
  }
) => {
  const appId = useCurrentAppId()

  const toast = useCustomToast()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: EntityId | null) =>
      await api.remove(resource, id, { ...options.params, appId }),
    {
      onError: (resp: FeathersError) => {
        toast({
          title: `An error occured`,
          description: resp.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      },
      onSuccess: resp => {
        if (options.showNotification) {
          toast({
            title: `${resource} deleted.`,
            description: `${resource} has been succesfully deleted`,
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        }

        if (options.invalidateQuery) {
          queryClient.invalidateQueries(resource)
        }

        if (resp) {
          options.onSuccess(resp)
        }
      },
    }
  )
}
