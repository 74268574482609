import {
  Badge,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import {
  maxLimitDefault,
  PrintServiceConfigurationEntity,
} from "@jackfruit/common"
import Body from "components/Body"
import { ColumnDefinition } from "components/Table"
import TableResetField from "components/TableResetField"
import TableRowActions from "components/TableRowActions"
import TableSort from "components/TableSort"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { BiChevronDown } from "react-icons/bi"
import { BsCheck2Circle, BsCircle } from "react-icons/bs"
import { GrSatellite } from "react-icons/gr"
import PrintServiceConfigurationTableAction from "./PrintServiceConfigurationTableAction"

export type EntityType = "app" | "page" | "block"

interface Props {
  canGoBack?: boolean
  title: string
  entityType: EntityType
  entityId: number
}

interface EnabledModeDropdownProps {
  printServiceConfiguration: PrintServiceConfigurationEntity
}

const EnabledModeDropdown: React.FC<EnabledModeDropdownProps> = ({
  printServiceConfiguration,
}) => {
  const { isEnabled, hasProductsAutoEnabled } = printServiceConfiguration

  const { mutateAsync: patchPrintServiceConfiguration } =
    usePatchEntity<PrintServiceConfigurationEntity>(
      "print-service-configurations"
    )

  const handleDisable = async () => {
    await patchPrintServiceConfiguration({
      id: printServiceConfiguration.id,
      isEnabled: false,
      hasProductsAutoEnabled: false,
    })
  }
  const handleEnable = async () => {
    await patchPrintServiceConfiguration({
      id: printServiceConfiguration.id,
      isEnabled: true,
      hasProductsAutoEnabled: false,
    })
  }
  const handleAutomatic = async () => {
    await patchPrintServiceConfiguration({
      id: printServiceConfiguration.id,
      isEnabled: true,
      hasProductsAutoEnabled: true,
    })
  }

  const isDisabled = !isEnabled
  const isOnlyEnabled = isEnabled && !hasProductsAutoEnabled
  const isAutomatic = isEnabled && hasProductsAutoEnabled

  const disabledIcon = <BsCircle />
  const onlyEnabledIcon = <BsCheck2Circle />
  const automaticIcon = <GrSatellite />

  const disabledColor = "gray.400"
  const onlyEnabledColor = "green.500"
  const automaticColor = "primary.500"

  let icon = automaticIcon
  let color = automaticColor
  if (isDisabled) {
    icon = disabledIcon
    color = disabledColor
  } else if (isOnlyEnabled) {
    icon = onlyEnabledIcon
    color = onlyEnabledColor
  } else if (isAutomatic) {
    color = automaticColor
    icon = automaticIcon
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        aria-label="Options"
        variant="ghost"
        color={color}
        leftIcon={icon}
        rightIcon={<BiChevronDown />}
      />
      <MenuList>
        <MenuItem
          color={disabledColor}
          icon={disabledIcon}
          onClick={handleDisable}
        >
          Disabled
        </MenuItem>
        <MenuItem
          color={onlyEnabledColor}
          icon={onlyEnabledIcon}
          onClick={handleEnable}
        >
          Enabled
        </MenuItem>
        <MenuItem
          color={automaticColor}
          icon={automaticIcon}
          onClick={handleAutomatic}
        >
          Automatic
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

const columns: ColumnDefinition[] = [
  {
    header: "Enable Mode",
    accessor: "isEnabled",
    render: (printService: PrintServiceConfigurationEntity) => {
      return <EnabledModeDropdown printServiceConfiguration={printService} />
    },
  },
  {
    header: "Remote Id",
    accessor: "remoteId",
  },
  {
    header: "Total",
    accessor: "totalProductCount",
  },
  {
    header: "Enabled",
    accessor: "enabledProductCount",
  },
  {
    header: "Unavailable",
    accessor: "unavailableProductCount",
  },
  {
    header: "Display Name",
    accessor: "displayName",
  },
  {
    header: "Fulfillment Type",
    accessor: "fulfillmentType",
    render: (printService: PrintServiceConfigurationEntity) => (
      <Badge colorScheme="blue">{printService.fulfillmentType}</Badge>
    ),
  },

  {
    header: "",
    accessor: "actions",
    render: (printService: PrintServiceConfigurationEntity) => {
      const { isEnabled, hasProductsAutoEnabled } = printService
      const isAutomatic = isEnabled && hasProductsAutoEnabled

      if (isAutomatic) {
        return null
      }

      return (
        <TableRowActions
          resource="print-service-configurations"
          entity={{ id: printService.printServiceId }}
          canView={true}
          canEdit={false}
          canDelete={false}
        />
      )
    },
  },
  {
    header: "",
    render: (printService: PrintServiceConfigurationEntity) => {
      if (printService.hasBeenOverriden)
        return (
          <TableResetField
            resource="print-service-configurations"
            entity={printService}
            fields={["isEnabled"]}
          />
        )
    },
  },
]

const PrintServiceConfigurationTable: React.FC<Props> = ({
  canGoBack = false,
  title,
  entityType,
  entityId,
}) => {
  const query = {
    entityType,
    entityId,
    $withProductsCount: true,
  }
  return (
    <Body
      canGoBack={canGoBack}
      fullWidth={true}
      padding={0}
      title={title}
      headerActions={
        <PrintServiceConfigurationTableAction
          entityType={entityType}
          entityId={entityId}
        />
      }
    >
      <TableSort
        resource="print-service-configurations"
        columns={columns}
        query={query}
        limit={maxLimitDefault}
      />
    </Body>
  )
}

export default PrintServiceConfigurationTable
