import React from 'react'
import { Text } from '@chakra-ui/react'

interface Props {}

const Label: React.FC<Props> = ({ children }) => {
  return <Text>{children}</Text>
}

export default Label
