import { Flex, Text } from "@chakra-ui/react"
import React from "react"
import DeploymentStepIndicator from "./DeploymentStepIndicator"

export type StepStatus = "processing" | "pending" | "completed" | "failed"
export type StepType = "setup" | "build" | "deploy" | "completed" | "test"

const labels = {
  setup: "Preparing Build",
  build: "Building Website",
  deploy: "Deploying",
  completed: "Completed",
  test: "Testing",
}

export interface Step {
  type: StepType
  progress: number
}

interface Props {
  stepType: StepType
  step?: Step
  status: StepStatus
  index: number
  isLast?: boolean
}

const DeploymentStep: React.FC<Props> = ({
  index,
  status,
  stepType,
  isLast = false,
}) => {
  return (
    <>
      <Flex borderColor="blackAlpha.200" alignItems="center">
        <DeploymentStepIndicator status={status} index={index} />
        <Flex ml={3}>
          <Text fontWeight="medium">{labels[stepType]}</Text>
        </Flex>
      </Flex>

      {!isLast && (
        <Flex
          ml={3}
          my={1}
          width="5px"
          backgroundColor={
            status === "completed" ? "green.300" : "blackAlpha.200"
          }
          height="36px"
        />
      )}
    </>
  )
}

export default DeploymentStep
