import { Badge, Button, Icon, Text } from "@chakra-ui/react"
import { UserEntity } from "@jackfruit/common"
import Body from "components/Body"
import Page from "components/Page"
import Table, { ColumnDefinition } from "components/Table"
import TableRowActions from "components/TableRowActions"
import TableRowId from "components/TableRowId"
import { format } from "date-fns"
import React from "react"
import { FiPlusCircle } from "react-icons/fi"
import { Link, useRouteMatch } from "react-router-dom"

interface Props {}

const columns: ColumnDefinition[] = [
  {
    header: "Id",
    render: (user: UserEntity) => <TableRowId entity={user} />,
  },
  {
    header: "Username",
    render: (user: UserEntity) => (
      <Text as={user.isActive ? "text" : "s"}>{user.email}</Text>
    ),
  },
  {
    header: "Role",
    render: (user: UserEntity) =>
      user.isActive ? (
        <Badge colorScheme="green">{user.role}</Badge>
      ) : (
        <Badge colorScheme="red">Disabled</Badge>
      ),
  },
  {
    header: "Updated",
    render: (user: UserEntity) =>
      format(new Date(user.createdAt!), "yyyy/MM/dd"),
  },
  {
    header: "Created",
    render: (user: UserEntity) =>
      format(new Date(user.updatedAt!), "yyyy/MM/dd"),
  },
  {
    header: "",
    accessor: "actions",
    render: user => (
      <TableRowActions
        resource="users"
        entity={user}
        canView={false}
        canEdit={true}
        canDelete={true}
      />
    ),
  },
]

const Actions = () => {
  const { url } = useRouteMatch()
  return (
    <Link to={`${url}/new`}>
      <Button leftIcon={<Icon as={FiPlusCircle} />}>Create User</Button>
    </Link>
  )
}

const UserListScreen: React.FC<Props> = () => {
  return (
    <Page title="Users">
      <Body title="All Users" headerActions={<Actions />} padding={0}>
        <Table resource="users" columns={columns} />
      </Body>
    </Page>
  )
}

export default UserListScreen
