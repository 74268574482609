import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { BlockEntity, BlockType, EntityId, PageEntity } from "@jackfruit/common"
import AppPreview from "components/AppPreview/AppPreview"
import AppPreviewMobileDevices from "components/AppPreview/AppPreviewMobileDevices"
import AppPreviewTabletDevices from "components/AppPreview/AppPreviewTabletDevices"
import Body from "components/Body"
import FormSectionHeader from "components/FormSectionHeader"
import { NavbarConfig } from "components/Navbar/Navbar"
import blocksDefaultValues from "data/blocksDefaultValues"
import { useAppRevision } from "hooks/useAppRevision"
import { useBlocksEntities } from "hooks/useBlocksEntities"
import { useCreateEntity } from "hooks/useCreateEntity"
import { useEntity } from "hooks/useEntity"
import { RouteParams } from "navigation/RouteParams"
import React, { useCallback, useState } from "react"
import { FiPlusCircle } from "react-icons/fi"
import {
  MdOutlineDesktopMac,
  MdOutlineFullscreen,
  MdOutlinePhoneAndroid,
  MdTabletMac,
} from "react-icons/md"
import { useParams } from "react-router-dom"
import { app } from "services/api"
import { getStorybookPreviewUrl } from "services/storybook"
import BlockEditor from "./blocks/BlockEditor"
import BlockSortableList from "./blocks/BlockSortableList"
import BlockToolBox from "./blocks/BlockToolBox"
import AppPageNav from "./navigation/AppPageNav"

interface Props {}

const AppPageBlockScreen: React.FC<Props> = () => {
  const { pageId, appId } = useParams<RouteParams>()
  const { blocks } = useBlocksEntities({ appId, pageId })
  const {
    isOpen: isBlockModalOpen,
    onClose: onCloseBlockModal,
    onOpen: onOpenBlockModal,
  } = useDisclosure()
  const [currentPreviewMode, setCurrentPreviewMode] = useState<
    "desktop" | "mobile" | "tablet"
  >("desktop")

  const [selectedBlockId, setSelectedBlockId] = useState<EntityId>("")
  const { bumpRevision } = useAppRevision()

  const { mutateAsync: create } = useCreateEntity<Partial<BlockEntity>>(
    "blocks",
    {
      showNotification: true,
      onSuccess: async (data: BlockEntity) => {
        const type = data.type
        if (blocksDefaultValues[type]) {
          await app
            .service(`blocks-${type}`)
            .patch(data.childId, blocksDefaultValues[type])
        }
      },
    }
  )

  const { isLoading: isLoadingPage } = useEntity<PageEntity>(
    "pages",
    parseInt(pageId)
  )

  const onAddBlock = useCallback(
    async (type: BlockType, index?: number) => {
      const newBlock = await create({
        type,
        pageId: parseInt(pageId),
        order: blocks.total + 1,
      })
      const newBlockEntity = newBlock as BlockEntity

      onCloseBlockModal()
      bumpRevision()
      setSelectedBlockId(newBlockEntity.id)
    },
    [blocks.total, bumpRevision, create, onCloseBlockModal, pageId]
  )

  const extraItems: NavbarConfig = {
    items: [
      {
        type: "component",
        component: <Flex flex={1} />,
      },
      {
        type: "component",
        component: (
          <IconButton
            icon={<MdOutlineDesktopMac />}
            size="sm"
            aria-label="move"
            rounded="full"
            variant="ghost"
            isActive={currentPreviewMode === "desktop"}
            onClick={() => setCurrentPreviewMode("desktop")}
          />
        ),
      },
      {
        type: "component",
        component: (
          <IconButton
            icon={<MdTabletMac />}
            size="sm"
            aria-label="move"
            rounded="full"
            variant="ghost"
            isActive={currentPreviewMode === "tablet"}
            onClick={() => setCurrentPreviewMode("tablet")}
          />
        ),
      },
      {
        type: "component",
        component: (
          <IconButton
            icon={<MdOutlinePhoneAndroid />}
            size="sm"
            aria-label="move"
            rounded="full"
            variant="ghost"
            isActive={currentPreviewMode === "mobile"}
            onClick={() => setCurrentPreviewMode("mobile")}
          />
        ),
      },
      {
        type: "component",
        component: (
          <IconButton
            icon={<MdOutlineFullscreen />}
            size="sm"
            aria-label="move"
            rounded="full"
            variant="ghost"
            onClick={() => window.open(fullPreviewUrl, "_blank")}
          />
        ),
      },
    ],
  }

  const fullPreviewUrl = getStorybookPreviewUrl({
    appId,
    pageId,
  })

  return (
    <Box w="full">
      <AppPageNav extra={extraItems} />
      <Body variant="transparent" fullWidth={true} isLoading={isLoadingPage}>
        <Flex mt={4}>
          <Box w="250px">
            <Box
              backgroundColor="white"
              p={3}
              borderRadius="md"
              borderWidth={1}
            >
              <VStack w="full" alignItems="stretch" spacing={3}>
                <FormSectionHeader>Blocks</FormSectionHeader>
                <BlockSortableList
                  appId={appId}
                  pageId={pageId}
                  onSelect={setSelectedBlockId}
                  selectedBlockId={selectedBlockId}
                />
                <Button
                  variant="ghost"
                  colorScheme="primary"
                  justifyContent="flex-start"
                  leftIcon={<Icon as={FiPlusCircle} />}
                  onClick={onOpenBlockModal}
                >
                  Add Block
                </Button>
              </VStack>
              <Modal
                isOpen={isBlockModalOpen}
                onClose={onCloseBlockModal}
                size="xl"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Add Block</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <BlockToolBox onAdd={onAddBlock} />
                  </ModalBody>
                  <ModalFooter></ModalFooter>
                </ModalContent>
              </Modal>
            </Box>
          </Box>

          <Flex flex={1} direction="column">
            {currentPreviewMode === "desktop" && (
              <AppPreview appId={appId} pageId={pageId} />
            )}
            {currentPreviewMode === "mobile" && (
              <AppPreviewMobileDevices appId={appId} pageId={pageId} />
            )}
            {currentPreviewMode === "tablet" && (
              <AppPreviewTabletDevices appId={appId} pageId={pageId} />
            )}
          </Flex>

          <Box w="350px">
            {selectedBlockId && (
              <Box
                backgroundColor="white"
                p={3}
                borderRadius="md"
                borderWidth={1}
              >
                <VStack w="full" alignItems="stretch" spacing={3}>
                  <BlockEditor id={selectedBlockId} key={selectedBlockId} />
                </VStack>
              </Box>
            )}
          </Box>
        </Flex>
      </Body>
    </Box>
  )
}

export default AppPageBlockScreen
