import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { FiLock, FiMail } from "react-icons/fi"
import FormActions from "./FormActions"
import FormContainer from "../components/FormContainer"
import { useAuthState } from "hooks/useAuthState"

interface Props {
  user?: any
  onSubmit: (data: any) => void
  isLoading: boolean
}

const UserForm: React.FC<Props> = ({ user, onSubmit, isLoading }) => {
  const [changePassword, setChangePassword] = useState(false)
  //check if the current user is admin
  const {
    user: { role },
  } = useAuthState()
  const isAdmin = role === "admin"

  const defaultValues = {
    email: user?.email,
    role: user?.role,
    password: "",
    passwordConfirm: "",
    isActive: user?.isActive,
  }
  const { handleSubmit, register, errors, watch } = useForm({
    defaultValues,
  })

  const validatePasswordConfirm = (passwordConfirm: string) => {
    if (passwordConfirm === watch("password")) {
      return true
    } else {
      return "Password does not match"
    }
  }

  const beforeSubmit = (data: any) => {
    const sanitized: any = {
      email: data.email,
      role: data.role,
      isActive: data.isActive,
    }
    if (changePassword || !user) {
      sanitized.password = data.password
    }
    onSubmit(sanitized)
  }

  return (
    <FormContainer onSubmit={handleSubmit(beforeSubmit)}>
      <FormControl isInvalid={errors.email}>
        <FormLabel>Email address</FormLabel>
        <InputGroup>
          <InputLeftElement children={<Icon as={FiMail} color="gray.300" />} />
          <Input ref={register} name="email" type="text" placeholder="Email" />
        </InputGroup>
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.email}>
        <FormLabel>Role</FormLabel>
        <InputGroup>
          <Select name="role" ref={register()} disabled={!isAdmin}>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </Select>
        </InputGroup>
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.isActive}>
        <FormLabel>Status</FormLabel>
        <InputGroup>
          <Select name="isActive" ref={register()} disabled={!isAdmin}>
            <option value="1">Active</option>
            <option value="0">Disabled</option>
          </Select>
        </InputGroup>
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      {user && !changePassword && (
        <Button
          variant="outline"
          colorScheme="red"
          onClick={() => setChangePassword(true)}
        >
          Change Password
        </Button>
      )}
      {(!user || changePassword) && (
        <>
          <FormControl isInvalid={errors.password !== undefined}>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<Icon as={FiLock} color="gray.300" />}
              />
              <Input
                ref={register}
                name="password"
                type="password"
                placeholder="Password"
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.password && errors.password.message}&nbsp;
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.passwordConfirm !== undefined}>
            <FormLabel>Confirm Password</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<Icon as={FiLock} color="gray.300" />}
              />
              <Input
                ref={register({ validate: validatePasswordConfirm })}
                name="passwordConfirm"
                type="password"
                placeholder="Confirm Password"
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.passwordConfirm && errors.passwordConfirm.message}&nbsp;
            </FormErrorMessage>
          </FormControl>
        </>
      )}
      <FormActions isLoading={isLoading} entity={user} />
    </FormContainer>
  )
}

export default UserForm
