import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { login, logout } from "redux/state/auth"
import { loadConfiguration } from "redux/state/configuration"
import { Credentials } from "services/api"

export const useAuth = () => {
  const dispatch = useDispatch()
  return {
    reLogin: useCallback(() => {
      dispatch(login({ username: "", password: "" }))
      return dispatch(loadConfiguration())
    }, [dispatch]),
    login: (credentials: Credentials) => {
      dispatch(login(credentials))
      return dispatch(loadConfiguration())
    },
    logout: () => {
      return dispatch(logout())
    },
  }
}
