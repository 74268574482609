import {
  Box,
  Flex,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react"
import { BlockEntity, EntityId } from "@jackfruit/common"
import { Sortable } from "@jackfruit/common/build/interfaces"
import ConfirmDialog from "components/ConfirmDialog"
import TableRowActions from "components/TableRowActions"
import { usePatchEntity } from "hooks/usePatchEntity"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { BsLock } from "react-icons/bs"
import { GrDrag } from "react-icons/gr"
import { app, Resources } from "services/api"
import { allBlockGroups } from "./BlockToolBox"

const overrideText = {
  title: "Override",
  content: "Are you sure to override this global block?",
}

const resetOverrideText = {
  title: "Reset data from global configuration",
  content:
    "You are about to reset the block configuration as defined in the common section",
}

interface Props {
  block: BlockEntity & Sortable
  referer: BlockEntity | null
  onDelete: () => void
  onOverride: () => void
  onSelect: (id: EntityId) => void
  isSelected: boolean
}

const BlockItem: React.FC<Props> = ({
  block,
  referer = null,
  onDelete,
  onOverride,
  onSelect,
  isSelected,
}) => {
  const blockTypeResource = `blocks-${block.type}` as Resources
  const { mutate: patch } = usePatchEntity<BlockEntity & Sortable>("blocks")
  const { mutate: patchBlockType } = usePatchEntity(blockTypeResource)
  const cancelRef = useRef<HTMLButtonElement>(null)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isReadOnly, setIsReadOnly] = useState(!!block.inherit)
  const [color, setColor] = useState("secondary")

  useEffect(() => {
    setIsReadOnly(!!block.inherit)
    setColor(!isReadOnly ? "secondary" : "gray.400")
  }, [block, isReadOnly, referer])

  const onOverrideDialog = useCallback(async () => {
    const { createdAt, updatedAt, chosen, selected, ...rest } = block
    const editedBlock = { ...rest, inherit: !isReadOnly }

    if (referer) {
      const childId = editedBlock.childId
      const childReferer = await app.service(blockTypeResource).find({
        query: {
          id: referer.childId,
        },
      })

      if (childReferer.data.length > 0) {
        const { id, blockId, createdAt, updatedAt, ...data } =
          childReferer.data[0]

        patchBlockType({ ...data, id: childId })
      }
    }

    patch(editedBlock)

    onOverride()
    onClose()
  }, [
    block,
    blockTypeResource,
    isReadOnly,
    onClose,
    onOverride,
    patch,
    patchBlockType,
    referer,
  ])

  const label = allBlockGroups
    .flatMap(group => group.blocks)
    .find(b => b.type === block.type)?.label

  return (
    <>
      <Box
        backgroundColor={isSelected ? "primary.100" : "transparent"}
        _hover={{
          backgroundColor: isSelected ? "primary.100" : "gray.100",
        }}
        borderRadius="md"
        px={2}
      >
        <Flex alignItems="center" justifyContent="space-between" p={0}>
          <Stack direction="row" alignItems="center" color={color} w="full">
            <Icon cursor="move" className="handle" as={GrDrag} boxSize={4} />
            <Flex
              flex={1}
              h="40px"
              alignItems="center"
              cursor="pointer"
              onClick={() => {
                if (!isReadOnly) {
                  onSelect(block.id)
                }
              }}
            >
              <Text fontSize="sm">{label}</Text>
            </Flex>
          </Stack>

          <Flex align="center">
            {!isReadOnly && referer && (
              <Tooltip label="Reset to global block">
                <IconButton
                  aria-label="view"
                  icon={<BsLock />}
                  size="xs"
                  rounded="full"
                  variant="ghost"
                  colorScheme="gray"
                  onClick={onOpen}
                />
              </Tooltip>
            )}

            {!!isReadOnly && (
              <Tooltip label="Override block">
                <IconButton
                  aria-label="view"
                  icon={<BsLock />}
                  size="xs"
                  rounded="full"
                  variant="ghost"
                  colorScheme="gray"
                  onClick={onOpen}
                />
              </Tooltip>
            )}

            <TableRowActions
              resource="blocks"
              entity={block}
              size="xs"
              canDelete={true}
              onDelete={onDelete}
            />
          </Flex>
        </Flex>
      </Box>

      <ConfirmDialog
        cancelRef={cancelRef}
        text={!isReadOnly ? resetOverrideText : overrideText}
        isOpen={isOpen}
        onCancel={onClose}
        onConfirm={onOverrideDialog}
      />
    </>
  )
}

export default React.memo(BlockItem)
