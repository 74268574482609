import { Box, HStack, Text } from "@chakra-ui/layout"
import React from "react"

interface Props {
  value: string
}

const ChangelogFieldColor: React.FC<Props> = ({ value }) => {
  return (
    <HStack>
      <Box bg={value} w="16px" h="16px" borderRadius="md" />
      <Text fontSize="xs">{value}</Text>
    </HStack>
  )
}

export default ChangelogFieldColor
