import { VStack } from "@chakra-ui/layout"
import { DeploymentEntity, EntityId } from "@jackfruit/common"
import AceEditorEnhanced from "components/AceEditorEnhanced"
import DeploymentTestResults from "components/DeploymentTestResults"
import { useEntity } from "hooks/useEntity"
import React from "react"

interface Props {
  deploymentId: EntityId
}

const DeploymentTestResultsContainer: React.FC<Props> = ({ deploymentId }) => {
  const { data: entity, isLoading } = useEntity<DeploymentEntity>(
    "deployments",
    deploymentId,
    {
      refetchInterval: 7000,
    },
    {
      query: {
        $select: ["id", "testResults"],
      },
    }
  )

  if (isLoading) {
    return null
  }

  return (
    <VStack alignItems="stretch" spacing={4} mt={3}>
      <DeploymentTestResults testResults={entity.testResults} width="full" />
      <AceEditorEnhanced
        height="600px"
        value={JSON.stringify(entity.testResults, undefined, 2)}
        mode="json"
        name="config"
      />
    </VStack>
  )
}

export default DeploymentTestResultsContainer
