import { Fade } from "@chakra-ui/react"
import React, { useState } from "react"
import { useDebounce } from "react-use"

interface Props {
  children: React.ReactNode
}

const ContentTransition: React.FC<Props> = ({ children }) => {
  const [isReady, setIsReady] = useState(false)
  const [shouldShow, setShouldShow] = useState(false)

  useDebounce(() => setIsReady(true), 150, [])
  useDebounce(() => setShouldShow(true), 250, [])

  return <Fade in={shouldShow}>{isReady && children}</Fade>
}

export default ContentTransition
