import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  Select,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react"
import { BlockFooterEntity } from "@jackfruit/common"
import ColorPickerInput from "forms/inputs/ColorPickerInput"
import React, { useCallback } from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { FiMinus, FiPlus } from "react-icons/fi"
import BlockFooterColumns from "./BlockFooterColumns"
import BlockFooterMegaMenuWrapper from "./BlockFooterMegaMenuWrapper"
import BlockFooterRows from "./BlockFooterRows"

const nestedPrefix = "footer"
const rowsDef: number[] = [2, 4, 1] // Number of columns per row

interface Props {
  readOnly?: boolean
  prefix?: boolean
  entity: BlockFooterEntity
}

const BlockFooterFields: React.FC<Props> = ({
  readOnly = false,
  prefix = true,
  entity,
}) => {
  const { register, control, watch } = useFormContext()

  const {
    fields: countrySelectFields,
    append: countrySelectAppendRow,
    remove: countrySelectRemoveRow,
  } = useFieldArray({
    control,
    name: "footer.countrySelectorConfiguration",
  })

  const setName = useCallback(
    (name: string) => {
      return prefix ? `${nestedPrefix}.${name}` : name
    },
    [prefix]
  )

  if (!entity) {
    return null
  }

  const footerType = watch("footer.footerType")
  const shouldShowCoutrySelector = watch("footer.showCountrySelector")

  return (
    <Flex flexDirection="column" gap={4}>
      <FormControl>
        <FormLabel>Footer Type</FormLabel>
        <Select ref={register} name="footer.footerType">
          <option value="html">Html</option>
          <option value="megaMenu">Mega Menu</option>
        </Select>
      </FormControl>
      {footerType === "html" && (
        <Tabs>
          <TabList>
            <BlockFooterRows rowsDef={rowsDef} />
          </TabList>

          <TabPanels>
            {rowsDef.map((colsLength, index) => {
              const rowIndex = index + 1
              return (
                <TabPanel key={index}>
                  <FormControl marginBottom={15}>
                    <FormLabel>Row {rowIndex} - background color</FormLabel>
                    <Controller
                      render={({ name, value, onChange }) => (
                        <ColorPickerInput
                          onChange={onChange}
                          value={value}
                          name={name}
                          placeholder="#424242"
                          disabled={readOnly}
                        />
                      )}
                      name={setName(`row${rowIndex}Bg`)}
                      register={register}
                      control={control}
                      defaultValue={
                        entity[`row${rowIndex}Bg` as keyof BlockFooterEntity]
                      }
                    />
                  </FormControl>
                  <BlockFooterColumns
                    setName={setName}
                    colsLength={colsLength}
                    rowIndex={rowIndex}
                    readOnly={readOnly}
                  />
                </TabPanel>
              )
            })}
          </TabPanels>
        </Tabs>
      )}
      {footerType === "megaMenu" && (
        <>
          <FormControl>
            <InputGroup>
              <Checkbox
                ref={register}
                name="footer.showAppButtons"
                defaultChecked={entity?.showAppButtons}
              >
                Show App Buttons
              </Checkbox>
            </InputGroup>
          </FormControl>
          <FormControl>
            <InputGroup>
              <Checkbox
                ref={register}
                name="footer.showCountrySelector"
                defaultChecked={entity?.showCountrySelector}
              >
                Show Country Selector
              </Checkbox>
            </InputGroup>
          </FormControl>

          {Boolean(shouldShowCoutrySelector) && (
            <Flex
              flexDirection="column"
              gap={4}
              p={4}
              backgroundColor="gray.50"
              borderRadius="md"
            >
              <Flex flex={1}>
                <Flex flex={1}>
                  <FormLabel>Country Name</FormLabel>
                </Flex>
                <Flex flex={1}>
                  <FormLabel>Url</FormLabel>
                </Flex>
                <Flex width="50px" />
              </Flex>
              {countrySelectFields.map((field, index) => {
                return (
                  <Flex key={field.id} gap={3} alignItems="center">
                    <Flex flex={1} flexDirection="column">
                      <Input
                        ref={register}
                        name={`footer.countrySelectorConfiguration.${index}.label`}
                        size="sm"
                        bg="white"
                        defaultValue={field.label}
                      />
                    </Flex>
                    <Flex flex={1} flexDirection="column">
                      <Input
                        ref={register}
                        name={`footer.countrySelectorConfiguration.${index}.url`}
                        size="sm"
                        bg="white"
                        defaultValue={field.url}
                      />
                    </Flex>
                    <Flex w="50px">
                      <IconButton
                        aria-label="Remove Row"
                        onClick={() => countrySelectRemoveRow(index)}
                        colorScheme="red"
                        rounded="full"
                        size="xs"
                        icon={<FiMinus />}
                      />
                    </Flex>
                  </Flex>
                )
              })}
              <Flex>
                <Button
                  aria-label="Add Row"
                  onClick={() =>
                    countrySelectAppendRow({
                      label: "",
                      url: "",
                    })
                  }
                  colorScheme="primary"
                  size="sm"
                  leftIcon={<FiPlus />}
                >
                  Add Country
                </Button>
              </Flex>
            </Flex>
          )}
          {rowsDef.map((colsLength, index) => {
            const rowIndex = index + 1
            return (
              <HStack key={rowIndex} alignItems="flex-start">
                <FormControl marginBottom={15} w="300px">
                  <FormLabel>Row {rowIndex} - background color</FormLabel>
                  <Controller
                    render={({ name, value, onChange }) => (
                      <ColorPickerInput
                        onChange={onChange}
                        value={value}
                        name={name}
                        placeholder="#424242"
                        disabled={readOnly}
                      />
                    )}
                    name={setName(`row${rowIndex}Bg`)}
                    register={register}
                    control={control}
                    defaultValue={
                      entity[`row${rowIndex}Bg` as keyof BlockFooterEntity]
                    }
                  />
                </FormControl>
                <FormControl marginBottom={15} w="300px">
                  <FormLabel>Row {rowIndex} - text color</FormLabel>
                  <Controller
                    render={({ name, value, onChange }) => (
                      <ColorPickerInput
                        onChange={onChange}
                        value={value}
                        name={name}
                        placeholder="#424242"
                        disabled={readOnly}
                      />
                    )}
                    name={setName(`row${rowIndex}TextColor`)}
                    register={register}
                    control={control}
                    defaultValue={
                      entity[
                        `row${rowIndex}TextColor` as keyof BlockFooterEntity
                      ]
                    }
                  />
                </FormControl>
              </HStack>
            )
          })}
          <BlockFooterMegaMenuWrapper blockFooter={entity!} />
        </>
      )}
    </Flex>
  )
}

export default BlockFooterFields
