import React from "react"
import { Heading } from "@chakra-ui/react"

interface Props {
  children: any
}

const FormSectionHeader: React.FC<Props> = ({ children }) => {
  return (
    <Heading as="h3" fontSize="lg" borderBottomWidth={1} pb={3}>
      {children}
    </Heading>
  )
}

export default FormSectionHeader
